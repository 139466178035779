import React, { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import { useSelector } from 'react-redux'
import Popup from 'reactjs-popup'

import { Table } from '../../../components'
import {
  onSortCurrency,
  onSortDate,
  onSortDocument
} from '../../../components/Table/sort'
import { mapWithRelationship } from '../../../lib/detectMatch'
import { selectMinData } from '../../../redux/selectors/dadosBasicos'
import { selectRelateds } from '../../../redux/selectors/relateds'
import {
  formatCurrency,
  formatDocument,
  hashObject,
  onlyNumbers
} from '../../../utils'

const { SearchBar } = Search

export default function Empresas ({ isCPF, dadosBasicos, onChange }) {
  const { targetCase } = useSelector(selectMinData)
  const [filiais, setFiliais] = useState({
    data: [],
    open: false
  })
  const relateds = useSelector(selectRelateds)

  const [selectedTable, setSelectedTable] = useState('correlacionadas')

  useEffect(() => {
    if (onChange) {
      onChange(selectedTable)
    }
  }, [selectedTable, onChange])

  const correlacionadas = dadosBasicos.data?.empresas?.correlacionadas || []
  const coligadas = dadosBasicos.data?.empresas?.coligadas || []
  const participacoes = dadosBasicos.data?.empresas?.participacoes || []
  const familiar = dadosBasicos.data?.empresas?.familiar || []
  const coligadasFamiliar = dadosBasicos.data?.empresas?.coligadasFamiliar || []
  const socios = dadosBasicos.data?.empresas?.socios || []

  if (dadosBasicos.error) {
    return <div>Erro no carregamento dos dados</div>
  }

  if (
    coligadas.length === 0 &&
    correlacionadas.length === 0 &&
    participacoes.length === 0 &&
    familiar.length === 0 &&
    coligadasFamiliar.length === 0 &&
    socios.length === 0
  ) {
    return <div />
  }

  const tableDataMap = {
    coligadas: mapWithRelationship({
      array: coligadas || [],
      documentKey: 'cnpj',
      nameKey: 'razaoSocial',
      relateds
    }),
    correlacionadas: mapWithRelationship({
      array: correlacionadas || [],
      documentKey: 'personCpf',
      nameKey: 'personName',
      relateds
    }),
    participacoes: participacoes,
    familiar: mapWithRelationship({
      array: familiar || [],
      documentKey: 'documentoPessoaLigacao',
      nameKey: 'nomePessoaLigacao',
      relateds
    }),
    coligadaFamiliar: mapWithRelationship({
      array: coligadasFamiliar || [],
      documentKey: 'documentoPessoaLigacao',
      nameKey: 'nomePessoaLigacao',
      relateds
    }),
    socios: socios
  }
  const columnsMap = {
    coligadas: [
      {
        dataField: 'razaoSocial',
        text: 'Razão Social',
        sort: true
      },
      {
        dataField: 'cnpj',
        text: 'CNPJ',
        sortFunc: onSortDocument,
        sort: true,
        searchable: false
      },
      {
        dataField: 'situacaoCadastral',
        text: 'Situação Cadastral',
        sort: true,
        searchable: false
      },
      {
        dataField: 'capitalSocial',
        text: 'Capital Social',
        sortFunc: onSortCurrency,
        sort: true,
        searchable: false
      },
      {
        dataField: 'uf',
        text: 'UF',
        sort: true
      },
      {
        dataField: 'municipio',
        text: 'Município',
        sort: true
      },
      {
        dataField: 'dataInicioAtividade',
        text: 'Data de Início das Atividades',
        sortFunc: onSortDate,
        sort: true,
        searchable: false
      },
      {
        dataField: 'dataEntradaSociedade',
        text: 'Data de Entrada na Sociedade',
        sortFunc: onSortDate,
        sort: true,
        searchable: false
      },
      {
        dataField: 'descricaoCnaeFiscalPrincipal',
        text: 'Ramo de Atividade',
        sort: true,
        searchable: false
      },
      {
        dataField: 'grau',
        text: 'Grau de ligação',
        sort: true
      },
      {
        dataField: 'pessoaLigacao',
        text: 'Pessoa de ligação',
        sort: true
      },
      {
        dataField: 'rawDocumento',
        hidden: true
      }
    ],
    correlacionadas: [
      {
        dataField: 'razaoSocial',
        text: 'Razão Social',
        sort: true
      },
      {
        dataField: 'cnpj',
        text: 'CNPJ',
        sortFunc: onSortDocument,
        searchable: false,
        sort: true
      },
      {
        dataField: 'situacaoCadastral',
        text: 'Situação Cadastral',
        sort: true,
        searchable: false
      },
      {
        dataField: 'uf',
        text: 'UF',
        sort: true
      },
      {
        dataField: 'municipio',
        text: 'Município',
        sort: true
      },
      {
        dataField: 'dataInicioAtividade',
        text: 'Data de Início das Atividades',
        sortFunc: onSortDate,
        sort: true,
        searchable: false
      },
      {
        dataField: 'descricaoCnaeFiscalPrincipal',
        text: 'Ramo de Atividade',
        sort: true,
        searchable: false
      },
      {
        dataField: 'nomePessoaLigacao',
        text: 'Nome pessoa',
        sort: true
      },
      {
        dataField: 'grauLigacao',
        text: 'Grau de ligação',
        sort: true
      },
      {
        dataField: 'rawDocumento',
        hidden: true
      }
    ],
    participacoes: [
      {
        dataField: 'razaoSocial',
        text: 'Razão Social',
        sort: true
      },
      {
        dataField: 'cnpj',
        text: 'CNPJ',
        sort: true,
        sortFunc: onSortDocument,
        searchable: false
      },
      {
        dataField: 'situacaoCadastral',
        text: 'Situação Cadastral',
        sort: true,
        searchable: false
      },
      {
        dataField: 'uf',
        text: 'UF',
        sort: true,
        searchable: false
      },
      {
        dataField: 'descricaoMunicipio',
        text: 'Município',
        sort: true,
        searchable: false
      },
      {
        dataField: 'dataInicioAtividade',
        text: 'Data de Início das Atividades',
        sortFunc: onSortDate,
        sort: true,
        searchable: false
      },
      {
        dataField: 'descricaoCnaeFiscalPrincipal',
        text: 'Ramo de Atividade',
        sort: true,
        searchable: false
      },
      {
        dataField: 'qualificacaoSocio',
        text: 'Qualificação',
        sort: true,
        searchable: false
      },
      {
        dataField: 'filiaisAtivas',
        text: 'Filiais ativas',
        sort: true,
        searchable: true
      },
      {
        dataField: 'rawDocumento',
        hidden: true
      }
    ],
    familiar: [
      {
        dataField: 'razaoSocial',
        text: 'Razão Social',
        sort: true
      },
      {
        dataField: 'cnpj',
        text: 'CNPJ',
        sort: true,
        sortFunc: onSortDocument,
        searchable: false
      },
      {
        dataField: 'situacaoCadastral',
        text: 'Situação Cadastral',
        sort: true,
        searchable: false
      },
      {
        dataField: 'uf',
        text: 'UF',
        sort: true,
        searchable: false
      },
      {
        dataField: 'descricaoMunicipio',
        text: 'Município',
        sort: true,
        searchable: false
      },
      {
        dataField: 'dataInicioAtividade',
        text: 'Data de Início das Atividades',
        sortFunc: onSortDate,
        sort: true,
        searchable: false
      },
      {
        dataField: 'descricaoCnaeFiscalPrincipal',
        text: 'Ramo de Atividade',
        sort: true,
        searchable: false
      },
      {
        dataField: 'nomePessoaLigacao',
        text: 'Nome familiar',
        sort: true
      },
      {
        dataField: 'grauLigacao',
        text: 'Grau de ligação',
        sort: true
      },
      {
        dataField: 'qualificacaoSocio',
        text: 'Qualificação',
        sort: true
      },
      {
        dataField: 'filiaisAtivas',
        text: 'Filiais ativas',
        sort: true,
        searchable: true
      },
      {
        dataField: 'rawDocumento',
        hidden: true
      }
    ],
    coligadaFamiliar: [
      {
        dataField: 'razaoSocial',
        text: 'Razão Social',
        sort: true
      },
      {
        dataField: 'cnpj',
        text: 'CNPJ',
        sort: true,
        sortFunc: onSortDocument,
        searchable: false
      },
      {
        dataField: 'situacaoCadastral',
        text: 'Situação Cadastral',
        sort: true,
        searchable: false
      },
      {
        dataField: 'capitalSocial',
        text: 'Capital Social',
        sortFunc: onSortCurrency,
        sort: true,
        searchable: false
      },
      {
        dataField: 'uf',
        text: 'UF',
        sort: true,
        searchable: false
      },
      {
        dataField: 'descricaoMunicipio',
        text: 'Município',
        sort: true,
        searchable: false
      },
      {
        dataField: 'dataInicioAtividade',
        text: 'Data de Início das Atividades',
        sortFunc: onSortDate,
        sort: true,
        searchable: false
      },
      {
        dataField: 'dataEntradaSociedade',
        text: 'Data de Entrada na Sociedade',
        sortFunc: onSortDate,
        sort: true,
        searchable: false
      },
      {
        dataField: 'descricaoCnaeFiscalPrincipal',
        text: 'Ramo de Atividade',
        sort: true,
        searchable: false
      },
      {
        dataField: 'nomePessoaLigacao',
        text: 'Pessoa de ligação',
        sort: true
      },
      {
        dataField: 'grauLigacao',
        text: 'Grau de ligação',
        sort: true
      },
      {
        dataField: 'qualificacaoSocio',
        text: 'Qualificação',
        sort: true
      },
      {
        dataField: 'rawDocumento',
        hidden: true
      }
    ],
    socios: [
      {
        dataField: 'razaoSocial',
        text: 'Razão Social',
        sort: true
      },
      {
        dataField: 'cnpj',
        text: 'CNPJ',
        sortFunc: onSortDocument,
        sort: true,
        searchable: false
      },
      {
        dataField: 'situacaoCadastral',
        text: 'Situação Cadastral',
        sort: true,
        searchable: false
      },
      {
        dataField: 'uf',
        text: 'UF',
        sort: true,
        searchable: false
      },
      {
        dataField: 'descricaoMunicipio',
        text: 'Município',
        sort: true,
        searchable: false
      },
      {
        dataField: 'dataInicioAtividade',
        text: 'Data de Início das Atividades',
        sortFunc: onSortDate,
        sort: true,
        searchable: false
      },
      {
        dataField: 'descricaoCnaeFiscalPrincipal',
        text: 'Ramo de Atividade',
        sort: true,
        searchable: false
      },
      {
        dataField: 'pessoaLigacao',
        text: 'Pessoa de ligação',
        sort: true
      },
      {
        dataField: 'filiaisAtivas',
        text: 'Filiais ativas',
        sort: true,
        searchable: true
      },
      {
        dataField: 'rawDocumento',
        hidden: true
      }
    ]
  }

  const tableData = tableDataMap[selectedTable]
  const columns = columnsMap[selectedTable].map(item => ({
    ...item,
    align: 'center',
    headerAlign: 'center'
  }))

  const mappedData = (tableData || []).map(item => {
    const id = hashObject(item)
    const filiais = item.filiaisAtivas || []
    if (selectedTable === 'coligadas') {
      return {
        id,
        rawDocumento: formatDocument(item.cnpj),
        cnpj: item.cnpj ? (
          <a
            href={`?documento=${onlyNumbers(item.cnpj)}&casos=${targetCase.join(
              ';'
            )}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            {formatDocument(item.cnpj)}
          </a>
        ) : (
          '-'
        ),
        municipio: item.descricaoMunicipio,
        razaoSocial: item.razaoSocial,
        situacaoCadastral: item.situacaoCadastral,
        cnaeFiscalPrincipal: item.cnaeFiscalPrincipal,
        descricaoCnaeFiscalPrincipal: item.descricaoCnaeFiscalPrincipal,
        dataInicioAtividade: resolveDate(item.dataInicioAtividade),
        dataEntradaSociedade: resolveDate(item.dataEntradaSociedade),
        capitalSocial: formatCurrency(parseFloat(item.capitalSocial)),
        rawCapitalSocial: item.capitalSocial,
        uf: item.uf,
        pessoaLigacao: `${item.nomePessoaLigacao} (${item.documentoPessoaLigacao})`,
        grau: item.relationship
      }
    } else if (selectedTable === 'correlacionadas') {
      return {
        id,
        rawDocumento: formatDocument(item.cnpj),
        cnpj: item.cnpj ? (
          <a
            href={`?documento=${onlyNumbers(item.cnpj)}&casos=${targetCase.join(
              ';'
            )}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            {formatDocument(item.cnpj)}
          </a>
        ) : (
          '-'
        ),
        municipio: item.municipio,
        situacaoCadastral: item.situacaoCadastral,
        cnaeFiscalPrincipal: item.cnaeFiscalPrincipal,
        descricaoCnaeFiscalPrincipal: item.descricaoCnaeFiscalPrincipal,
        dataInicioAtividade: resolveDate(item.dataInicioAtividade),
        uf: item.uf,
        razaoSocial: item.razaoSocial,
        nomePessoaLigacao: item.personName,
        grauLigacao: item.relationship
      }
    } else if (selectedTable === 'familiar') {
      return {
        id,
        rawDocumento: formatDocument(item.cnpj),
        cnpj: item.cnpj ? (
          <a
            href={`?documento=${onlyNumbers(item.cnpj)}&casos=${targetCase.join(
              ';'
            )}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            {formatDocument(item.cnpj)}
          </a>
        ) : (
          '-'
        ),
        razaoSocial: item.razaoSocial,
        situacaoCadastral: item.situacaoCadastral,
        uf: item.uf,
        filiaisAtivas:
          filiais.length === 0 ? null : (
            <button
              className='btn btn-primary'
              disabled={filiais.length === 0}
              onClick={() =>
                setFiliais({
                  data: filiais,
                  open: true
                })
              }
            >
              Ver {filiais.length}
            </button>
          ),
        descricaoMunicipio: item.descricaoMunicipio,
        dataInicioAtividade: resolveDate(item.dataInicioAtividade),
        descricaoCnaeFiscalPrincipal: item.descricaoCnaeFiscalPrincipal,
        nomePessoaLigacao: item.documentoPessoaLigacao ? (
          <a
            href={`?documento=${onlyNumbers(
              item.documentoPessoaLigacao
            )}&casos=${targetCase.join(';')}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            {item.nomePessoaLigacao}
          </a>
        ) : (
          `${item.nomePessoaLigacao}`
        ),
        grauLigacao: item.relationship,
        qualificacaoSocio: item.qualificacaoSocio
      }
    } else if (selectedTable === 'coligadaFamiliar') {
      return {
        id,
        rawDocumento: formatDocument(item.cnpj),
        cnpj: item.cnpj ? (
          <a
            href={`?documento=${onlyNumbers(item.cnpj)}&casos=${targetCase.join(
              ';'
            )}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            {formatDocument(item.cnpj)}
          </a>
        ) : (
          '-'
        ),
        razaoSocial: item.razaoSocial,
        situacaoCadastral: item.situacaoCadastral,
        uf: item.uf,
        dataEntradaSociedade: resolveDate(item.dataEntradaSociedade),
        capitalSocial: formatCurrency(parseFloat(item.capitalSocial)),
        descricaoMunicipio: item.descricaoMunicipio,
        dataInicioAtividade: resolveDate(item.dataInicioAtividade),
        descricaoCnaeFiscalPrincipal: item.descricaoCnaeFiscalPrincipal,
        nomePessoaLigacao: item.documentoPessoaLigacao ? (
          <a
            href={`?documento=${onlyNumbers(
              item.documentoPessoaLigacao
            )}&casos=${targetCase.join(';')}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            {item.nomePessoaLigacao}
          </a>
        ) : (
          `${item.nomePessoaLigacao}`
        ),
        grauLigacao: item.relationship,
        qualificacaoSocio: item.qualificacaoSocio
      }
    } else if (selectedTable === 'socios') {
      return {
        id,
        rawDocumento: formatDocument(item.cnpj),
        cnpj: item.cnpj ? (
          <a
            href={`?documento=${onlyNumbers(item.cnpj)}&casos=${targetCase.join(
              ';'
            )}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            {formatDocument(item.cnpj)}
          </a>
        ) : (
          '-'
        ),
        filiaisAtivas:
          filiais.length === 0 ? null : (
            <button
              className='btn btn-primary'
              disabled={filiais.length === 0}
              onClick={() =>
                setFiliais({
                  data: filiais,
                  open: true
                })
              }
            >
              Ver {filiais.length}
            </button>
          ),
        razaoSocial: item.razaoSocial,
        situacaoCadastral: item.situacaoCadastral,
        uf: item.uf,
        descricaoMunicipio: item.descricaoMunicipio,
        dataInicioAtividade: resolveDate(item.dataInicioAtividade),
        descricaoCnaeFiscalPrincipal: item.descricaoCnaeFiscalPrincipal,
        pessoaLigacao: item.nomePessoaLigacao
      }
    }

    return {
      id,
      rawDocumento: formatDocument(item.cnpj),
      cnpj: item.cnpj ? (
        <a
          href={`?documento=${onlyNumbers(item.cnpj)}&casos=${targetCase.join(
            ';'
          )}`}
          target='_blank'
          rel='noopener noreferrer'
        >
          {formatDocument(item.cnpj)}
        </a>
      ) : (
        '-'
      ),
      filiaisAtivas:
        filiais.length === 0 ? null : (
          <button
            className='btn btn-primary'
            disabled={filiais.length === 0}
            onClick={() =>
              setFiliais({
                data: filiais,
                open: true
              })
            }
          >
            Ver {filiais.length}
          </button>
        ),
      razaoSocial: item.razaoSocial,
      situacaoCadastral: item.situacaoCadastral,
      uf: item.uf,
      descricaoMunicipio: item.descricaoMunicipio,
      dataInicioAtividade: resolveDate(item.dataInicioAtividade),
      descricaoCnaeFiscalPrincipal: item.descricaoCnaeFiscalPrincipal,
      qualificacaoSocio: item.qualificacaoSocio
    }
  })

  return (
    <>
      <PopupFiliais
        filiais={filiais.data}
        open={filiais.open}
        onClose={() => setFiliais({ data: [], open: false })}
      />
      <ToolkitProvider keyField='id' data={mappedData} columns={columns} search>
        {props => (
          <>
            <div className='row mb-2'>
              <div className='col-12 col-lg-6 d-flex flex-row gap-3 mb-3'>
                <Form.Check
                  type='radio'
                  label='Participações'
                  onChange={() => setSelectedTable('participacoes')}
                  checked={selectedTable === 'participacoes'}
                  disabled={dadosBasicos.error || isCPF === false}
                />

                <Form.Check
                  type='radio'
                  label='Familiar'
                  onChange={() => setSelectedTable('familiar')}
                  checked={selectedTable === 'familiar'}
                  disabled={dadosBasicos.error || isCPF === false}
                />

                <Form.Check
                  type='radio'
                  label='Correlacionadas'
                  onChange={() => setSelectedTable('correlacionadas')}
                  checked={selectedTable === 'correlacionadas'}
                  disabled={dadosBasicos.error}
                />

                <Form.Check
                  type='radio'
                  label='Coligadas'
                  onChange={() => setSelectedTable('coligadas')}
                  checked={selectedTable === 'coligadas'}
                  disabled={dadosBasicos.error}
                />

                <Form.Check
                  type='radio'
                  label='Coligadas familiares'
                  onChange={() => setSelectedTable('coligadaFamiliar')}
                  checked={selectedTable === 'coligadaFamiliar'}
                  disabled={dadosBasicos.error || isCPF === false}
                />

                <Form.Check
                  type='radio'
                  label='Sócios'
                  onChange={() => setSelectedTable('socios')}
                  checked={selectedTable === 'socios'}
                  disabled={dadosBasicos.error || isCPF}
                />
              </div>
              <div className='col-12 col-lg-6 text-lg-right'>
                <SearchBar {...props.searchProps} placeholder='Pesquisar' />
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <Table {...props.baseProps} enablePagination />
              </div>
            </div>
          </>
        )}
      </ToolkitProvider>
    </>
  )
}

const resolveDate = date => {
  if (!date) {
    return '-'
  }

  if (date.includes('T')) {
    return date
      .split('T')[0]
      .split('-')
      .reverse()
      .join('/')
  }

  return date.replace('-', '/')
}

const PopupFiliais = ({ filiais, open, onClose }) => {
  return (
    <Popup
      closeOnDocumentClick
      modal
      nested
      contentStyle={{ maxHeight: '90vh', width: '95%', overflowY: 'scroll' }}
      open={open}
      onClose={onClose}
    >
      <div className='mx-2 my-2'>
        <div style={{ width: '100%', display: 'flex' }} className='mb-3'>
          <span style={{ flex: 1 }}>Filiais ativas</span>
          <button
            style={{
              cursor: 'pointer',
              padding: '2px 5px',
              lineHeight: '20px',
              right: '-10px',
              top: '-10px',
              fontSize: '24px',
              background: '#ffffff',
              borderRadius: '18px',
              border: '1px solid #cfcece'
            }}
            onClick={onClose}
          >
            X
          </button>
        </div>
        <table className='table table-sm table-striped col-12'>
          <thead>
            <tr>
              <th scope='col'>CNPJ</th>
              <th scope='col'>Município</th>
              <th scope='col'>UF</th>
            </tr>
          </thead>
          <tbody>
            {(filiais || []).map((item, key) => {
              const id = hashObject({ item, key })
              return (
                <tr key={id}>
                  <td>{formatDocument(item.documento)}</td>
                  <td>{item.municipio}</td>
                  <td>{item.uf}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </Popup>
  )
}

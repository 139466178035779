import React from 'react'
import { useHistory } from 'react-router-dom'

import '../../assets/sass/cards.scss'
import { getScope, getUserId, useTokenValidation } from '../../utils'

import { SubHeader } from '../../components'

import { TOKEN_VALIDATION_INTERVAL } from '../../config/consts'
import Main from '../../components/Main'
import { Container, Item } from './components'

import PesquisaDocumento from '../../assets/img/icons/pesquisa_documento.svg'
import Historico from '../../assets/img/icons/historico.svg'
import Matricula from '../../assets/img/icons/matricula.png'

const Home = () => {
  useTokenValidation(TOKEN_VALIDATION_INTERVAL)
  const history = useHistory()

  return (
    <Main>
      <SubHeader hideSearch hideTitle />
      <div className='d-flex flex-row align-items-center'>
        <Container>
          <div className='ml-4 row'>
            <div className='mt-2 col-12 text-primary h5'>
              Bem-vindo ao Dossiê Jive 2.0
            </div>
            <div className='col-12 text-primary h5'>
              Por favor, escolha uma das opções abaixo:
            </div>
          </div>
          <div className='row mt-4 pt-4'>
            <div className='col-4 d-flex align-items-center justify-content-center'>
              <Item onClick={() => history.push('/pesquisa')} className='ml-5'>
                <div className='text-primary h5'>Pesquisa de documentos</div>
                <img
                  className='mt-4'
                  alt='Pesquisa de documento'
                  src={PesquisaDocumento}
                />
              </Item>
            </div>
            <div className='col-4 d-flex align-items-center justify-content-center'>
              <Item onClick={() => history.push('/historico')} className='mr-5'>
                <div className='text-primary h5'>Histórico de pesquisas</div>
                <img
                  className='mt-4'
                  alt='Histórico de pesquisa'
                  src={Historico}
                />
              </Item>
            </div>
            <div className='col-4 d-flex align-items-center justify-content-center'>
              <Item
                onClick={() => {
                  const uuid = getUserId()
                  const scope = getScope()
                  window.open(
                    scope === 'jive' ? `https://smart-assistant-staging-jive.indrema.com.br/?uuid=${uuid}` : 'https://smart-assistant-staging-jive.indrema.com.br',
                    '_blank'
                  )
                }}
                className='mr-5'
              >
                <div className='text-primary text-center h5'>Matrícula</div>
                <img
                  className='mt-4'
                  alt='Matrícula'
                  src={Matricula}
                  width={200}
                />
              </Item>
            </div>
          </div>
        </Container>
      </div>
    </Main>
  )
}

export default Home

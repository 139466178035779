import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { selectMinData } from '../../../redux/selectors/dadosBasicos'

import { fetchDadosBasicosAnalyticsFromS3 } from '../../../lib/s3Theme'
import { formatDecimal, isPending } from '../../../utils'

import { JiveWindow, Loading } from '../../../components'
import { Box, CategoriesBox, PercentageListBox, TotalBox } from './Boxes'

const INITIAL_DATA_STATE = {
  beneficiarios: {
    total: 0,
    categories: []
  },
  pessoasFisicasRelacionadas: {
    total: 0,
    categories: []
  },
  empresas: {
    total: 0,
    categories: []
  },
  empresasCorrelacionadas: {
    total: 0,
    items: []
  },
  funcionarios: {
    total: 0,
    years: []
  },
  exfuncionarios: {
    total: 0,
    years: []
  },
  socios: {
    total: 0,
    types: []
  },
  status: {
    beneficiarios: 'PENDING',
    pessoasFisicasRelacionadas: 'PENDING',
    empresas: 'PENDING',
    empresasCorrelacionadas: 'PENDING',
    funcionarios: 'PENDING',
    exfuncionarios: 'PENDING',
    socios: 'PENDING'
  }
}

export default function DadosBasicosQuantitativos () {
  const { targetDocument } = useSelector(selectMinData)
  const dadosBasicosTheme = useSelector(
    state => state.analyticsTheme.dadosBasicos
  )

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({
    data: INITIAL_DATA_STATE,
    loaded: false,
    error: false
  })

  useEffect(() => {
    const pending = isPending(dadosBasicosTheme)
    if (pending) {
      return
    }

    setLoading(true)

    fetchDadosBasicosAnalyticsFromS3({
      payload: dadosBasicosTheme
    })
      .then(dadosBasicos => {
        setData(dadosBasicos)
      })
      .catch(error => {
        toast.error(error.message)
        console.error(error)
        setData({
          data: {
            ...INITIAL_DATA_STATE,
            status: {
              beneficiarios: 'FAILED',
              pessoasFisicasRelacionadas: 'FAILED',
              empresas: 'FAILED',
              empresasCorrelacionadas: 'FAILED',
              funcionarios: 'FAILED',
              exfuncionarios: 'FAILED',
              socios: 'FAILED'
            }
          },
          loaded: false,
          error: true
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }, [dadosBasicosTheme])

  const isStepPending = targetDocument && isPending(dadosBasicosTheme)
  const name = 'quantitativos.dadosBasicos'

  if (loading || isStepPending) {
    return <Loading />
  }

  return (
    <JiveWindow title='Dados Básicos' name={name} showNote defaultOpen>
      <div className='container-fluid gap-2'>
        <div className='row'>
          <div className='col-4'>
            <div className='row'>
              <BeneficiariosBox
                error={data.error}
                data={data.data.beneficiarios}
                status={data.data.status.beneficiarios}
              />
              <PessoasFisicasRelacionadasBox
                error={data.error}
                data={data.data.pessoasFisicasRelacionadas}
                status={data.data.status.pessoasFisicasRelacionadas}
              />
              <EmpresasBox
                error={data.error}
                data={data.data.empresas}
                status={data.data.status.empresas}
              />
              <EmpresasCorrelacionadasBox
                error={data.error}
                data={data.data.empresasCorrelacionadas}
                status={data.data.status.empresasCorrelacionadas}
              />
            </div>
          </div>
          <div className='col-4'>
            <div className='row'>
              <FuncionariosBox
                error={data.error}
                data={data.data.funcionarios}
                status={data.data.status.funcionarios}
              />
              <ExFuncionariosBox
                error={data.error}
                data={data.data.exfuncionarios}
                status={data.data.status.exfuncionarios}
              />
            </div>
          </div>
          <div className='col-4'>
            <div className='row'>
              <SociosBox
                error={data.error}
                data={data.data.socios}
                status={data.data.status.socios}
              />
            </div>
          </div>
        </div>
      </div>
    </JiveWindow>
  )
}

const BeneficiariosBox = ({ error, data, status }) => {
  const title = 'Beneficiários'

  if (error || status === 'FAILED') {
    return (
      <Box title={title}>
        <div className='row'>
          <div>Erro no carregamento dos dados</div>
        </div>
      </Box>
    )
  }

  if (status === 'SKIPPED') {
    return (
      <Box title={title}>
        <div className='row'>
          <div>Dados não carregados para este tema</div>
        </div>
      </Box>
    )
  }

  const { total, categories } = data
  const mappedCategories = categories.map(item => ({
    name: item.category.replace(/grau_(\d+)/, '$1º Grau'),
    total: item.total,
    percentage: item.percentage
  }))

  const formattedTotal = formatDecimal(total, null, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  })

  return (
    <Box title={title}>
      <div className='row'>
        <TotalBox value={formattedTotal} />
      </div>
      <div className='row'>
        <PercentageListBox items={mappedCategories} />
      </div>
    </Box>
  )
}

const PessoasFisicasRelacionadasBox = ({ error, data, status }) => {
  const title = 'Pessoas físicas relacionadas'

  if (error || status === 'FAILED') {
    return (
      <Box title={title}>
        <div className='row'>
          <div>Erro no carregamento dos dados</div>
        </div>
      </Box>
    )
  }

  if (status === 'SKIPPED') {
    return (
      <Box title={title}>
        <div className='row'>
          <div>Dados não carregados para este tema</div>
        </div>
      </Box>
    )
  }

  const { total, categories } = data
  const mappedCategories = categories.map(item => ({
    name: item.category,
    total: item.total,
    percentage: item.percentage
  }))

  const formattedTotal = formatDecimal(total, null, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  })

  return (
    <Box title={title}>
      <div className='row'>
        <TotalBox value={formattedTotal} />
      </div>
      <div className='row'>
        <PercentageListBox items={mappedCategories} />
      </div>
    </Box>
  )
}

const EmpresasBox = ({ error, data, status }) => {
  const title = 'Empresas'

  if (error || status === 'FAILED') {
    return (
      <Box title={title}>
        <div className='row'>
          <div>Erro no carregamento dos dados</div>
        </div>
      </Box>
    )
  }

  if (status === 'SKIPPED') {
    return (
      <Box title={title}>
        <div className='row'>
          <div>Dados não carregados para este tema</div>
        </div>
      </Box>
    )
  }

  const labelDict = {
    familiar: 'Familiar',
    socios: 'Sócios',
    correlacionadas: 'Correlacionadas',
    coligadas: 'Coligadas'
  }
  const { total, categories } = data
  const mappedCategories = categories.map(item => ({
    name: labelDict[item.category] || item.category,
    total: item.total,
    percentage: item.percentage
  }))

  const formattedTotal = formatDecimal(total, null, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  })

  return (
    <Box title={title}>
      <div className='row'>
        <TotalBox value={formattedTotal} />
      </div>
      <div className='row'>
        <CategoriesBox items={mappedCategories} />
      </div>
      <div className='row'>
        <PercentageListBox items={mappedCategories} />
      </div>
    </Box>
  )
}

const EmpresasCorrelacionadasBox = ({ error, data, status }) => {
  const title = 'Empresas Correlacionadas'

  if (error || status === 'FAILED') {
    return (
      <Box title={title}>
        <div className='row'>
          <div>Erro no carregamento dos dados</div>
        </div>
      </Box>
    )
  }

  if (status === 'SKIPPED') {
    return (
      <Box title={title}>
        <div className='row'>
          <div>Dados não carregados para este tema</div>
        </div>
      </Box>
    )
  }

  const { total, items } = data
  const mappedItems = items.map(item => ({
    name: item.name,
    total: item.total,
    percentage: item.percentage
  }))

  const formattedTotal = formatDecimal(total, null, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  })

  return (
    <Box title={title}>
      <div className='row'>
        <TotalBox value={formattedTotal} />
      </div>
      <div className='row'>
        <PercentageListBox items={mappedItems} showTotal />
      </div>
    </Box>
  )
}

const FuncionariosBox = ({ error, data, status }) => {
  const title = 'Funcionários'

  if (error || status === 'FAILED') {
    return (
      <Box title={title}>
        <div className='row'>
          <div>Erro no carregamento dos dados</div>
        </div>
      </Box>
    )
  }

  if (status === 'SKIPPED') {
    return (
      <Box title={title}>
        <div className='row'>
          <div>Dados não carregados para este tema</div>
        </div>
      </Box>
    )
  }

  const { total, years } = data
  const mappedYears = years
    .sort((a, b) => b.year - a.year)
    .map(item => ({
      name: item.year,
      total: item.total,
      percentage: item.percentage
    }))

  const formattedTotal = formatDecimal(total, null, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  })

  return (
    <Box title={title}>
      <div className='row'>
        <TotalBox value={formattedTotal} />
      </div>
      <div className='row'>
        <PercentageListBox showTotal items={mappedYears} />
      </div>
    </Box>
  )
}

const ExFuncionariosBox = ({ error, data, status }) => {
  const title = 'Ex-funcionários'

  if (error || status === 'FAILED') {
    return (
      <Box title={title}>
        <div className='row'>
          <div>Erro no carregamento dos dados</div>
        </div>
      </Box>
    )
  }

  if (status === 'SKIPPED') {
    return (
      <Box title={title}>
        <div className='row'>
          <div>Dados não carregados para este tema</div>
        </div>
      </Box>
    )
  }

  const { total, years } = data
  const mappedYears = years
    .sort((a, b) => b.year - a.year)
    .map(item => ({
      name: item.year,
      total: item.total,
      percentage: item.percentage
    }))

  const formattedTotal = formatDecimal(total, null, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  })

  return (
    <Box title={title}>
      <div className='row'>
        <TotalBox value={formattedTotal} />
      </div>
      <div className='row'>
        <PercentageListBox items={mappedYears} />
      </div>
    </Box>
  )
}

const SociosBox = ({ error, data, status }) => {
  const title = 'Sócios'

  if (error || status === 'FAILED') {
    return (
      <Box title={title}>
        <div className='row'>
          <div>Erro no carregamento dos dados</div>
        </div>
      </Box>
    )
  }

  if (status === 'SKIPPED') {
    return (
      <Box title={title}>
        <div className='row'>
          <div>Dados não carregados para este tema</div>
        </div>
      </Box>
    )
  }

  const { total, types } = data
  const mappedSocios = types
    .sort((a, b) => a.type.localeCompare(b.type))
    .map(item => ({
      name: item.type,
      percentage: item.percentage
    }))

  const formattedTotal = formatDecimal(total, null, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  })

  return (
    <Box title={title}>
      <div className='row'>
        <TotalBox value={formattedTotal} />
      </div>
      <div className='row'>
        <PercentageListBox items={mappedSocios} />
      </div>
    </Box>
  )
}

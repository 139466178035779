import { useState } from 'react'

import {
  DBJus,
  DBJusTable,
  JiveWindow,
  JuridicoToolbar
} from '../../../components'

const VISUALIZATION_TYPES = {
  TABLE: 'table',
  CARD: 'cards'
}

export default function Processos ({
  data,
  onSelectProcesso,
  scores,
  probableVinculados
}) {
  const [visualization, setVisualization] = useState(VISUALIZATION_TYPES.TABLE)
  const [showPartes, setShowPartes] = useState(true)
  const [showScores, setShowScores] = useState(true)

  return (
    <JiveWindow title='Processos' defaultOpen>
      <div className='row'>
        <JuridicoToolbar
          visualization={visualization}
          showPartes={showPartes}
          onChangeVisualization={newVisualization =>
            setVisualization(newVisualization)
          }
          data={data.map(processo => {
            const processosVinculados =
              processo.nupsVinculados ||
              []
                .map(item => probableVinculados.find(p => p.nup === item))
                .filter(Boolean)
                .map(i => i.nup)

            return {
              ...processo,
              'processos vinculados': processosVinculados.join(', ')
            }
          })}
          onChangeShowPartes={newPartes => setShowPartes(newPartes)}
          showScores={showScores}
          onChangeShowScores={newShowScores => setShowScores(newShowScores)}
          showCsvButton
        />
      </div>
      <div className='row'>
        {visualization === VISUALIZATION_TYPES.CARD &&
          data?.length > 0 &&
          data.map((processo, key) => {
            const nup = processo.nup.replace(/\D/g, '')
            const processosVinculados =
              processo.nupsVinculados ||
              []
                .map(item => probableVinculados.find(p => p.nup === item))
                .filter(Boolean)
            return (
              <DBJus
                data={processo}
                key={`${nup}-${key}`}
                showPartes={showPartes}
                showScores={showScores}
                processosVinculados={processosVinculados}
                onClick={onSelectProcesso}
              />
            )
          })}
        {visualization === VISUALIZATION_TYPES.TABLE && (
          <DBJusTable
            data={data}
            clickItemProcesso={onSelectProcesso}
            probableVinculados={probableVinculados}
            scores={scores}
            showScores={showScores}
            showPartes={showPartes}
          />
        )}
      </div>
    </JiveWindow>
  )
}

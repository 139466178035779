import React, { useMemo, useState } from 'react'

import API from '../services/api'
import { hashObject } from '../utils'

import Table from './Table'
import { onSortNumber } from './Table/sort'

const DBJusTable = ({
  data,
  scores,
  clickItemProcesso,
  probableVinculados,
  showScores,
  showPartes
}) => {
  const [vinculados, setVinculados] = useState({})
  const dataSubstring = 40

  const fixJustica = justica => {
    switch (justica) {
      case 'JUSTIÇA DO TRABALHO':
        return 'JT'
      case 'JUSTIÇA ESTADUAL':
        return 'JE'
      case 'JUSTIÇA FEDERAL':
        return 'JF'
      default:
        return justica
    }
  }

  const tipoPartes = useMemo(
    () =>
      Array.from(
        new Set(
          (data || [])
            .map(item =>
              item.partes.map(parte => parte.tipo.replace(/_/g, ' '))
            )
            .flat()
        )
      ),
    [data]
  )

  const columns = useMemo(() => {
    const defaultColumns1 = [
      {
        dataField: 'nup',
        text: 'NUP',
        sort: true
      },
      {
        dataField: 'estado',
        text: 'UF',
        sort: true
      },
      {
        dataField: 'justica',
        text: 'Jus.',
        sort: true
      },
      {
        dataField: 'classes',
        text: 'Classes',
        sort: true
      },
      {
        dataField: 'assuntos',
        text: 'Assuntos',
        sort: true
      },
      {
        dataField: 'valor',
        text: 'Valor',
        sortFunc: onSortNumber,
        sort: true
      }
    ]

    const partesColumns = showPartes
      ? tipoPartes.map(item => ({
          dataField: item,
          text: item
        }))
      : []

    const defaultColumns2 = [
      showScores && {
        dataField: 'score',
        text: 'Score',
        sort: true
      },
      {
        dataField: 'actions',
        text: ''
      }
    ].filter(Boolean)

    const groupedColumns = [
      ...defaultColumns1,
      ...partesColumns,
      ...defaultColumns2
    ]

    return groupedColumns.map(item => ({
      ...item,
      align: 'center',
      headerAlign: 'center'
    }))
  }, [tipoPartes, showScores, showPartes])

  const mappedData = useMemo(() => {
    return (data || []).map(item => {
      const id = hashObject(item)

      const partesObj = tipoPartes.reduce((obj, tipo) => {
        const partes = item.partes
          .filter(parte => parte.tipo === tipo)
          .map(parte => parte.nome)
          .join('; ')
          .substr(0, dataSubstring)

        obj[tipo] = partes
        return obj
      }, {})

      return {
        id,
        nup: item.nup,
        estado: item.estado,
        justica: fixJustica(item.justica),
        classes: item.classes,
        assuntos:
          item.assuntos.length > dataSubstring
            ? item.assuntos.substr(0, dataSubstring) + '...'
            : item.assuntos,
        valor: item.valor || '',
        ...partesObj,
        score: item.score?.toFixed(2),
        processosVinculados: item.nupsVinculados,
        actions: (
          <button
            type='button'
            className='btn btn-primary btn-sm'
            style={{ width: '100px' }}
            onClick={() => clickItemProcesso(item)}
          >
            Ver detalhes
          </button>
        )
      }
    })
  }, [data, tipoPartes, clickItemProcesso])

  const nonExpandable = useMemo(() => {
    return (mappedData || [])
      .filter(item => item.processosVinculados.length === 0)
      .map(item => item.id)
  }, [mappedData])

  const expandRow = {
    expandColumnRenderer: ({ expanded, expandable }) => {
      if (expanded) {
        return <b>-</b>
      }
      if (!expandable) {
        return null
      }

      return <b>+</b>
    },
    showExpandColumn: true,
    onExpand: (row, isExpand) => {
      const { processosVinculados } = row

      if (!isExpand) {
        return
      }

      const pendingProcessosVinculados = processosVinculados
        .map(nup => nup.replace(/\D/g, ''))
        .filter(nup => !Object.keys(vinculados).includes(nup))

      if (pendingProcessosVinculados.length === 0) {
        return
      }

      API.juridico
        .consultarVinculados({ nups: pendingProcessosVinculados.join(',') })
        .then(data => {
          const groupedNups = pendingProcessosVinculados.reduce(
            (obj, cleanedNup) => {
              obj[cleanedNup] =
                data.find(item => item.nup.replace(/\D/g, '') === cleanedNup) ||
                {}
              return obj
            },
            {}
          )
          setVinculados(prev => ({ ...prev, ...groupedNups }))
        })
        .catch(err => {
          console.error(err)
        })
    },
    nonExpandable,
    renderer: row => {
      const processosVinculados = row.processosVinculados || []

      const mappedProcessosVinculados = processosVinculados.map(nup => {
        const cleanedNup = nup.replace(/\D/g, '')
        const data = vinculados[cleanedNup] || {}
        const id = hashObject({ ...data, nup: cleanedNup })

        const partesObj = tipoPartes.reduce((obj, tipo) => {
          const partes = (data.partes || [])
            .filter(parte => parte.tipo === tipo)
            .map(parte => parte.nome)
            .join('; ')
            .substr(0, dataSubstring)

          obj[tipo] = partes
          return obj
        }, {})

        return {
          id,
          nup,
          estado: data.estado,
          justica: fixJustica(data.justica || ''),
          classes: data.classes || '',
          assuntos:
            (data.assuntos || []).length > dataSubstring
              ? data.assuntos.substr(0, dataSubstring) + '...'
              : data.assuntos,
          valor: data.valor,
          ...partesObj,
          score: data.score,
          actions: (
            <button
              type='button'
              className='btn btn-primary btn-sm'
              style={{ width: '100px' }}
              disabled={!data}
              onClick={() => clickItemProcesso(data)}
            >
              Ver detalhes
            </button>
          )
        }
      })
      return (
        <>
          {mappedProcessosVinculados.map(item => {
            const keys = Object.keys(item).filter(
              key => !['id', 'processosVinculados'].includes(key)
            )

            return (
              <tr key={item.id}>
                <td />
                {keys.map(key => (
                  <td key={key} style={{ textAlign: 'center' }}>
                    {item[key]}
                  </td>
                ))}
              </tr>
            )
          })}
        </>
      )
    }
  }

  return (
    <div className='col-12'>
      <Table
        enablePagination
        keyField='id'
        columns={columns}
        data={mappedData}
        expandRow={expandRow}
      />
    </div>
  )
}

export default DBJusTable

import { useEffect, useRef } from 'react'
import { zonedTimeToUtc } from 'date-fns-tz'
import { differenceInMinutes, format } from 'date-fns'
import jwtDecode from 'jwt-decode'
import shajs from 'sha.js'
import { toast } from 'react-toastify'
import camelcaseKeys from 'camelcase-keys'

import { BUILD_ENV, ENV } from './config/env'
import { useSelector } from 'react-redux'
import { selectJuridicoProgressStatus } from './redux/selectors/progressStatus'
import { mapWithFullDocument, mapWithRelationship } from './lib/detectMatch'

const { version } = require('../package.json')

export const VERSION = version
console.log(`version: v${VERSION} - ${BUILD_ENV}`)

export const isValid = value =>
  value !== undefined &&
  value !== null &&
  (typeof value === 'string' ? value.length > 0 : true)

export const resolveBoolean = data => {
  return data ? 'Sim' : 'Não'
}

export const formatCNPJ = cnpj => {
  cnpj = cnpj.replace(/[^\d]/g, '')

  return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
}

export const formatPartialCpf = cpf => {
  const cleanedCpf = cpf.replace(/\D/g, '').slice(0, 11)

  let output = ''
  if (cleanedCpf.length > 0) {
    output = cleanedCpf.substring(0, 3)
  }

  if (cleanedCpf.length > 3) {
    output += '.' + cleanedCpf.substring(3, 6)
  }

  if (cleanedCpf.length > 6) {
    output += '.' + cleanedCpf.substring(6, 9)
  }

  if (cleanedCpf.length > 9) {
    output += '-' + cleanedCpf.substring(9, 11)
  }

  return output
}

export const isPending = data => {
  if (!data) {
    return true
  }

  return data.loaded !== true && data.error !== true
}

export const formatPartialCnpj = cnpj => {
  const cleanedCnpj = cnpj.replace(/\D/g, '').slice(0, 14)

  let output = ''
  if (cleanedCnpj.length > 0) {
    output = cleanedCnpj.substring(0, 2)
  }

  if (cleanedCnpj.length > 2) {
    output += '.' + cleanedCnpj.substring(2, 5)
  }

  if (cleanedCnpj.length > 5) {
    output += '.' + cleanedCnpj.substring(5, 8)
  }

  if (cleanedCnpj.length > 8) {
    output += '/' + cleanedCnpj.substring(8, 12)
  }

  if (cleanedCnpj.length > 12) {
    output += '-' + cleanedCnpj.substring(12, 14)
  }

  return output
}

export const formatCurrency = (
  number,
  lang = 'pt-BR',
  currency = 'BRL',
  options = {
    fractionDigits: 2
  }
) => {
  const internalLang = lang ?? 'pt-BR'
  const internalCurrency = currency ?? 'BRL'
  const internalOptions = options || {
    fractionDigits: 2
  }

  const formatter = new Intl.NumberFormat(internalLang, {
    style: 'currency',
    currency: internalCurrency,
    minimumFractionDigits: internalOptions?.fractionDigits,
    maximumFractionDigits: internalOptions?.fractionDigits,
    ...internalOptions
  })

  return formatter.format(number)
}

export const removeAccents = str => {
  const type = typeof str
  if (['null', 'undefined'].includes(type)) {
    return str
  }

  const internalStr = type !== 'string' ? str?.toString() : str

  return internalStr.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

export const hashObject = obj => {
  return shajs('sha256')
    .update(JSON.stringify(obj))
    .digest('hex')
}

export const formatDocument = document => {
  if (!document) {
    return ''
  }
  const cleanedDocument = document.replace(/\D/g, '')
  const isCPF = cleanedDocument.length === 11

  return isCPF
    ? CPF_FORMATTER(cleanedDocument)
    : CNPJ_FORMATTER(cleanedDocument)
}

export const zip = (a, b) => {
  const bigger = a.length > b.length ? a : b
  const smaller = a.length > b.length ? b : a

  return bigger
    .map((item, index) => [item, smaller[index]])
    .flat()
    .filter(Boolean)
}

export const isCPF = document => {
  if (!document) {
    return false
  }

  const cleanedDocument = document.replace(/\D/g, '')

  return cleanedDocument.length === 11
}

export const isCNPJ = document => {
  if (!document) {
    return false
  }

  const cleanedDocument = document.replace(/\D/g, '')

  return cleanedDocument.length === 14
}

export const delay = ms => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export const capitalize = value => {
  return value
    .split(' ')
    .map(word => {
      const firstLetter = word.charAt(0).toUpperCase()
      const otherLetters = word.slice(1).toLowerCase()

      return firstLetter + otherLetters
    })
    .join(' ')
}

export const mapWithFullDocumentAndRelationship = ({
  array,
  relateds,
  documentKey,
  nameKey
}) => {
  const mappedWithFullDocument = mapWithFullDocument({
    array,
    relateds,
    documentKey,
    nameKey
  })

  return mapWithRelationship({
    array: mappedWithFullDocument,
    relateds,
    documentKey,
    nameKey
  })
}

export const resolveDate = date => {
  if (!date) {
    return '-'
  }

  if (date.includes('T')) {
    return date
      .split('T')[0]
      .split('-')
      .reverse()
      .join('/')
  }

  return date.replace('-', '/')
}

export const resolveGrauBeneficiario = grau => {
  switch (grau) {
    case '1':
      return 'Sócio'
    case '2':
      return 'Tutor'
    default:
      return grau
  }
}

export const onlyNumbers = string => {
  if (typeof string !== 'string') {
    return ''
  }

  return string.replace(/\D/g, '')
}

export const toCamelCase = value => {
  if (typeof value === 'string') {
    return Object.keys(camelcaseKeys({ [value]: '' }))[0]
  }

  return camelcaseKeys(value, { deep: true })
}

export const isAuthenticated = () => {
  return !!getAuthToken()
}

const decodeToken = token => {
  const decoded = jwtDecode(token)
  return decoded
}

export const isValidToken = token => {
  try {
    decodeToken(token)
    return true
  } catch (err) {
    return false
  }
}

export const useTokenValidation = delay => {
  useEffect(() => {
    const handler = () => {
      const token = getAuthToken()
      // WARNING: quando o token não é válido, não é feita a remoção pq provavelmente é usuário da jive
      // TODO: repensar como é feita a validação da jive para não permitir usuários que não sejam da jive e nem da indrema
      if (isAuthenticated() && isValidToken(token)) {
        const { exp } = decodeToken(token)
        const expiration = exp * 1000

        const diffMin = differenceInMinutes(expiration, new Date())

        if (diffMin <= 15) {
          if (diffMin <= 0) {
            // removeUserId()
            // removeAuthToken()
            // history.replace('/login')
            // toast.warning('Seu token expirou, faça login novamente', {
            //   position: toast.POSITION.BOTTOM_RIGHT,
            //   hideAfter: 10,
            //   toastId: 'token-expired'
            // })
          } else {
            // toast.warning(
            //   `Expira em ${diffMin} minutos(s), salve e faça login novamente para não perder seus trabalho`,
            //   {
            //     position: toast.POSITION.BOTTOM_RIGHT,
            //     hideAfter: 10,
            //     toastId: 'token-expiration'
            //   }
            // )
          }
        }
      }
    }

    if (delay !== null) {
      const intervalId = setInterval(handler, delay)
      return () => clearInterval(intervalId)
    }
  }, [delay])
}

export const useJuridicoNotification = delay => {
  const { startedAt } = useSelector(state => state.globalStep.metadata)
  const { visible, pending } = useSelector(selectJuridicoProgressStatus)

  useEffect(() => {
    const handler = () => {
      const MAX_DURATION = 8

      const duration = differenceInMinutes(new Date(), startedAt)

      if (
        visible &&
        duration >= MAX_DURATION &&
        pending &&
        pending.length > 0
      ) {
        const pendingProcess = pending[0]

        const name = (pendingProcess.name || '').slice(0, 10)

        toast.warning(`Os processos que estão demorando são do(a) ${name}...`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideAfter: 10,
          toastId: 'pending-juridico-process'
        })
      }
    }

    if (delay !== null) {
      const intervalId = setInterval(handler, delay)
      return () => clearInterval(intervalId)
    }
  }, [delay, visible, pending, startedAt])
}

export const useInterval = (callback, delay) => {
  const savedCallback = useRef()

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    function tick () {
      savedCallback.current()
    }

    if (delay !== null && delay !== undefined) {
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

export const getAuthToken = () => {
  return localStorage.getItem('authToken')
}

export const setAuthToken = token => {
  return localStorage.setItem('authToken', token)
}

export const removeAuthToken = () => localStorage.removeItem('authToken')

export const setUserId = userId => {
  return localStorage.setItem('userID', userId)
}

export const getUserId = () => {
  const scope = getScope()
  const userId = localStorage.getItem('userID')

  if (scope === 'discovery') {
    return parseInt(userId)
  }

  return userId
}

export const setScope = scope => {
  return localStorage.setItem('scope', scope)
}

export const getScope = () => {
  return localStorage.getItem('scope')
}

export const removeUserId = () => localStorage.removeItem('userID')

export const getWhiteListOfDomains = () => {
  if (ENV === 'development') {
    return [
      'localhost',
      'jiveassetdev.service-now.com',
      'jiveasset.service-now.com',
      'jiveassetqa.service-now.com'
    ]
  }

  return [
    'jiveassetdev.service-now.com',
    'jiveasset.service-now.com',
    'jiveassetqa.service-now.com'
  ]
}

export const getParentHostName = () => {
  const isADistinctLocation = window.location !== window.parent.location
  const currentLocation = document.location

  const parentOrigin = (
    (isADistinctLocation ? document.referrer : currentLocation) || ''
  ).toString()

  if (parentOrigin) {
    return new URL(parentOrigin).hostname
  } else if (
    currentLocation.ancestorOrigins &&
    currentLocation.ancestorOrigins.length
  ) {
    return new URL(currentLocation.ancestorOrigins[0]).hostname
  }

  return ''
}

export const loadImage = async src => {
  return new Promise((resolve, reject) => {
    const img = new Image()

    img.onload = () => {
      let canvas = document.createElement('CANVAS')
      const ctx = canvas.getContext('2d')
      canvas.height = img.height
      canvas.width = img.width
      ctx.drawImage(img, 0, 0)
      const dataURL = canvas.toDataURL().replace('data:image/png;base64,', '')
      canvas = null
      resolve(dataURL)
    }

    img.src = src.default
  })
}

const PHONE_FORMATTER = phone => {
  if (!phone) {
    return ''
  }
  phone = phone.replace(/[^\d]/g, '')

  if (phone.length >= 10) {
    return phone.replace(/^(\+?55)?(\d{2})(\d{4,5})(\d{4})$/, '($2) $3-$4')
  }

  return phone.replace(/^(\d{4,5})(\d{4})$/, '$1-$2')
}

const CEP_FORMATTER = cep => {
  if (!cep) {
    return ''
  }

  cep = cep.replace(/[^\d]/g, '')

  return cep.replace(/(\d{5})(\d{2})/, '$1-$2')
}

const CNPJ_FORMATTER = cnpj => {
  if (!cnpj) {
    return ''
  }
  cnpj = cnpj.replace(/[^\d]/g, '')

  return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
}

const RESOLVE_NONE = value => {
  if (!value || (typeof value === 'string' && value.toLowerCase() === 'none')) {
    return ''
  }

  return value
}

const CPF_FORMATTER = cpf => {
  if (!cpf) {
    return ''
  }
  cpf = cpf.replace(/[^\d]/g, '')

  return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
}

const RETURN_DATA2 = date => {
  if (!date) {
    return ''
  }
  // 20180921
  return date.replace(/(\d{4})(\d{2})(\d{2})/, '$3/$2/$1')
}

const formatDate = date => {
  if (!date) {
    return ''
  }

  return format(new Date(date), 'dd/MM/yyyy')
}

export const isCypheredDocument = document => {
  if (!document) {
    return true
  }

  return document.includes('*')
}

export const factoryDate = (date, timezone = 'America/Sao_Paulo') => {
  if (!timezone) {
    return new Date(date)
  }

  return zonedTimeToUtc(date, timezone)
}

export const formatDateTime = (date, short = true) => {
  if (!date) {
    return ''
  }

  const template = short ? 'dd/MM/yyyyy HH:mm' : 'dd/MM/yyyyy HH:mm:ss'

  return format(new Date(date), template)
}

export const formatDecimal = (
  number,
  lang = 'pt-BR',
  options = {
    fractionDigits: 2
  }
) => {
  const internalLang = lang || 'pt-BR'
  const internalOptions = options || {
    fractionDigits: 2
  }
  const formatter = new Intl.NumberFormat(internalLang, {
    style: 'decimal',
    minimumFractionDigits: internalOptions?.fractionDigits,
    maximumFractionDigits: internalOptions?.fractionDigits,
    ...internalOptions
  })

  return formatter.format(number)
}

export const formatPercent = (
  number,
  lang = 'pt-BR',
  options = {
    fractionDigits: 2
  }
) => {
  const internalLang = lang || 'pt-BR'
  const internalOptions = options || {
    fractionDigits: 2
  }
  const formatter = new Intl.NumberFormat(internalLang, {
    style: 'percent',
    minimumFractionDigits: internalOptions.fractionDigits,
    maximumFractionDigits: internalOptions.fractionDigits,
    ...internalOptions
  })

  return formatter.format(number)
}

export const isEmpty = value => {
  return typeof value === 'undefined' || value === null
}

const RETURN_DATA = date => {
  if (!date) {
    return ''
  }

  const v = date.split('-')
  // 2018-09-21
  return v[2] + '/' + v[1] + '/' + v[0]
}

const RESUME_TYPE_SOCIO = socio => {
  switch (socio) {
    case 'Diretor':
      return 'DIR.'
    case 'Titular Pessoa Física Residente ou Domiciliado no Brasil':
      return 'TITULAR'
    case 'Sócio-Administrador':
      return 'SOC-ADM'
    case 'Administrador':
      return 'ADM'
    case 'Presidente':
      return 'PRES'
    default:
      return socio
  }
}

export const usePrevious = value => {
  const ref = useRef()

  // Store current value in ref
  useEffect(() => {
    ref.current = value
  }, [value]) // Only re-run if value changes

  return ref.current
}

export const resolveNaturezaJuridica = natureza => {
  const dict = {
    '1015': 'Órgão Público do Poder Executivo Federal',
    '1023': 'Órgão Público do Poder Executivo Estadual ou do Distrito Federal',
    '1031': 'Órgão Público do Poder Executivo Municipal',
    '1040': 'Órgão Público do Poder Legislativo Federal',
    '1058':
      'Órgão Público do Poder Legislativo Estadual ou do Distrito Federal',
    '1066': 'Órgão Público do Poder Legislativo Municipal',
    '1074': 'Órgão Público do Poder Judiciário Federal ',
    '1082': 'Órgão Público do Poder Judiciário Estadual',
    '1104': 'Autarquia Federal',
    '1112': 'Autarquia Estadual ou do Distrito Federal',
    '1120': 'Autarquia Municipal ',
    '1139': 'Fundação Pública de Direito Público Federal',
    '1147':
      'Fundação Pública de Direito Público Estadual ou do Distrito Federal',
    '1155': 'Fundação Pública de Direito Público Municipal',
    '1163': 'Órgão Público Autônomo Federal',
    '1171': 'Órgão Público Autônomo Estadual ou do Distrito Federal',
    '1180': 'Órgão Público Autônomo Municipal',
    '1198': 'Comissão Polinacional',
    '1210': 'Consórcio Público de Direito Público (Associação Pública)',
    '1228': 'Consórcio Público de Direito Privado',
    '1236': 'Estado ou Distrito Federal',
    '1244': 'Município',
    '1252': 'Fundação Pública de Direito Privado Federal',
    '1260':
      'Fundação Pública de Direito Privado Estadual ou do Distrito Federal',
    '1279': 'Fundação Pública de Direito Privado Municipal',
    '1287': 'Fundo Público da Administração Indireta Federal',
    '1295':
      'Fundo Público da Administração Indireta Estadual ou do Distrito Federal',
    '1309': 'Fundo Público da Administração Indireta Municipal',
    '1317': 'Fundo Público da Administração Direta Federal',
    '1325':
      'Fundo Público da Administração Direta Estadual ou do Distrito Federal',
    '1333': 'Fundo Público da Administração Direta Municipal',
    '1341': 'União',
    '2011': 'Empresa Pública',
    '2038': 'Sociedade de Economia Mista',
    '2046': 'Sociedade Anônima Aberta',
    '2054': 'Sociedade Anônima Fechada',
    '2062': 'Sociedade Empresária Limitada',
    '2070': 'Sociedade Empresária em Nome Coletivo',
    '2089': 'Sociedade Empresária em Comandita Simples',
    '2097': 'Sociedade Empresária em Comandita por Ações',
    '2127': 'Sociedade em Conta de Participação',
    '2135': 'Empresário (Individual)',
    '2143': 'Cooperativa',
    '2151': 'Consórcio de Sociedades',
    '2160': 'Grupo de Sociedades',
    '2178': 'Estabelecimento, no Brasil, de Sociedade Estrangeira',
    '2194':
      'Estabelecimento, no Brasil, de Empresa Binacional Argentino-Brasileira',
    '2216': 'Empresa Domiciliada no Exterior',
    '2224': 'Clube/Fundo de Investimento',
    '2232': 'Sociedade Simples Pura',
    '2240': 'Sociedade Simples Limitada',
    '2259': 'Sociedade Simples em Nome Coletivo',
    '2267': 'Sociedade Simples em Comandita Simples',
    '2275': 'Empresa Binacional',
    '2283': 'Consórcio de Empregadores',
    '2291': 'Consórcio Simples',
    '2305':
      'Empresa Individual de Responsabilidade Limitada (de Natureza Empresária)',
    '2313':
      'Empresa Individual de Responsabilidade Limitada (de Natureza Simples)',
    '2321': 'Sociedade Unipessoal de Advogados',
    '2330': 'Cooperativas de Consumo',
    '3034': 'Serviço Notarial e Registral (Cartório)',
    '3069': 'Fundação Privada',
    '3077': 'Serviço Social Autônomo',
    '3085': 'Condomínio Edilício',
    '3107': 'Comissão de Conciliação Prévia',
    '3115': 'Entidade de Mediação e Arbitragem',
    '3131': 'Entidade Sindical',
    '3204':
      'Estabelecimento, no Brasil, de Fundação ou Associação Estrangeiras',
    '3212': 'Fundação ou Associação Domiciliada no Exterior',
    '3220': 'Organização Religiosa ',
    '3239': 'Comunidade Indígena ',
    '3247': 'Fundo Privado ',
    '3255': 'Órgão de Direção Nacional de Partido Político',
    '3263': 'Órgão de Direção Regional de Partido Político',
    '3271': 'Órgão de Direção Local de Partido Político',
    '3280': 'Comitê Financeiro de Partido Político',
    '3298': 'Frente Plebiscitária ou Referendária',
    '3301': 'Organização Social (OS)',
    '3310': 'Demais Condomínios',
    '3999': 'Associação Privada',
    '4014': 'Empresa Individual Imobiliária',
    '4022': 'Segurado Especial',
    '4081': 'Contribuinte individual',
    '4090': 'Candidato a Cargo Político Eletivo',
    '4111': 'Leiloeiro ',
    '4120': 'Produtor Rural (Pessoa Física)',
    '5010': 'Organização Internacional',
    '5029': 'Representação Diplomática Estrangeira',
    '5037': 'Outras Instituições Extraterritoriais'
  }

  return dict[natureza]
}

export {
  CNPJ_FORMATTER,
  RETURN_DATA,
  RETURN_DATA2,
  RESUME_TYPE_SOCIO,
  CPF_FORMATTER,
  PHONE_FORMATTER,
  CEP_FORMATTER,
  RESOLVE_NONE,
  formatDate
}

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import { useSelector } from 'react-redux'

import { JiveWindow, Table } from '../../../components'
import { mapWithRelationship } from '../../../lib/detectMatch'
import { selectRelateds } from '../../../redux/selectors/relateds'
import {
  factoryDate,
  formatDocument,
  isCypheredDocument,
  isPending,
  onlyNumbers,
  formatDate,
  hashObject,
  formatCurrency
} from '../../../utils'

const { SearchBar } = Search

export default function TermoApreensao ({ data, error, loaded }) {
  const relateds = useSelector(selectRelateds)

  const name = 'bandeiraAmarela.termoApreensao'
  const title = 'Termo de Apreensão'

  if (isPending({ error, loaded })) {
    return (
      <JiveWindow title={title} name={name}>
        <div />
      </JiveWindow>
    )
  }

  if (error) {
    return (
      <JiveWindow title={title} name={name} defaultOpen>
        <div>Ops! Aconteceu um erro ao carregar os termos de apreensão</div>
      </JiveWindow>
    )
  }

  return (
    <JiveWindow title={title} name={name} showNote>
      {data.length > 0 && (
        <ToolkitProvider
          keyField='id'
          search
          columns={[
            { dataField: 'nomPessoaApreensao', text: 'Nome' },
            {
              dataField: 'relationship',
              text: 'Grau'
            },
            { dataField: 'document', text: 'CPF / CNPJ', searchable: false },
            { dataField: 'rawDocument', text: '', hidden: true },
            { dataField: 'statusFormulario', text: 'Situação' },
            { dataField: 'date', text: 'Data' },
            { dataField: 'valor', text: 'Valor' },
            { dataField: 'numProcesso', text: 'Processo' },
            { dataField: 'desTad', text: 'Circunstâncias da apreensão' },
            { dataField: 'local', text: 'Local' }
          ].map(item => ({ ...item, align: 'center', headerAlign: 'center' }))}
          data={mapWithRelationship({
            array: data || [],
            documentKey: 'cpfCnpjPessoaApreensao',
            nameKey: 'nomPessoaApreensao',
            relateds
          }).map(item => {
            const id = hashObject(item)
            return {
              ...item,
              id,
              rawDocument: item.cpfCnpjPessoaApreensao,
              document: isCypheredDocument(item.cpfCnpjPessoaApreensao) ? (
                item.cpfCnpjPessoaApreensao
              ) : (
                <a
                  href={`?documento=${onlyNumbers(
                    item.cpfCnpjPessoaApreensao
                  )}`}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {formatDocument(item.cpfCnpjPessoaApreensao)}
                </a>
              ),
              date: formatDate(factoryDate(item.datTad)),
              local: `${item.nomMunicipio} - ${item.sigUf}`,
              valor: formatCurrency(
                parseFloat(item.valorTad?.replace(/,/g, ''))
              )
            }
          })}
        >
          {props => (
            <>
              <div className='row'>
                <div className='col-12 text-lg-right'>
                  <SearchBar {...props.searchProps} placeholder='Pesquisar' />
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <Table enablePagination {...props.baseProps} />
                </div>
              </div>
            </>
          )}
        </ToolkitProvider>
      )}
    </JiveWindow>
  )
}

import {
  resolveProgressStatus,
  isError,
  isLoaded
} from '../../lib/progressStatus'

// Action Types
export const Types = {
  RESET: 'BANEIRA_AMARELA_THEME/RESET',
  SET_S3_PATH: 'BANEIRA_AMARELA_THEME/SET_S3_PATH',
  SET_ERROR: 'BANEIRA_AMARELA_THEME/SET_ERROR'
}

// Reducer
const initialState = {
  pep: {
    s3Path: undefined,
    error: false,
    loaded: false
  },
  acordosLeniencia: {
    s3Path: undefined,
    error: false,
    loaded: false
  },
  autoInfracao: {
    s3Path: undefined,
    error: false,
    loaded: false
  },
  ofac: {
    s3Path: undefined,
    error: false,
    loaded: false
  },
  termoApreensao: {
    s3Path: undefined,
    error: false,
    loaded: false
  },
  termoEmbargo: {
    s3Path: undefined,
    error: false,
    loaded: false
  },
  termoSuspensao: {
    s3Path: undefined,
    error: false,
    loaded: false
  },
  cadastroExpulsoes: {
    s3Path: undefined,
    error: false,
    loaded: false
  },
  cnep: {
    s3Path: undefined,
    error: false,
    loaded: false
  },
  ceis: {
    s3Path: undefined,
    error: false,
    loaded: false
  },
  ceaf: {
    s3Path: undefined,
    error: false,
    loaded: false
  }
}

export default function reducer (state = initialState, action) {
  switch (action.type) {
    case Types.SET_S3_PATH: {
      const statusCnep = resolveProgressStatus(action.payload.cnep)
      const statusCeaf = resolveProgressStatus(action.payload.ceaf)
      const statusCeis = resolveProgressStatus(action.payload.ceis)
      const statusPep = resolveProgressStatus(action.payload.pep)
      const statusAcordosLeniencia = resolveProgressStatus(
        action.payload.acordosLeniencia
      )
      const statusAutoInfracao = resolveProgressStatus(
        action.payload.autoInfracao
      )
      const statusCepim = resolveProgressStatus(action.payload.cepim)
      const statusOfac = resolveProgressStatus(action.payload.ofac)
      const statusTermoApreensao = resolveProgressStatus(
        action.payload.termoApreensao
      )
      const statusTermoEmbargo = resolveProgressStatus(
        action.payload.termoEmbargo
      )
      const statusTermoSuspensao = resolveProgressStatus(
        action.payload.termoSuspensao
      )
      const statusCadastroExpulsoes = resolveProgressStatus(
        action.payload.cadastroExpulsoes
      )

      return {
        ...state,
        ceaf: {
          ...state.ceaf,
          s3Path: action.payload.ceaf,
          loaded: isLoaded(statusCeaf),
          error: isError(statusCeaf)
        },
        cnep: {
          ...state.cnep,
          s3Path: action.payload.cnep,
          loaded: isLoaded(statusCnep),
          error: isError(statusCnep)
        },
        ceis: {
          ...state.ceis,
          s3Path: action.payload.ceis,
          loaded: isLoaded(statusCeis),
          error: isError(statusCeis)
        },
        pep: {
          ...state.pep,
          s3Path: action.payload.pep,
          loaded: isLoaded(statusPep),
          error: isError(statusPep)
        },
        acordosLeniencia: {
          ...state.acordosLeniencia,
          s3Path: action.payload.acordosLeniencia,
          loaded: isLoaded(statusAcordosLeniencia),
          error: isError(statusAcordosLeniencia)
        },
        autoInfracao: {
          ...state.autoInfracao,
          s3Path: action.payload.autoInfracao,
          loaded: isLoaded(statusAutoInfracao),
          error: isError(statusAutoInfracao)
        },
        cepim: {
          ...state.cepim,
          s3Path: action.payload.cepim,
          loaded: isLoaded(statusCepim),
          error: isError(statusCepim)
        },
        ofac: {
          ...state.ofac,
          s3Path: action.payload.ofac,
          loaded: isLoaded(statusOfac),
          error: isError(statusOfac)
        },
        termoApreensao: {
          ...state.termoApreensao,
          s3Path: action.payload.termoApreensao,
          loaded: isLoaded(statusTermoApreensao),
          error: isError(statusTermoApreensao)
        },
        termoEmbargo: {
          ...state.termoEmbargo,
          s3Path: action.payload.termoEmbargo,
          loaded: isLoaded(statusTermoEmbargo),
          error: isError(statusTermoEmbargo)
        },
        termoSuspensao: {
          ...state.termoSuspensao,
          s3Path: action.payload.termoSuspensao,
          loaded: isLoaded(statusTermoSuspensao),
          error: isError(statusTermoSuspensao)
        },
        cadastroExpulsoes: {
          ...state.cadastroExpulsoes,
          s3Path: action.payload.cadastroExpulsoes,
          loaded: isLoaded(statusCadastroExpulsoes),
          error: isError(statusCadastroExpulsoes)
        }
      }
    }
    case Types.SET_ERROR:
      return {
        ...state,
        pep: {
          ...state.pep,
          s3Path: action.payload.pep,
          error: true,
          loaded: true
        },
        acordosLeniencia: {
          ...state.acordosLeniencia,
          s3Path: action.payload.acordosLeniencia,
          error: true,
          loaded: true
        },
        autoInfracao: {
          ...state.autoInfracao,
          s3Path: action.payload.autoInfracao,
          error: true,
          loaded: true
        },
        cepim: {
          ...state.cepim,
          s3Path: action.payload.cepim,
          error: true,
          loaded: true
        },
        ofac: {
          ...state.ofac,
          s3Path: action.payload.ofac,
          error: true,
          loaded: true
        },
        termoApreensao: {
          ...state.termoApreensao,
          s3Path: action.payload.termoApreensao,
          error: true,
          loaded: true
        },
        termoEmbargo: {
          ...state.termoEmbargo,
          s3Path: action.payload.termoEmbargo,
          error: true,
          loaded: true
        },
        termoSuspensao: {
          ...state.termoSuspensao,
          s3Path: action.payload.termoSuspensao,
          error: true,
          loaded: true
        },
        cadastroExpulsoes: {
          ...state.cadastroExpulsoes,
          s3Path: action.payload.cadastroExpulsoes,
          error: true,
          loaded: true
        },
        cnep: {
          ...state.cnep,
          s3Path: action.payload.cnep,
          error: true,
          loaded: true
        },
        ceis: {
          ...state.ceis,
          s3Path: action.payload.ceis,
          error: true,
          loaded: true
        },
        ceaf: {
          ...state.ceaf,
          s3Path: action.payload.ceaf,
          error: true,
          loaded: true
        }
      }
    case Types.RESET:
      return initialState
    default:
      return state
  }
}

// Action Creators
export function setErrorBandeiraAmarela (data) {
  return {
    type: Types.SET_ERROR,
    payload: data
  }
}

export function setS3PathBandeiraAmarela (payload) {
  return {
    type: Types.SET_S3_PATH,
    payload
  }
}

export function resetBandeiraAmarelaTheme () {
  return {
    type: Types.RESET
  }
}

export const PROGRESS_TYPES = {
  UNLOADED: 'unloaded',
  LOADED: 'loaded',
  PENDING: 'pending',
  ERROR: 'error',
  PARTIAL_ERROR: 'partialError'
}

export const resolveProgressStatus = (s3Path, name) => {
  if (!s3Path) {
    return PROGRESS_TYPES.UNLOADED
  }

  const { succeeded, total } = s3Path
  if (typeof succeeded !== 'number' || typeof total !== 'number') {
    throw new Error(`${name}:Tipo inválido`)
  }

  if (succeeded === total) {
    return PROGRESS_TYPES.LOADED
  }

  if (succeeded === 0) {
    return PROGRESS_TYPES.ERROR
  }

  return PROGRESS_TYPES.PARTIAL_ERROR
}

export const isLoaded = status =>
  [PROGRESS_TYPES.LOADED, PROGRESS_TYPES.PARTIAL_ERROR].includes(status)

export const isError = status => status === PROGRESS_TYPES.ERROR

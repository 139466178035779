import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'

import Swal from 'sweetalert2'

import { ReactComponent as Logo } from '../../assets/img/logo-fill.svg'
import { setUser } from '../../redux/actions/autenticacao'
import API from '../../services/api'
import {
  getAuthToken,
  setAuthToken,
  setScope,
  setUserId,
  VERSION
} from '../../utils'

import '../../assets/sass/cards.scss'

const LoginPage = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [email, setEmail] = useState()
  const [password, setPassword] = useState()
  const [loading, setLoading] = useState(false)

  const onSubmit = async event => {
    event.preventDefault()

    try {
      setLoading(true)

      const { data } = await API.autenticacao.logar({ email, password })
      const { access_token: token, user } = data

      setAuthToken(token)
      setUserId(user.id)
      setScope('discovery')
      dispatch(setUser({ id: user.id, scope: 'discovery' }))
      history.replace('/')
    } catch (err) {
      console.error(err)

      const message =
        err.response?.data?.name === 'InvalidCredentials'
          ? 'E-mail ou senha inválida!'
          : err.response?.data?.message || err.message

      Swal.fire({
        title: 'Ops! Não foi possível fazer o login',
        text: message,
        icon: 'error'
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const currentToken = getAuthToken()
    if (currentToken) {
      history.replace('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div
      className='container-fluid d-flex align-items-center justify-content-center h-100'
      style={{ backgroundColor: '#E5E5E5' }}
    >
      <div
        className='row flex-grow-1 text-center bg-white py-4 px-3'
        style={{ maxWidth: '540px', backgroundColor: '#FFFFFF' }}
      >
        <form className='form-signin col-12' onSubmit={onSubmit}>
          <Logo
            className='mb-4'
            alt='Jive'
            width='100'
            style={{ color: '#00b07d' }}
          />
          <label htmlFor='inputEmail' className='sr-only'>
            E-mail
          </label>
          <input
            disabled={loading}
            value={email}
            type='email'
            id='inputEmail'
            className='form-control'
            placeholder='E-mail'
            required
            autoFocus
            onChange={event => setEmail(event.target.value)}
          />
          <label htmlFor='inputPassword' className='sr-only'>
            Senha
          </label>
          <input
            disabled={loading}
            value={password}
            type='password'
            id='inputPassword'
            className='form-control mt-3'
            placeholder='Senha'
            required
            onChange={event => setPassword(event.target.value)}
          />
          <button
            className='btn btn-lg btn-primary btn-block mt-4'
            type='submit'
          >
            {loading ? (
              <div className='spinner-border' role='status'>
                <span className='sr-only'>Loading...</span>
              </div>
            ) : (
              'Entrar'
            )}
          </button>
          <p
            className='mt-4 mb-1 text-muted text-right'
            style={{ fontSize: '12px' }}
          >
            v{VERSION}
          </p>
        </form>
      </div>
    </div>
  )
}

export default LoginPage

import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { selectMinData } from '../../../redux/selectors/dadosBasicos'

import { fetchOffshoreAnalyticsFromS3 } from '../../../lib/s3Theme'

import quantOffshore from '../../../mocks/quant/offshore.json'

import { formatCurrency, formatDecimal, isPending } from '../../../utils'

import { JiveWindow, Loading } from '../../../components'
import { Box, ListBox, PercentageListBox, TotalBox } from './Boxes'

const INITIAL_DATA_STATE = {
  offshoreLeaks: {
    total: 0,
    countries: []
  },
  status: {
    offshoreLeaks: 'PENDING'
  }
}

export default function OffshoreQuantitativos () {
  const { targetDocument } = useSelector(selectMinData)
  const offshoreTheme = useSelector(state => state.analyticsTheme.offshore)

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({
    data: INITIAL_DATA_STATE,
    loaded: false,
    error: false
  })

  useEffect(() => {
    const pending = isPending(offshoreTheme)
    if (pending) {
      return
    }

    setLoading(true)

    fetchOffshoreAnalyticsFromS3({
      payload: offshoreTheme
    })
      .then(offshore => {
        setData(offshore)
      })
      .catch(error => {
        toast.error(error.message)
        console.error(error)
        setData({
          data: {
            ...INITIAL_DATA_STATE,
            status: {
              offshoreLeaks: 'FAILED'
            }
          },
          loaded: false,
          error: true
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }, [offshoreTheme])

  const isStepPending = targetDocument && isPending(offshoreTheme)
  const title = 'Offshore'
  const name = 'quantitativos.offshore'

  if (loading || isStepPending) {
    return <Loading />
  }

  return (
    <JiveWindow title={title} name={name} showNote defaultOpen>
      <div className='container-fluid gap-2'>
        <div className='row'>
          <div className='col-6'>
            <div className='row'>
              <ListagemEmpresasOffshoreBox
                error={data.error}
                data={data.data.offshoreLeaks}
                status={data.data.status.offshoreLeaks}
              />
              {/* <CapitalEstrangeiroBox /> */}
            </div>
          </div>
          <div className='col-6'>
            <div className='row'>
              {/* TODO: descomentar */}
              {/* <ImoveisJiveOffshoreBox />
              <QuantidadePorSegmentoDeNegocioBox /> */}
            </div>
          </div>
        </div>
      </div>
    </JiveWindow>
  )
}

const ListagemEmpresasOffshoreBox = ({ error, data, status }) => {
  const title = 'Listagem de Empresas Offshore'

  if (error || status === 'FAILED') {
    return (
      <Box title={title}>
        <div className='row'>
          <div>Erro no carregamento dos dados</div>
        </div>
      </Box>
    )
  }

  if (status === 'SKIPPED') {
    return (
      <Box title={title}>
        <div className='row'>
          <div>Dados não carregados para este tema</div>
        </div>
      </Box>
    )
  }

  const { total, countries } = data
  const mappedCountries = countries
    .sort((a, b) => b.percentage - a.percentage)
    .map(item => ({
      name: item.country,
      total: item.total,
      percentage: item.percentage
    }))

  const formattedTotal = formatDecimal(total, null, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  })

  return (
    <Box title={title}>
      <div className='row'>
        <TotalBox value={formattedTotal} />
      </div>
      <div className='row'>
        <PercentageListBox showTotal items={mappedCountries} />
      </div>
    </Box>
  )
}

const CapitalEstrangeiroBox = () => {
  const {
    valor_total: valorTotal,
    countries
  } = quantOffshore.capital_estrangeiro
  const mappedCountries = countries
    .sort((a, b) => b.percentage - a.percentage)
    .map(item => ({
      name: item.country,
      total: formatCurrency(item.valor_total, null, null, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
      }),
      percentage: item.percentage
    }))

  const formattedTotal = formatCurrency(valorTotal, null, null, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  })

  return (
    <Box title='Capital Estrangeiro'>
      <div className='row'>
        <TotalBox value={formattedTotal} />
      </div>
      <div className='row'>
        <PercentageListBox items={mappedCountries} showTotal />
      </div>
    </Box>
  )
}

const ImoveisJiveOffshoreBox = () => {
  const {
    total,
    total_imoveis: totalImoveis,
    countries
  } = quantOffshore.imoveis_jive_offshore
  const mappedCountries = countries
    .sort((a, b) => b.percentage - a.percentage)
    .map(item => ({
      name: item.country,
      total: item.total,
      value: formatCurrency(item.valor_total, null, null, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
      }),
      percentage: item.percentage
    }))

  const formattedTotalImoveis = formatCurrency(totalImoveis, null, null, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  })
  const formattedTotal = formatDecimal(total, null, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  })

  return (
    <Box title='Imóveis Jive Offshore'>
      <div className='row pl-3 d-flex flex-row gap-3'>
        <TotalBox value={formattedTotal} className='' />
        <TotalBox
          label='Valor total dos imóveis'
          value={formattedTotalImoveis}
          className=''
        />
      </div>
      <div className='row'>
        <PercentageListBox showTotal items={mappedCountries} />
      </div>
      <div className='row'>
        <ListBox items={mappedCountries} />
      </div>
    </Box>
  )
}

const QuantidadePorSegmentoDeNegocioBox = () => {
  const { total } = quantOffshore.quantidade_por_segmento_negocio

  const formattedTotal = formatDecimal(total, null, {
    fractionDigits: 0
  })

  const mappedItems = [
    {
      name: 'Total',
      value: formattedTotal
    }
  ]

  return (
    <Box title='Quantidade por segmento de negócio'>
      <div className='row'>
        <ListBox items={mappedItems} />
      </div>
    </Box>
  )
}

import { consultarPermissaoJive, logar } from './autenticacao'
import { consultarDetalhes, consultarVinculados } from './juridico'
import {
  bloquearComentario,
  desbloquearComentario,
  pegarComentario,
  salvarComentario,
  pegarTodosOsComentarios
} from './comentarios'
import {
  getDadosCadastrais,
  getGrafo,
  getGrafo2,
  consultarCorreioEletronico,
  consultarDominioEmail,
  consultarEndereco,
  consultarTelefone,
  listarMatches
} from './grupoSocietario'
import { consultarImagens } from './googleMaps'
import { salvarLog } from './logs'
import { startGenerateDocumento } from './docx'
import { startGenerateXlsx } from './xlsx'
import { createQueue } from './sqs'
import { startStepFunctionDiscovery } from './stepDiscovey'

const API = {
  autenticacao: {
    logar,
    consultarPermissaoJive,
    salvarLog
  },
  juridico: {
    consultarDetalhes,
    consultarVinculados
  },
  comentarios: {
    bloquearComentario,
    desbloquearComentario,
    pegarComentario,
    salvarComentario,
    pegarTodosOsComentarios
  },
  receitaFederal: {
    matches: {
      consultarCorreioEletronico,
      consultarDominioEmail,
      consultarEndereco,
      consultarTelefone
    }
  },
  googleMaps: {
    consultar: consultarImagens
  },
  docx: {
    startGenerateDocumento
  },
  xlsx: {
    startGenerate: startGenerateXlsx
  },
  sqs: {
    create: createQueue
  },
  stepDiscovery: {
    start: startStepFunctionDiscovery
  },
  grupoSocietario: {
    getDadosCadastrais,
    getGrafo,
    getGrafo2,
    matches: {
      consultarCorreioEletronico,
      consultarDominioEmail,
      consultarEndereco,
      consultarTelefone,
      listar: listarMatches
    }
  }
}

export default API

import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { getScope, getUserId, useInterval } from '../utils'
import RecentSearches from './recentSearches'

const INTERVAL_TO_UPDATE_HISTORY = 1 * 60 * 1000 // 3 minutes

export const useSearchHistory = () => {
  const currentUser = useSelector(state => state.autenticacao.user)
  const [loading, setLoading] = useState(false)
  const [allSearches, setAllSearches] = useState(
    RecentSearches.getRecentSearches()
  )
  const [mySearches, setMySearches] = useState(
    RecentSearches.getMyRecentSearches()
  )
  const [updatedAt, setUpdatedAt] = useState(
    RecentSearches.getUpdatedAtFromLocalStorage()
  )

  useInterval(() => {
    setLoading(true)
    RecentSearches.updateRecentSearches()
      .then(() => {
        setAllSearches(RecentSearches.getRecentSearches())
        setMySearches(RecentSearches.getMyRecentSearches())
        setUpdatedAt(RecentSearches.getUpdatedAtFromLocalStorage())
      })
      .catch(err => {
        console.error(err)
        toast.error('Erro ao atualizar as buscas recentes', {
          position: toast.POSITION.BOTTOM_RIGHT,
          toastId: 'recent-searches-error'
        })
      })
      .finally(() => setLoading(false))
  }, INTERVAL_TO_UPDATE_HISTORY)

  useEffect(() => {
    const { userId, scope } = RecentSearches.getMetadata()
    const userFromLocalStorage = getUserId()
    const scopeFromLocalStorage = getScope()

    if (
      userFromLocalStorage &&
      (userId !== userFromLocalStorage?.toString() ||
        scope !== scopeFromLocalStorage)
    ) {
      RecentSearches.resetLocalStorage()
      RecentSearches.updateRecentSearches()
        .then(() => {
          setAllSearches(RecentSearches.getRecentSearches())
          setMySearches(RecentSearches.getMyRecentSearches())
          setUpdatedAt(RecentSearches.getUpdatedAtFromLocalStorage())
        })
        .catch(err => {
          console.error(err)
          toast.error('Erro ao atualizar as buscas recentes', {
            position: toast.POSITION.BOTTOM_RIGHT,
            toastId: 'recent-searches-error'
          })
        })
    }
  }, [currentUser])

  return {
    loading,
    allSearches,
    mySearches,
    updatedAt
  }
}

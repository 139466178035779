import {
  resolveProgressStatus,
  isError,
  isLoaded
} from '../../lib/progressStatus'

// Action Types
export const Types = {
  RESET: 'ANALYTICS_THEME/RESET',
  SET_DADOS_BASICOS_S3_PATH: 'ANALYTICS_THEME/SET_DADOS_BASICOS_S3_PATH',
  SET_DADOS_BASICOS_ERROR: 'ANALYTICS_THEME/SET_DADOS_BASICOS_ERROR',
  SET_OPERACIONAIS_S3_PATH: 'ANALYTICS_THEME/SET_OPERACIONAIS_S3_PATH',
  SET_OPERACIONAIS_ERROR: 'ANALYTICS_THEME/SET_OPERACIONAIS_ERROR',
  SET_JIVE_S3_PATH: 'ANALYTICS_THEME/SET_JIVE_S3_PATH',
  SET_JIVE_ERROR: 'ANALYTICS_THEME/SET_JIVE_ERROR',
  SET_ENDIVIDAMENTO_S3_PATH: 'ANALYTICS_THEME/SET_ENDIVIDAMENTO_S3_PATH',
  SET_ENDIVIDAMENTO_ERROR: 'ANALYTICS_THEME/SET_ENDIVIDAMENTO_ERROR',
  SET_PATRIMONIO_S3_PATH: 'ANALYTICS_THEME/SET_PATRIMONIO_S3_PATH',
  SET_PATRIMONIO_ERROR: 'ANALYTICS_THEME/SET_PATRIMONIO_ERROR',
  SET_JURIDICO_S3_PATH: 'ANALYTICS_THEME/SET_JURIDICO_S3_PATH',
  SET_JURIDICO_ERROR: 'ANALYTICS_THEME/SET_JURIDICO_ERROR',
  SET_OFFSHORE_S3_PATH: 'ANALYTICS_THEME/SET_OFFSHORE_S3_PATH',
  SET_OFFSHORE_ERROR: 'ANALYTICS_THEME/SET_OFFSHORE_ERROR'
}

// Reducer
const initialState = {
  dadosBasicos: {
    s3Path: undefined,
    error: false,
    loaded: false
  },
  operacionais: {
    s3Path: undefined,
    error: false,
    loaded: false
  },
  jive: {
    s3Path: undefined,
    error: false,
    loaded: false
  },
  endividamento: {
    s3Path: undefined,
    error: false,
    loaded: false
  },
  patrimonio: {
    s3Path: undefined,
    error: false,
    loaded: false
  },
  offshore: {
    s3Path: undefined,
    error: false,
    loaded: false
  },
  juridico: {
    s3Path: undefined,
    error: false,
    loaded: false
  }
}

export default function reducer (state = initialState, action) {
  switch (action.type) {
    case Types.SET_OPERACIONAIS_S3_PATH: {
      const status = resolveProgressStatus(action.payload)
      return {
        ...state,
        operacionais: {
          ...state.operacionais,
          s3Path: action.payload,
          loaded: isLoaded(status),
          error: isError(status)
        }
      }
    }
    case Types.SET_OPERACIONAIS_ERROR:
      return {
        ...state,
        operacionais: {
          ...state.operacionais,
          s3Path: action.payload,
          error: true,
          loaded: true
        }
      }
    case Types.SET_JURIDICO_S3_PATH: {
      const status = resolveProgressStatus(action.payload)
      return {
        ...state,
        juridico: {
          ...state.juridico,
          s3Path: action.payload,
          loaded: isLoaded(status),
          error: isError(status)
        }
      }
    }
    case Types.SET_JURIDICO_ERROR:
      return {
        ...state,
        juridico: {
          ...state.juridico,
          s3Path: action.payload,
          error: true,
          loaded: true
        }
      }
    case Types.SET_JIVE_S3_PATH: {
      const status = resolveProgressStatus(action.payload)
      return {
        ...state,
        jive: {
          ...state.jive,
          s3Path: action.payload,
          loaded: isLoaded(status),
          error: isError(status)
        }
      }
    }
    case Types.SET_JIVE_ERROR:
      return {
        ...state,
        jive: {
          ...state.jive,
          s3Path: action.payload,
          error: true,
          loaded: true
        }
      }
    case Types.SET_DADOS_BASICOS_S3_PATH: {
      const status = resolveProgressStatus(action.payload)
      return {
        ...state,
        dadosBasicos: {
          ...state.dadosBasicos,
          s3Path: action.payload,
          loaded: isLoaded(status),
          error: isError(status)
        }
      }
    }
    case Types.SET_DADOS_BASICOS_ERROR:
      return {
        ...state,
        dadosBasicos: {
          ...state.dadosBasicos,
          s3Path: action.payload,
          error: true,
          loaded: true
        }
      }
    case Types.SET_ENDIVIDAMENTO_S3_PATH: {
      const status = resolveProgressStatus(action.payload)
      return {
        ...state,
        endividamento: {
          ...state.endividamento,
          s3Path: action.payload,
          loaded: isLoaded(status),
          error: isError(status)
        }
      }
    }
    case Types.SET_ENDIVIDAMENTO_ERROR:
      return {
        ...state,
        endividamento: {
          ...state.endividamento,
          s3Path: action.payload,
          error: true,
          loaded: true
        }
      }
    case Types.SET_PATRIMONIO_S3_PATH: {
      const status = resolveProgressStatus(action.payload)
      return {
        ...state,
        patrimonio: {
          ...state.patrimonio,
          s3Path: action.payload,
          loaded: isLoaded(status),
          error: isError(status)
        }
      }
    }
    case Types.SET_PATRIMONIO_ERROR:
      return {
        ...state,
        patrimonio: {
          ...state.patrimonio,
          s3Path: action.payload,
          error: true,
          loaded: true
        }
      }
    case Types.SET_OFFSHORE_S3_PATH: {
      const status = resolveProgressStatus(action.payload)
      return {
        ...state,
        offshore: {
          ...state.offshore,
          s3Path: action.payload,
          loaded: isLoaded(status),
          error: isError(status)
        }
      }
    }
    case Types.SET_OFFSHORE_ERROR:
      return {
        ...state,
        offshore: {
          ...state.offshore,
          s3Path: action.payload,
          error: true,
          loaded: true
        }
      }
    case Types.RESET:
      return initialState
    default:
      return state
  }
}

// Action Creators
export function setErrorOperacionaisAnalytics (data) {
  return {
    type: Types.SET_OPERACIONAIS_ERROR,
    payload: data
  }
}

export function setS3PathOperacionaisAnalytics (payload) {
  if (!payload.succeeded) {
    payload.succeeded = 1
  }

  if (!payload.total) {
    payload.total = 1
  }

  return {
    type: Types.SET_OPERACIONAIS_S3_PATH,
    payload
  }
}

export function setErrorJiveAnalytics (data) {
  return {
    type: Types.SET_JIVE_ERROR,
    payload: data
  }
}

export function setS3PathJiveAnalytics (payload) {
  if (!payload.succeeded) {
    payload.succeeded = 1
  }

  if (!payload.total) {
    payload.total = 1
  }

  return {
    type: Types.SET_JIVE_S3_PATH,
    payload
  }
}

export function setErrorDadosBasicosAnalytics (data) {
  return {
    type: Types.SET_DADOS_BASICOS_ERROR,
    payload: data
  }
}

export function setS3PathDadosBasicosAnalytics (payload) {
  if (!payload.succeeded) {
    payload.succeeded = 1
  }

  if (!payload.total) {
    payload.total = 1
  }

  return {
    type: Types.SET_DADOS_BASICOS_S3_PATH,
    payload
  }
}

export function setErrorEndividamentoAnalytics (data) {
  return {
    type: Types.SET_ENDIVIDAMENTO_ERROR,
    payload: data
  }
}

export function setS3PathEndividamentoAnalytics (payload) {
  if (!payload.succeeded) {
    payload.succeeded = 1
  }

  if (!payload.total) {
    payload.total = 1
  }

  return {
    type: Types.SET_ENDIVIDAMENTO_S3_PATH,
    payload
  }
}

export function setErrorOffshoreAnalytics (data) {
  return {
    type: Types.SET_OFFSHORE_ERROR,
    payload: data
  }
}

export function setS3PathOffshoreAnalytics (payload) {
  if (!payload.succeeded) {
    payload.succeeded = 1
  }

  if (!payload.total) {
    payload.total = 1
  }

  return {
    type: Types.SET_OFFSHORE_S3_PATH,
    payload
  }
}

export function setErrorPatrimonioAnalytics (data) {
  return {
    type: Types.SET_PATRIMONIO_ERROR,
    payload: data
  }
}

export function setS3PathPatrimonioAnalytics (payload) {
  if (!payload.succeeded) {
    payload.succeeded = 1
  }

  if (!payload.total) {
    payload.total = 1
  }

  return {
    type: Types.SET_PATRIMONIO_S3_PATH,
    payload
  }
}

export function setErrorJuridicoAnalytics (data) {
  return {
    type: Types.SET_JURIDICO_S3_PATH,
    payload: data
  }
}

export function setS3PathJuridicoAnalytics (payload) {
  if (!payload.succeeded) {
    payload.succeeded = 1
  }

  if (!payload.total) {
    payload.total = 1
  }

  return {
    type: Types.SET_JURIDICO_S3_PATH,
    payload
  }
}

export function resetAnalytics () {
  return {
    type: Types.RESET
  }
}

import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity'
import {
  SQSClient as SQSClientAws,
  SendMessageCommand,
  ReceiveMessageCommand,
  DeleteMessageCommand,
  GetQueueAttributesCommand
} from '@aws-sdk/client-sqs'
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity'

import { AWS_IDENTITY_POOL, AWS_REGION } from '../config/env'
import { delay } from '../utils'

const FIRST_DELAY = 20 // 20s
const FAST = 5 * 60 * 1000 // 5min

const SECOND_DELAY = 50 // 50s

const changeDelay = () => {
  const consumer = SQSConsumer.getInstance()
  consumer.delay = SECOND_DELAY
  const timer = consumer.timers.shift()

  if (timer) {
    clearTimeout(timer)
  }
}

export class SQSConsumer {
  constructor ({
    callback,
    queueUrl,
    delay = FIRST_DELAY,
    credentials = undefined,
    identityPoolId = AWS_IDENTITY_POOL,
    region = AWS_REGION,
    sqsClient = undefined
  }) {
    this.region = region
    this.providers = []
    this.delay = delay
    this.queueUrl = queueUrl
    this.callback = callback
    this.running = false
    this.pendingMessages = []
    this.startedTimer = false
    this.timers = []

    SQSConsumer.instance = this

    let internalCredentials
    if (!credentials) {
      internalCredentials = fromCognitoIdentityPool({
        client: new CognitoIdentityClient({ region }),
        identityPoolId: identityPoolId
      })
    } else {
      internalCredentials = credentials
    }

    if (sqsClient) {
      this.client = sqsClient
    } else {
      this.client = new SQSClientAws({
        region: this.region,
        credentials: internalCredentials
      })
    }
  }

  static getInstance () {
    if (!SQSConsumer.instance) {
      SQSConsumer.instance = new SQSConsumer({
        queueUrl: undefined,
        identityPoolId: AWS_IDENTITY_POOL,
        delay: FIRST_DELAY
      })
    }

    return SQSConsumer.instance
  }

  addProvider (providerName) {
    this.providers.push(providerName)
  }

  _someMessageContainsEnd (messages) {
    return messages.some(message => {
      const content = JSON.parse(message.Body)
      return content.action === 'end'
    })
  }

  _endedProviders (messages) {
    return (messages || [])
      .filter(message => {
        const content = JSON.parse(message.Body)
        return content.action === 'end'
      })
      .map(message => message.Attributes?.MessageGroupId)
  }

  async _getAmountOfUndeliveredMessages () {
    const { Attributes } = await this.client.send(
      new GetQueueAttributesCommand({
        QueueUrl: this.queueUrl,
        AttributeNames: ['ApproximateNumberOfMessages']
      })
    )
    return parseInt(Attributes.ApproximateNumberOfMessages)
  }

  async sendMessage ({ queueUrl, message }) {
    const jsonMessage = JSON.stringify(message)

    const response = await this.client.send(
      new SendMessageCommand({
        QueueUrl: queueUrl,
        MessageBody: jsonMessage,
        MessageGroupId: 'MessageGroupId',
        ContentBasedDeduplication: false
      })
    )

    return response
  }

  async _getMessages (maxNumberOfMessages) {
    const response = await this.client.send(
      new ReceiveMessageCommand({
        QueueUrl: this.queueUrl,
        MaxNumberOfMessages: maxNumberOfMessages,
        AttributeNames: ['MessageGroupId']
      })
    )

    const parsedMessages = (response.Messages || []).map(message => {
      const id = message.MessageId
      const receiptHandler = message.ReceiptHandle
      const messageGroupId = message.Attributes?.MessageGroupId
      const content = JSON.parse(message.Body)

      return { id, content, messageGroupId, receiptHandler }
    })
    if (this._someMessageContainsEnd(response.Messages || [])) {
      const endedProviders = this._endedProviders(response.Messages || [])
      const filteredProviders = this.providers.filter(
        provider => !endedProviders.includes(provider)
      )
      this.providers = filteredProviders
    }

    await Promise.allSettled(
      parsedMessages.map(async message =>
        this._ackMessage({
          queueUrl: this.queueUrl,
          receiptHandler: message.receiptHandler
        })
      )
    )

    return parsedMessages
  }

  async consume (maxNumberOfMessages = 10) {
    this.running = true
    while (this.providers.length > 0) {
      await delay(this.delay * 1000)
      const totalUndelivered = await this._getAmountOfUndeliveredMessages()
      const expectedSteps = Math.ceil(totalUndelivered / maxNumberOfMessages)
      const steps = Math.min(expectedSteps, 10)

      console.debug(`mensagens: ${totalUndelivered}, steps: ${steps}`)
      for (let i = 0; i < steps; i += maxNumberOfMessages) {
        const messages = await this._getMessages(maxNumberOfMessages)
        this.pendingMessages.push(...messages)
      }

      if (this.callback && this.pendingMessages.length > 0) {
        if (!this.startedTimer) {
          this.startedTimer = true
          const timeoutId = setTimeout(changeDelay, FAST)
          this.timers.push(timeoutId)
        }
        this.callback(this.pendingMessages)
      }
    }
    this.running = false
  }

  stop () {
    this.providers = []
    this.timers.forEach(timer => clearTimeout(timer))
    this.timers = []
    this.startedTimer = false
    this.running = false
  }

  async _ackMessage ({ queueUrl, receiptHandler }) {
    try {
      const response = await this.client.send(
        new DeleteMessageCommand({
          QueueUrl: queueUrl,
          ReceiptHandle: receiptHandler
        })
      )

      return response
    } catch (err) {
      console.error(err)
    }
  }
}

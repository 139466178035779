import React from 'react'
import { FaTrashAlt } from 'react-icons/fa'
import ReactTooltip from 'react-tooltip'
const RemoveSelected = props => {
  const { onCleanSelected } = props
  return (
    <>
      <ReactTooltip />
      <button
        className='btn btnRemoveSelected'
        data-tip='Limpar seleção'
        onClick={() => onCleanSelected()}
      >
        <FaTrashAlt size={20} />
      </button>
    </>
  )
}

export default RemoveSelected

/* eslint-disable */

import React, { useMemo, useState } from 'react'
import Select from 'react-select'
import camelcaseKeys from 'camelcase-keys'

import './../assets/sass/cards.scss'

import { estatisticas_de_filtro, filterAnd, filterOr } from './../filters'

import classeCNPJ from '../config/classesJuridicasCnj.json'

export default function FiltrosComponent ({ data, onChangeFilter }) {
  const filtersOptions = useMemo(() => {
    const { dbjus } = data || {}
    return estatisticas_de_filtro(dbjus?.found, dbjus?.notFound?.probable)
  }, [data])

  const [filterSelectEstado, setFilterSelectEstado] = useState({
    filter: [],
    option: [],
    type: []
  })

  const [filterSelectJustica, setFilterSelectJustica] = useState({
    filter: [],
    option: [],
    type: []
  })

  const [filterSelectAssuntos, setFilterSelectAssuntos] = useState({
    filter: [],
    option: [],
    type: []
  })

  const [filterSelectPartes, setFilterSelectPartes] = useState({
    filter: [],
    option: [],
    type: []
  })

  const [filterSelectClasseCNJ, setFilterSelectClasseCNJ] = useState({
    filter: [],
    option: [],
    type: []
  })

  const FilterGetSelectItemsEstado = () => {
    const data = filtersOptions
      ? filtersOptions
      : {
          assuntos: {},
          estado: {},
          justica: {},
          partes: {}
        }

    var data2Array = Object.keys(data.estado).map(function (key) {
      return {
        filter: key + ' (' + data.estado[key] + ')',
        option: key,
        type: 'estado'
      }
    })

    return data2Array
  }

  const FilterGetSelectItemsAssuntos = () => {
    const data = filtersOptions
      ? filtersOptions
      : {
          assuntos: {},
          estado: {},
          justica: {},
          partes: {}
        }

    var data2Array = Object.keys(data.assuntos).map(function (key) {
      return {
        filter: key + ' (' + data.assuntos[key] + ')',
        option: key,
        type: 'assuntos'
      }
    })

    return data2Array
  }

  const FilterGetSelectItemsPartes = () => {
    const data = filtersOptions
      ? filtersOptions
      : {
          assuntos: {},
          estado: {},
          justica: {},
          partes: {}
        }

    var data2Array = Object.keys(data.partes).map(function (key) {
      return {
        filter: key + ' (' + data.partes[key] + ')',
        option: key,
        type: 'partes'
      }
    })

    return data2Array
  }

  const FilterGetSelectItemsJustica = () => {
    const data = filtersOptions
      ? filtersOptions
      : {
          assuntos: {},
          estado: {},
          justica: {},
          partes: {}
        }

    var data2Array = Object.keys(data.justica).map(function (key) {
      return {
        filter: key + ' (' + data.justica[key] + ')',
        option: key,
        type: 'justica'
      }
    })

    return data2Array
  }

  const FilterGetSelectItemsClassesCNJ = () => {
    const data = classeCNPJ

    var data2Array = data.map(function (i) {
      return { filter: i, option: i, type: 'classes' }
    })

    return data2Array
  }

  const filterDataset = () => {
    var found = data.dbjus.found
    var not_found = data.dbjus.notFound?.probable
    var relateds = data.relateds
    var scores = data.scores

    let compare_word_classeCNJ = filterSelectClasseCNJ.filter
    let key_classeCNJ = filterSelectClasseCNJ.type

    let compare_word_estado = filterSelectEstado.filter
    let key_estado = filterSelectEstado.type

    let compare_word_justica = filterSelectJustica.filter
    let key_justica = filterSelectJustica.type

    let compare_word_assuntos = filterSelectAssuntos.filter
    let key_assuntos = filterSelectAssuntos.type

    let compare_word_partes = filterSelectPartes.filter
    let key_partes = filterSelectPartes.type

    let estado_filter = filterOr(
      key_estado,
      compare_word_estado,
      found,
      not_found,
      relateds,
      scores
    )
    found = estado_filter[0]
    not_found = estado_filter[1]
    relateds = estado_filter[2]
    scores = estado_filter[3]

    let justica_filter = filterOr(
      key_justica,
      compare_word_justica,
      found,
      not_found,
      relateds,
      scores
    )
    found = justica_filter[0]
    not_found = justica_filter[1]
    relateds = justica_filter[2]
    scores = justica_filter[3]

    let assuntos_filter = filterAnd(
      key_assuntos,
      compare_word_assuntos,
      found,
      not_found,
      relateds,
      scores
    )
    found = assuntos_filter[0]
    not_found = assuntos_filter[1]
    relateds = assuntos_filter[2]
    scores = assuntos_filter[3]

    let partes_filter = filterAnd(
      key_partes,
      compare_word_partes,
      found,
      not_found,
      relateds,
      scores
    )
    found = partes_filter[0]
    not_found = partes_filter[1]
    relateds = partes_filter[2]
    scores = partes_filter[3]

    let classesCNJ_filter = filterAnd(
      key_classeCNJ,
      compare_word_classeCNJ,
      found,
      not_found,
      relateds,
      scores
    )
    found = classesCNJ_filter[0]
    not_found = classesCNJ_filter[1]
    relateds = classesCNJ_filter[2]
    scores = classesCNJ_filter[3]

    let Filtered = {
      dbjus: {
        found: found,
        not_found: {
          probable: not_found,
          improbable: null
        }
      },
      relateds: relateds,
      scores: scores
    }

    onChangeFilter({
      ...data,
      dbjus: camelcaseKeys(Filtered.dbjus, { deep: true }),
      scores: camelcaseKeys(Filtered.scores, { deep: true }),
      relateds: camelcaseKeys(Filtered.relateds, { deep: true })
    })
  }

  return (
    <div className='row mb-2'>
      <div className='col-2'>
        <div className='jive-content'>Estado</div>
        <Select
          styles={{
            multiValueLabel: (styles, { data }) => ({
              ...styles,
              fontSize: '12px',
              backgroundColor: '#bce1a4',
              color: '#343a40'
            })
          }}
          options={FilterGetSelectItemsEstado().map(function (i) {
            return {
              label: i.filter,
              value: i.option,
              type: i.type
            }
          })}
          isMulti={true}
          onChange={changedItems => {
            if (changedItems) {
              if (changedItems.length === 0) {
                setFilterSelectEstado({
                  filter: [],
                  option: [],
                  type: []
                })
              } else {
                setFilterSelectEstado({
                  filter: changedItems.map(function (i) {
                    return i.value
                  }),
                  option: changedItems.map(function (i) {
                    return i.label
                  }),
                  type: changedItems.map(function (i) {
                    return i.type
                  })
                })
              }
            } else {
              setFilterSelectEstado({
                filter: [],
                option: [],
                type: []
              })
            }
          }}
        />
      </div>

      <div className='col-2'>
        <div className='jive-content'>Justiça</div>
        <Select
          styles={{
            multiValueLabel: (styles, { data }) => ({
              ...styles,
              fontSize: '12px',
              backgroundColor: '#bce1a4',
              color: '#343a40'
            })
          }}
          options={FilterGetSelectItemsJustica().map(function (i) {
            return {
              label: i.filter,
              value: i.option,
              type: i.type
            }
          })}
          isMulti={true}
          onChange={changedItems => {
            if (changedItems) {
              if (changedItems.length === 0) {
                setFilterSelectJustica({
                  filter: [],
                  option: [],
                  type: []
                })
              } else {
                setFilterSelectJustica({
                  filter: changedItems.map(function (i) {
                    return i.value
                  }),
                  option: changedItems.map(function (i) {
                    return i.label
                  }),
                  type: changedItems.map(function (i) {
                    return i.type
                  })
                })
              }
            } else {
              setFilterSelectJustica({
                filter: [],
                option: [],
                type: []
              })
            }
          }}
        />
      </div>

      <div className='col-2'>
        <div className='jive-content'>Partes</div>
        <Select
          styles={{
            multiValueLabel: (styles, { data }) => ({
              ...styles,
              fontSize: '12px',
              backgroundColor: '#bce1a4',
              color: '#343a40'
            })
          }}
          options={FilterGetSelectItemsPartes().map(function (i) {
            return {
              label: i.filter,
              value: i.option,
              type: i.type
            }
          })}
          isMulti={true}
          onChange={changedItems => {
            if (changedItems) {
              if (changedItems.length === 0) {
                setFilterSelectPartes({
                  filter: [],
                  option: [],
                  type: []
                })
              } else {
                setFilterSelectPartes({
                  filter: changedItems.map(function (i) {
                    return i.value
                  }),
                  option: changedItems.map(function (i) {
                    return i.label
                  }),
                  type: changedItems.map(function (i) {
                    return i.type
                  })
                })
              }
            } else {
              setFilterSelectPartes({
                filter: [],
                option: [],
                type: []
              })
            }
          }}
        />
      </div>

      <div className='col-2'>
        <div className='jive-content'>Assuntos</div>
        <Select
          styles={{
            multiValueLabel: (styles, { data }) => ({
              ...styles,
              fontSize: '12px',
              backgroundColor: '#bce1a4',
              color: '#343a40'
            })
          }}
          options={FilterGetSelectItemsAssuntos().map(function (i) {
            return {
              label: i.filter,
              value: i.option,
              type: i.type
            }
          })}
          isMulti={true}
          onChange={changedItems => {
            if (changedItems) {
              if (changedItems.length === 0) {
                setFilterSelectAssuntos({
                  filter: [],
                  option: [],
                  type: []
                })
              } else {
                setFilterSelectAssuntos({
                  filter: changedItems.map(function (i) {
                    return i.value
                  }),
                  option: changedItems.map(function (i) {
                    return i.label
                  }),
                  type: changedItems.map(function (i) {
                    return i.type
                  })
                })
              }
            } else {
              setFilterSelectAssuntos({
                filter: [],
                option: [],
                type: []
              })
            }
          }}
        />
      </div>

      <div className='col-2'>
        <div className='jive-content'>Classes CNJ</div>
        <Select
          styles={{
            multiValueLabel: (styles, { data }) => ({
              ...styles,
              fontSize: '12px',
              backgroundColor: '#bce1a4',
              color: '#343a40'
            })
          }}
          options={FilterGetSelectItemsClassesCNJ().map(function (i) {
            return {
              label: i.filter,
              value: i.option,
              type: i.type
            }
          })}
          isMulti={true}
          onChange={changedItems => {
            if (changedItems) {
              if (changedItems.length === 0) {
                setFilterSelectClasseCNJ({
                  filter: [],
                  option: [],
                  type: []
                })
              } else {
                setFilterSelectClasseCNJ({
                  filter: changedItems.map(function (i) {
                    return i.value
                  }),
                  option: changedItems.map(function (i) {
                    return i.label
                  }),
                  type: changedItems.map(function (i) {
                    return i.type
                  })
                })
              }
            } else {
              setFilterSelectClasseCNJ({
                filter: [],
                option: [],
                type: []
              })
            }
          }}
        />
      </div>
      <div className='col-2 d-flex justify-content-end align-items-end'>
        <div>
          <button
            className='btn btn-outline-dark btnFilter'
            onClick={() => filterDataset()}
          >
            <span>Filtrar</span>
          </button>
        </div>
      </div>
    </div>
  )
}

export const DISCOVERY_PROVIDER_NAME = 'DiscoveryStateMachine'
export const DISCOVERY_TOAST_ID = DISCOVERY_PROVIDER_NAME

export const BASIC_INFO_PROVIDER_NAME = 'BasicInfoStateMachine'
export const BASIC_INFO_TOAST_ID = BASIC_INFO_PROVIDER_NAME

export const ENDIVIDAMENTO_PROVIDER_NAME = 'EndividamentoStateMachine'
export const ENDIVIDAMENTO_TOAST_ID = ENDIVIDAMENTO_PROVIDER_NAME

export const OPERACIONAIS_PROVIDER_NAME = 'OperacionaisStateMachine'
export const OPERACIONAIS_TOAST_ID = OPERACIONAIS_PROVIDER_NAME

export const JIVE_PROVIDER_NAME = 'JiveStateMachine'
export const JIVE_TOAST_ID = JIVE_PROVIDER_NAME

export const JIVE_BOTS_PROVIDER_NAME = 'JiveBotsStateMachine'
export const JIVE_BOTS__TOAST_ID = JIVE_BOTS_PROVIDER_NAME

export const OFFSHORE_PROVIDER_NAME = 'OffshoreStateMachine'
export const OFFSHORE_TOAST_ID = OFFSHORE_PROVIDER_NAME

export const BANDEIRA_AMARELA_PROVIDER_NAME = 'BandeiraAmarelaStateMachine'
export const BANDEIRA_AMARELA_TOAST_ID = BANDEIRA_AMARELA_PROVIDER_NAME

export const PATRIMONIO_PROVIDER_NAME = 'PatrimonioStateMachine'
export const PATRIMONIO_TOAST_ID = PATRIMONIO_PROVIDER_NAME

export const JURIDICO_PROVIDER_NAME = 'JuridicoStateMachine'
export const JURIDICO_TOAST_ID = JURIDICO_PROVIDER_NAME

export const MATCH_GRUPO_SOCIETARIO_PROVIDER_NAME =
  'MatchGrupoSocietarioStateMachine'
export const MATCH_GRUPO_SOCIETARIO_TOAST_ID = MATCH_GRUPO_SOCIETARIO_PROVIDER_NAME

export const DISCREDITING_MEDIA_PROVIDER_NAME = 'DiscreditingMediaStateMachine'
export const DISCREDITING_MEDIA_TOAST_ID = JURIDICO_PROVIDER_NAME

export const DOCX_PROVIDER_NAME = 'DocxGeneratorStateMachine'
export const DOCX_TOAST_ID = DOCX_PROVIDER_NAME

export const XLSX_PROVIDER_NAME = 'XlsxGeneratorStateMachine'
export const XLSX_TOAST_ID = XLSX_PROVIDER_NAME

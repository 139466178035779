import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import { useSelector } from 'react-redux'
import Popup from 'reactjs-popup'

import { Table } from '../../../components'
import { onSortDate, onSortDocument } from '../../../components/Table/sort'
import { mapWithRelationship, SOCIO_NUMBER } from '../../../lib/detectMatch'
import { selectMinData } from '../../../redux/selectors/dadosBasicos'
import { selectRelateds } from '../../../redux/selectors/relateds'
import {
  formatDate,
  formatDocument,
  hashObject,
  mapWithFullDocumentAndRelationship,
  onlyNumbers
} from '../../../utils'

const { SearchBar } = Search

export default function Socios ({ data, error }) {
  const [filiais, setFiliais] = useState({
    data: [],
    open: false
  })
  const { targetCase } = useSelector(selectMinData)
  const relateds = useSelector(selectRelateds)
  const [selectedTable, setSelectedTable] = useState('sociosReceitaFederal')

  const { neoway, neowayJunta, receitaFederal } = data?.socios || {}

  if (error) {
    return <div>Erro no carregamento dos dados</div>
  }

  if (
    neoway.length === 0 &&
    neowayJunta.length === 0 &&
    receitaFederal.length === 0
  ) {
    return <div />
  }

  const tableData =
    selectedTable === 'sociosReceitaFederal'
      ? mapWithFullDocumentAndRelationship({
          array: receitaFederal || [],
          relateds,
          documentKey: 'cnpjCpfSocio',
          nameKey: 'nomeRazaoSocial'
        }).map(item => {
          const fullDocument = item.fullDocument || undefined
          const relationshipNumber = item.relationshipNumber || 0
          const isSocios = relationshipNumber & SOCIO_NUMBER

          if (isSocios) {
            return { ...item, fullDocument }
          } else {
            return { ...item, fullDocument: undefined }
          }
        })
      : selectedTable === 'sociosJunta'
      ? neowayJunta
      : neoway

  const columns =
    selectedTable === 'sociosReceitaFederal'
      ? [
          {
            dataField: 'nome',
            text: 'Nome',
            sort: true
          },
          {
            dataField: 'documento',
            text: 'CPF / CNPJ',
            sort: true,
            sortFunc: onSortDocument
          },
          {
            dataField: 'qualificacao',
            text: 'Qualificação',
            sort: true
          },
          {
            dataField: 'relationship',
            text: 'Grau',
            sort: true
          },
          {
            dataField: 'dataEntrada',
            text: 'Data de entrada',
            sortFunc: onSortDate,
            sort: true
          },
          {
            dataField: 'filiaisAtivas',
            text: 'Filiais ativas',
            sort: true
          },
          {
            dataField: 'rawDocumento',
            hidden: true
          }
        ]
      : [
          {
            dataField: 'nome',
            text: 'Nome',
            sort: true
          },
          {
            dataField: 'documento',
            text: 'CPF / CNPJ',
            sortFunc: onSortDocument,
            sort: true
          },
          {
            dataField: 'relationship',
            text: 'Grau',
            sort: true
          },
          {
            dataField: 'falecido',
            text: 'Falecido',
            sort: true
          },
          {
            dataField: 'pep',
            text: 'Nível PEP',
            sort: true
          },
          {
            dataField: 'rawDocumento',
            hidden: true
          }
        ]

  const mappedData = (tableData || []).map(item => {
    const id = hashObject(item)

    if (selectedTable === 'sociosReceitaFederal') {
      const filiais = item.filiaisAtivas || []
      return {
        id,
        rawDocumento: item.cnpjCpfSocio,
        documento: !item.cnpjCpfSocio ? (
          '-'
        ) : item.fullDocument ? (
          <a
            href={`?documento=${onlyNumbers(
              item.fullDocument
            )}&casos=${targetCase.join(';')}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            {!item.fullDocument ? '-' : formatDocument(item.fullDocument)}
          </a>
        ) : (
          item.cnpjCpfSocio
        ),
        nome: item.nomeRazaoSocial,
        dataEntrada: formatDate(item.dataEntradaSociedade),
        qualificacao: item.qualificacaoSocio,
        filiaisAtivas:
          filiais.length === 0 ? null : (
            <button
              className='btn btn-primary'
              disabled={filiais.length === 0}
              onClick={() =>
                setFiliais({
                  data: filiais,
                  open: true
                })
              }
            >
              Ver {filiais.length}
            </button>
          )
      }
    }

    return {
      id,
      rawDocumento: formatDocument(item.documento),
      documento: item.documento ? (
        <a
          href={`?documento=${onlyNumbers(
            item.documento
          )}&casos=${targetCase.join(';')}`}
          target='_blank'
          rel='noopener noreferrer'
        >
          {!item.documento ? '-' : formatDocument(item.documento)}
        </a>
      ) : (
        '-'
      ),
      nome: item.nome,
      falecido: item.falecido ? 'Sim' : 'Não',
      pep: item.nivelPep
    }
  })

  return (
    <>
      <PopupFiliais
        filiais={filiais.data}
        open={filiais.open}
        onClose={() => setFiliais({ data: [], open: false })}
      />
      <ToolkitProvider
        keyField='id'
        data={mapWithRelationship({
          array: mappedData,
          relateds,
          documentKey: 'rawDocumento',
          nameKey: 'nome'
        })}
        columns={columns.map(item => ({
          ...item,
          align: 'center',
          headerAlign: 'center'
        }))}
        search
      >
        {props => (
          <>
            <div className='row mb-2'>
              <div className='col-12 col-lg-6 d-flex flex-row gap-3 mb-3'>
                <Form.Check
                  type='radio'
                  label='Sócios RF'
                  onChange={() => setSelectedTable('sociosReceitaFederal')}
                  checked={selectedTable === 'sociosReceitaFederal'}
                  disabled={error}
                />

                <Form.Check
                  type='radio'
                  label='Sócios QSA (neoway)'
                  onChange={() => setSelectedTable('sociosQsa')}
                  checked={selectedTable === 'sociosQsa'}
                  disabled={error}
                />
                <Form.Check
                  type='radio'
                  label='Sócios Junta (neoway)'
                  onChange={() => setSelectedTable('sociosJunta')}
                  checked={selectedTable === 'sociosJunta'}
                  disabled={error}
                />
              </div>
              <div className='col-12 col-lg-6 text-lg-right'>
                <SearchBar {...props.searchProps} placeholder='Pesquisar' />
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <Table {...props.baseProps} enablePagination />
              </div>
            </div>
          </>
        )}
      </ToolkitProvider>
    </>
  )
}

const PopupFiliais = ({ filiais, open, onClose }) => {
  return (
    <Popup
      closeOnDocumentClick
      modal
      nested
      contentStyle={{ maxHeight: '90vh', width: '95%', overflowY: 'scroll' }}
      open={open}
      onClose={onClose}
    >
      <div className='mx-2 my-2'>
        <div style={{ width: '100%', display: 'flex' }} className='mb-3'>
          <span style={{ flex: 1 }}>Filiais ativas</span>
          <button
            style={{
              cursor: 'pointer',
              padding: '2px 5px',
              lineHeight: '20px',
              right: '-10px',
              top: '-10px',
              fontSize: '24px',
              background: '#ffffff',
              borderRadius: '18px',
              border: '1px solid #cfcece'
            }}
            onClick={onClose}
          >
            X
          </button>
        </div>
        <table className='table table-sm table-striped col-12'>
          <thead>
            <tr>
              <th scope='col'>CNPJ</th>
              <th scope='col'>Município</th>
              <th scope='col'>UF</th>
            </tr>
          </thead>
          <tbody>
            {(filiais || []).map((item, key) => {
              const id = hashObject({ item, key })
              return (
                <tr key={id}>
                  <td>{formatDocument(item.documento)}</td>
                  <td>{item.municipio}</td>
                  <td>{item.uf}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </Popup>
  )
}

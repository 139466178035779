import { onlyNumbers } from '../../utils'

export const onSortDocument = (...args) => {
  const order = args[2]
  const a = args[4]?.rawDocumento ?? ''
  const b = args[5]?.rawDocumento ?? ''

  const cleanedA = onlyNumbers(a)
  const cleanedB = onlyNumbers(b)

  if (order === 'asc') {
    return cleanedA.localeCompare(cleanedB)
  } else {
    return cleanedB.localeCompare(cleanedA)
  }
}

export const compareWithNaN = (a, b) => {
  if (isNaN(a) && isNaN(b)) {
    return 0
  } else if (isNaN(a)) {
    return 1
  } else if (isNaN(b)) {
    return -1
  } else {
    return a - b
  }
}

export const onSortDate = (...args) => {
  const resolveDate = dateString => {
    const date = (dateString || '').replace(
      /(\d{2})\/(\d{2})\/(\d{4})/,
      '$3-$2-$1'
    )

    if (!date || date.length === 0) {
      return NaN
    }

    return new Date(date).getTime()
  }

  const order = args[2]
  const a = args[0] ?? ''
  const b = args[1] ?? ''

  const cleanedA = resolveDate(a)
  const cleanedB = resolveDate(b)

  if (order === 'asc') {
    return compareWithNaN(cleanedA, cleanedB)
  } else {
    return compareWithNaN(cleanedB, cleanedA)
  }
}

export const onSortNumber = (...args) => {
  const order = args[2]
  const a = parseFloat(args[0] ?? '')
  const b = parseFloat(args[1] ?? '')

  if (order === 'asc') {
    return compareWithNaN(a, b)
  } else {
    return compareWithNaN(b, a)
  }
}

export const onSortCurrency = (...args) => {
  const order = args[2]
  const fieldName = args[3]

  const rawFieldName =
    'raw' + fieldName.slice(0, 1).toUpperCase() + fieldName.slice(1)

  const a = args[4]?.[rawFieldName] || ''
  const b = args[5]?.[rawFieldName] || ''

  if (order === 'asc') {
    return compareWithNaN(a, b)
  } else {
    return compareWithNaN(b, a)
  }
}

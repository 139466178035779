export const TOKEN_VALIDATION_INTERVAL = 5 * 60 * 1000 // 5 minutos
export const JURIDICO_NOTIFICATION_INTERVAL = 3 * 60 * 1000 // 3 minutos

export const SEARCH_OPTIONS = [
  {
    value: 'all',
    label: 'Todos',
    flags: []
  },
  // todas as steps dependem dos dados básicos, por isso ela sempre estará habilitada
  // {
  //   value: 'basicInfo',
  //   label: 'Dados Básicos',
  //   flags: []
  // },
  {
    value: 'grupoSocietario',
    label: 'Grupo Societário',
    flags: ['skip_cruzamentos']
  },
  {
    value: 'patrimonio',
    label: 'Patrimônio',
    flags: [
      'skip_aeronaves',
      'skip_sncr',
      'skip_inpi',
      'skip_documents_jive',
      'skip_nome_jive',
      'skip_nomes_jive',
      'skip_jivebots',
      'skip_operacionais'
    ]
  },
  {
    value: 'pontosDeAtencao',
    label: 'Pontos de Atenção',
    flags: [
      'skip_termo_suspensao',
      'skip_termo_embargo',
      'skip_termo_apreensao',
      'skip_auto_infracao',
      'skip_pep',
      'skip_acordos_leniencia',
      'skip_ceaf',
      'skip_ceis',
      'skip_cepim',
      'skip_cnep',
      'skip_cadastro_expulsoes',
      'skip_ofac'
    ]
  },
  {
    value: 'registrais',
    label: 'Registrais',
    flags: ['skip_certidoes']
  },
  {
    value: 'offshore',
    label: 'Offshore',
    flags: [
      'skip_offshore',
      'skip_documents_jive',
      'skip_nome_jive',
      'skip_nomes_jive'
    ]
  },
  {
    value: 'endividamento',
    label: 'Endividamento',
    flags: [
      'skip_bndes',
      'skip_cheque',
      'skip_cndt',
      'skip_comprot',
      'skip_pgfn',
      'skip_protesto'
    ]
  },
  {
    value: 'juridico',
    label: 'Jurídico',
    flags: ['skip_juridico']
  },
  {
    value: 'midiaDesabonadora',
    label: 'Mídia Desabonadora',
    flags: ['skip_news']
  },
  {
    value: 'operacionais',
    label: 'Operacionais',
    flags: ['skip_devedores', 'skip_operacionais']
  }
]

import { createSelector } from 'reselect'

import { removeAccents } from '../../utils'

const getRelateds = state => state.relateds

export const selectRelateds = createSelector(getRelateds, relatedsRedux => {
  const { relateds, relatedsMissingDoc, target } = relatedsRedux.data

  const result = [
    ...relateds,
    ...relatedsMissingDoc,
    { ...target, relationship: 'Target' }
  ]
    .map(related => ({
      ...related,
      personType:
        related.personType === 0
          ? 'Física'
          : related.personType === 1
          ? 'Jurídica'
          : 'Desconhecida',
      relationship: related.relationship || 'Desconhecido'
    }))
    .filter(related => related.name || related.document)
    .map(related => ({
      ...related,
      name: removeAccents(related.name?.toUpperCase().trim()),
      document: related.document?.replace(/\D/g, '')
    }))

  return result
})

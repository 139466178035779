import React, { useState, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import Popup from 'reactjs-popup'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import { toast } from 'react-toastify'

import 'reactjs-popup/dist/index.css'

import { selectMinData } from '../redux/selectors/dadosBasicos'
import { selectRelateds } from '../redux/selectors/relateds'
import { selectSearchOptions } from '../redux/selectors/searchOptions'

import { Content, JiveWindow, Table } from './../components'

import {
  useTokenValidation,
  hashObject,
  formatDocument,
  onlyNumbers,
  useJuridicoNotification,
  isEmpty,
  formatCurrency,
  isPending
} from '../utils'

import { fetchEndividamentoFromS3 } from '../lib/s3Theme'
import { mapWithRelationship } from '../lib/detectMatch'
import {
  JURIDICO_NOTIFICATION_INTERVAL,
  TOKEN_VALIDATION_INTERVAL
} from '../config/consts'
import {
  compareWithNaN,
  onSortDocument,
  onSortNumber
} from '../components/Table/sort'

const { SearchBar } = Search

export default function Endividamento () {
  useTokenValidation(TOKEN_VALIDATION_INTERVAL)
  useJuridicoNotification(JURIDICO_NOTIFICATION_INTERVAL)

  const endividamentoTheme = useSelector(state => state.endividamentoTheme)
  const { targetName, targetDocument } = useSelector(selectMinData)
  const { pages: enabledPages } = useSelector(selectSearchOptions)

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({
    cheque: { data: { target: [], group: [] }, loaded: false, error: false },
    cndt: { data: { target: [], group: [] }, loaded: false, error: false },
    pgfnFgts: { data: { target: [], group: [] }, loaded: false, error: false },
    pgfnPrevidenciario: {
      data: { target: [], group: [] },
      loaded: false,
      error: false
    },
    pgfnNaoPrevidenciario: {
      data: { target: [], group: [] },
      loaded: false,
      error: false
    },
    protesto: { data: { target: [], group: [] }, loaded: false, error: false }
  })

  const [grupoSelecinadoData, setGrupoSelecionadoData] = useState()
  const [visiblePopup, setVisiblePopup] = useState(false)
  const [visiblePopupTitulos, setVisiblePopupTitulos] = useState(false)
  const [cartorioSelecionado, setCartorioSelecionado] = useState(false)
  const [origin, setOrigin] = useState(undefined)

  const isStepPending =
    targetDocument &&
    (isPending(endividamentoTheme.cheque) ||
      isPending(endividamentoTheme.cndt) ||
      isPending(endividamentoTheme.pgfn) ||
      isPending(endividamentoTheme.protesto))

  useEffect(() => {
    if (
      isPending(endividamentoTheme.cheque) ||
      isPending(endividamentoTheme.cndt) ||
      isPending(endividamentoTheme.pgfn) ||
      isPending(endividamentoTheme.protesto)
    ) {
      return
    }

    setLoading(true)

    fetchEndividamentoFromS3({
      chequePayload: endividamentoTheme.cheque,
      cndtPayload: endividamentoTheme.cndt,
      pgfnPayload: endividamentoTheme.pgfn,
      protestoPayload: endividamentoTheme.protesto
    })
      .then(endividamento => {
        setData(endividamento)
      })
      .catch(error => {
        toast.error(error.message)
        console.error(error)
        setData({
          cheque: {
            data: { target: [], group: [] },
            loaded: false,
            error: true
          },
          cndt: { data: { target: [], group: [] }, loaded: false, error: true },
          pgfnFgts: {
            data: { target: [], group: [] },
            loaded: false,
            error: true
          },
          pgfnPrevidenciario: {
            data: { target: [], group: [] },
            loaded: false,
            error: true
          },
          pgfnNaoPrevidenciario: {
            data: { target: [], group: [] },
            loaded: false,
            error: true
          },
          protesto: {
            data: { target: [], group: [] },
            loaded: false,
            error: true
          }
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }, [endividamentoTheme])

  const endividamentoDoGrupo = useMemo(() => {
    const chequeData = [...data.cheque.data.target, ...data.cheque.data.group]
    const cndtData = [...data.cndt.data.target, ...data.cndt.data.group]
    const fgtsData = [...data.pgfnFgts.data.target, ...data.pgfnFgts.data.group]
    const naoPrevidenciarioData = [
      ...data.pgfnNaoPrevidenciario.data.target,
      ...data.pgfnNaoPrevidenciario.data.group
    ]
    const previdenciarioData = [
      ...data.pgfnPrevidenciario.data.target,
      ...data.pgfnPrevidenciario.data.group
    ]
    const protestoData = [
      ...data.protesto.data.target,
      ...data.protesto.data.group
    ]

    const chequesCnpj = chequeData.map(item => item.cpfCnpj)
    const cndtCnpj = cndtData.map(item => item.cpfCnpj)
    const fgtsCnpj = fgtsData.map(item => item.cpfCnpj)
    const naoPrevidenciarioCnpj = naoPrevidenciarioData.map(
      item => item.cpfCnpj
    )
    const protestoCnpj = protestoData.map(item => item.cpfCnpj)

    const documentos = Array.from(
      new Set([
        ...chequesCnpj,
        ...cndtCnpj,
        ...fgtsCnpj,
        ...naoPrevidenciarioCnpj,
        ...protestoCnpj
      ])
    )

    return documentos
      .map(cpfCnpj => {
        const cheques = chequeData.filter(item => item.cpfCnpj === cpfCnpj)
        const cndts = cndtData.filter(item => item.cpfCnpj === cpfCnpj)
        const fgts = fgtsData.filter(
          item => item.cpfCnpj === cpfCnpj && item.isInDebt
        )
        const naoPrevidenciario = naoPrevidenciarioData.filter(
          item => item.cpfCnpj === cpfCnpj && item.isInDebt
        )
        const previdenciario = previdenciarioData.filter(
          item => item.cpfCnpj === cpfCnpj && item.isInDebt
        )
        const protestos = protestoData.filter(item => item.cpfCnpj === cpfCnpj)

        const nomes = Array.from([
          ...cheques.map(item => item.nomeRazaoSocial),
          ...cndts.map(item => item.name),
          ...cndts.map(item => item.name),
          ...fgts.map(item => item.name),
          ...naoPrevidenciario.map(item => item.name),
          ...previdenciario.map(item => item.name),
          ...protestos.map(item => item.name)
        ])

        return {
          cpfCnpj,
          nome: nomes?.[0] || undefined,
          cheque: {
            ...data.cheque,
            data: cheques?.[0]
          },
          cndt: {
            ...data.cndt,
            data: cndts?.[0]
          },
          fgts: {
            ...data.pgfnFgts,
            data: fgts
          },
          naoPrevidenciario: {
            ...data.pgfnNaoPrevidenciario,
            data: naoPrevidenciario
          },
          previdenciario: {
            ...data.pgfnPrevidenciario,
            data: previdenciario
          },
          protesto: {
            ...data.protesto,
            data: protestos?.[0]
          }
        }
      })
      .sort((a, b) => {
        const compareName = a.nome.localeCompare(b.nome)
        const compareDoc = a.cpfCnpj.localeCompare(b.cpfCnpj)

        return compareName === 0 ? compareDoc : compareName
      })
      .sort((x, y) => {
        return x.cpfCnpj === targetDocument
          ? -1
          : y.cpfCnpj === targetDocument
          ? 1
          : 0
      })
  }, [data, targetDocument])

  return (
    <>
      <Content
        title={`Endividamento ${targetName ? `- ${targetName}` : ''}`}
        subtitle='Busca por dívidas e direitos contra o Target.'
      >
        {!enabledPages.endividamento ? (
          <div className='d-flex w-100 h-100 flex-1 justify-content-center align-items-center'>
            <p className='h3'>Esta aba está desabilitada!</p>
          </div>
        ) : loading || isStepPending ? (
          <div className='d-flex w-100 h-100 flex-1 justify-content-center align-items-center'>
            <div
              className='spinner-border text-primary'
              style={{ width: '3rem', height: '3rem' }}
              role='status'
            >
              <span className='sr-only'>Loading...</span>
            </div>
          </div>
        ) : (
          <>
            <div className='row'>
              <div className='col-12'>
                <EndividamentoDoGrupo
                  targetDocument={targetDocument}
                  grupo={endividamentoDoGrupo}
                  onClick={data => {
                    setGrupoSelecionadoData(data)
                    setVisiblePopup(true)
                  }}
                />
                <PopupEndividamentoDoGrupo
                  selecionado={grupoSelecinadoData}
                  open={visiblePopup}
                  onClose={() => setVisiblePopup(false)}
                  onClick={item => {
                    setVisiblePopup(false)
                    setCartorioSelecionado(item)
                    setVisiblePopupTitulos(true)
                    setOrigin('grupo')
                  }}
                />
                <PopupTitulos
                  open={visiblePopupTitulos}
                  onClose={() => {
                    setVisiblePopupTitulos(false)
                    if (origin === 'grupo') {
                      setVisiblePopup(true)
                    }
                  }}
                  selecionado={cartorioSelecionado}
                />
              </div>
            </div>
          </>
        )}
      </Content>
    </>
  )
}

const EndividamentoDoGrupo = ({ targetDocument, grupo, onClick }) => {
  const { targetCase } = useSelector(selectMinData)
  const relateds = useSelector(selectRelateds)
  const name = 'endividamentos.endividamentoDoGrupo'

  const tableData = mapWithRelationship({
    array: grupo || [],
    documentKey: 'cpfCnpj',
    nameKey: 'nome',
    relateds
  })
    .map(
      ({
        cpfCnpj,
        fgts,
        naoPrevidenciario,
        previdenciario,
        protesto,
        cheque,
        cndt,
        relationship,
        nome
      }) => ({
        nome,
        doc: cpfCnpj,
        fgts: fgts?.data,
        naoPrevidenciario: naoPrevidenciario?.data,
        previdenciario: previdenciario?.data,
        protesto: protesto?.data,
        cheque: cheque?.data,
        relationship,
        cndt: cndt?.data
      })
    )
    .map(data => {
      const totalProtestado = data.protesto?.totalProtestado || 0
      const totalFgts = (data.fgts || []).reduce(
        (acc, item) => acc + parseFloat(item.valorConsolidado),
        0
      )
      const totalNaoPrevidenciario = (data.naoPrevidenciario || []).reduce(
        (acc, item) => acc + parseFloat(item.valorConsolidado),
        0
      )
      const totalPrevidenciario = (data.previdenciario || []).reduce(
        (acc, item) => acc + parseFloat(item.valorConsolidado),
        0
      )

      const total =
        totalProtestado +
        totalFgts +
        totalNaoPrevidenciario +
        totalPrevidenciario

      return {
        nome: data.nome,
        cleanDocumento: data.doc,
        rawDocumento: formatDocument(data.doc),
        grau: data.relationship,
        doc: data.doc ? (
          <a
            href={`?documento=${onlyNumbers(data.doc)}&casos=${targetCase.join(
              ';'
            )}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            {formatDocument(data.doc)}
          </a>
        ) : (
          '-'
        ),
        total: formatCurrency(total, undefined, undefined, {
          notation: 'compact'
        }),
        rawTotal: total,
        numProtestos: isEmpty(data.protesto?.qtdTitulos)
          ? data.protesto?.isInDebt === false
            ? 0
            : '-'
          : data.protesto?.qtdTitulos,
        temCartorios:
          data.protesto?.cartorio?.length > 0 ? (
            <button
              style={{
                all: 'unset',
                color: 'blue',
                cursor: 'pointer',
                textDecoration: 'underline'
              }}
              onClick={() => {
                onClick({
                  type: 'cartorios',
                  data: data.protesto?.cartorio
                })
              }}
            >
              Sim (
              {formatCurrency(totalProtestado, undefined, undefined, {
                notation: 'compact'
              })}
              )
            </button>
          ) : (
            'Não'
          ),
        cndt: isEmpty(data.cndt?.isInDebt)
          ? '-'
          : data.cndt?.isInDebt
          ? 'Consta'
          : 'Não consta',
        fgts:
          data.fgts?.length > 0 ? (
            <button
              style={{
                all: 'unset',
                color: 'blue',
                cursor: 'pointer',
                textDecoration: 'underline'
              }}
              onClick={() => {
                onClick({
                  type: 'fgts',
                  data: data.fgts
                })
              }}
            >
              {data.fgts.length} (
              {formatCurrency(totalFgts, undefined, undefined, {
                notation: 'compact'
              })}
              ) Sim
            </button>
          ) : (
            'Não'
          ),
        naoPrev:
          data.naoPrevidenciario?.length > 0 ? (
            <button
              style={{
                all: 'unset',
                color: 'blue',
                cursor: 'pointer',
                textDecoration: 'underline'
              }}
              onClick={() => {
                onClick({
                  type: 'naoPrevidenciarios',
                  data: data.naoPrevidenciario
                })
              }}
            >
              {data.naoPrevidenciario.length} (
              {formatCurrency(totalNaoPrevidenciario, undefined, undefined, {
                notation: 'compact'
              })}
              )
            </button>
          ) : (
            'Não'
          ),
        prev:
          data.previdenciario?.length > 0 ? (
            <button
              style={{
                all: 'unset',
                color: 'blue',
                cursor: 'pointer',
                textDecoration: 'underline'
              }}
              onClick={() => {
                onClick({
                  type: 'previdenciarios',
                  data: data.previdenciario
                })
              }}
            >
              {data.previdenciario.length} (
              {formatCurrency(totalPrevidenciario, undefined, undefined, {
                notation: 'compact'
              })}
              )
            </button>
          ) : (
            'Não'
          )
      }
    })
    .sort((x, y) => {
      return x.cleanDocumento === targetDocument
        ? -1
        : y.cleanDocumento === targetDocument
        ? 1
        : 0
    })

  const columns = [
    { dataField: 'nome', text: 'Nome', sort: true },
    {
      dataField: 'doc',
      text: 'DOC',
      searchable: false,
      sort: true,
      sortFunc: onSortDocument
    },
    { dataField: 'rawDocumento', hidden: true },
    {
      dataField: 'grau',
      text: 'Grau',
      sort: true
    },
    {
      dataField: 'total',
      text: 'Valor total',
      sort: true,
      sortFunc: (...args) => {
        const order = args[2]
        const a = parseFloat(args[4]?.rawTotal ?? '')
        const b = parseFloat(args[5]?.rawTotal ?? '')

        if (order === 'asc') {
          return compareWithNaN(a, b)
        } else {
          return compareWithNaN(b, a)
        }
      }
    },
    {
      dataField: 'numProtestos',
      text: 'Nº de protestos',
      sort: true,
      sortFunc: onSortNumber
    },
    { dataField: 'temCartorios', text: 'Cartórios', sort: true },
    { dataField: 'cndt', text: 'CNDT', sort: true },
    { dataField: 'fgts', text: 'PGFN.FGTS', sort: true },
    { dataField: 'naoPrev', text: 'PGFN.NAO PREV', sort: true },
    { dataField: 'prev', text: 'PGFN.PREV' }
  ].map(item => ({ ...item, align: 'center', headerAlign: 'center' }))

  return (
    <JiveWindow title='Endividamento do grupo' name={name} showNote>
      <ToolkitProvider keyField='id' data={tableData} columns={columns} search>
        {props => (
          <>
            <div className='row'>
              <div className='col-12 text-lg-right'>
                <SearchBar {...props.searchProps} placeholder='Pesquisar' />
              </div>
              <div className='col-12'>
                <Table enablePagination {...props.baseProps} />
              </div>
            </div>
          </>
        )}
      </ToolkitProvider>
    </JiveWindow>
  )
}

const PopupEndividamentoDoGrupo = ({ selecionado, open, onClose, onClick }) => {
  return (
    <Popup
      closeOnDocumentClick
      modal
      nested
      contentStyle={{ maxHeight: '90vh', width: '95%', overflowY: 'scroll' }}
      open={open}
      onClose={onClose}
    >
      {selecionado?.type === 'fgts' && (
        <div>
          <div
            className='titleDividas'
            style={{ width: '100%', display: 'flex' }}
          >
            <span style={{ flex: 1 }}>Dados Abertos FGTS</span>
            <button
              style={{
                cursor: 'pointer',
                padding: '2px 5px',
                lineHeight: '20px',
                right: '-10px',
                top: '-10px',
                fontSize: '24px',
                background: '#ffffff',
                borderRadius: '18px',
                border: '1px solid #cfcece'
              }}
              onClick={onClose}
            >
              X
            </button>
          </div>
          <table className='table table-sm table-striped tableDividas'>
            <thead>
              <tr>
                <th scope='col'>valor</th>
                <th scope='col'>doc</th>
                <th scope='col'>data insc.</th>
                <th scope='col'>entidade</th>
                <th scope='col'>ajuizado</th>
                <th scope='col'>devedor</th>
                <th scope='col'>num. inscrição</th>
                <th scope='col'>receita</th>
                <th scope='col'>sit. inscrição</th>
                <th scope='col'>tipo</th>
                <th scope='col'>pessoa</th>
                <th scope='col'>situação inscr.</th>
                <th scope='col'>uf</th>
                <th scope='col'>unidade inscr.</th>
                <th scope='col'>unidade resp.</th>
              </tr>
            </thead>
            <tbody>
              {selecionado.data.map((divida, key) => (
                <tr key={key}>
                  <th scope='row'>{divida.valorConsolidado}</th>
                  <td>{divida.cpfCnpj}</td>
                  <td>{divida.dataInscricao}</td>
                  <td>{divida.entidadeResponsavel}</td>
                  <td>{divida.indicadorAjuizado}</td>
                  <td>{divida.nomeDevedor}</td>
                  <td>{divida.numeroInscricao}</td>
                  <td>{divida.receitaPrincipal}</td>
                  <td>{divida.situacaoInscricao}</td>
                  <td>{divida.tipoDevedor}</td>
                  <td>{divida.tipoPessoa}</td>
                  <td>{divida.tipoSituacaoInscricao}</td>
                  <td>{divida.ufUnidadeResponsavel}</td>
                  <td>{divida.unidadeInscricao}</td>
                  <td>{divida.unidadeResponsavel}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {selecionado?.type === 'cartorios' && (
        <div>
          <div
            className='titleDividas'
            style={{ width: '100%', display: 'flex' }}
          >
            <span style={{ flex: 1 }}>Cartórios</span>
            <button
              style={{
                cursor: 'pointer',
                padding: '2px 5px',
                lineHeight: '20px',
                right: '-10px',
                top: '-10px',
                fontSize: '24px',
                background: '#ffffff',
                borderRadius: '18px',
                border: '1px solid #cfcece'
              }}
              onClick={onClose}
            >
              X
            </button>
          </div>
          <table className='table table-sm table-striped col-12 table-cartorio'>
            <thead>
              <tr>
                <th scope='col'>#</th>
                <th scope='col'>Cod. Cid.</th>
                <th scope='col'>Endereço</th>
                <th scope='col'>Nome</th>
                <th scope='col'>Protestos</th>
                <th scope='col'>Telefone</th>
                <th scope='col'>UF</th>
                <th scope='col' />
              </tr>
            </thead>
            <tbody>
              {(selecionado?.data || []).map((item, key) => {
                const id = hashObject({ item, key })
                return (
                  <tr key={id}>
                    <th scope='row'>{item.numeroCartorio}</th>
                    <td>{item.codIbge}</td>
                    <td>{item.endereco}</td>
                    <td>{item.nomeCartorio}</td>
                    <td className='text-center'>{item.qtdTitulos}</td>
                    <td>{item.telefone}</td>
                    <td>{item.uf}</td>
                    <td>
                      <button
                        className='btn btn-primary btn-sm'
                        onClick={() => onClick(item)}
                      >
                        Ver títulos
                      </button>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      )}
      {selecionado?.type === 'previdenciarios' && (
        <div>
          <div
            className='titleDividas'
            style={{ width: '100%', display: 'flex' }}
          >
            <span style={{ flex: 1 }}>Dados abertos previdenciários</span>
            <button
              style={{
                cursor: 'pointer',
                padding: '2px 5px',
                lineHeight: '20px',
                right: '-10px',
                top: '-10px',
                fontSize: '24px',
                background: '#ffffff',
                borderRadius: '18px',
                border: '1px solid #cfcece'
              }}
              onClick={onClose}
            >
              X
            </button>
          </div>
          <table className='table table-sm table-striped tableDividas'>
            <thead>
              <tr>
                <th scope='col'>valor</th>
                <th scope='col'>doc</th>
                <th scope='col'>data insc.</th>
                <th scope='col'>entidade</th>
                <th scope='col'>ajuizado</th>
                <th scope='col'>devedor</th>
                <th scope='col'>num. inscrição</th>
                <th scope='col'>receita</th>
                <th scope='col'>sit. inscrição</th>
                <th scope='col'>tipo</th>
                <th scope='col'>pessoa</th>
                <th scope='col'>situação inscr.</th>
                <th scope='col'>uf</th>
                <th scope='col'>unidade inscr.</th>
                <th scope='col'>unidade resp.</th>
              </tr>
            </thead>
            <tbody>
              {selecionado?.data?.map((divida, key) => (
                <tr key={key}>
                  <th scope='row'>{divida.valorConsolidado}</th>
                  <td>{divida.cpfCnpj}</td>
                  <td>{divida.dataInscricao}</td>
                  <td>{divida.entidadeResponsavel}</td>
                  <td>{divida.indicadorAjuizado}</td>
                  <td>{divida.nomeDevedor}</td>
                  <td>{divida.numeroInscricao}</td>
                  <td>{divida.receitaPrincipal}</td>
                  <td>{divida.situacaoInscricao}</td>
                  <td>{divida.tipoDevedor}</td>
                  <td>{divida.tipoPessoa}</td>
                  <td>{divida.tipoSituacaoInscricao}</td>
                  <td>{divida.ufUnidadeResponsavel}</td>
                  <td>{divida.unidadeInscricao}</td>
                  <td>{divida.unidadeResponsavel}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {selecionado?.type === 'naoPrevidenciarios' && (
        <div>
          <div
            className='titleDividas'
            style={{ width: '100%', display: 'flex' }}
          >
            <span style={{ flex: 1 }}>Dados abertos não previdenciários</span>
            <button
              style={{
                cursor: 'pointer',
                padding: '2px 5px',
                lineHeight: '20px',
                right: '-10px',
                top: '-10px',
                fontSize: '24px',
                background: '#ffffff',
                borderRadius: '18px',
                border: '1px solid #cfcece'
              }}
              onClick={onClose}
            >
              X
            </button>
          </div>
          <table className='table table-sm table-striped tableDividas'>
            <thead>
              <tr>
                <th scope='col'>valor</th>
                <th scope='col'>doc</th>
                <th scope='col'>data insc.</th>
                <th scope='col'>entidade</th>
                <th scope='col'>ajuizado</th>
                <th scope='col'>devedor</th>
                <th scope='col'>num. inscrição</th>
                <th scope='col'>receita</th>
                <th scope='col'>sit. inscrição</th>
                <th scope='col'>tipo</th>
                <th scope='col'>pessoa</th>
                <th scope='col'>situação inscr.</th>
                <th scope='col'>uf</th>
                <th scope='col'>unidade inscr.</th>
                <th scope='col'>unidade resp.</th>
              </tr>
            </thead>
            <tbody>
              {selecionado?.data?.map((divida, key) => (
                <tr key={key}>
                  <th scope='row'>{divida.valorConsolidado}</th>
                  <td>{divida.cpfCnpj}</td>
                  <td>{divida.dataInscricao}</td>
                  <td>{divida.entidadeResponsavel}</td>
                  <td>{divida.indicadorAjuizado}</td>
                  <td>{divida.nomeDevedor}</td>
                  <td>{divida.numeroInscricao}</td>
                  <td>{divida.receitaPrincipal}</td>
                  <td>{divida.situacaoInscricao}</td>
                  <td>{divida.tipoDevedor}</td>
                  <td>{divida.tipoPessoa}</td>
                  <td>{divida.tipoSituacaoInscricao}</td>
                  <td>{divida.ufUnidadeResponsavel}</td>
                  <td>{divida.unidadeInscricao}</td>
                  <td>{divida.unidadeResponsavel}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </Popup>
  )
}

const PopupTitulos = ({ selecionado, open, onClose }) => {
  return (
    <Popup
      closeOnDocumentClick
      modal
      nested
      contentStyle={{ maxHeight: '90vh', width: '95%', overflowY: 'scroll' }}
      open={open}
      onClose={onClose}
    >
      <div>
        <div
          className='titleDividas'
          style={{ width: '100%', display: 'flex' }}
        >
          <span style={{ flex: 1 }}>Titulos</span>
          <button
            style={{
              cursor: 'pointer',
              padding: '2px 5px',
              lineHeight: '20px',
              right: '-10px',
              top: '-10px',
              fontSize: '24px',
              background: '#ffffff',
              borderRadius: '18px',
              border: '1px solid #cfcece'
            }}
            onClick={onClose}
          >
            X
          </button>
        </div>
        <table className='table table-sm table-striped col-12 table-cartorio'>
          <thead>
            <tr>
              <th scope='col'>Número chave</th>
              <th scope='col'>Data protesto</th>
              <th scope='col'>Data vencimento</th>
              <th scope='col'>Apresentante</th>
              <th scope='col'>Cedente</th>
              <th scope='col'>Tem anuência?</th>
              <th scope='col'>Valor</th>
            </tr>
          </thead>
          <tbody>
            {(selecionado?.titulos || []).map((item, key) => {
              const id = hashObject({ item, key })
              return (
                <tr key={id}>
                  <th scope='row'>{item.nmChave}</th>
                  <td>{item.dataProtesto}</td>
                  <td>{item.dataVencimento}</td>
                  <td>{item.nomeApresentante}</td>
                  <td>{item.nomeCedente}</td>
                  <td>
                    {item.temAnuencia?.toLowerCase() === 'true' ? 'Sim' : 'Não'}
                  </td>
                  <td>{item.valorProtestado}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </Popup>
  )
}

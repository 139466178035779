import { select } from 'redux-saga/effects'
import { toast } from 'react-toastify'

import { selectJuridicoProgressStatus } from '../selectors/progressStatus'
import { JURIDICO_TOAST_ID } from '../../config/stepFunction'
import { PROGRESS_TYPES } from '../../lib/progressStatus'

export function * handleErrorJuridico (action) {
  const { status } = action.payload
  const { error } = yield select(selectJuridicoProgressStatus)

  if (status === PROGRESS_TYPES.ERROR) {
    const totalErrors = error.length
    const message =
      totalErrors === 1
        ? `${totalErrors} documento falhou`
        : `${totalErrors} documentos falharam`
    toast.error(`Jurídico: ${message}`, {
      toastId: `${JURIDICO_TOAST_ID}-document-error-message`
    })
  }
}

import { toast } from 'react-toastify'

import {
  isValidCasesOrOpportunities,
  isValidCNPJ,
  isValidCPF
} from './validators'
import API from '../services/api'
import { getAuthToken } from '../utils'

import { resetOffshoreTheme } from '../redux/store/offshoreTheme'
import { resetJiveTheme } from '../redux/store/jiveTheme'
import { resetJuridicoTheme } from '../redux/store/juridicoTheme'
import { resetPatrimonioTheme } from '../redux/store/patrimonioTheme'
import { resetDadosBasicosTheme } from '../redux/store/dadosBasicosTheme'
import { resetOperacionaisTheme } from '../redux/store/operacionaisTheme'
import { resetEndividamentoTheme } from '../redux/store/endividamentoTheme'
import { resetDiscreditingMediaTheme } from '../redux/store/discreditingMediaTheme'

import { resetGrafoData } from '../redux/actions/grafo'
import { resetGoogleMaps } from '../redux/actions/googleMaps'
import { resetMatches } from '../redux/actions/matches'
import { resetDocx } from '../redux/store/doc'
import {
  openProgressStatus,
  resetProgressStatus
} from '../redux/store/progressStatus'
import { resetGlobalStep, trocarDocumento } from '../redux/store/globalStep'
import { resetXlsx } from '../redux/store/xlsx'
import { SEARCH_OPTIONS } from '../config/consts'
import { resetAnalytics } from '../redux/store/analyticsTheme'

const TOAST_ID_DOCUMENT_INPUT = 'error_invalid_document_input'

export default function searchDocument ({
  documento,
  casos,
  user,
  options,
  dispatch
}) {
  documento = documento.replace(/\D/g, '')

  const allKeys = SEARCH_OPTIONS.filter(item => item.value !== 'all')
    .map(item => item.flags)
    .flat()
    .reduce((obj, flag) => {
      obj[flag] = true // desabilita todas as flags por default
      return obj
    }, {})

  const allThemes = SEARCH_OPTIONS.filter(item => item.value !== 'all')
    .map(item => item.value)
    .flat()

  const internalOptions = options.find(item => item.value === 'all')
    ? Object.fromEntries(
        Object.entries(allKeys).map(([key, _]) => [key, false])
      )
    : options
        .map(item => item.flags)
        .flat()
        .reduce((obj, flag) => {
          obj[flag] = false // ativa apenas as flags selecionadas
          return obj
        }, {})

  const themes = options.find(item => item.value === 'all')
    ? allThemes
    : options.map(item => item.value).flat()

  if (options.length === 0) {
    toast.error('Selecione ao menos uma opção de busca', {
      position: toast.POSITION.BOTTOM_RIGHT,
      toastId: TOAST_ID_DOCUMENT_INPUT
    })
    return
  }

  const filteredCasos = casos
    .map(item => item.trim().toUpperCase())
    .filter(Boolean)

  if (!filteredCasos || filteredCasos.length === 0) {
    toast.error('Você deve informar um caso ou oportunidade', {
      position: toast.POSITION.BOTTOM_RIGHT,
      toastId: TOAST_ID_DOCUMENT_INPUT
    })
    return false
  }
  if (!isValidCasesOrOpportunities(filteredCasos)) {
    toast.error('Caso ou oportunidade inválido', {
      position: toast.POSITION.BOTTOM_RIGHT,
      toastId: TOAST_ID_DOCUMENT_INPUT
    })
    return false
  }

  if (documento.length !== 11 && documento.length !== 14) {
    toast.error('Documento inválido! Deve informar um CPF ou CNPJ', {
      position: toast.POSITION.BOTTOM_RIGHT,
      toastId: TOAST_ID_DOCUMENT_INPUT
    })
    return false
  }

  if (documento.length === 11 && !isValidCPF(documento)) {
    toast.error('CPF inválido', {
      position: toast.POSITION.BOTTOM_RIGHT,
      toastId: TOAST_ID_DOCUMENT_INPUT
    })
    return false
  }

  if (documento.length === 14 && !isValidCNPJ(documento)) {
    toast.error('CNPJ inválido', {
      position: toast.POSITION.BOTTOM_RIGHT,
      toastId: TOAST_ID_DOCUMENT_INPUT
    })
    return false
  }

  if (documento === '') {
    toast.error('Sem documento', {
      position: toast.POSITION.BOTTOM_RIGHT,
      toastId: TOAST_ID_DOCUMENT_INPUT
    })
    return false
  }

  dispatch(resetDocx())
  dispatch(resetXlsx())
  dispatch(resetProgressStatus())
  dispatch(resetGrafoData())
  dispatch(resetGoogleMaps())
  dispatch(resetMatches())
  dispatch(resetAnalytics())

  dispatch(resetOffshoreTheme())
  dispatch(resetJiveTheme())
  dispatch(resetJuridicoTheme())
  dispatch(resetPatrimonioTheme())
  dispatch(resetDadosBasicosTheme())
  dispatch(resetOperacionaisTheme())
  dispatch(resetEndividamentoTheme())
  dispatch(resetDiscreditingMediaTheme())
  dispatch(resetGlobalStep())

  dispatch(
    trocarDocumento({
      documento,
      casos: filteredCasos,
      options: { ...allKeys, ...internalOptions },
      themes
    })
  )

  dispatch(openProgressStatus())

  const notificarPesquisa = async () => {
    try {
      await API.autenticacao.salvarLog({
        application: user?.scope,
        resource: documento,
        token: getAuthToken(),
        user: user
      })
    } catch (err) {
      console.error(err)
    }
  }

  notificarPesquisa()
  return true
}

import { createSelector } from 'reselect'

import { toCamelCase } from '../../utils'

const getOptions = state => state.globalStep.options

export const selectSearchOptions = createSelector(getOptions, options => {
  const objectFiltered = Object.fromEntries(
    Object.entries(options).map(([key, value]) => {
      const newKey = key.replace('skip_', 'enable_')
      return [newKey, !value]
    })
  )

  const hasItems = Object.keys(objectFiltered).length > 0
  const items = toCamelCase(objectFiltered)

  const dadosBasicos = true // todas as steps dependem dos dados básicos, por isso ela sempre estará habilitada
  const grupoSocietario = !hasItems ? true : items.enableCruzamentos
  const patrimonio = !hasItems
    ? true
    : items.enableAeronaves &&
      items.enableSncr &&
      items.enableInpi &&
      items.enableJivebots &&
      items.enableDocumentsJive &&
      items.enableNomeJive &&
      items.enableNomesJive
  const registrais = !hasItems ? true : items.enableCertidoes
  const offshore = !hasItems
    ? true
    : items.enableOffshore &&
      items.enableDocumentsJive &&
      items.enableNomeJive &&
      items.enableNomesJive
  const endividamento = !hasItems
    ? true
    : items.enableBndes &&
      items.enableCheque &&
      items.enableCndt &&
      items.enableComprot &&
      items.enablePgfn &&
      items.enableProtesto
  const juridico = !hasItems ? true : items.enableJuridico
  const pontosAtencao = !hasItems
    ? true
    : items.enableTermoSuspensao &&
      items.enableTermoEmbargo &&
      items.enableTermoApreensao &&
      items.enableAutoInfracao &&
      items.enablePep &&
      items.enableAcordosLeniencia &&
      items.enableCeaf &&
      items.enableCeis &&
      items.enableCepim &&
      items.enableCnep &&
      items.enableCadastroExpulsoes &&
      items.enableOfac
  const midiaDesabonadora = !hasItems ? true : items.enableNews
  const operacionais = !hasItems
    ? true
    : items.enableDevedores && items.enableOperacionais

  return {
    items,
    pages: {
      dadosBasicos,
      grupoSocietario,
      patrimonio,
      registrais,
      offshore,
      endividamento,
      juridico,
      operacionais,
      pontosAtencao,
      midiaDesabonadora
    }
  }
})

import React from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import {
  TiArrowSortedUp,
  TiArrowUnsorted,
  TiArrowSortedDown
} from 'react-icons/ti'

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'

const sizePerPageRenderer = ({
  options,
  currSizePerPage,
  onSizePerPageChange
}) => (
  <span style={{ fontSize: 12 }} className='mr-2'>
    <span className='mr-2'>Linhas por página:</span>
    <select
      className='custom-select w-auto'
      style={{ fontSize: 12 }}
      onChange={event => onSizePerPageChange(event.target.value)}
    >
      {options.map(option => (
        <option
          key={option.text}
          value={option.page}
          selected={option.page === currSizePerPage}
        >
          {option.text}
        </option>
      ))}
    </select>
  </span>
)

const pageListRenderer = ({ pages, onPageChange }) => {
  const pageWithoutIndication = pages.filter(
    p => typeof p.page !== 'number' || ['>>', '<<'].includes(p.page)
  )

  return (
    <div className='col-md-6 col-xs-6 col-sm-6 col-lg-6 mx-0 text-right'>
      <div className='gap-2'>
        {pageWithoutIndication.map(p => (
          <button
            key={p.page.toString()}
            className={`btn btn-sm ml-2 ${p.active ? 'btn-primary' : ''}`}
            onClick={() => onPageChange(p.page)}
          >
            {p.page}
          </button>
        ))}
      </div>
    </div>
  )
}

const customTotal = (from, to, size) => (
  <span
    className='react-bootstrap-table-pagination-total'
    style={{ fontSize: 12 }}
  >
    {from} - {to} de {size}
  </span>
)

const options = {
  sizePerPageRenderer,
  pageListRenderer,
  alwaysShowAllBtns: true, // Always show next and previous button
  withFirstAndLast: false, // Hide the going to First and Last page button
  prePageText: '<',
  nextPageText: '>',
  showTotal: true,
  paginationTotalRenderer: customTotal,
  sizePerPageList: [
    {
      text: '5',
      value: 5
    },
    {
      text: '10',
      value: 10
    },
    {
      text: '30',
      value: 30
    },
    {
      text: '50',
      value: 50
    }
  ]
}

const sortOption = {
  sortCaret: (order, column) => {
    const SortIcon = !order
      ? TiArrowUnsorted
      : order === 'desc'
      ? TiArrowSortedDown
      : order === 'asc'
      ? TiArrowSortedUp
      : null
    if (SortIcon !== null) {
      return (
        <span>
          {' '}
          <SortIcon alt='sortIcon' />{' '}
        </span>
      )
    }
    return null
  }
}

function Table ({
  columns,
  data,
  enablePagination,
  keyField,
  noDataIndication = 'Sem dados',
  wrapperClasses,
  ...props
}) {
  return (
    <BootstrapTable
      bootstrap4
      bordered={false}
      wrapperClasses={wrapperClasses ?? 'table-max-width overflow-auto'}
      classes='table table-striped table-horizontal-scroll'
      footerClasses='mx-0 px-0'
      noDataIndication={noDataIndication}
      keyField={keyField}
      data={data}
      columns={columns}
      sort={sortOption}
      {...(enablePagination && { pagination: paginationFactory(options) })}
      {...props}
    />
  )
}

export default Table

import axios from 'axios'
import { LOGS_BASE_URL } from '../../config/env'
import { getScope, getUserId, isValidToken } from '../../utils'

export const salvarLog = ({ application, resource, user, token }) => {
  const url = `${LOGS_BASE_URL}/api/logs`

  const scope = getScope()
  const userId = getUserId()

  let headers = {}
  let payload = {}

  if (scope === 'jive') {
    payload = {
      application: scope,
      resource,
      user: userId
    }
  } else if (scope === 'discovery') {
    if (token && isValidToken(token)) {
      headers = {
        Authorization: `Bearer ${token}`
      }

      payload = {
        application: scope,
        resource
      }
    } else {
      payload = {
        application: scope,
        resource,
        user: userId.toString()
      }
    }
  } else {
    console.error(`Scope inválido:${scope}`)
  }

  return axios.post(url, payload, { headers })
}

import { toast } from 'react-toastify'
import { call, put, select } from 'redux-saga/effects'

import {
  BASIC_INFO_PROVIDER_NAME,
  DISCOVERY_PROVIDER_NAME,
  JIVE_PROVIDER_NAME,
  JURIDICO_PROVIDER_NAME,
  MATCH_GRUPO_SOCIETARIO_PROVIDER_NAME,
  OFFSHORE_PROVIDER_NAME,
  OPERACIONAIS_PROVIDER_NAME,
  PATRIMONIO_PROVIDER_NAME,
  DISCREDITING_MEDIA_PROVIDER_NAME
} from '../../config/stepFunction'
import { PROGRESS_TYPES } from '../../lib/progressStatus'

import { fetchDadosBasicosFromS3 } from '../../lib/s3Theme'
import { SQSConsumer } from '../../lib/sqs'
import API from '../../services/api'

import {
  setErrorGlobalStep,
  setMetadataGlobalStep,
  setQueueUrlGlobalStep,
  setTargetCaseGlobalStep,
  setTargetDocumentGlobalStep,
  setSearchOptionsGlobalStep,
  setTargetNameGlobalStep,
  setTargetThemesGlobalStep
} from '../store/globalStep'
import { setStatusProgressStatus } from '../store/progressStatus'

export function * startGlobalStep (action) {
  try {
    const { documento: document, casos, options, themes } = action.payload

    yield put(setTargetDocumentGlobalStep(document))
    yield put(setTargetCaseGlobalStep(casos))
    yield put(setTargetThemesGlobalStep(themes))
    yield put(setSearchOptionsGlobalStep(options))

    const { queueUrl } = yield call(API.sqs.create, { documento: document })

    yield put(setQueueUrlGlobalStep(queueUrl))

    const consumer = SQSConsumer.getInstance()
    consumer.addProvider(DISCOVERY_PROVIDER_NAME)
    consumer.addProvider(BASIC_INFO_PROVIDER_NAME)
    consumer.addProvider(OFFSHORE_PROVIDER_NAME)
    consumer.addProvider(OPERACIONAIS_PROVIDER_NAME)
    consumer.addProvider(PATRIMONIO_PROVIDER_NAME)
    consumer.addProvider(JURIDICO_PROVIDER_NAME)
    consumer.addProvider(JIVE_PROVIDER_NAME)
    consumer.addProvider(MATCH_GRUPO_SOCIETARIO_PROVIDER_NAME)
    consumer.addProvider(DISCREDITING_MEDIA_PROVIDER_NAME)

    const { executionArn, startDate } = yield call(API.stepDiscovery.start, {
      document,
      casos,
      queueUrl,
      options
    })

    yield put(
      setMetadataGlobalStep({
        executionArn,
        startedAt: new Date(startDate * 1000)
      })
    )

    if (!consumer.running) {
      consumer.consume()
    }

    yield put(
      setStatusProgressStatus({
        globalStep: PROGRESS_TYPES.PENDING
      })
    )
  } catch (err) {
    console.error(err)
    toast.error('Erro ao iniciar processamento dos dados', {
      position: toast.POSITION.BOTTOM_RIGHT
    })

    yield put(setErrorGlobalStep(true))
    const consumer = SQSConsumer.getInstance()
    consumer.stop()
  }
}

export function * fetchGlobalStepName (action) {
  try {
    const payload = yield select(state => state.dadosBasicosTheme)
    const dadosBasicos = yield call(fetchDadosBasicosFromS3, { payload })
    const name =
      dadosBasicos.data.dadosBasicos.razaoSocial ||
      dadosBasicos.data.dadosBasicos.nome
    yield put(setTargetNameGlobalStep(name))
  } catch (err) {
    console.error(err)
    toast.error('Erro ao capturar nome do target', {
      position: toast.POSITION.BOTTOM_RIGHT,
      toastId: 'globalStep-fetch-name-error'
    })
  }
}

import { combineReducers } from 'redux'

import grafoReducer from './grafo'
import autenticacaoReducer from './autenticacao'
import googleMapsReducer from './googleMaps'
import matchesReducer from './matches'

import devedoresJiveReducer from '../store/devedoresJive'
import offshoreThemeReducer from '../store/offshoreTheme'
import operacionaisThemeReducer from '../store/operacionaisTheme'
import dadosBasicosThemeReducer from '../store/dadosBasicosTheme'
import patrimonioThemeReducer from '../store/patrimonioTheme'
import globalStepThemeReducer from '../store/globalStep'
import jiveThemeReducer from '../store/jiveTheme'
import endividamentoThemeReducer from '../store/endividamentoTheme'
import juridicoThemeReducer from '../store/juridicoTheme'
import matchGrupoSocietarioThemeReducer from '../store/matchGrupoSocietarioTheme'
import discreditingMediaThemeReduce from '../store/discreditingMediaTheme'
import bandeiraAmarelaThemeReducer from '../store/bandeiraAmarelaTheme'
import analyticsThemeReducer from '../store/analyticsTheme'

import docReducer from '../store/doc'
import xlsxReducer from '../store/xlsx'
import progressStatusReducer from '../store/progressStatus'
import relatedsReducer from '../store/relateds'

const rootReducer = combineReducers({
  autenticacao: autenticacaoReducer,
  grafo: grafoReducer,
  googleMaps: googleMapsReducer,
  matches: matchesReducer,
  analyticsTheme: analyticsThemeReducer,

  devedoresJive: devedoresJiveReducer,
  offshoreTheme: offshoreThemeReducer,
  operacionaisTheme: operacionaisThemeReducer,
  dadosBasicosTheme: dadosBasicosThemeReducer,
  patrimonioTheme: patrimonioThemeReducer,
  endividamentoTheme: endividamentoThemeReducer,
  bandeiraAmarelaTheme: bandeiraAmarelaThemeReducer,
  globalStep: globalStepThemeReducer,
  jiveTheme: jiveThemeReducer,
  juridicoTheme: juridicoThemeReducer,
  matchGrupoSocietarioTheme: matchGrupoSocietarioThemeReducer,
  discreditingMediaTheme: discreditingMediaThemeReduce,

  doc: docReducer,
  xlsx: xlsxReducer,
  progressStatus: progressStatusReducer,
  relateds: relatedsReducer
})

export default rootReducer

import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import { useSelector } from 'react-redux'

import { Table } from '../../../components'
import { onSortDate, onSortDocument } from '../../../components/Table/sort'
import { selectMinData } from '../../../redux/selectors/dadosBasicos'
import {
  formatDocument,
  hashObject,
  onlyNumbers,
  resolveDate
} from '../../../utils'

const { SearchBar } = Search

export default function Funcionarios ({ data, error }) {
  const { targetCase } = useSelector(selectMinData)
  const [selectedTable, setSelectedTable] = useState('funcionarios')

  if (error) {
    return <div>Erro no carregamento dos dados</div>
  }

  const funcionarios = data?.funcionarios || []
  const exfuncionarios = data?.exfuncionarios || []

  if (funcionarios.length === 0 && exfuncionarios.length === 0) {
    return <div />
  }

  const tableData =
    selectedTable === 'funcionarios' ? funcionarios : exfuncionarios

  const columns =
    selectedTable === 'funcionarios'
      ? [
          {
            dataField: 'nome',
            text: 'Nome',
            sort: true
          },
          {
            dataField: 'cpf',
            text: 'CPF',
            sort: true,
            sortFunc: onSortDocument
          },
          {
            dataField: 'admissao',
            text: 'Data de admissão',
            sortFunc: onSortDate,
            sort: true
          },
          {
            dataField: 'nascimento',
            text: 'Data de nascimento',
            sort: true,
            sortFunc: onSortDate
          },
          {
            dataField: 'rawDocumento',
            hidden: true
          }
        ]
      : [
          {
            dataField: 'nome',
            text: 'Nome',
            sort: true
          },
          {
            dataField: 'cpf',
            text: 'CPF',
            sort: true,
            sortFunc: onSortDocument
          },

          {
            dataField: 'admissao',
            text: 'Data de admissão',
            sort: true,
            sortFunc: onSortDate
          },
          {
            dataField: 'desligamento',
            text: 'Desligamento',
            sort: true
          },
          {
            dataField: 'nascimento',
            text: 'Data de nascimento',
            sortFunc: onSortDate,
            sort: true
          },
          {
            dataField: 'rawDocumento',
            hidden: true
          }
        ]

  const mappedData = (tableData || []).map(item => {
    const id = hashObject(item)

    return {
      id,
      desligamento: resolveDate(item.anoMesDesligamento),
      rawDocumento: formatDocument(item.cpf),
      cpf: item.cpf ? (
        <a
          href={`?documento=${onlyNumbers(item.cpf)}&casos=${targetCase.join(
            ';'
          )}`}
          target='_blank'
          rel='noopener noreferrer'
        >
          {formatDocument(item.cpf)}
        </a>
      ) : (
        '-'
      ),
      admissao: resolveDate(item.dataAdmissao),
      nascimento: resolveDate(item.dataNascimento),
      nome: item.nome
    }
  })

  return (
    <ToolkitProvider
      keyField='id'
      data={mappedData}
      columns={columns.map(item => ({
        ...item,
        align: 'center',
        headerAlign: 'center'
      }))}
      search
    >
      {props => (
        <>
          <div className='row mb-2'>
            <div className='col-12 col-lg-6 d-flex flex-row gap-3 mb-3'>
              <Form.Check
                type='radio'
                label='Funcionários'
                onChange={() => setSelectedTable('funcionarios')}
                checked={selectedTable === 'funcionarios'}
                disabled={error}
              />

              <Form.Check
                type='radio'
                label='Ex-Funcionários'
                onChange={() => setSelectedTable('exFuncionarios')}
                checked={selectedTable === 'exFuncionarios'}
                disabled={error}
              />
            </div>
            <div className='col-12 col-lg-6 text-lg-right'>
              <SearchBar {...props.searchProps} placeholder='Pesquisar' />
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <Table {...props.baseProps} enablePagination />
            </div>
          </div>
        </>
      )}
    </ToolkitProvider>
  )
}

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import { useSelector } from 'react-redux'

import { JiveWindow, Table } from '../../../components'
import { mapWithRelationship } from '../../../lib/detectMatch'
import { selectRelateds } from '../../../redux/selectors/relateds'
import { hashObject, isPending } from '../../../utils'

const { SearchBar } = Search

export default function ListaProibicaoEua ({ data, error, loaded }) {
  const relateds = useSelector(selectRelateds)

  const name = 'bandeiraAmarela.ofac'
  const title = 'Lista de proibição dos EUA'

  if (isPending({ error, loaded })) {
    return (
      <JiveWindow title={title} name={name}>
        <div />
      </JiveWindow>
    )
  }

  if (error) {
    return (
      <JiveWindow title={title} name={name} defaultOpen>
        <div>
          Ops! Aconteceu um erro ao carregar lista de proibição dos EUA
          Lucrativos
        </div>
      </JiveWindow>
    )
  }

  return (
    <JiveWindow title={title} name={name} showNote>
      {data.length > 0 && (
        <ToolkitProvider
          keyField='id'
          search
          columns={[
            { dataField: 'personName', text: 'Nome' },
            {
              dataField: 'relationship',
              text: 'Grau'
            },
            { dataField: 'program', text: 'Programa' },
            { dataField: 'remarks', text: 'Observações' }
          ].map(item => ({ ...item, align: 'center', headerAlign: 'center' }))}
          data={mapWithRelationship({
            array: data || [],
            nameKey: 'personName',
            relateds
          }).map(item => {
            const id = hashObject(item)
            return {
              ...item,
              id
            }
          })}
        >
          {props => (
            <>
              <div className='row'>
                <div className='col-12 text-lg-right'>
                  <SearchBar {...props.searchProps} placeholder='Pesquisar' />
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <Table enablePagination {...props.baseProps} />
                </div>
              </div>
            </>
          )}
        </ToolkitProvider>
      )}
    </JiveWindow>
  )
}

import React, { useMemo, useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import { formatRelative } from 'date-fns'
import { ptBR } from 'date-fns/locale'

import {
  formatDate,
  formatDocument,
  isCPF,
  onlyNumbers,
  useTokenValidation
} from '../../utils'

import { TOKEN_VALIDATION_INTERVAL } from '../../config/consts'

import { Loading, Table } from '../../components'
import { onSortDate, onSortDocument } from '../../components/Table/sort'

import { useSearchHistory } from '../../lib/hooks'

const { SearchBar } = Search

const Historico = () => {
  useTokenValidation(TOKEN_VALIDATION_INTERVAL)

  const { loading, allSearches, mySearches, updatedAt } = useSearchHistory()
  const [filterBy, setFilterBy] = useState('all')

  const columns = [
    { dataField: 'casos', text: 'Caso / Oportunidade', sort: true },
    {
      dataField: 'document',
      text: 'CPF / CNPJ',
      sort: true,
      sortFunc: onSortDocument
    },
    { dataField: 'name', text: 'Nome', sort: true },
    { dataField: 'tipoDocumento', text: 'Tipo do documento', sort: true },
    { dataField: 'timestamp', text: 'Data', sort: true, sortFunc: onSortDate },
    { dataField: 'user', text: 'Pesquisado por', sort: true },
    { dataField: 'rawDocumento', text: '', hidden: true },
    {
      dataField: 'formattedDocumento',
      text: '',
      hidden: true,
      sort: true,
      sortFunc: onSortDocument
    }
  ].map(item => ({ ...item, align: 'center', headerAlign: 'center' }))

  const allSearchesMapped = useMemo(() => {
    const mappedData = (allSearches || []).map(item => {
      return {
        document:
          (item.casos || []).length > 0 ? (
            <a
              href={`/dados-basicos?documento=${onlyNumbers(
                item.document
              )}&casos=${(item.casos || []).join(';')}`}
              target='_blank'
              rel='noopener noreferrer'
            >
              {formatDocument(item.document)}
            </a>
          ) : (
            formatDocument(item.document)
          ),
        tipoDocumento: isCPF(item.document) ? 'CPF' : 'CNPJ',
        casos: (item.casos || []).join(', '),
        timestamp: formatDate(item.timestamp),
        user: (item.user || '').toString(),
        rawDocumento: item.document,
        name: item.name,
        formattedDocumento: formatDocument(item.document)
      }
    })

    if (filterBy === 'all') {
      return mappedData
    }

    return mappedData.filter(
      item => item.tipoDocumento === filterBy.toUpperCase()
    )
  }, [allSearches, filterBy])

  const mySearchesMapped = useMemo(() => {
    const mappedData = (mySearches || []).map(item => {
      return {
        document: (
          <a
            href={`/dados-basicos?documento=${onlyNumbers(
              item.document
            )}&casos=${(item.casos || []).join(';')}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            {formatDocument(item.document)}
          </a>
        ),
        tipoDocumento: isCPF(item.document) ? 'CPF' : 'CNPJ',
        casos: (item.casos || []).join(', '),
        timestamp: formatDate(item.timestamp),
        user: (item.user || '').toString(),
        rawDocumento: item.document,
        formattedDocumento: formatDocument(item.document),
        name: item.name
      }
    })

    if (filterBy === 'all') {
      return mappedData
    }

    return mappedData.filter(
      item => item.tipoDocumento === filterBy.toUpperCase()
    )
  }, [mySearches, filterBy])

  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-12 h2 text-primary'>Histórico de Pesquisas</div>
        <div className='col-12'>
          <p>
            {updatedAt && (
              <small className='text-muted'>
                Última atualização:{' '}
                {formatRelative(updatedAt, new Date(), {
                  locale: ptBR
                })}
              </small>
            )}
          </p>
        </div>
      </div>
      <div className='row mt-4'>
        <div className='col-12'>
          <Tabs defaultActiveKey='historico' className='mb-3'>
            <Tab eventKey='historico' title='Todas as pesquisas'>
              {loading && allSearchesMapped.length === 0 ? (
                <Loading />
              ) : (
                <div className='container-fluid'>
                  <ToolkitProvider
                    keyField='id'
                    search
                    columns={columns}
                    data={allSearchesMapped}
                  >
                    {props => (
                      <>
                        <div className='row'>
                          <div className='col-10'>
                            <div className='form-group'>
                              <label forId='documentField'>
                                Pesquisar documento:
                              </label>
                              <div>
                                <SearchBar
                                  {...props.searchProps}
                                  placeholder='Número do documento'
                                  id='documentField'
                                />
                              </div>
                            </div>
                          </div>
                          <div className='col-2'>
                            <div className='form-group'>
                              <label forId='exampleFormControlSelect1'>
                                Filtrar por:
                              </label>
                              <select
                                className='form-control'
                                id='filterField'
                                value={filterBy}
                                onChange={event => {
                                  setFilterBy(event.target.value)
                                }}
                              >
                                <option value='all'>Ambos</option>
                                <option value='cpf'>CPF</option>
                                <option value='cnpj'>CNPJ</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-12'>
                            <Table
                              enablePagination
                              {...props.baseProps}
                              wrapperClasses='overflow-auto'
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </ToolkitProvider>
                </div>
              )}
            </Tab>
            <Tab eventKey='minhasPesquisas' title='Minhas pesquisas'>
              {loading && mySearchesMapped.length === 0 ? (
                <Loading />
              ) : (
                <div className='container-fluid'>
                  <ToolkitProvider
                    keyField='id'
                    search
                    columns={columns}
                    data={mySearchesMapped}
                  >
                    {props => (
                      <>
                        <div className='row'>
                          <div className='col-10'>
                            <div className='form-group'>
                              <label forId='documentField'>
                                Pesquisar documento:
                              </label>
                              <div>
                                <SearchBar
                                  {...props.searchProps}
                                  placeholder='Número do documento'
                                  id='documentField'
                                />
                              </div>
                            </div>
                          </div>
                          <div className='col-2'>
                            <div className='form-group'>
                              <label forId='exampleFormControlSelect1'>
                                Filtrar por:
                              </label>
                              <select
                                className='form-control'
                                id='filterField'
                                value={filterBy}
                                onChange={event => {
                                  setFilterBy(event.target.value)
                                }}
                              >
                                <option value='all'>Ambos</option>
                                <option value='cpf'>CPF</option>
                                <option value='cnpj'>CNPJ</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-12'>
                            <Table
                              enablePagination
                              {...props.baseProps}
                              wrapperClasses='overflow-auto'
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </ToolkitProvider>
                </div>
              )}
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  )
}

export default Historico

export const ENV = process.env.REACT_APP_ENV ?? 'development'
export const BUILD_ENV = process.env.REACT_APP_BUILD_ENV
export const AWS_REGION = 'us-east-1'
export const AWS_IDENTITY_POOL =
  process.env.AWS_IDENTITY_POOL ??
  'us-east-1:e382a144-aa33-4725-aebc-c3c0192b7a71'
export const BUCKET_NAME = process.env.REACT_APP_BUCKET_NAME

// GOOGLE MAPS API KEY
export const GOOGLE_AUTHORIZATION_KEY =
  process.env.REACT_APP_GOOGLE_AUTHORIZATION_KEY || ''

const REQUIRED_ENVS = {
  BUILD_ENV,
  BUCKET_NAME
}

Object.entries(REQUIRED_ENVS).forEach(([env, value]) => {
  if (!value) {
    throw new Error(`Missing environment variable: ${env}`)
  }
})

const commons = {
  AUTHENTICATION_BASE_URL: 'https://microservices-jive-auth.boanota.credit',
  LOGS_BASE_URL: 'https://microservices-jive-auth.boanota.credit'
}

const configs = {
  prod: {
    SQS_BASE_URL: 'https://wtk56jffii.execute-api.us-east-1.amazonaws.com/Prod',
    COMMENTS_BASE_URL:
      'https://pky1gpfc7e.execute-api.us-east-1.amazonaws.com/Prod',
    DOCX_API_BASE_URL:
      'https://bnzlegh7qh.execute-api.us-east-1.amazonaws.com/Prod',
    XLSX_API_BASE_URL:
      'https://qzr6489wg2.execute-api.us-east-1.amazonaws.com/Prod',
    DISCOVERY_STATE_MACHINE_BASE_URL:
      'https://7psqwt5jy6.execute-api.us-east-1.amazonaws.com/Prod',
    GOOGLE_MAPS_BASE_URL:
      'https://760o4hdpji.execute-api.us-east-1.amazonaws.com/Prod',
    JURIDICO_DETAILS_BASE_URL:
      'https://3t9903w835.execute-api.us-east-1.amazonaws.com/Prod',
    GRUPO_SOCIETARIO_BASE_URL:
      'https://7270a8jqpi.execute-api.us-east-1.amazonaws.com/Prod'
  },
  staging: {
    SQS_BASE_URL:
      'https://bcnjvqsdy4.execute-api.us-east-1.amazonaws.com/Staging',
    COMMENTS_BASE_URL:
      'https://8c77wcrcpj.execute-api.us-east-1.amazonaws.com/Staging',
    DOCX_API_BASE_URL:
      'https://uegleesx32.execute-api.us-east-1.amazonaws.com/Staging',
    XLSX_API_BASE_URL:
      'https://vavlbjbdb8.execute-api.us-east-1.amazonaws.com/Staging',
    DISCOVERY_STATE_MACHINE_BASE_URL:
      'https://eujkuexcx9.execute-api.us-east-1.amazonaws.com/Staging',
    GOOGLE_MAPS_BASE_URL:
      'https://gkpckl89b9.execute-api.us-east-1.amazonaws.com/Staging',
    JURIDICO_DETAILS_BASE_URL:
      'https://3uv1t6a1l7.execute-api.us-east-1.amazonaws.com/Staging',
    GRUPO_SOCIETARIO_BASE_URL:
      'https://9oauztwbj1.execute-api.us-east-1.amazonaws.com/Staging'
  },
  dev: {
    SQS_BASE_URL: 'https://4vqjtfkgu6.execute-api.us-east-1.amazonaws.com/Dev',
    COMMENTS_BASE_URL:
      'https://3wswlq1vqf.execute-api.us-east-1.amazonaws.com/Dev',
    DOCX_API_BASE_URL:
      'https://cc34nlqt0d.execute-api.us-east-1.amazonaws.com/Dev',
    XLSX_API_BASE_URL:
      'https://z6wcholxb9.execute-api.us-east-1.amazonaws.com/Dev',
    DISCOVERY_STATE_MACHINE_BASE_URL:
      'https://aaw9rewmr2.execute-api.us-east-1.amazonaws.com/Dev',
    GOOGLE_MAPS_BASE_URL:
      'https://34b29j11ji.execute-api.us-east-1.amazonaws.com/Dev',
    JURIDICO_DETAILS_BASE_URL:
      'https://31aerxtvu0.execute-api.us-east-1.amazonaws.com/Dev',
    GRUPO_SOCIETARIO_BASE_URL:
      'https://kj0rzhunk2.execute-api.us-east-1.amazonaws.com/Dev'
  }
}

export const {
  AUTHENTICATION_BASE_URL,
  COMMENTS_BASE_URL,
  GOOGLE_MAPS_BASE_URL,
  LOGS_BASE_URL,
  DOCX_API_BASE_URL,
  XLSX_API_BASE_URL,
  JURIDICO_DETAILS_BASE_URL,
  EXCEL_BASE_URL,
  SQS_BASE_URL,
  DISCOVERY_STATE_MACHINE_BASE_URL,
  GRUPO_SOCIETARIO_BASE_URL
} = { ...commons, ...configs[BUILD_ENV] }

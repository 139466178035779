import React from 'react'
import { useHistory } from 'react-router-dom'

import { removeAuthToken, removeUserId } from '../../utils'

import * as styles from './style.module.scss'
import logo from '../../assets/img/logo/jive/full_gray.svg'

import iconLogout from '../../assets/img/icons/logout.svg'

export default function Header () {
  const history = useHistory()
  return (
    <header className={styles.container}>
      <div className='d-flex flex-row justify-content-center px-4 py-2'>
        <div className='flex-grow-1'>
          <img src={logo} alt='Logo Jive' height={40} />
        </div>
        <div className='d-flex align-items-center'>
          <button
            className='btn'
            onClick={() => {
              removeUserId()
              removeAuthToken()
              history.replace('/login')
            }}
          >
            <img src={iconLogout} alt='Logout' height={25} />
          </button>
        </div>
      </div>
      <Divisor />
    </header>
  )
}

const Divisor = () => {
  return <div className={styles.divisor} />
}

import React from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import ReactTooltip from 'react-tooltip'

import { selectSearchOptions } from '../../redux/selectors/searchOptions'

import iconDadosQuantitativos from '../../assets/img/icons/sidebar/dados_quantitativos.svg'
import iconDadosBasicos from '../../assets/img/icons/sidebar/dados_basicos.svg'
import iconJuridico from '../../assets/img/icons/sidebar/juridico.svg'
import iconGrupoSocietario from '../../assets/img/icons/sidebar/grupo_societario.svg'
import iconPatrimonio from '../../assets/img/icons/sidebar/patrimonio.svg'
import iconRegistrais from '../../assets/img/icons/sidebar/registrais.svg'
import iconOffshore from '../../assets/img/icons/sidebar/offshore.svg'
import iconEndividamento from '../../assets/img/icons/sidebar/endividamento.svg'
import iconOperacionais from '../../assets/img/icons/sidebar/operacionais.svg'
import iconMidiaDesabonadora from '../../assets/img/icons/sidebar/midia_desabonadora.svg'
import iconBandeiraAmarela from '../../assets/img/icons/sidebar/bandeira_amarela.svg'

import * as styles from './style.module.scss'

export default function Sidebar () {
  const location = useLocation()
  const history = useHistory()
  const { pages: enabledPages } = useSelector(selectSearchOptions)

  const options = [
    {
      label: 'Dados Quantitativos',
      icon: iconDadosQuantitativos,
      url: '/dados-quantitativos',
      status: 'undefined',
      active: true
    },
    {
      label: 'Dados Básicos',
      icon: iconDadosBasicos,
      url: ['/dados-basicos', '/'],
      active: enabledPages.dadosBasicos
    },
    {
      label: 'Grupo Societário',
      icon: iconGrupoSocietario,
      url: '/grupo',
      active: enabledPages.grupoSocietario
    },
    {
      label: 'Patrimônio',
      icon: iconPatrimonio,
      url: '/patrimonio',
      active: enabledPages.patrimonio
    },
    {
      label: 'Pontos de atenção',
      icon: iconBandeiraAmarela,
      url: '/pontos-atencao',
      active: enabledPages.pontosAtencao
    },
    {
      label: 'Registrais',
      icon: iconRegistrais,
      url: '/registrais',
      active: enabledPages.registrais
    },
    {
      label: 'Offshore',
      icon: iconOffshore,
      url: '/offshore',
      active: enabledPages.offshore
    },
    {
      label: 'Endividamento',
      icon: iconEndividamento,
      url: '/endividamento',
      active: enabledPages.endividamento
    },
    {
      label: 'Mídia Desabonadora',
      icon: iconMidiaDesabonadora,
      url: '/midia-desabonadora',
      active: enabledPages.midiaDesabonadora
    },
    {
      label: 'Jurídico',
      icon: iconJuridico,
      url: '/juridico',
      active: enabledPages.juridico
    },
    {
      label: 'Operacionais',
      icon: iconOperacionais,
      url: '/operacionais',
      active: enabledPages.operacionais
    }
  ]

  return (
    <div
      className={`d-flex flex-column align-items-center ${styles.container}`}
    >
      <ReactTooltip />
      <div
        className={`d-flex flex-column justify-content-center ${styles.menuContainer}`}
      >
        <ul className={`ml-2 ${styles.menu}`}>
          {options.map(option => {
            const link = Array.isArray(option.url) ? option.url[0] : option.url
            const isCurrentPage = Array.isArray(option.url)
              ? option.url.includes(location.pathname)
              : location.pathname === option.url

            return (
              <li
                className={isCurrentPage ? styles.active : ''}
                key={option.url}
                data-tip={
                  option.active
                    ? option.label
                    : option.label + ' (Desabilitado)'
                }
                onClick={() => (option.active ? history.push(link) : null)}
                style={option.active ? {} : { opacity: 0.3 }}
              >
                <Link to={option.active ? link : '#'}>
                  <img src={option.icon} alt={option.label} height={20} />
                </Link>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

// Action Types
export const Types = {
  RESET: 'MATCH_GRUPO_SOCIETARIO/RESET',
  SET_S3_PATH: 'MATCH_GRUPO_SOCIETARIO/SET_S3_PATH',
  SET_ERROR: 'MATCH_GRUPO_SOCIETARIO/SET_ERROR'
}

// Reducer
const initialState = {
  email: {
    s3Path: undefined,
    error: false,
    loaded: false
  },
  endereco: {
    s3Path: undefined,
    error: false,
    loaded: false
  },
  telefone1: {
    s3Path: undefined,
    error: false,
    loaded: false
  },
  telefone2: {
    s3Path: undefined,
    error: false,
    loaded: false
  }
}

export default function reducer (state = initialState, action) {
  switch (action.type) {
    case Types.SET_S3_PATH: {
      const { email, endereco, telefone1, telefone2 } =
        action.payload.result || {}
      const myPayload = {
        email: { s3Path: email, loaded: true },
        endereco: { s3Path: endereco, loaded: true },
        telefone1: { s3Path: telefone1, loaded: true },
        telefone2: { s3Path: telefone2, loaded: true }
      }
      return { ...state, ...myPayload }
    }
    case Types.SET_ERROR:
      return {
        ...state,
        email: { ...state.email, error: action.payload, loaded: true },
        endereco: { ...state.endereco, error: action.payload, loaded: true },
        telefone1: { ...state.telefone1, error: action.payload, loaded: true },
        telefone2: { ...state.telefone2, error: action.payload, loaded: true }
      }
    case Types.RESET:
      return initialState
    default:
      return state
  }
}

// Action Creators
export function setErrorMatchGrupoSocietarioTheme (data) {
  return {
    type: Types.SET_ERROR,
    payload: data
  }
}

export function setS3PathMatchGrupoSocietarioTheme (data) {
  return {
    type: Types.SET_S3_PATH,
    payload: data
  }
}

export function resetMatchGrupoSocietarioTheme () {
  return {
    type: Types.RESET
  }
}

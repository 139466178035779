import React from 'react'

import { Sidebar, Content } from './../components'
import { useJuridicoNotification, useTokenValidation } from '../utils'
import {
  JURIDICO_NOTIFICATION_INTERVAL,
  TOKEN_VALIDATION_INTERVAL
} from '../config/consts'

export default function RF () {
  useTokenValidation(TOKEN_VALIDATION_INTERVAL)
  useJuridicoNotification(JURIDICO_NOTIFICATION_INTERVAL)

  return (
    <div className='container-fluid h-100'>
      <div className='row justify-content-between h-100'>
        <Sidebar />
        <Content title='Receita Federal' subtitle='Placeholder' />
      </div>
    </div>
  )
}

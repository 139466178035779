import axios from 'axios'

import { DISCOVERY_STATE_MACHINE_BASE_URL } from '../../config/env'
import { getScope, getUserId, toCamelCase } from '../../utils'

export const startStepFunctionDiscovery = async ({
  document,
  queueUrl,
  casos,
  options
}) => {
  const url = `${DISCOVERY_STATE_MACHINE_BASE_URL}/start-discovery`
  const scope = getScope()
  const user = getUserId()

  const { data } = await axios.post(url, {
    ...options,
    document,
    casos,
    queue_url: queueUrl,
    scope,
    user: user?.toString()
  })

  return toCamelCase(data)
}

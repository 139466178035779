import { createSelector } from 'reselect'

import { PROGRESS_TYPES } from '../../lib/progressStatus'

const getProgressStatus = state => state.progressStatus.dataStatus
const getJuridicoTheme = state => state.juridicoTheme
const getJiveBots = state => state.jiveTheme.jivebots.status
const getDocumento = state => state.globalStep.targetDocument

const dict = {
  globalStep: null,
  googleMaps: 'Google Maps',
  analyticsTheme: 'Dados quantitativos',

  matchGrupoSocietarioTheme: 'Grupo societário',
  certidoesJiveTheme: 'Certidões Jive',
  devedoresJiveTheme: 'Devedores Jive',
  jiveBotsJiveTheme: 'Jive Bots',
  jiveApiJiveTheme: [
    'Capital estrangeiro Jive',
    'Offshore Jive',
    'Ambientais Jive',
    'Imóveis Rurais Jive'
  ],
  aeronavesPatrimonioTheme: 'Aeronaves',
  inpiPatrimonioTheme: 'INPI',
  pepBandeiraAmarela: 'Pep',
  acordosLenienciaBandeiraAmarela: 'Acordos de leniência',
  autoInfracaoBandeiraAmarela: 'Infrações',
  cepimBandeiraAmarela: 'Entidades Privadas Sem Fins Lucrativos',
  ofacBandeiraAmarela: 'Ativos Estrangeiros dos EUA',
  termoApreensaoBandeiraAmarela: 'Termo de apreensão',
  termoEmbargoBandeiraAmarela: 'Termo de embargo',
  termoSuspensaoBandeiraAmarela: 'Termo de suspensão',
  cadastroExpulsoesBandeiraAmarela: 'Cadastro de expulsões',
  cnepBandeiraAmarela: 'Empresas Punidas',
  ceisBandeiraAmarela: 'Empresas Inidôneas e Suspensas',
  ceafBandeiraAmarela: 'Expulsões da Administração Federal',
  sncrPatrimonioTheme: 'SNCR',
  dadosBasicosTheme: 'Dados básicos',
  operacionaisTheme: ['Imóveis Neoway', 'Match (ex)funcionários'],
  dataLeaksOffshore: 'Offshore Leaks',
  chequeEndividamentoTheme: 'Cheques',
  cndtEndividamentoTheme: 'CNDT',
  pgfnEndividamentoTheme: 'PGFN',
  protestoEndividamentoTheme: 'Protesto',
  juridicoTheme: 'Jurídico',
  discreditingNewsTheme: 'Notícias Desabonadoras'
}

export const selectProgressStatus = createSelector(
  getProgressStatus,
  getDocumento,
  (progressStatus, document) => {
    const objectFiltered = Object.fromEntries(Object.entries(progressStatus))

    const grouped = Object.entries(objectFiltered).reduce(
      (obj, [key, value]) => {
        const texts = Array.isArray(dict[key])
          ? dict[key]
          : [dict[key] === null ? null : dict[key] || key]
        if (!value) {
          texts.forEach(text => {
            obj.pending.push({ raw: key, text })
          })
        } else {
          if (
            key === 'globalStep' &&
            [PROGRESS_TYPES.PENDING, null].includes(value)
          ) {
            obj.loaded.push({ raw: key, text: null })
          } else {
            texts.forEach(text => {
              obj[value].push({ raw: key, text })
            })
          }
        }

        return obj
      },
      { loaded: [], error: [], pending: [], partialError: [], unloaded: [] }
    )

    const array = grouped.loaded
      .map(item => ({ ...item, status: PROGRESS_TYPES.LOADED }))
      .concat(
        grouped.error.map(item => ({ ...item, status: PROGRESS_TYPES.ERROR }))
      )
      .concat(
        grouped.pending.map(item => ({
          ...item,
          status: PROGRESS_TYPES.PENDING
        }))
      )
      .concat(
        grouped.partialError.map(item => ({
          ...item,
          status: PROGRESS_TYPES.PARTIAL_ERROR
        }))
      )
      .concat(
        grouped.unloaded.map(item => ({
          ...item,
          status: PROGRESS_TYPES.UNLOADED
        }))
      )
      .filter(item => item.text !== null)
      .sort((a, b) => a.text.localeCompare(b.text))

    const total = array.length
    const visible =
      progressStatus.globalStep === PROGRESS_TYPES.PENDING &&
      document &&
      grouped.pending.length > 0
    const progressLoaded =
      ((grouped.loaded.length + grouped.unloaded.length) / (total || 1)) * 100
    const progressPartialError =
      (grouped.partialError.length / (total || 1)) * 100
    const progressError = (grouped.error.length / (total || 1)) * 100
    const progressPending = (grouped.pending.length / (total || 1)) * 100

    return {
      total,
      visible,
      progressLoaded,
      progressError,
      progressPending,
      progressPartialError,
      array,
      ...grouped
    }
  }
)

export const selectJiveBotsProgressStatus = createSelector(
  getJiveBots,
  getDocumento,
  getProgressStatus,
  (jivebots, document, progressStatus) => {
    const estimatedTime = jivebots.estimatedTime

    const total = jivebots.totalBots || 0
    const loaded = jivebots.totalBotsDone || 0
    const pending = jivebots.totalBotsPending || 0
    const visible = document && loaded !== total

    const progressLoaded = (loaded / (total || 1)) * 100
    const progressPending = (pending / (total || 1)) * 100

    if (progressStatus.jiveBotsJiveTheme === PROGRESS_TYPES.ERROR) {
      return {
        total: total || 1,
        visible,
        progressLoaded: total || 100,
        progressPending: 0,
        loaded: total || 1,
        pending: 0,
        estimatedTime: jivebots.elapsedTime || 0,
        status: jivebots
      }
    }

    // caso tenha pego informação cacheada
    if (
      progressStatus.jiveBotsJiveTheme === PROGRESS_TYPES.LOADED &&
      !jivebots.taskState
    ) {
      return {
        total: total || 1,
        visible,
        progressLoaded: total || 100,
        progressPending: 0,
        loaded: total || 1,
        pending: 0,
        estimatedTime: jivebots.estimatedTime || 0,
        status: jivebots
      }
    } else if (
      progressStatus.jiveBotsJiveTheme === PROGRESS_TYPES.LOADED &&
      jivebots.taskState === 'SUCCEEDED'
    ) {
      return {
        total,
        visible,
        progressLoaded,
        progressPending,
        loaded,
        pending,
        estimatedTime: jivebots.elapsedTime || 0,
        status: jivebots
      }
    }

    return {
      total,
      visible,
      progressLoaded,
      progressPending,
      loaded,
      pending,
      estimatedTime,
      status: jivebots
    }
  }
)

export const selectJuridicoProgressStatus = createSelector(
  getJuridicoTheme,
  getDocumento,
  (juridicoTheme, document) => {
    const total = juridicoTheme.relateds.length || 0
    const loaded = (juridicoTheme.relateds || []).filter(
      related => related.status === PROGRESS_TYPES.LOADED
    )
    const error = (juridicoTheme.relateds || []).filter(
      related => related.status === PROGRESS_TYPES.ERROR
    )
    const pending = (juridicoTheme.relateds || []).filter(
      related => related.status === PROGRESS_TYPES.PENDING
    )
    const visible = document && loaded.length + error.length !== total

    const progressLoaded = (loaded.length / (total || 1)) * 100
    const progressError = (error.length / (total || 1)) * 100
    const progressPending = (pending.length / (total || 1)) * 100

    const array = loaded
      .map(item => ({ ...item, status: PROGRESS_TYPES.LOADED }))
      .concat(error.map(item => ({ ...item, status: PROGRESS_TYPES.ERROR })))
      .concat(
        pending.map(item => ({
          ...item,
          status: PROGRESS_TYPES.PENDING
        }))
      )

    return {
      total,
      visible,
      progressLoaded,
      progressError,
      progressPending,
      loaded,
      error,
      pending,
      array
    }
  }
)

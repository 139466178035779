import React from 'react'
import {
  Dropdown,
  DropdownButton,
  Modal,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import {
  addSeconds,
  formatDistanceToNow,
  formatDuration,
  intervalToDuration
} from 'date-fns'
import { ptBR } from 'date-fns/locale'

import { ImFileExcel, ImFileWord, ImHourGlass } from 'react-icons/im'
import { RiDownload2Line } from 'react-icons/ri'
import { FiClock, FiHelpCircle } from 'react-icons/fi'
import { AiOutlineInfoCircle } from 'react-icons/ai'

import { selectMinData } from '../../redux/selectors/dadosBasicos'
import {
  selectJiveBotsProgressStatus,
  selectJuridicoProgressStatus,
  selectProgressStatus
} from '../../redux/selectors/progressStatus'
import { gerarDocumento } from '../../redux/store/doc'

import * as styles from './style.module.scss'
import {
  closeProgressStatus,
  openProgressStatus
} from '../../redux/store/progressStatus'

import { PROGRESS_TYPES } from '../../lib/progressStatus'

import { BUILD_ENV } from '../../config/env'
import { formatDocument } from '../../utils'
import { gerarXlsx } from '../../redux/store/xlsx'
import { useSearchHistory } from '../../lib/hooks'

const getEmoji = status => {
  if (status === 'unloaded') {
    return '❕'
  }

  if (status === 'loaded') {
    return '✔️'
  }

  if (status === 'error') {
    return '❌'
  }

  if (status === 'partialError') {
    return '⚠️'
  }

  return '🕑'
}

const formatDistanceLocale = {
  lessThanXSeconds: '{{count}}s',
  xSeconds: '{{count}}s',
  halfAMinute: '30s',
  lessThanXMinutes: '{{count}}m',
  xMinutes: '{{count}}m',
  aboutXHours: '{{count}}h',
  xHours: '{{count}}h',
  xDays: '{{count}}d',
  aboutXWeeks: '{{count}}w',
  xWeeks: '{{count}}w',
  aboutXMonths: '{{count}}m',
  xMonths: '{{count}}m',
  aboutXYears: '{{count}}y',
  xYears: '{{count}}y',
  overXYears: '{{count}}y',
  almostXYears: '{{count}}y'
}

const formatDistance = (token, count, options) => {
  options = options || {}

  const result = formatDistanceLocale[token].replace('{{count}}', count)

  if (options.addSuffix) {
    if (options.comparison > 0) {
      return 'in ' + result
    } else {
      return result + ' ago'
    }
  }

  return result
}

const Content = ({ title, subtitle, children }) => {
  useSearchHistory()

  return (
    <div className='container-fluid w-100'>
      <div className='mb-3'>
        <div className='d-flex flex-row'>
          <h1 className={`mb-0 ${styles.title} flex-grow-1`}>{title}</h1>
          <div className='ml-2 d-flex align-items-center'>
            <Options />
          </div>
        </div>
        <hr className='mt-1 mb-1' />
        <span className={`${styles.subtitle}`}>{subtitle}</span>
      </div>
      <div>{children}</div>
    </div>
  )
}

const Options = () => {
  const { targetName, targetDocument, isCPF } = useSelector(selectMinData)
  const progressStatus = useSelector(selectProgressStatus)
  const juridicoProgress = useSelector(selectJuridicoProgressStatus)
  const jiveBotsProgress = useSelector(selectJiveBotsProgressStatus)
  const metadata = useSelector(state => state.globalStep.metadata)
  const globalExecutionArn = metadata?.executionArn
  const executionId = globalExecutionArn
    ? globalExecutionArn.split(':').slice(-1)[0]
    : null
  const executionArnDocx = useSelector(state => state.doc.executionArn)
  const openProgressStatusAlert = useSelector(
    state => state.progressStatus.popupOpened
  )
  const targetType = isCPF ? 'pf' : 'pj'
  const juridicoProgressTarget = (juridicoProgress?.array || []).find(
    item =>
      item.document === targetDocument &&
      item.name === targetName &&
      item.type === targetType
  )
  const dispatch = useDispatch()

  const renderHelpTarget = props => (
    <Tooltip {...props}>
      Target é o nome do alvo que você está buscando.{' '}
      {targetDocument
        ? `Neste caso é o ${
            targetName
              ? `${targetName} (${formatDocument(targetDocument)})`
              : formatDocument(targetDocument)
          }`
        : ''}
    </Tooltip>
  )

  const renderInfoJiveBots = props => {
    const completedStatus = [
      PROGRESS_TYPES.LOADED,
      PROGRESS_TYPES.ERROR,
      PROGRESS_TYPES.PARTIAL_ERROR
    ]

    const jiveBotsStatus = progressStatus.array.find(
      item => item.raw === 'jiveBotsJiveTheme'
    )?.status

    return (
      <Tooltip {...props}>
        {completedStatus.includes(jiveBotsStatus) ? 'Duração' : 'Estimativa'}:{' '}
        {typeof jiveBotsProgress.estimatedTime === 'number'
          ? formatDistanceToNow(
              addSeconds(new Date(), jiveBotsProgress.estimatedTime),
              { locale: ptBR }
            )
          : 'Indeterminado'}
      </Tooltip>
    )
  }

  const renderInfoJuridico = props => (
    <Tooltip {...props}>
      Em alguns casos o jurídico pode demorar um pouco e isso se deve ao fato de
      uma pessoa ou empresa possuir diversos processos vinculados.
      <br />
      Mas mesmo assim você conseguirá usar a plataforma normalmente
    </Tooltip>
  )

  return (
    <>
      <button
        className='btn'
        style={{ color: 'black', background: 'none' }}
        data-tip='Ver detalhes do carregamento'
        onClick={() => {
          if (!targetDocument) {
            toast.warn('Selecione um CPF ou CNPJ', {
              position: toast.POSITION.BOTTOM_RIGHT,
              toastId: 'toast-progress-status-select-document'
            })
            return
          }
          dispatch(openProgressStatus())
        }}
        disabled={!targetDocument}
      >
        <ImHourGlass size={25} color='#2C9E69' />
      </button>
      <DropdownButton
        variant='none'
        disabled={!targetDocument}
        className={styles.dropdown}
        title={<RiDownload2Line color='#2C9E69' size={25} />}
      >
        <Dropdown.Item
          disabled={!targetDocument}
          onClick={() => dispatch(gerarXlsx())}
        >
          <ImFileExcel size={25} /> Exportar em formato XLSX
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => dispatch(gerarDocumento())}
          disabled={!targetDocument}
        >
          <ImFileWord size={25} /> Exportar em formato DOCX
        </Dropdown.Item>
      </DropdownButton>
      <Modal
        show={openProgressStatusAlert}
        onHide={() => {
          if (BUILD_ENV !== 'dev' && progressStatus.loaded.length <= 1) {
            // 1 por causa da step global que começa com loaded
            toast.warn('Nenhum dado carregou até o momento', {
              position: toast.POSITION.TOP_RIGHT,
              toastId: 'toast-progress-status-wait'
            })

            return
          }

          dispatch(closeProgressStatus())
        }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Status de carregamento dos dados</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row'>
            <div className='col-12 text-center'>Global</div>
            <div className='col-12 mb-3'>
              <div className='progress'>
                <div
                  className={`progress-bar progress-bar-striped ${
                    progressStatus.visible ? 'progress-bar-animated' : ''
                  } bg-success`}
                  role='progressbar'
                  style={{ width: `${progressStatus.progressLoaded}%` }}
                  aria-valuenow={progressStatus.progressLoaded}
                  aria-valuemin='0'
                  aria-valuemax='100'
                />
                <div
                  className={`progress-bar progress-bar-striped ${
                    progressStatus.visible ? 'progress-bar-animated' : ''
                  } bg-warning`}
                  role='progressbar'
                  style={{ width: `${progressStatus.progressPartialError}%` }}
                  aria-valuenow={progressStatus.progressPartialError}
                  aria-valuemin='0'
                  aria-valuemax='100'
                />
                <div
                  className={`progress-bar progress-bar-striped ${
                    progressStatus.visible ? 'progress-bar-animated' : ''
                  } bg-danger`}
                  role='progressbar'
                  style={{ width: `${progressStatus.progressError}%` }}
                  aria-valuenow={progressStatus.progressError}
                  aria-valuemin='0'
                  aria-valuemax='100'
                />
              </div>
            </div>
          </div>
          <div className='row mt-2'>
            <div className='col-12 text-center position-relative'>
              Jurídico
              <span className='position-abolute float-right'>
                <OverlayTrigger
                  placement='right'
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderInfoJuridico}
                >
                  <AiOutlineInfoCircle />
                </OverlayTrigger>
              </span>
            </div>

            <div className='col-12 mb-3'>
              <div className='progress'>
                <div
                  className={`progress-bar progress-bar-striped ${
                    juridicoProgress.visible ? 'progress-bar-animated' : ''
                  } bg-success`}
                  role='progressbar'
                  style={{ width: `${juridicoProgress.progressLoaded}%` }}
                  aria-valuenow={juridicoProgress.progressLoaded}
                  aria-valuemin='0'
                  aria-valuemax='100'
                />
                <div
                  className={`progress-bar progress-bar-striped ${
                    juridicoProgress.visible ? 'progress-bar-animated' : ''
                  } bg-danger`}
                  role='progressbar'
                  style={{ width: `${juridicoProgress.progressError}%` }}
                  aria-valuenow={juridicoProgress.progressError}
                  aria-valuemin='0'
                  aria-valuemax='100'
                />
              </div>
            </div>
          </div>
          <div className='row mt-2'>
            <div className='col-12 text-center position-relative'>
              Jurídico - Target
              <span className='position-abolute float-right'>
                {' '}
                <OverlayTrigger
                  placement='right'
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderHelpTarget}
                >
                  <FiHelpCircle />
                </OverlayTrigger>
              </span>
            </div>
            <div className='col-12 mb-3'>
              <div className='progress'>
                <div
                  className={`progress-bar progress-bar-striped ${
                    juridicoProgress.visible ? 'progress-bar-animated' : ''
                  } ${
                    juridicoProgressTarget?.status === PROGRESS_TYPES.ERROR
                      ? 'bg-danger'
                      : 'bg-success'
                  }`}
                  role='progressbar'
                  style={{
                    width: `${
                      !juridicoProgress.visible &&
                      juridicoProgressTarget?.status === PROGRESS_TYPES.PENDING
                        ? '0'
                        : [
                            PROGRESS_TYPES.ERROR,
                            PROGRESS_TYPES.LOADED
                          ].includes(juridicoProgressTarget?.status)
                        ? '100'
                        : '0'
                    }%`
                  }}
                  aria-valuenow={10}
                  aria-valuemin='0'
                  aria-valuemax='100'
                />
              </div>
            </div>
          </div>
          <div className='row mt-2'>
            <div className='col-12 text-center position-relative'>
              Jive Bots
              <span className='position-abolute float-right'>
                {' '}
                <OverlayTrigger
                  placement='right'
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderInfoJiveBots}
                >
                  <FiClock />
                </OverlayTrigger>
              </span>
            </div>
            <div className='col-12'>
              <div className='progress'>
                <div
                  className={`progress-bar progress-bar-striped ${
                    jiveBotsProgress.visible ? 'progress-bar-animated' : ''
                  } bg-success`}
                  role='progressbar'
                  style={{ width: `${jiveBotsProgress.progressLoaded}%` }}
                  aria-valuenow={jiveBotsProgress.progressLoaded}
                  aria-valuemin='0'
                  aria-valuemax='100'
                />
                <div
                  className={`progress-bar progress-bar-striped ${
                    jiveBotsProgress.visible ? 'progress-bar-animated' : ''
                  } bg-danger`}
                  role='progressbar'
                  style={{ width: `${jiveBotsProgress.progressError}%` }}
                  aria-valuenow={jiveBotsProgress.progressError}
                  aria-valuemin='0'
                  aria-valuemax='100'
                />
              </div>
            </div>
            {['dev', 'staging'].includes(BUILD_ENV) && (
              <div className='col-12 text-center mt-2'>
                {jiveBotsProgress.status.taskId && (
                  <p>Task ID: {jiveBotsProgress.status.taskId}</p>
                )}
                {jiveBotsProgress.status.presigned.length > 0 && (
                  <p>
                    Presigned:{' '}
                    {(jiveBotsProgress.status.presigned || []).map(
                      (link, index) => (
                        <a
                          key={(link || '') + index.toString()}
                          href={link}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          link {index + 1}
                        </a>
                      )
                    )}
                  </p>
                )}
              </div>
            )}
          </div>
          <hr className='mt-3 mb-3' />
          {BUILD_ENV === 'dev' && (
            <>
              <div className='row mb-3'>
                <div className='col-4'>
                  <span>
                    Início:{' '}
                    {metadata.startedAt
                      ? new Date(metadata.startedAt).toLocaleTimeString()
                      : '-'}
                  </span>
                </div>
                <div className='col-4'>
                  <span>
                    Fim:{' '}
                    {metadata.finishedAt
                      ? new Date(metadata.finishedAt).toLocaleTimeString()
                      : '-'}
                  </span>
                </div>
                <div className='col-4'>
                  <span>
                    Duração:{' '}
                    {metadata.finishedAt
                      ? formatDuration(
                          intervalToDuration({
                            start: new Date(metadata.startedAt),
                            end: new Date(metadata.finishedAt)
                          }),
                          { locale: { ...ptBR, formatDistance } }
                        )
                      : '-'}
                  </span>
                </div>
                <div className='col-12'>
                  <span>
                    Link da execução:{' '}
                    <a
                      target='_blank'
                      rel='noopener noreferrer'
                      href={`https://us-east-1.console.aws.amazon.com/states/home?region=us-east-1#/executions/details/${metadata.executionArn}`}
                    >
                      clique aqui
                    </a>
                  </span>
                </div>
                {executionArnDocx && (
                  <div className='col-12'>
                    <span>
                      Link da execução do DOCX:{' '}
                      <a
                        target='_blank'
                        rel='noopener noreferrer'
                        href={`https://us-east-1.console.aws.amazon.com/states/home?region=us-east-1#/executions/details/${executionArnDocx}`}
                      >
                        clique aqui
                      </a>
                    </span>
                  </div>
                )}
              </div>
            </>
          )}
          <span>
            Identificador da consulta: <strong>{executionId ?? '-'}</strong>
          </span>
          <hr className='mt-3 mb-3' />
          <div className='row'>
            {progressStatus.array.map(item => {
              return (
                <div
                  className='col-6'
                  key={`${item.raw}-${item.text}-${item.status}`}
                  aria-label={item.raw}
                >
                  <span>{getEmoji(item.status)}</span> {item.text}
                </div>
              )
            })}
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Content

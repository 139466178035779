import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import Select from 'react-select'

import { selectMinData } from '../../redux/selectors/dadosBasicos'

import { Content } from '../../components'
import {
  DadosBasicosQuantitativos,
  EndividamentoQuantitativos,
  JuridicoQuantitativos,
  OffshoreQuantitativos,
  OperacionaisQuantitativos,
  PatrimonioQuantitativos
} from './components'

const QUANTITATIVES = [
  { label: 'Dados básicos', value: 'dadosBasicos', disabled: false },
  { label: 'Patrimônio', value: 'patrimonio', disabled: false },
  { label: 'Offshore', value: 'offshore', disabled: false },
  { label: 'Endividamento', value: 'endividamento', disabled: false },
  { label: 'Jurídico', value: 'juridico', disabled: false },
  { label: 'Operacionais', value: 'operacionais', disabled: false }
]

export default function DadosQuantitativos () {
  const { targetName } = useSelector(selectMinData)
  const themes = useSelector(state => state.globalStep.themes)
  const [selectedOption, setSelectedOption] = useState(undefined)

  const options = useMemo(() => {
    return QUANTITATIVES.map(option => ({
      ...option,
      disabled:
        option.value !== 'dadosBasicos' ? !themes.includes(option.value) : false
    }))
  }, [themes])

  const ContentValue = () => {
    switch (selectedOption?.value) {
      case 'endividamento':
        return <EndividamentoQuantitativos />
      case 'operacionais':
        return <OperacionaisQuantitativos />
      case 'offshore':
        return <OffshoreQuantitativos />
      case 'patrimonio':
        return <PatrimonioQuantitativos />
      case 'dadosBasicos':
        return <DadosBasicosQuantitativos />
      case 'juridico':
        return <JuridicoQuantitativos />
      default:
        return null
    }
  }
  return (
    <Content
      title={`Dados Quantitativos ${targetName ? `- ${targetName}` : ''}`}
      subtitle=''
    >
      <div className='row'>
        <div className='form-group col-4 d-flex flex-column'>
          <label
            className='col-form-label col-form-label-sm flex-grow-1'
            style={{ fontSize: 12 }}
          >
            Selecione qual a seção de dados quantitativos deseja visualizar
          </label>
          <Select
            size={100}
            styles={{
              option: (styles, { isDisabled, isFocused, isSelected }) => {
                return {
                  ...styles,
                  backgroundColor: isDisabled
                    ? undefined
                    : isSelected
                    ? '#2c9e69'
                    : isFocused
                    ? '#00b07d'
                    : undefined
                }
              },
              multiValueLabel: styles => ({
                ...styles,
                fontSize: '11px',
                backgroundColor: '#47ddb3',
                color: '#343a40'
              })
            }}
            options={options}
            hideSelectedOptions={false}
            value={selectedOption}
            isOptionDisabled={option => option.disabled}
            onChange={(changedItems, { option }) => {
              if (changedItems === null) {
                setSelectedOption([])
                return
              }
              setSelectedOption(changedItems)
            }}
          />
        </div>
      </div>
      <ContentValue />
    </Content>
  )
}

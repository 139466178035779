import { call, put, select } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import streamSaver from 'streamsaver'
import Swal from 'sweetalert2'

import { AWS_IDENTITY_POOL, AWS_REGION } from '../../config/env'
import { exportXlsx } from '../../components'
import { selectMinData } from '../selectors/dadosBasicos'
import S3Client from '../../lib/s3'
import { setExecutionArnXlsx, setFileNameXlsx } from '../store/xlsx'
import { XLSX_TOAST_ID } from '../../config/stepFunction'

class CantExportError extends Error {
  name = 'CantExportError'
}

class CantExportWarning extends Error {
  name = 'CantExportWarning'
}

export function * listenerGerarXlsx (action) {
  const queueUrl = yield select(state => state.globalStep.queueUrl)
  const { targetDocument } = yield select(selectMinData)

  const state = yield select(state => ({
    globalStep: state.globalStep,
    offshoreTheme: state.offshoreTheme,
    operacionaisTheme: state.operacionaisTheme,
    dadosBasicosTheme: state.dadosBasicosTheme,
    patrimonioTheme: state.patrimonioTheme,
    endividamentoTheme: state.endividamentoTheme,
    jiveTheme: state.jiveTheme,
    relateds: state.relateds
  }))

  const canExport = () => {
    if (!targetDocument) {
      throw new CantExportError('Sem documento selecionado')
    }

    if (!state.dadosBasicosTheme.loaded || state.dadosBasicosTheme.error) {
      if (state.dadosBasicosTheme.error) {
        throw new CantExportWarning('Dados básicos falharam')
      }

      throw new CantExportWarning('Dados básicos ainda não carregaram')
    }

    if (!queueUrl) {
      throw new CantExportError(
        'Ops, um parâmetro está faltando, tente pesquisar novamente'
      )
    }

    // Essa deve ser a última verificação para não bloquear geração do dossier
    // e não bloquear a exibição de outro toast
    if (state.offshoreTheme.error || !state.offshoreTheme.loaded) {
      if (!state.offshoreTheme.loaded) {
        toast.warning('Ainda não carregou dados offshore', {
          toastId: 'export-warning-loading',
          position: toast.POSITION.BOTTOM_RIGHT
        })
      } else {
        toast.warning('Erro ao carregar offshore', {
          toastId: 'export-error',
          position: toast.POSITION.BOTTOM_RIGHT
        })
      }
    }

    if (state.operacionaisTheme.error || !state.operacionaisTheme.loaded) {
      if (!state.operacionaisTheme.loaded) {
        toast.warning('Ainda não carregou dados operacionais', {
          toastId: 'export-warning-loading',
          position: toast.POSITION.BOTTOM_RIGHT
        })
      } else {
        toast.warning('Erro ao carregar operacionais', {
          toastId: 'export-error',
          position: toast.POSITION.BOTTOM_RIGHT
        })
      }
    }

    if (
      state.patrimonioTheme.sncr.error ||
      !state.patrimonioTheme.sncr.loaded ||
      state.patrimonioTheme.aeronaves.error ||
      !state.patrimonioTheme.aeronaves.loaded
    ) {
      if (
        !state.patrimonioTheme.sncr.loaded ||
        !state.patrimonioTheme.aeronaves.loaded
      ) {
        toast.warning('Ainda não carregou dados patrimonial', {
          toastId: 'export-warning-loading',
          position: toast.POSITION.BOTTOM_RIGHT
        })
      } else {
        toast.warning('Erro ao carregar patrimonial', {
          toastId: 'export-error',
          position: toast.POSITION.BOTTOM_RIGHT
        })
      }
    }

    if (
      state.endividamentoTheme.cheque.error ||
      !state.endividamentoTheme.cheque.loaded ||
      state.endividamentoTheme.cndt.error ||
      !state.endividamentoTheme.cndt.loaded ||
      state.endividamentoTheme.pgfn.error ||
      !state.endividamentoTheme.pgfn.loaded ||
      state.endividamentoTheme.protesto.error ||
      !state.endividamentoTheme.protesto.loaded
    ) {
      if (
        !state.endividamentoTheme.cheque.loaded ||
        !state.endividamentoTheme.cndt.loaded ||
        !state.endividamentoTheme.pgfn.loaded ||
        !state.endividamentoTheme.protesto.loaded
      ) {
        toast.warning('Ainda não carregou dados endividamento', {
          toastId: 'export-warning-loading',
          position: toast.POSITION.BOTTOM_RIGHT
        })
      } else {
        toast.warning('Erro ao carregar endividamento', {
          toastId: 'export-error',
          position: toast.POSITION.BOTTOM_RIGHT
        })
      }
    }

    if (
      state.jiveTheme.certidoes.error ||
      !state.jiveTheme.certidoes.loaded ||
      state.jiveTheme.devedores.error ||
      !state.jiveTheme.devedores.loaded ||
      state.jiveTheme.jiveApi.error ||
      !state.jiveTheme.jiveApi.loaded // ||
      // state.jiveTheme.jivebots.error ||
      // !state.jiveTheme.jivebots.loaded
      // TODO: descomentar linhas acima para bloquear exportação quando jivebots falhar
    ) {
      if (
        !state.jiveTheme.certidoes.loaded ||
        !state.jiveTheme.devedores.loaded ||
        !state.jiveTheme.jiveApi.loaded // ||
        // !state.jiveTheme.jivebots.loaded
        // TODO: descomentar linhas acima para bloquear exportação quando jivebots falhar
      ) {
        toast.warning('Ainda não carregou dados jive', {
          toastId: 'export-warning-loading',
          position: toast.POSITION.BOTTOM_RIGHT
        })
      } else {
        toast.warning('Erro ao carregar jive', {
          toastId: 'export-error',
          position: toast.POSITION.BOTTOM_RIGHT
        })
      }
    }

    return true
  }

  try {
    canExport()
    const { name: fileName, executionArn } = yield call(exportXlsx, {
      state,
      queueUrl
    })

    if (executionArn) {
      yield put(setExecutionArnXlsx(executionArn))
    }

    if (fileName) {
      yield put(setFileNameXlsx(fileName))
    }
  } catch (err) {
    const message = err.message || 'Erro ao exportar'
    if (err.name === 'CantExportError') {
      toast.warning(message, {
        toastId: 'export-error',
        position: toast.POSITION.BOTTOM_RIGHT
      })
    } else if (err.name === 'CantExportWarning') {
      toast.warning(message, {
        toastId: 'export-warning-loading',
        position: toast.POSITION.BOTTOM_RIGHT
      })
    } else {
      toast.error(message, {
        toastId: 'export-error',
        position: toast.POSITION.BOTTOM_RIGHT
      })
    }
  }
}

export function * listenerSaveXlsx (action) {
  const { link, bucket, key, type } = action.payload

  const filename = yield select(state => state.xlsx.filename)

  try {
    const s3Client = S3Client({
      region: AWS_REGION,
      identityPoolId: AWS_IDENTITY_POOL
    })

    const contentType =
      type === 'zip'
        ? 'application/zip'
        : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

    const readableStream = yield call(s3Client.readFile, {
      bucket,
      key,
      useStream: true,
      contentType
    })

    const text =
      'Se o download não iniciar em 5 segundos, clique no botão abaixo para realizar o download!'

    try {
      yield call(downloadFileFromStream, { readableStream, filename, link })
    } catch (err) {
      console.error(err)
    }

    yield call(downloadFileFromPresignedUrl, { link, text })
  } catch (err) {
    const message = err.message || 'Erro ao exportar'
    if (err.name === 'CantExportError') {
      toast.warning(message, {
        toastId: 'erro-salvar-xlsx',
        position: toast.POSITION.BOTTOM_RIGHT
      })
    } else {
      toast.error(message, {
        toastId: 'erro-salvar-xlsx',
        position: toast.POSITION.BOTTOM_RIGHT
      })
    }
  }
}

const downloadFileFromStream = async ({ readableStream, filename }) => {
  const fileStream = streamSaver.createWriteStream(`${filename}.xlsx`, {
    writableStrategy: undefined, // (optional)
    readableStrategy: undefined // (optional)
  })
  await readableStream.pipeTo(fileStream)
  toast.dismiss(XLSX_TOAST_ID)
  toast.success('Planilhas geradas com sucesso!', {
    position: toast.POSITION.BOTTOM_RIGHT,
    toastId: 'xlsx-gerado'
  })
}

const downloadFileFromPresignedUrl = async ({ link, text }) => {
  toast.dismiss(XLSX_TOAST_ID)
  const { isConfirmed } = await Swal.fire({
    title: 'Planilhas geradas',
    text: text || 'Clique no botão abaixo para baixar as planilhas geradas!',
    showConfirmButton: true,
    showCloseButton: true,
    icon: 'success',
    confirmButtonText: 'Baixar'
  })

  if (isConfirmed) {
    window.open(link, '_blank')
  }
}

import React from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'

import * as styles from './style.module.scss'
import Search from '../Search'

export default function SubHeader ({ hideSearch, hideTitle }) {
  const location = useLocation()
  const history = useHistory()

  const cleanedPath = location.pathname.replace('/', '')

  return (
    <>
      {cleanedPath !== '' && <Nav />}
      {!hideTitle && (
        <div
          className={`pl-3 mr-3 d-flex flex-row align-items-center ${styles.container}`}
        >
          <div className={`flex-grow-1 ${styles.header}`}>Dossiê 2.0</div>
          <div>
            <button
              className='btn btn-primary mr-2 text-uppercase'
              onClick={() => history.push('/historico')}
            >
              Histórico
            </button>
          </div>
        </div>
      )}
      {!hideSearch && (
        <div className={`pl-3 mr-4 ${styles.container}`}>
          <Search
            {...(cleanedPath === 'pesquisa'
              ? { onSearch: () => history.push('/dados-basicos') }
              : {})}
          />
        </div>
      )}
    </>
  )
}

const Nav = () => {
  const location = useLocation()

  const cleanedPath = location.pathname.replace('/', '')
  const currentPage = !['login', 'historico'].includes(cleanedPath)
    ? 'Pesquisa de Documentos'
    : 'Histórico Pesquisas'
  return (
    <div className={`d-flex flex-row align-items-center ${styles.container}`}>
      <nav aria-label='breadcrumb'>
        <ol
          className='breadcrumb mb-0'
          style={{ backgroundColor: 'transparent' }}
        >
          <li className='breadcrumb-item'>
            <Link to='/'>Dashboard</Link>
          </li>
          <li className='breadcrumb-item active' aria-current='page'>
            {currentPage}
          </li>
        </ol>
      </nav>
    </div>
  )
}

export const isValidCNPJ = cnpj => {
  cnpj = cnpj ?? ''

  cnpj = cnpj.replace(/[^\d]+/g, '')

  if (cnpj === '' || cnpj.length !== 14) {
    return false
  }

  // Elimina CNPJs invalidos conhecidos
  if (
    cnpj === '00000000000000' ||
    cnpj === '11111111111111' ||
    cnpj === '22222222222222' ||
    cnpj === '33333333333333' ||
    cnpj === '44444444444444' ||
    cnpj === '55555555555555' ||
    cnpj === '66666666666666' ||
    cnpj === '77777777777777' ||
    cnpj === '88888888888888' ||
    cnpj === '99999999999999'
  ) {
    return false
  }

  const numsDV1 = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]

  const DV1 = numsDV1.reduce((acc, num, index) => acc + num * cnpj[index], 0)
  const restoDV1 = DV1 % 11
  const digito1 = restoDV1 < 2 ? 0 : 11 - restoDV1

  const numsDV2 = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]
  const DV2 = numsDV2.reduce((acc, num, index) => acc + num * cnpj[index], 0)
  const restoDV2 = DV2 % 11
  const digito2 = restoDV2 < 2 ? 0 : 11 - restoDV2

  return digito1 === parseInt(cnpj[12]) && digito2 === parseInt(cnpj[13])
}

export const isValidCPF = cpf => {
  if (typeof cpf !== 'string') {
    return false
  }

  cpf = cpf.replace(/[^\d]+/g, '')
  if (
    !cpf ||
    cpf.length !== 11 ||
    cpf === '00000000000' ||
    cpf === '11111111111' ||
    cpf === '22222222222' ||
    cpf === '33333333333' ||
    cpf === '44444444444' ||
    cpf === '55555555555' ||
    cpf === '66666666666' ||
    cpf === '77777777777' ||
    cpf === '88888888888' ||
    cpf === '99999999999'
  ) {
    return false
  }
  let soma = 0
  let resto

  for (let i = 1; i <= 9; i++) {
    soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i)
  }

  resto = (soma * 10) % 11
  if (resto === 10 || resto === 11) {
    resto = 0
  }

  if (resto !== parseInt(cpf.substring(9, 10))) {
    return false
  }

  soma = 0
  for (var i = 1; i <= 10; i++) {
    soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i)
  }

  resto = (soma * 10) % 11
  if (resto === 10 || resto === 11) {
    resto = 0
  }

  if (resto !== parseInt(cpf.substring(10, 11))) {
    return false
  }

  return true
}

export const isValidCasesOrOpportunities = casesOrOpportunities => {
  const formattedItems = casesOrOpportunities.map(item =>
    item.toUpperCase().trim()
  )

  return formattedItems.every(item => item.match(/^(SPEC|CAS)(\d+)$/) !== null)
}

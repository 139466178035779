import React from 'react'
import colormap from 'colormap'

import { formatPercent, hashObject } from '../../../utils'

export const PercentageListBox = ({
  items,
  showPercentage = true,
  showName = true,
  alternate = false,
  showTotal,
  showQtd,
  className,
  classNameItem,
  classNameTitleRow
}) => {
  const colors = colormap({
    colormap: 'hsv',
    nshades: Math.max(items.length, 11),
    format: 'hex',
    alpha: 1
  })

  const max = Math.max(...items.map(item => item.percentage)) || 1

  const TitleRow = ({ item }) => {
    return (
      <div className={classNameTitleRow ?? 'd-flex flex-row gap-4'}>
        {showName && <span>{item.name}</span>}
        {showPercentage && (
          <strong>
            {formatPercent(item.percentage, null, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 2
            })}
          </strong>
        )}
        {showQtd && <strong>{item.quantity}</strong>}
      </div>
    )
  }

  return (
    <div className={className ?? 'col-12'}>
      {items.map((item, index) => {
        const showTop = !alternate ? true : index % 2 === 0
        const showBottom = !alternate ? false : !showTop
        return (
          <div key={hashObject(item)} className={classNameItem ?? 'mt-3'}>
            {showTop && <TitleRow item={item} />}
            <div
              className='progress'
              style={{ backgroundColor: 'transparent' }}
            >
              <div
                className='progress-bar rounded'
                role='progressbar'
                style={{
                  backgroundColor: colors[index],
                  width: `${((item.percentage / max) * 100).toFixed(2)}%`
                }}
              />
              {showTotal && (
                <strong className='d-flex align-items-center ml-2'>
                  {item.total}
                </strong>
              )}
            </div>
            {showBottom && <TitleRow item={item} />}
          </div>
        )
      })}
    </div>
  )
}

export const CategoriesPercentageListBox = ({
  items,
  showPercentage = true,
  showName = true,
  alternate = false,
  showTotal,
  showQtd,
  className,
  classNameItem,
  classNameTitleRow
}) => {
  const subcategories = Array.from(
    new Set(
      items.map(item => item.subItems.map(subItem => subItem.name)).flat()
    )
  ).sort((a, b) => a.localeCompare(b))
  const colors = colormap({
    colormap: 'hsv',
    nshades: Math.max(subcategories.length, 11),
    format: 'hex',
    alpha: 1
  })
  const colorMap = new Map(
    subcategories.map((subItem, index) => [subItem, colors[index]])
  )

  const max =
    Math.max(
      ...items
        .map(item => item.subItems.map(subItem => subItem.percentage))
        .flat()
    ) || 1

  const TitleRow = ({ item }) => {
    return (
      <div className={classNameTitleRow ?? 'd-flex flex-row gap-4'}>
        {showName && <span>{item.name}</span>}
        {showPercentage && (
          <strong>
            {formatPercent(item.percentage, null, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 2
            })}
          </strong>
        )}
        {showQtd && <strong>{item.quantity}</strong>}
      </div>
    )
  }

  return (
    <>
      {items.map(item => {
        return (
          <>
            <div className='row mt-3'>
              <div className='col-12'>
                <b>{item.name}</b>
              </div>
            </div>
            <div className='row'>
              <div className={className ?? 'col-12'}>
                {item.subItems
                  .filter(subItem => subItem.quantity !== '0')
                  .map((subItem, index) => {
                    const showTop = !alternate ? true : index % 2 === 0
                    const showBottom = !alternate ? false : !showTop
                    return (
                      <div
                        key={hashObject(subItem)}
                        className={classNameItem ?? 'mt-3'}
                      >
                        {showTop && <TitleRow item={subItem} />}
                        <div
                          className='progress'
                          style={{ backgroundColor: 'transparent' }}
                        >
                          <div
                            className='progress-bar rounded'
                            role='progressbar'
                            style={{
                              backgroundColor: colorMap.get(subItem.name),
                              width: `${(
                                (subItem.percentage / max) *
                                100
                              ).toFixed(2)}%`
                            }}
                          />
                          {showTotal && (
                            <strong className='d-flex align-items-center ml-2'>
                              {subItem.total}
                            </strong>
                          )}
                        </div>
                        {showBottom && <TitleRow item={subItem} />}
                      </div>
                    )
                  })}
              </div>
            </div>
          </>
        )
      })}
      <div className='row mt-3'>
        <div className='col-12'>
          <div className='d-flex flex-row gap-3'>
            {subcategories.map(subItemName => (
              <div
                key={subItemName}
                className='d-flex flex-row align-items-center gap-2'
              >
                <div
                  className='rounded'
                  style={{
                    backgroundColor: colorMap.get(subItemName),
                    width: '24px',
                    height: '15px'
                  }}
                />
                <div>{subItemName}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export const CategoriesBox = ({ items, className }) => {
  return (
    <div
      className={
        className ??
        'mt-3 col-12 d-flex flex-row justify-content-between gap-2 flex-wrap'
      }
    >
      {items.map(item => (
        <div key={hashObject(item)} className=''>
          <strong>{item.name}</strong>
          <div className='mt-1 text-center'>{item.total}</div>
        </div>
      ))}
    </div>
  )
}

export const ListBox = ({ items, className }) => {
  return (
    <div className={className ?? 'col-12 mt-3'}>
      {(items || []).map(item => {
        return (
          <div key={hashObject(item)} className='mt-3'>
            <div className='d-flex flex-row justify-content-between gap-4'>
              <span>{item.name}</span>
              <strong>{item.value}</strong>
            </div>
            <hr className='mt-1' />
          </div>
        )
      })}
    </div>
  )
}

export const TotalBox = ({ value, label = 'Total', className }) => {
  return (
    <div className={className ?? 'col-12'}>
      {label}: <strong className='ml-2'>{value}</strong>
    </div>
  )
}

export const Box = ({ className, children, title }) => {
  const childrens = React.Children.toArray(children)
  return (
    <div className={`${className ?? 'col-12 px-2 mb-3'}`}>
      <div
        className={`${className ?? 'container-fluid py-1 px-0 pb-3'}`}
        style={{
          backgroundColor: 'rgba(0, 214, 177, 0.05)',
          color: '#002A40',
          fontSize: 14
        }}
      >
        <div className='row ml-0'>
          <div className='col-12 mb-2 mx-0 mt-1'>
            <div style={{ fontSize: 16 }}>
              <strong>{title}</strong>
            </div>
          </div>
        </div>
        <div className='row pl-2'>
          <div className='col-12'>
            <div className='container-fluid'>{childrens}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

import React from 'react'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import { useSelector } from 'react-redux'

import { Table } from '../../../components'
import { onSortDocument, onSortDate } from '../../../components/Table/sort'
import { selectMinData } from '../../../redux/selectors/dadosBasicos'
import { formatDocument, hashObject, onlyNumbers } from '../../../utils'

const { SearchBar } = Search

export default function HistoricoFuncional ({ data, error }) {
  const { targetCase } = useSelector(selectMinData)
  if (error) {
    return <div>Erro no carregamento dos dados</div>
  }

  const tableData = data?.historicoFuncional?.neoway || []

  if (tableData.length === 0) {
    return <div />
  }

  const columns = [
    {
      dataField: 'cnpj',
      text: 'CNPJ',
      sort: true,
      sortFunc: onSortDocument,
      searchable: false
    },
    {
      dataField: 'razaoSocial',
      text: 'Razão Social',
      sort: true
    },
    {
      dataField: 'dataAdmissao',
      text: 'Data admissão',
      sortFunc: onSortDate,
      sort: true
    },
    {
      dataField: 'dataDesligamento',
      text: 'Data desligamento',
      sortFunc: onSortDate,
      sort: true
    },
    {
      dataField: 'numMeses',
      text: 'Nº meses',
      sort: true
    },
    {
      dataField: 'rawDocumento',
      hidden: true
    }
  ].map(item => ({ ...item, align: 'center', headerAlign: 'center' }))

  const mappedData = (tableData || []).map(item => {
    const id = hashObject(item)

    return {
      id,
      cnpj: item.cnpj ? (
        <a
          href={`?documento=${onlyNumbers(item.cnpj)}&casos=${targetCase.join(
            ';'
          )}`}
          target='_blank'
          rel='noopener noreferrer'
        >
          {formatDocument(item.cnpj)}
        </a>
      ) : (
        '-'
      ),
      razaoSocial: item.razaoSocial,
      rawDocumento: formatDocument(item.cnpj),
      dataAdmissao: resolveDate(item.dataAdmissao),
      dataDesligamento: resolveDate(item.dataDesligamento),
      numMeses: item.numeroMesesEmpresa
    }
  })

  return (
    <ToolkitProvider keyField='id' data={mappedData} columns={columns} search>
      {props => (
        <>
          <div className='row mb-2'>
            <div className='col-12 text-lg-right'>
              <SearchBar {...props.searchProps} placeholder='Pesquisar' />
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <Table {...props.baseProps} enablePagination />
            </div>
          </div>
        </>
      )}
    </ToolkitProvider>
  )
}

const resolveDate = date => {
  if (!date) {
    return '-'
  }

  if (date.includes('T')) {
    return date
      .split('T')[0]
      .split('-')
      .reverse()
      .join('/')
  }

  return date.replace('-', '/')
}

import {
  resolveProgressStatus,
  isError,
  isLoaded
} from '../../lib/progressStatus'

// Action Types
export const Types = {
  RESET: 'JIVE_THEME/RESET',
  SET_S3_PATH: 'JIVE_THEME/SET_S3_PATH',
  SET_S3_PATH_BOTS: 'JIVE_THEME/SET_S3_PATH_BOTS',
  SET_BOTS_STATUS: 'JIVE_THEME/SET_BOTS_STATUS',
  SET_ERROR_BOTS: 'JIVE_THEME/SET_ERROR_BOTS',
  SET_ERROR: 'JIVE_THEME/SET_ERROR'
}

// Reducer
const initialState = {
  jivebots: {
    s3Path: undefined,
    error: false,
    loaded: false,
    status: {
      taskState: undefined,
      totalBots: undefined,
      totalBotsDone: undefined,
      totalBotsPending: undefined,
      elapsedTime: undefined,
      taskId: undefined,
      presigned: [],
      estimatedTime: 300 // tempo inicial fixo para que o primeiro pooling ocorra
    }
  },
  certidoes: {
    s3Path: undefined,
    error: false,
    loaded: false
  },
  devedores: {
    s3Path: undefined,
    error: false,
    loaded: false
  },
  jiveApi: {
    s3Path: undefined,
    error: false,
    loaded: false
  }
}

export default function reducer (state = initialState, action) {
  switch (action.type) {
    case Types.SET_BOTS_STATUS: {
      return {
        ...state,
        jivebots: {
          ...state.jivebots,
          status: {
            ...state.jivebots.status,
            ...action.payload
          }
        }
      }
    }
    case Types.SET_S3_PATH_BOTS: {
      const statusJivebots = resolveProgressStatus(action.payload.jivebots)

      return {
        ...state,
        jivebots: {
          ...state.jivebots,
          s3Path: action.payload.jivebots,
          loaded: isLoaded(statusJivebots),
          error: isError(statusJivebots)
        }
      }
    }
    case Types.SET_S3_PATH: {
      const statusCertidoes = resolveProgressStatus(action.payload.certidoes)
      const statusDevedores = resolveProgressStatus(action.payload.devedores)
      const statusJiveApi = resolveProgressStatus(action.payload.jiveApi)

      return {
        ...state,
        certidoes: {
          ...state.certidoes,
          s3Path: action.payload.certidoes,
          loaded: isLoaded(statusCertidoes),
          error: isError(statusCertidoes)
        },
        devedores: {
          ...state.devedores,
          s3Path: action.payload.devedores,
          loaded: isLoaded(statusDevedores),
          error: isError(statusDevedores)
        },
        jiveApi: {
          ...state.jiveApi,
          s3Path: action.payload.jiveApi,
          loaded: isLoaded(statusJiveApi),
          error: isError(statusJiveApi)
        }
      }
    }
    case Types.SET_ERROR:
      return {
        ...state,
        certidoes: { ...state.certidoes, error: action.payload, loaded: true },
        devedores: { ...state.devedores, error: action.payload, loaded: true },
        jiveApi: { ...state.jiveApi, error: action.payload, loaded: true }
      }
    case Types.SET_ERROR_BOTS:
      return {
        ...state,
        jivebots: { ...state.jivebots, error: action.payload, loaded: true }
      }
    case Types.RESET:
      return initialState
    default:
      return state
  }
}

// Action Creators
export function setErrorJive (data) {
  return {
    type: Types.SET_ERROR,
    payload: data
  }
}

export function setS3PathJive ({ certidoes, devedores, jiveApi }) {
  return {
    type: Types.SET_S3_PATH,
    payload: { certidoes, devedores, jiveApi }
  }
}

export function setErrorBotsJive (data) {
  return {
    type: Types.SET_ERROR_BOTS,
    payload: data
  }
}

export function setS3PathBotsJive ({ jivebots }) {
  return {
    type: Types.SET_S3_PATH_BOTS,
    payload: { jivebots }
  }
}

export function setBotsStatusJive ({
  taskState,
  totalBots,
  totalBotsDone,
  totalBotsPending,
  elapsedTime,
  estimatedTime,
  taskId,
  presigned
}) {
  return {
    type: Types.SET_BOTS_STATUS,
    payload: {
      taskState,
      totalBots,
      totalBotsDone,
      totalBotsPending,
      elapsedTime,
      estimatedTime,
      taskId,
      presigned
    }
  }
}

export function resetJiveTheme () {
  return {
    type: Types.RESET
  }
}

import React from 'react'

import './../assets/sass/registrais.scss'
import { CNPJ_FORMATTER } from './../utils'

import RegistraisCardPartes from './registraisCardPartes'
import JiveWindow from './JiveWindow'

const RegistraisCard = ({ certidao, hash }) => {
  const name = `registrais.${hash}`

  return (
    <JiveWindow
      title={CNPJ_FORMATTER(certidao.cpfCnpj)}
      defaultOpen
      name={name}
      showNote
    >
      <div className='regCardBody row justify-content-start no-gutters'>
        <div className='regCardItem col-12 row'>
          <div className='col-12 regCardLabel'>Cartório</div>
          <div className='col-12 regCardValue regCardCartorio'>
            {certidao.cartorio}
          </div>
        </div>

        <div className='regCardItem col-auto row'>
          <div className='col-12 regCardLabel'>Pagina</div>
          <div className='col-12 regCardValue'>{certidao.pagina}</div>
        </div>

        <div className='regCardItem col-auto row'>
          <div className='col-12 regCardLabel'>Tipo</div>
          <div className='col-12 regCardValue'>{certidao.tipo}</div>
        </div>

        <div className='regCardItem col-12 row'>
          <div className='regCardItem col-9 row no-gutters'>
            <div className='col-12 regCardLabel'>Cidade</div>
            <div className='col-12 regCardValue'>{certidao.cidade}</div>
          </div>

          <div className='regCardItem col-3 row no-gutters'>
            <div className='col-12 regCardLabel'>Estado</div>
            <div className='col-12 regCardValue'>{certidao.estado}</div>
          </div>

          <RegistraisCardPartes cert={certidao} />
        </div>
      </div>
    </JiveWindow>
  )
}

export default RegistraisCard

import React, { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import { toast } from 'react-toastify'

import {
  Content,
  FiltrosComponent,
  DetailsJuridico,
  JiveWindow,
  RemoveSelected
} from '../../components'

// comentados, pois, a pedido da Jive após reunião de 2023-03-03 o Jurídico só irá pesquisar o Target.
// import Select from './components/Select'
// import Menu from './components/Menu'
import Processos from './components/Processos'

import { selectSearchOptions } from '../../redux/selectors/searchOptions'
import { selectMinData } from '../../redux/selectors/dadosBasicos'

import '../../assets/sass/juridico.scss'

import API from '../../services/api'
import S3Client from '../../lib/s3'
import { PROGRESS_TYPES } from '../../lib/progressStatus'

import {
  toCamelCase,
  useJuridicoNotification,
  useTokenValidation
} from '../../utils'

import { AWS_IDENTITY_POOL, AWS_REGION } from '../../config/env'
import {
  JURIDICO_NOTIFICATION_INTERVAL,
  TOKEN_VALIDATION_INTERVAL
} from '../../config/consts'

const s3Client = S3Client({
  region: AWS_REGION,
  identityPoolId: AWS_IDENTITY_POOL
})

export default function Juridico () {
  useTokenValidation(TOKEN_VALIDATION_INTERVAL)
  useJuridicoNotification(JURIDICO_NOTIFICATION_INTERVAL)
  const relateds = useSelector(state => state.juridicoTheme.relateds)

  const user = useSelector(state => state.autenticacao.user)
  const { pages: enabledPages } = useSelector(selectSearchOptions)
  const { targetName, targetDocument, isCPF } = useSelector(selectMinData)

  const metadata = useSelector(state => state.globalStep.metadata)
  const globalExecutionArn = metadata?.executionArn
  const executionId = globalExecutionArn
    ? globalExecutionArn.split(':').slice(-1)[0]
    : null

  const [mounted, setMounted] = useState(false)

  const [consultaTipo, setTipoConsulta] = useState(0)
  const [processoDetails, setProcessoDetails] = useState(null)
  const [processoChoiced, setProcessoChoiced] = useState(null)
  const [isSearching, setIsSearching] = useState(true)
  const [resultData, setResultData] = useState(undefined)
  const [filteredResultData, setFilteredResultData] = useState(undefined)
  const [targetData, setTargetData] = useState(undefined)
  const [selectedContent, setSelectedContent] = useState()

  const handleSearch = useCallback(
    async param => {
      setProcessoChoiced(null)
      setProcessoDetails(null)

      if (consultaTipo === 0 && targetData) {
        setResultData(targetData)
        return
      }

      const content = param || selectedContent
      if (!content) {
        toast.error('Selecione um documento para consultar')
        return
      }

      setIsSearching(true)
      try {
        const data = await s3Client.readFile({
          bucket: content.bucketName,
          key: content.key
        })
        const formattedData = toCamelCase(data)
        setResultData(formattedData)
        if (consultaTipo === 0) {
          setTargetData(formattedData)
        }
      } catch (err) {
        console.error(err)
        setSelectedContent(null)
      } finally {
        setIsSearching(false)
      }
    },
    [consultaTipo, targetData, selectedContent]
  )

  useEffect(() => {
    if (mounted || !relateds || targetData) {
      return
    }

    const type = isCPF ? 'pf' : 'pj'
    const targetQueryType = 'document'
    const targetMetadata = relateds.find(
      item =>
        item.document === targetDocument &&
        type === item.type &&
        item.queryType === targetQueryType
    )
    const isReady = targetMetadata?.status === PROGRESS_TYPES.LOADED

    if (!isReady) {
      return
    }

    setIsSearching(true)
    s3Client
      .readFile({
        bucket: targetMetadata.bucketName,
        key: targetMetadata.key
      })
      .then(data => {
        const formatted = toCamelCase(data)
        setTargetData(formatted)
        setResultData(formatted)
        setSelectedContent(targetMetadata)
      })
      .finally(() => {
        setIsSearching(false)
        setMounted(true)
      })
  }, [mounted, relateds, targetDocument, targetData, isCPF])

  const clickItemProcesso = processo => {
    setProcessoDetails(null)
    setProcessoChoiced(null)

    const toastId = 'carregando-detalhes-protesto'
    toast('Carregando dados deste processo', {
      position: toast.POSITION.BOTTOM_RIGHT,
      toastId,
      autoClose: false
    })

    const nup = processo.nup?.replace(/\D/g, '')
    if (!nup) {
      console.log('Sem nup')
      return
    }

    API.juridico
      .consultarDetalhes({ nup: nup })
      .then(data => {
        toast.dismiss(toastId)

        if (data.length === 0) {
          data = null
          processo = null

          toast.warn('Detalhes indisponíveis no DBJus', {
            position: toast.POSITION.BOTTOM_RIGHT
          })
        }

        setProcessoDetails(data)
        setProcessoChoiced(processo)
      })
      .catch(error => {
        console.error('ERROR DETAILS', error)
        const message =
          error.response?.data?.message ||
          'Erro ao obter detalhes deste processo'

        toast.dismiss(toastId)
        toast.error(message, {
          position: toast.POSITION.BOTTOM_RIGHT
        })
      })
  }

  const renderSelectedContent = () => {
    if (isSearching) {
      return (
        <div className='d-flex w-100 h-100 flex-1 justify-content-center align-items-center'>
          <div
            className='spinner-border text-primary'
            style={{ width: '3rem', height: '3rem' }}
            role='status'
          >
            <span className='sr-only'>Loading...</span>
          </div>
        </div>
      )
    }

    if (!selectedContent || !resultData) {
      return <div />
    }

    const content = filteredResultData || resultData

    const dbjusArray = [
      ...(content.dbjus?.found ?? []),
      ...(content.dbjus?.notFound?.probable ?? [])
    ].sort((a, b) => b.score - a.score)
    const { resumo, warning: hasInvoices, auditErrors: invoices } = resultData

    return (
      <>
        {hasInvoices && (
          <div className='row'>
            <div className='col-12'>
              <div className='alert alert-warning' role='alert'>
                <strong>Atenção!</strong>
                <p>Identificamos os seguintes problemas na busca jurídica.</p>
                <ul className='ml-4 mb-4'>
                  {invoices.map((invoice, index) => {
                    return (
                      <li key={index.toString()} style={{ listStyle: 'disc' }}>
                        {invoice}
                      </li>
                    )
                  })}
                </ul>
                <p>
                  Para solicitar o re-processamento dos dados, entre em contato
                  com o suporte e forneça o identificador dessa consulta:{' '}
                  <strong>{executionId}</strong>.
                </p>
              </div>
            </div>
          </div>
        )}

        <ResultadosDaBusca length={dbjusArray.length} />
        <Resumo text={resumo} />
        <Processos
          user={user}
          data={dbjusArray}
          onSelectProcesso={clickItemProcesso}
          scores={content?.scores}
          probableVinculados={
            content?.dbjus?.notFound?.probableVinculados || []
          }
        />
        <DetailsJuridico
          processoDetails={processoDetails}
          processo={processoChoiced}
          onCleanSelected={() => {
            setProcessoChoiced(null)
            setProcessoDetails(null)
          }}
        />

        {processoDetails && (
          <RemoveSelected
            onCleanSelected={() => {
              setProcessoChoiced(null)
              setProcessoDetails(null)
            }}
          />
        )}
      </>
    )
  }

  // comentados, pois, a pedido da Jive após reunião de 2023-03-03 o Jurídico só irá pesquisar o Target.
  // const renderSelect = () => {
  //   if (consultaTipo === 0) {
  //     return (
  //       <div className='col-12 d-flex'>
  //         <button
  //           className='btn btn-outline-dark font-weight-bold text-uppercase'
  //           onClick={() => {
  //             const targetMetadata = relateds.find(
  //               item => item.document === targetDocument
  //             )
  //             setSelectedContent(targetMetadata)
  //             handleSearch(targetMetadata)
  //           }}
  //         >
  //           <span>Re-pesquisar</span>
  //         </button>
  //       </div>
  //     )
  //   }
  //   if (consultaTipo === 1) {
  //     return (
  //       <>
  //         <div className='col-10'>
  //           <Select
  //             options={relateds}
  //             type='pf'
  //             value={selectedContent}
  //             onChange={setSelectedContent}
  //           />
  //         </div>
  //         <div className='col-2 d-flex justify-content-end align-items-end'>
  //           <div className='h-100'>
  //             <button
  //               className='h-100 btn btn-outline-dark font-weight-bold text-uppercase'
  //               onClick={() => handleSearch()}
  //             >
  //               <span>Pesquisar</span>
  //             </button>
  //           </div>
  //         </div>
  //       </>
  //     )
  //   }

  //   if (consultaTipo === 2) {
  //     return (
  //       <>
  //         <div className='col-10'>
  //           <Select
  //             options={relateds}
  //             type='pj'
  //             value={selectedContent}
  //             onChange={setSelectedContent}
  //           />
  //         </div>
  //         <div className='col-2 d-flex justify-content-end align-items-end'>
  //           <div className='h-100'>
  //             <button
  //               className='h-100 btn btn-outline-dark font-weight-bold text-uppercase'
  //               onClick={() => handleSearch()}
  //             >
  //               <span>Pesquisar</span>
  //             </button>
  //           </div>
  //         </div>
  //       </>
  //     )
  //   }
  // }

  return (
    <>
      <ReactTooltip />
      <Content
        title={`Jurídico ${targetName ? `- ${targetName}` : ''}`}
        subtitle='Busca judicial cruzada pela base de dados do DBJus e do Escavador.'
      >
        {!enabledPages.juridico ? (
          <div className='d-flex w-100 h-100 flex-1 justify-content-center align-items-center'>
            <p className='h3'>Esta aba está desabilitada!</p>
          </div>
        ) : (
          <>
            <JiveWindow title='Filtros'>
              {/* `Menu` e `renderSelect` estão comentados, pois, a pedido da Jive após reunião de 2023-03-03 o Jurídico só irá pesquisar o Target. */}
              {/* <Menu
                selected={consultaTipo}
                onChange={newTipo => {
                  setTipoConsulta(newTipo)
                }}
              /> */}
              <FiltrosComponent
                data={resultData}
                onChangeFilter={newResultData =>
                  setFilteredResultData(newResultData)
                }
              />
              {/* {targetDocument && (
                <div className='row mt-3 mb-2'>{renderSelect()}</div>
              )} */}
            </JiveWindow>

            {targetDocument && renderSelectedContent()}
          </>
        )}
      </Content>
    </>
  )
}

const ResultadosDaBusca = ({ length }) => {
  return (
    <JiveWindow title='Resultados da Busca' defaultOpen>
      <div>
        Foram encontrados <b>{length}</b> processos
      </div>
    </JiveWindow>
  )
}

const Resumo = ({ text }) => {
  return (
    <JiveWindow title='Resumo' defaultOpen>
      <div>{text}</div>
    </JiveWindow>
  )
}

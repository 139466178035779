import axios from 'axios'
import camelcaseKeys from 'camelcase-keys'

import { COMMENTS_BASE_URL } from '../../config/env'
import { getScope } from '../../utils'

const nEntries = 1

export const salvarComentario = async ({ texto, documento, campo, user }) => {
  const scope = getScope()

  const { data } = await axios.put(
    `${COMMENTS_BASE_URL}/putField`,
    {
      value: texto
    },
    {
      headers: {
        'x-username-id': user,
        'x-scope-id': scope
      },
      params: {
        fields: campo,
        key: documento
      }
    }
  )
  const formattedData = camelcaseKeys(data, { deep: true })
  return formattedData
}

export const pegarComentario = async ({ documento, campo }) => {
  const scope = getScope()

  const { data } = await axios.get(`${COMMENTS_BASE_URL}/getField`, {
    params: {
      fields: campo,
      key: documento,
      n_entries: nEntries
    },
    headers: {
      'x-scope-id': scope
    }
  })
  const formattedData = camelcaseKeys(data, { deep: true })
  return formattedData
}

export const bloquearComentario = async ({ documento, campo, user }) => {
  const scope = getScope()

  const { data } = await axios.get(`${COMMENTS_BASE_URL}/lockField`, {
    headers: {
      'x-username-id': user,
      'x-scope-id': scope
    },
    params: {
      fields: campo,
      key: documento
    }
  })
  const formattedData = camelcaseKeys(data, { deep: true })
  return formattedData
}

export const desbloquearComentario = async ({ documento, campo }) => {
  const scope = getScope()

  const { data } = await axios.get(`${COMMENTS_BASE_URL}/unlockField`, {
    params: {
      fields: campo,
      key: documento
    },
    headers: {
      'x-scope-id': scope
    }
  })
  const formattedData = camelcaseKeys(data, { deep: true })
  return formattedData
}

export const pegarTodosOsComentarios = async ({ documento }) => {
  const nEntries = 1

  const scope = getScope()

  const { data } = await axios.get(`${COMMENTS_BASE_URL}/getKey`, {
    params: {
      key: documento,
      n_entries: nEntries
    },
    headers: {
      'x-scope-id': scope
    }
  })

  const formattedData = camelcaseKeys(data, { deep: true })
  return formattedData
}

// Action Types
export const Types = {
  GERAR_DOCUMENTO: 'XLSX/GERAR_DOCUMENTO',
  SET_LOADING: 'XLSX/SET_LOADING',
  SET_ERROR: 'XLSX/SET_ERROR',
  SET_DOCUMENTO: 'XLSX/SET_DOCUMENTO',
  SET_FILE_NAME: 'XLSX/SET_FILE_NAME',
  SET_EXECUTION_ARN: 'XLSX/SET_EXECUTION_ARN',
  SAVE_XLSX: 'XLSX/SAVE_XLSX',
  RESET: 'XLSX/RESET'
}

export const INITIAL_STATE = {
  executionArn: undefined,
  loading: false,
  error: false,
  filename: undefined
}

export default function reducer (state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SET_EXECUTION_ARN:
      return {
        ...state,
        executionArn: action.payload
      }
    case Types.SET_LOADING:
      return {
        ...state,
        loading: action.payload
      }
    case Types.SET_ERROR:
      return {
        ...state,
        ERROR: action.payload
      }
    case Types.SET_FILE_NAME:
      return {
        ...state,
        filename: action.payload
      }

    case Types.RESET:
      return INITIAL_STATE
    default:
      return state
  }
}

export const resetXlsx = () => ({
  type: Types.RESET
})

export const saveXlsx = ({ bucket, key, link, type }) => ({
  type: Types.SAVE_XLSX,
  payload: { bucket, key, link, type }
})

export const gerarXlsx = () => ({
  type: Types.GERAR_DOCUMENTO
})

export const setLoadingXlsx = data => ({
  type: Types.SET_LOADING,
  payload: data
})

export const setFileNameXlsx = data => ({
  type: Types.SET_FILE_NAME,
  payload: data
})

export const setExecutionArnXlsx = data => ({
  type: Types.SET_EXECUTION_ARN,
  payload: data
})

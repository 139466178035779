import S3Client from './s3'
import API from '../services/api'
import { AWS_IDENTITY_POOL, AWS_REGION, BUCKET_NAME } from '../config/env'

import { getUserId, getScope, hashObject } from '../utils'

const s3Client = S3Client({
  region: AWS_REGION,
  identityPoolId: AWS_IDENTITY_POOL
})

export const gerarDocumento = async ({
  state,
  queueUrl,
  document,
  discoveryStartedAt
}) => {
  const userId = getUserId()
  const scope = getScope()

  const timestamp = new Date().getTime()
  const hash = hashObject({ state, timestamp, scope, userId })

  const s3Path = {
    bucket: BUCKET_NAME,
    key: `discovery/${scope}/applications/docx/document=${document}/date=${discoveryStartedAt}/${userId}-${hash}-input.json`
    // key: `dataflow/discovery/resource=docx/document=${document}/${scope}-${userId}-${hash}_input.json`
  }

  await s3Client.writeFile({
    bucket: s3Path.bucket,
    key: s3Path.key,
    data: state,
    contentType: 'application/json'
  })

  const { executionArn } = await API.docx.startGenerateDocumento({
    queueUrl,
    document,
    hash,
    s3InputPath: s3Path,
    userId,
    scope,
    bucketOutput: BUCKET_NAME,
    discoveryStartedAt
  })

  return executionArn
}

export const gerarXlsx = async ({
  state,
  queueUrl,
  document,
  discoveryStartedAt
}) => {
  const userId = getUserId()
  const scope = getScope()

  const timestamp = new Date().getTime()
  const hash = hashObject({ state, timestamp, scope, userId })

  const s3Path = {
    bucket: BUCKET_NAME,
    key: `discovery/${scope}/applications/excel/document=${document}/date=${discoveryStartedAt}/${userId}-${hash}-input.json`
    // key: `dataflow/discovery/resource=excel/document=${document}/${scope}-${userId}-${hash}_input.json`
  }

  await s3Client.writeFile({
    bucket: s3Path.bucket,
    key: s3Path.key,
    data: state,
    contentType: 'application/json'
  })

  const { executionArn } = await API.xlsx.startGenerate({
    queueUrl,
    document,
    hash,
    s3InputPath: s3Path,
    userId,
    scope,
    bucketOutput: BUCKET_NAME,
    discoveryStartedAt
  })

  return executionArn
}

import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'

import { mapWithRelationship } from '../lib/detectMatch'

import { selectMinData } from './../redux/selectors/dadosBasicos'
import { selectRelateds } from './../redux/selectors/relateds'
import { selectSearchOptions } from '../redux/selectors/searchOptions'

import {
  formatDocument,
  hashObject,
  isPending,
  RESOLVE_NONE,
  useJuridicoNotification,
  useTokenValidation
} from '../utils'
import { fetchOffshoreFromS3 } from '../lib/s3Theme'

import { Content, JiveWindow, Table } from './../components'
import {
  JURIDICO_NOTIFICATION_INTERVAL,
  TOKEN_VALIDATION_INTERVAL
} from '../config/consts'
import { onSortDate, onSortNumber } from '../components/Table/sort'

const { SearchBar } = Search

export default function Offshore () {
  useTokenValidation(TOKEN_VALIDATION_INTERVAL)
  useJuridicoNotification(JURIDICO_NOTIFICATION_INTERVAL)

  const { targetName, targetDocument } = useSelector(selectMinData)
  const { pages: enabledPages } = useSelector(selectSearchOptions)
  const offshoreTheme = useSelector(state => state.offshoreTheme)
  const jiveTheme = useSelector(state => state.jiveTheme)

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({
    capitalEstrangeiro: { data: [], loaded: false, error: false },
    empresasOffshore: { data: [], loaded: false, error: false },
    imoveisOffshore: { data: [], loaded: false, error: false },
    offshoreLeaks: { data: [], loaded: false, error: false }
  })

  useEffect(() => {
    if (isPending(offshoreTheme) && isPending(jiveTheme.jiveApi)) {
      return
    }

    setLoading(true)
    fetchOffshoreFromS3({
      offshoreLeaksPayload: offshoreTheme,
      jiveApiPayload: jiveTheme.jiveApi
    })
      .then(offshore => {
        setData(offshore)
      })
      .catch(error => {
        toast.error(error.message)
        console.error(error)
        setData({
          capitalEstrangeiro: { data: [], loaded: false, error: true },
          empresasOffshore: { data: [], loaded: false, error: true },
          imoveisOffshore: { data: [], loaded: false, error: true },
          offshoreLeaks: { data: [], loaded: false, error: true }
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }, [offshoreTheme, jiveTheme])

  const isStepPending =
    targetDocument && isPending(offshoreTheme) && isPending(jiveTheme.jiveApi)

  return (
    <Content
      title={`Offshore ${targetName ? `- ${targetName}` : ''}`}
      subtitle='Busca por offshore na base de dados Offshore Leaks e Jive.'
    >
      {!enabledPages.offshore ? (
        <div className='d-flex w-100 h-100 flex-1 justify-content-center align-items-center'>
          <p className='h3'>Esta aba está desabilitada!</p>
        </div>
      ) : loading || isStepPending ? (
        <div className='d-flex w-100 h-100 flex-1 justify-content-center align-items-center'>
          <div
            className='spinner-border text-primary'
            style={{ width: '3rem', height: '3rem' }}
            role='status'
          >
            <span className='sr-only'>Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <ListagemImoveisJiveOffshore
            data={data.imoveisOffshore}
            error={jiveTheme.error}
          />
          <ListagemEmpresasOffshore
            data={data.offshoreLeaks}
            error={offshoreTheme.error}
          />
          <ListagemCapitalEstrangeiro
            data={data.capitalEstrangeiro}
            error={offshoreTheme.error}
          />
          <ListagemEmpresasJiveOffshore
            data={data.empresasOffshore}
            error={offshoreTheme.error}
          />
        </>
      )}
    </Content>
  )
}

const ListagemEmpresasOffshore = React.memo(({ data, error }) => {
  const relateds = useSelector(selectRelateds)

  if (isPending(data) && !error) {
    return <div />
  }

  const name = 'offshore.listagemDeEmpresasOffshore'

  if (data.error || error) {
    return (
      <div className='col-12 px-0 mx-0'>
        <JiveWindow
          title='Listagem de Empresas Offshore'
          subtitle='Dados do Offshore Leaks'
          name={name}
          showNote
        >
          <div>
            Ops! Aconteceu um erro e por isso não foi possível carregar a
            listagem
          </div>
        </JiveWindow>
      </div>
    )
  }

  const tableData = data.data || []

  if (tableData.length === 0) {
    return (
      <div className='col-12 px-0 mx-0'>
        <JiveWindow
          title='Listagem de Empresas Offshore'
          subtitle='Dados do Offshore Leaks'
          name={name}
          showNote
        >
          <div />
        </JiveWindow>
      </div>
    )
  }

  return (
    <div className='col-12 px-0 mx-0'>
      <JiveWindow
        title='Listagem de Empresas Offshore'
        subtitle='Dados do Offshore Leaks'
        name={name}
        showNote
      >
        <ToolkitProvider
          keyField='id'
          search
          columns={[
            { dataField: 'sourceId', text: 'Tipo', sort: true },
            { dataField: 'relType', text: 'Relacionado', sort: true },
            { dataField: 'name1', text: 'Nome PF', sort: true },
            {
              dataField: 'grau',
              text: 'Grau',
              sort: true
            },
            { dataField: 'name2', text: 'Nome PJ', sort: true },
            { dataField: 'address', text: 'Endereço', sort: true },
            {
              dataField: 'startDate',
              text: 'Início',
              sort: true,
              sortFunc: onSortDate
            },
            {
              dataField: 'endDate',
              text: 'Término',
              sort: true,
              sortFunc: onSortDate
            }
          ].map(item => ({ ...item, headerAlign: 'center', align: 'center' }))}
          data={mapWithRelationship({
            array: tableData || [],
            nameKey: 'name1',
            relateds
          }).map(item => {
            const id = hashObject(item)

            return {
              id,
              sourceId: item.sourceId,
              relType: item.relType,
              name1: item.name1,
              name2: item.name2,
              grau: item.relationship,
              address: item.address,
              startDate: item.startDate,
              endDate: item.endDate
            }
          })}
        >
          {props => (
            <>
              <div className='row'>
                <div className='col-12 text-right'>
                  <SearchBar {...props.searchProps} placeholder='Pesquisar' />
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <Table enablePagination {...props.baseProps} />
                </div>
              </div>
            </>
          )}
        </ToolkitProvider>
      </JiveWindow>
    </div>
  )
})

const ListagemImoveisJiveOffshore = React.memo(({ data, error }) => {
  const { targetName, isCPF, targetDocument } = useSelector(selectMinData)
  const name = 'offshore.imoveisJiveOffshore'
  if (isPending(data) && !error) {
    return <div />
  }

  if (data.error || error) {
    return (
      <div className='col-12 px-0 mx-0'>
        <JiveWindow
          title={`Jive Imóveis Offshore ${targetName ? `- ${targetName}` : ''}`}
          subtitle={`${
            targetDocument ? (isCPF ? 'CPF:' : 'CNPJ:') : ''
          } ${formatDocument(targetDocument)}`}
          name={name}
          showNote
        >
          <div>
            Ops! Aconteceu um erro e por isso não foi possível carregar a
            listagem
          </div>
        </JiveWindow>
      </div>
    )
  }

  const tableData = data.data || []

  if (tableData.length === 0) {
    return (
      <div className='col-12 px-0 mx-0'>
        <JiveWindow
          title={`Jive Imóveis Offshore ${targetName ? `- ${targetName}` : ''}`}
          subtitle={`${
            targetDocument ? (isCPF ? 'CPF:' : 'CNPJ:') : ''
          } ${formatDocument(targetDocument)}`}
          name={name}
          showNote
        >
          <div />
        </JiveWindow>
      </div>
    )
  }

  return (
    <div className='col-12 px-0 mx-0'>
      <JiveWindow
        title={`Jive Imóveis Offshore ${targetName ? `- ${targetName}` : ''}`}
        subtitle={`${
          targetDocument ? (isCPF ? 'CPF:' : 'CNPJ:') : ''
        } ${formatDocument(targetDocument)}`}
        name={name}
        showNote
      >
        <ToolkitProvider
          keyField='id'
          search
          columns={[
            { dataField: 'proprietarios', text: 'Proprietário(s)', sort: true },
            { dataField: 'uf', text: 'UF', sort: true },
            { dataField: 'endereco', text: 'Endereço', sort: true },
            {
              dataField: 'inscricaoImobiliaria',
              text: 'Inscrição Imobiliária',
              sort: true
            },
            { dataField: 'municipio', text: 'Município', sort: true },
            { dataField: 'pais', text: 'País', sort: true }
          ].map(item => ({ ...item, headerAlign: 'center', align: 'center' }))}
          data={tableData.map(imovel => {
            const id = hashObject(imovel)

            return {
              id,
              proprietarios:
                imovel.proprietarios && imovel.proprietarios.length > 0
                  ? imovel.proprietarios.map(function (i) {
                      const nome = i.nomePessoa ? i.nomePessoa : '-'
                      const tipo = i.tipoPessoa ? i.tipoPessoa : '-'
                      const doc = i.documentoPessoa ? i.documentoPessoa : '-'
                      return nome + ' (' + tipo + ') : ' + doc + '\t'
                    })
                  : '-',
              uf: imovel.UF,
              endereco: imovel.endereco,
              inscricaoImobiliaria: imovel.inscricaoImobiliaria,
              municipio: RESOLVE_NONE(imovel.municipio),
              pais: RESOLVE_NONE(imovel.pais)
            }
          })}
        >
          {props => (
            <>
              <div className='row'>
                <div className='col-12 text-right'>
                  <SearchBar {...props.searchProps} placeholder='Pesquisar' />
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <Table enablePagination {...props.baseProps} />
                </div>
              </div>
            </>
          )}
        </ToolkitProvider>
      </JiveWindow>
    </div>
  )
})

const ListagemCapitalEstrangeiro = React.memo(({ data, error }) => {
  const relateds = useSelector(selectRelateds)
  const name = 'offshore.listagemCapitalEstrangeiro'

  if (isPending(data) && !error) {
    return <div />
  }

  if (data.error || error) {
    return (
      <div className='col-12 px-0 mx-0'>
        <JiveWindow
          title='Capital Estrangeiro'
          subtitle='Dados da Jive'
          name={name}
          showNote
        >
          <div>
            Ops! Aconteceu um erro e por isso não foi possível carregar a
            listagem
          </div>
        </JiveWindow>
      </div>
    )
  }

  const tableData = data.data || []

  if (tableData.length === 0) {
    return (
      <div className='col-12 px-0 mx-0'>
        <JiveWindow
          title='Capital Estrangeiro'
          subtitle='Dados da Jive'
          name={name}
          showNote
        >
          <div />
        </JiveWindow>
      </div>
    )
  }
  return (
    <div className='col-12 px-0 mx-0'>
      <JiveWindow
        title='Capital Estrangeiro'
        subtitle='Dados da Jive'
        name={name}
        showNote
      >
        <ToolkitProvider
          keyField='id'
          search
          columns={[
            {
              dataField: 'nomePessoaNacional',
              text: 'Nome pessoa nacional',
              sort: true
            },
            {
              dataField: 'grau',
              text: 'Grau',
              sort: true
            },
            {
              dataField: 'nomePessoaEstrangeira',
              text: 'Nome pessoa estrangeira',
              sort: true
            },
            { dataField: 'codigo', text: 'Código', sort: true },
            { dataField: 'ano', text: 'Ano', sort: true },
            { dataField: 'mes', text: 'Mês', sort: true },
            { dataField: 'uf', text: 'UF', sort: true },
            {
              dataField: 'tipoPessoaNacional',
              text: 'Tipo pessoa nacional',
              sort: true
            },
            {
              dataField: 'paisPessoaEstrangeira',
              text: 'País pessoa estrangeira',
              sort: true
            },
            {
              dataField: 'documentoPessoaNacional',
              text: 'Documento pessoa nacional',
              sort: true
            },
            { dataField: 'moedaOperacao', text: 'Moeda operação', sort: true },
            {
              dataField: 'valorOperacao',
              text: 'Valor operação',
              sort: true,
              sortFunc: onSortNumber
            }
          ]}
          data={mapWithRelationship({
            array: tableData || [],
            documentKey: 'documentoPessoaNacional',
            nameKey: 'nomePessoaNacional',
            relateds
          }).map(capital => {
            const id = hashObject(capital)

            return {
              id,
              nomePessoaNacional: capital.nomePessoaNacional,
              nomePessoaEstrangeira: capital.nomePessoaEstrangeira,
              codigo: capital.codigo,
              grau: capital.relationship,
              ano: capital.ano,
              mes: capital.mes,
              uf: capital.uf,
              tipoPessoaNacional: capital.tipoPessoaNacional,
              paisPessoaEstrangeira: capital.paisPessoaEstrangeira,
              documentoPessoaNacional: capital.documentoPessoaNacional,
              moedaOperacao: capital.moedaOperacao,
              valorOperacao: capital.valorOperacao
            }
          })}
        >
          {props => (
            <>
              <div className='row'>
                <div className='col-12 text-right'>
                  <SearchBar {...props.searchProps} placeholder='Pesquisar' />
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <Table enablePagination {...props.baseProps} />
                </div>
              </div>
            </>
          )}
        </ToolkitProvider>
      </JiveWindow>
    </div>
  )
})

const ListagemEmpresasJiveOffshore = React.memo(({ data, error }) => {
  const relateds = useSelector(selectRelateds)
  const name = 'offshore.empresasOffshore'

  if (isPending(data) && !error) {
    return <div />
  }

  if (data.error || error) {
    return (
      <div className='col-12 px-0 mx-0'>
        <JiveWindow
          title='Empresas offshore'
          subtitle='Dados da Jive'
          name={name}
          showNote
        >
          <div>
            Ops! Aconteceu um erro e por isso não foi possível carregar a
            listagem
          </div>
        </JiveWindow>
      </div>
    )
  }

  const tableData = data.data || []

  if (tableData.length === 0) {
    return (
      <div className='col-12 px-0 mx-0'>
        <JiveWindow
          title='Empresas offshore'
          subtitle='Dados da Jive'
          name={name}
          showNote
        >
          <div />
        </JiveWindow>
      </div>
    )
  }

  return (
    <div className='col-12 px-0 mx-0'>
      <JiveWindow
        title='Empresas offshore'
        subtitle='Dados da Jive'
        name={name}
        showNote
      >
        <ToolkitProvider
          keyField='id'
          search
          columns={[
            { dataField: 'nomeEmpresa', text: 'Nome empresa', sort: true },
            { dataField: 'nomePessoa', text: 'Nome pessoa', sort: true },
            {
              dataField: 'grau',
              text: 'Grau',
              sort: true
            },
            { dataField: 'tipoPessoa', text: 'Tipo pessoa', sort: true },
            { dataField: 'cargo', text: 'Cargo', sort: true },
            {
              dataField: 'documentoPessoa',
              text: 'Documento pessoa',
              sort: true
            },
            { dataField: 'status', text: 'Status', sort: true },
            { dataField: 'dataInicio', text: 'Data de início', sort: true },
            { dataField: 'dataRegistro', text: 'Data de registro', sort: true },
            { dataField: 'endereco', text: 'Endereço', sort: true },
            { dataField: 'municipio', text: 'Município', sort: true },
            { dataField: 'uf', text: 'UF', sort: true },
            { dataField: 'pais', text: 'País', sort: true }
          ]}
          data={mapWithRelationship({
            array: tableData || [],
            documentKey: 'documentoPessoa',
            nameKey: 'nomeEmpresa',
            relateds
          }).map(empresa => {
            const id = hashObject(empresa)

            return {
              id,
              nomeEmpresa: empresa.nomeEmpresa,
              nomePessoa: empresa.nomePessoa,
              tipoPessoa: empresa.tipoPessoa,
              cargo: empresa.cargo,
              documentoPessoa: empresa.documentoPessoa,
              status: empresa.status,
              dataInicio: empresa.dataInicio,
              dataRegistro: empresa.dataRegistro,
              endereco: empresa.endereco,
              municipio: empresa.municipio,
              uf: empresa.uf,
              pais: empresa.pais
            }
          })}
        >
          {props => (
            <>
              <div className='row'>
                <div className='col-12 text-right'>
                  <SearchBar {...props.searchProps} placeholder='Pesquisar' />
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <Table enablePagination {...props.baseProps} />
                </div>
              </div>
            </>
          )}
        </ToolkitProvider>
      </JiveWindow>
    </div>
  )
})

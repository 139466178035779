import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'

import { selectMinData } from '../../../redux/selectors/dadosBasicos'

import { formatDecimal, isPending } from '../../../utils'
import { fetchOperacionaisAnalyticsFromS3 } from '../../../lib/s3Theme'

import { JiveWindow, Loading } from '../../../components'
import { Box, PercentageListBox, TotalBox } from './Boxes'

const INITIAL_DATA_STATE = {
  operacionais: {
    matchFuncionarios: {
      total: 0,
      years: []
    },
    status: {
      matchFuncionarios: 'PENDING'
    }
  },
  jive: {
    devedores: {
      total: 0,
      categories: []
    },
    status: {
      devedores: 'PENDING'
    }
  }
}

export default function OperacionaisQuantitativos () {
  const { targetDocument } = useSelector(selectMinData)
  const [operacionaisTheme, jiveTheme] = useSelector(state => [
    state.analyticsTheme.operacionais,
    state.analyticsTheme.jive
  ])
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({
    data: INITIAL_DATA_STATE,
    loaded: false,
    error: false
  })

  useEffect(() => {
    const pending = isPending(operacionaisTheme) && isPending(jiveTheme)
    if (pending) {
      return
    }

    setLoading(true)

    fetchOperacionaisAnalyticsFromS3({
      payloadOperacionais: operacionaisTheme,
      payloadJive: jiveTheme
    })
      .then(operacionais => {
        setData(operacionais)
      })
      .catch(error => {
        toast.error(error.message)
        console.error(error)
        setData({
          data: {
            ...INITIAL_DATA_STATE,
            operacionais: {
              ...INITIAL_DATA_STATE.operacionais,
              status: {
                matchFuncionarios: 'FAILED'
              }
            },
            jive: {
              ...INITIAL_DATA_STATE.jive,
              status: {
                devedores: 'FAILED'
              }
            }
          },
          loaded: false,
          error: true
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }, [operacionaisTheme, jiveTheme])

  const isStepPending =
    targetDocument && isPending(operacionaisTheme) && isPending(jiveTheme)

  if (loading || isStepPending) {
    return <Loading />
  }

  const name = 'quantitativos.operacionais'

  return (
    <JiveWindow title='Operacionais' name={name} showNote defaultOpen>
      <div className='container-fluid gap-2'>
        <div className='row'>
          <div className='col-6'>
            <div className='row'>
              <MathFuncionariosBox
                error={data.error}
                data={data.data.operacionais.matchFuncionarios}
                status={data.data.operacionais.status?.matchFuncionarios}
              />
            </div>
          </div>
          <div className='col-6'>
            <div className='row'>
              <DevedoresJiveBox
                error={data.error}
                data={data.data.jive.devedores}
                status={data.data.jive.status?.devedores}
              />
            </div>
          </div>
        </div>
      </div>
    </JiveWindow>
  )
}

const DevedoresJiveBox = ({ error, data, status }) => {
  const title = 'Devedores Jive'

  if (error || status === 'FAILED' || !status) {
    return (
      <Box title={title}>
        <div className='row'>
          <div>Erro no carregamento dos dados</div>
        </div>
      </Box>
    )
  }

  if (status === 'SKIPPED') {
    return (
      <Box title={title}>
        <div className='row'>
          <div>Dados não carregados para este tema</div>
        </div>
      </Box>
    )
  }

  const labelDict = {
    principal: 'Devedor Principal',
    devedor_de_risco: 'Devedor de Risco'
  }

  const { total, categories } = data
  const mappedCategories = categories.map(item => ({
    name: labelDict[item.category] || item.category,
    total: item.total,
    percentage: item.percentage
  }))

  const formattedTotal = formatDecimal(total, null, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  })

  return (
    <Box title={title}>
      <div className='row'>
        <TotalBox value={formattedTotal} />
      </div>
      <div className='row'>
        <PercentageListBox items={mappedCategories} showTotal />
      </div>
    </Box>
  )
}

const MathFuncionariosBox = ({ error, data, status }) => {
  const title = 'Match funcionários e ex-funcionários'

  if (error || status === 'FAILED' || !status) {
    return (
      <Box title={title}>
        <div className='row'>
          <div>Erro no carregamento dos dados</div>
        </div>
      </Box>
    )
  }

  if (status === 'SKIPPED') {
    return (
      <Box title={title}>
        <div className='row'>
          <div>Dados não carregados para este tema</div>
        </div>
      </Box>
    )
  }

  const { total, years } = data
  const mappedYears = years
    .sort((a, b) => b.year - a.year)
    .map(item => ({
      name: item.year,
      total: item.total,
      percentage: item.percentage
    }))

  const formattedTotal = formatDecimal(total, null, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  })

  return (
    <Box title={title}>
      <div className='row'>
        <TotalBox
          value={formattedTotal}
          label='Quantidade de funcionários que são ex-funcionários'
        />
      </div>
      <div className='row'>
        <PercentageListBox showTotal items={mappedYears} />
      </div>
    </Box>
  )
}

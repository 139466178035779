import React from 'react'
import Papa from 'papaparse'
import FileSaver from 'file-saver'
import iconv from 'iconv-lite'

import { FiBookmark } from 'react-icons/fi'
import { RiDonutChartFill } from 'react-icons/ri'
import { FaFileExport } from 'react-icons/fa'
import { BsTable } from 'react-icons/bs'
import { AiOutlineBorderlessTable } from 'react-icons/ai'

const JuridicoToolbar = ({
  onChangeVisualization,
  visualization,
  showPartes,
  onChangeShowPartes,
  showScores,
  onChangeShowScores,
  showCsvButton,
  encoding = 'ISO-8859-1',
  data
}) => {
  const handleExportCsv = () => {
    const partesTipo = Array.from(
      new Set(
        data
          .map(item => item.partes.map(parte => parte.tipo.toLowerCase()))
          .flat()
      )
    )

    const mappedData = data
      .map(({ partes, ...item }) => {
        const partesParsed = partesTipo.reduce((obj, tipo) => {
          obj[tipo] = partes
            .filter(parte => parte.tipo.toLowerCase() === tipo)
            .map(parte => parte.nome)
            .join(', ')
          return obj
        }, {})

        return { ...item, score: item.score.toFixed(2), ...partesParsed }
      })
      .map(item => {
        const entries = Object.entries(item).map(([key, value]) => {
          if (typeof value === 'string') {
            value = value
              .replace(/;/g, ',')
              .replace(/( ( )+)/g, ' ')
              .trim()
          }

          return [key, value]
        })
        return Object.fromEntries(entries)
      })

    const csv = Papa.unparse(mappedData, {
      delimiter: ';',
      columns: [
        'nup',
        'estado',
        'justica',
        'classes',
        'assuntos',
        'valor',
        'diario',
        'score',
        'processos vinculados',
        ...partesTipo
      ]
    })
    const buffer = iconv.encode(csv, encoding)

    const filename = 'juridico.csv'
    FileSaver.saveAs(
      new Blob([buffer], { type: `text/csv;charset=${encoding}` }),
      filename
    )
  }
  return (
    <div className='col-12' style={{ top: '-5px' }}>
      <div className='btn-group btn-group-toggle' data-toggle='buttons'>
        <label
          className={
            'btn btnToolbar ' +
            (visualization === 'cards' && ' active_visualization ')
          }
          data-tip='Visualização em Cards'
        >
          <input
            type='radio'
            name='options'
            autoComplete='off'
            onClick={() => onChangeVisualization('cards')}
          />
          <AiOutlineBorderlessTable size={15} />
        </label>
        <label
          className={
            'btn btnToolbar ' +
            (visualization === 'table' && ' active_visualization ')
          }
          data-tip='Visualização em Tabela'
        >
          <input
            type='radio'
            name='options'
            autoComplete='off'
            onClick={() => onChangeVisualization('table')}
          />
          <BsTable size={15} />
        </label>
      </div>
      {visualization !== 1 && <div className='divisor-vertical' />}
      {visualization !== 1 && (
        <div className='btn-group btn-group-toggle' data-toggle='buttons'>
          <label
            className={
              'btn btnToolbar ' +
              (visualization === 2 && ' active_visualization ')
            }
            data-tip='Mostrar Partes?'
          >
            <input
              type='radio'
              name='options'
              id='option3'
              autoComplete='off'
              onClick={() => {
                onChangeShowPartes(!showPartes)
              }}
            />
            <FiBookmark size={15} />
          </label>
          <label
            className={
              'btn btnToolbar ' +
              (visualization === 2 && ' active_visualization ')
            }
            data-tip='Mostrar Scores'
          >
            <input
              type='radio'
              name='options'
              id='option3'
              autoComplete='off'
              onClick={() => {
                onChangeShowScores(!showScores)
              }}
            />
            <RiDonutChartFill size={15} />
          </label>
          {showCsvButton && (
            <label
              onClick={handleExportCsv}
              className='btn btnToolbar'
              data-tip='Exportar CSV'
            >
              <FaFileExport size={15} />
            </label>
          )}
        </div>
      )}

      <div className='divisor-vertical' />
    </div>
  )
}

export default JuridicoToolbar

import axios from 'axios'

import { JURIDICO_DETAILS_BASE_URL } from '../../config/env'
import { getScope, getUserId } from '../../utils'

export const consultarVinculados = async ({ nups }) => {
  const url = `${JURIDICO_DETAILS_BASE_URL}/dbjus/v8/details`

  const userId = getUserId()
  const scope = getScope()

  const { data } = await axios.get(url, {
    headers: { 'x-username-id': `${userId}`, 'x-scope-id': scope },
    params: {
      nups: nups.replace(/[^(0-9|,)]/g, ''),
      mode: 'lawsuits'
    }
  })

  return data.result
}

export const consultarDetalhes = async ({ nup }) => {
  const url = `${JURIDICO_DETAILS_BASE_URL}/dbjus/v8/details`

  const userId = getUserId()
  const scope = getScope()

  const { data } = await axios.get(url, {
    headers: { 'x-username-id': `${userId}`, 'x-scope-id': scope },
    params: {
      nups: nup.replace(/\D/g, ''),
      mode: 'details'
    }
  })

  return data.result
}

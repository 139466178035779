import {
  resolveProgressStatus,
  isError,
  isLoaded
} from '../../lib/progressStatus'

// Action Types
export const Types = {
  RESET: 'ENDIVIDAMENTO_THEME/RESET',
  SET_S3_PATH: 'ENDIVIDAMENTO_THEME/SET_S3_PATH',
  SET_ERROR: 'ENDIVIDAMENTO_THEME/SET_ERROR'
}

// Reducer
const initialState = {
  cheque: {
    s3Path: undefined,
    error: false,
    loaded: false
  },
  cndt: {
    s3Path: undefined,
    error: false,
    loaded: false
  },
  pgfn: {
    s3Path: undefined,
    error: false,
    loaded: false
  },
  protesto: {
    s3Path: undefined,
    error: false,
    loaded: false
  }
}

export default function reducer (state = initialState, action) {
  switch (action.type) {
    case Types.SET_S3_PATH: {
      const chequeStatus = resolveProgressStatus(action.payload.cheque)
      const cndtStatus = resolveProgressStatus(action.payload.cndt)
      const pgfnStatus = resolveProgressStatus(action.payload.pgfn)
      const protestoStatus = resolveProgressStatus(action.payload.protesto)

      return {
        ...state,
        cheque: {
          ...state.cheque,
          s3Path: action.payload.cheque,
          error: isError(chequeStatus),
          loaded: isLoaded(chequeStatus)
        },
        cndt: {
          ...state.cndt,
          s3Path: action.payload.cndt,
          error: isError(cndtStatus),
          loaded: isLoaded(cndtStatus)
        },
        pgfn: {
          ...state.pgfn,
          s3Path: action.payload.pgfn,
          error: isError(pgfnStatus),
          loaded: isLoaded(pgfnStatus)
        },
        protesto: {
          ...state.protesto,
          s3Path: action.payload.protesto,
          error: isError(protestoStatus),
          loaded: isLoaded(protestoStatus)
        }
      }
    }
    case Types.SET_ERROR:
      return {
        ...state,
        cheque: { ...state.cheque, error: action.payload, loaded: true },
        cndt: { ...state.cndt, error: action.payload, loaded: true },
        pgfn: { ...state.pgfn, error: action.payload, loaded: true },
        protesto: { ...state.protesto, error: action.payload, loaded: true }
      }
    case Types.RESET:
      return initialState
    default:
      return state
  }
}

// Action Creators
export function setErrorEndividamento (data) {
  return {
    type: Types.SET_ERROR,
    payload: data
  }
}

export function setS3PathEndividamento ({ cheque, cndt, pgfn, protesto }) {
  return {
    type: Types.SET_S3_PATH,
    payload: { cheque, cndt, pgfn, protesto }
  }
}

export function resetEndividamentoTheme () {
  return {
    type: Types.RESET
  }
}

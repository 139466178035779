import axios from 'axios'

import { AUTHENTICATION_BASE_URL } from '../../config/env'

export const logar = ({ email, password }) => {
  return axios.post(`${AUTHENTICATION_BASE_URL}/api/users/login`, {
    email,
    password
  })
}

export const consultarPermissaoJive = ({ uuid }) => {
  return axios.post(`${AUTHENTICATION_BASE_URL}/api/jive/login`, { id: uuid })
}

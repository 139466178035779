import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FiHelpCircle } from 'react-icons/fi'
import { useDispatch, useSelector } from 'react-redux'

import searchDocument from '../../lib/searchDocument'
import { selectMinData } from '../../redux/selectors/dadosBasicos'
import {
  formatDocument,
  formatPartialCnpj,
  formatPartialCpf
} from '../../utils'
import { SEARCH_OPTIONS } from '../../config/consts'

const DEFAULT_INDREMA_CASE = 'SPEC0000001'

const Search = ({ onSearch }) => {
  const { targetCase, targetDocument } = useSelector(selectMinData)
  const user = useSelector(state => state.autenticacao.user)
  const [documento, setDocumento] = useState('')
  const [loadedCaso, setLoadedCaso] = useState(false)
  const [loadedDocumento, setLoadedDocumento] = useState(false)
  const [options, setOptions] = useState([SEARCH_OPTIONS[0]])
  const [caso, setCaso] = useState(
    user?.scope === 'discovery' ? DEFAULT_INDREMA_CASE : ''
  )
  const dispatch = useDispatch()

  useEffect(() => {
    if (caso.length > 0 || loadedCaso || targetCase.length === 0) {
      return
    }

    setLoadedCaso(true)
    setCaso(targetCase.join('; '))
  }, [caso, targetCase, loadedCaso])

  useEffect(() => {
    if (
      documento.length > 0 ||
      loadedDocumento ||
      (targetDocument || '').length === 0
    ) {
      return
    }

    setLoadedDocumento(true)
    setDocumento(formatDocument(targetDocument))
  }, [documento, targetDocument, loadedDocumento])

  const handleSearch = () => {
    const documentoSearch = documento
    const casoSearch = caso.split(';')

    const searching = searchDocument({
      documento: documentoSearch,
      casos: casoSearch,
      user,
      options,
      dispatch
    })

    if (searching && onSearch) {
      onSearch()
    }
  }

  const handleChangeDocument = event => {
    const input = event.target.value
    const cleanedInput = input.replace(/\D/g, '')

    const formatter =
      cleanedInput.length <= 11 ? formatPartialCpf : formatPartialCnpj

    setDocumento(formatter(cleanedInput))
  }

  return (
    <div className='container-fluid mx-0 px-0'>
      <div className='row'>
        <div className='text-primary h6 col-12'>
          Para iniciar uma busca, por favor preencha os campos abaixo
        </div>
      </div>
      <hr className='mt-0' />
      <div className='mt-4 row'>
        <div className='form-group col-4 d-flex flex-column'>
          <label
            htmlFor='cases'
            className='col-form-label col-form-label-sm flex-grow-1'
          >
            <span className='mr-1' style={{ fontSize: 12 }}>
              Digite a(s) oportunidade(s) ou o(s) caso(s) que deseja pesquisar
            </span>
            <OverlayTrigger
              placement='right'
              delay={{ show: 250, hide: 400 }}
              overlay={renderHelpCase}
            >
              <FiHelpCircle />
            </OverlayTrigger>
          </label>
          <input
            type='text'
            id='cases'
            className='form-control'
            placeholder='SPEC0000001; CAS0000002'
            style={{ height: 38 }}
            onChange={event => setCaso(event.target.value)}
            value={caso}
          />
        </div>
        <div className='form-group col-3 d-flex flex-column'>
          <label
            htmlFor='documento'
            className='col-form-label col-form-label-sm flex-grow-1'
            style={{ fontSize: 12 }}
          >
            Digite o CPF ou CNPJ que deseja pesquisar
          </label>
          <input
            type='text'
            id='documento'
            className='form-control'
            style={{ height: 38 }}
            onChange={handleChangeDocument}
            value={documento}
            onKeyDown={e => {
              if (e.code === 'Enter') {
                handleSearch()
              }
            }}
          />
        </div>
        <div className='form-group col-4 d-flex flex-column'>
          <label
            htmlFor='documento'
            className='col-form-label col-form-label-sm flex-grow-1'
            style={{ fontSize: 12 }}
          >
            Selecionar os tipos de dados a serem pesquisados
          </label>
          <Select
            size={100}
            styles={{
              option: (styles, { isDisabled, isFocused, isSelected }) => {
                return {
                  ...styles,
                  backgroundColor: isDisabled
                    ? undefined
                    : isSelected
                    ? '#2c9e69'
                    : isFocused
                    ? '#00b07d'
                    : undefined
                }
              },
              multiValueLabel: styles => ({
                ...styles,
                fontSize: '11px',
                backgroundColor: '#47ddb3',
                color: '#343a40'
              })
            }}
            options={SEARCH_OPTIONS}
            isMulti
            hideSelectedOptions={false}
            closeMenuOnSelect={false}
            value={options}
            onChange={(changedItems, { option }) => {
              if (changedItems === null) {
                setOptions([])
                return
              }
              const includeAll = changedItems.some(item => item.value === 'all')

              const newOptions =
                includeAll && option.value !== 'all'
                  ? changedItems.filter(item => item.value !== 'all')
                  : includeAll
                  ? [SEARCH_OPTIONS[0]]
                  : changedItems
              setOptions(newOptions)
            }}
          />
        </div>
        <div className='form-group col-1 d-flex align-items-end justify-content-end'>
          <button
            type='button'
            className='btn btn-primary text-uppercase'
            onClick={handleSearch}
          >
            Pesquisar
          </button>
        </div>
      </div>
    </div>
  )
}

const renderHelpCase = props => (
  <Tooltip {...props}>
    Para pesquisar mais de um caso ou oportunidades, separe-os com ;
  </Tooltip>
)

export default Search

import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import { toast } from 'react-toastify'

import { Content, JiveWindow, Table, Loading } from './../components'

import { selectMinData } from '../redux/selectors/dadosBasicos'
import { selectRelateds } from '../redux/selectors/relateds'
import { selectSearchOptions } from '../redux/selectors/searchOptions'

import {
  CNPJ_FORMATTER,
  CPF_FORMATTER,
  formatDocument,
  hashObject,
  onlyNumbers,
  useJuridicoNotification,
  useTokenValidation
} from '../utils'
import { matchFuncionarios } from '../lib/matchDeFuncionarios'
import { mapWithRelationship } from '../lib/detectMatch'
import {
  fetchDevedoresJiveFromS3,
  fetchOperacionaisFromS3
} from '../lib/s3Theme'
import {
  JURIDICO_NOTIFICATION_INTERVAL,
  TOKEN_VALIDATION_INTERVAL
} from '../config/consts'
import { onSortDocument } from '../components/Table/sort'

const { SearchBar } = Search

export default function Operacionais () {
  useTokenValidation(TOKEN_VALIDATION_INTERVAL)
  useJuridicoNotification(JURIDICO_NOTIFICATION_INTERVAL)

  const operacionaisTheme = useSelector(state => state.operacionaisTheme)
  const devedoresJive = useSelector(state => state.jiveTheme.devedores)
  const { pages: enabledPages } = useSelector(selectSearchOptions)

  const [loadedOperacionais, setLoadedOperacionais] = useState(false)
  const [loadedDevedores, setLoadedDevedores] = useState(false)
  const [dataOperacionais, setDataOperacionais] = useState({
    funcionarios: { data: [], loaded: false, error: false },
    exFuncionarios: { data: [], loaded: false, error: false },
    matchFuncionarios: { data: [], loaded: false, error: false }
  })
  const [dataDevedores, setDataDevedores] = useState({
    devedores: { data: [], loaded: false, error: false }
  })

  const { targetName, targetDocument } = useSelector(selectMinData)

  useEffect(() => {
    if (!operacionaisTheme.s3Path) {
      return
    }

    fetchOperacionaisFromS3({ payload: operacionaisTheme })
      .then(operacionais =>
        setDataOperacionais(prev => ({ ...prev, ...operacionais }))
      )
      .catch(error => {
        toast.error(error.message)
        console.error(error)
        setDataOperacionais(prev => ({
          ...prev,
          funcionarios: { data: [], loaded: false, error: true },
          exFuncionarios: { data: [], loaded: false, error: true },
          matchFuncionarios: { data: [], loaded: false, error: true }
        }))
      })
      .finally(() => {
        setLoadedOperacionais(true)
      })
  }, [operacionaisTheme])

  useEffect(() => {
    if (!devedoresJive.s3Path) {
      return
    }

    fetchDevedoresJiveFromS3({ payload: devedoresJive })
      .then(devedores => setDataDevedores(prev => ({ ...prev, ...devedores })))
      .catch(error => {
        toast.error(error.message)
        console.error(error)
        setDataDevedores(prev => ({
          ...prev,
          devedores: { data: [], loaded: false, error: true }
        }))
      })
      .finally(() => {
        setLoadedDevedores(true)
      })
  }, [devedoresJive])

  return (
    <Content
      title={`Operacionais ${targetName ? `- ${targetName}` : ''}`}
      subtitle='Cruzamento dos dados da RF, Neoway e Jive'
    >
      {!enabledPages.operacionais ? (
        <div className='d-flex w-100 h-100 flex-1 justify-content-center align-items-center'>
          <p className='h3'>Esta aba está desabilitada!</p>
        </div>
      ) : targetDocument && !loadedOperacionais && !loadedDevedores ? (
        <Loading />
      ) : (
        <>
          <TabelaMatchFuncionarios
            funcionarios={dataOperacionais.funcionarios}
            exFuncionarios={dataOperacionais.exFuncionarios}
            match={dataOperacionais.matchFuncionarios}
            loaded={loadedOperacionais}
          />
          <TabelaDevedoresJive
            devedores={dataDevedores.devedores.data}
            loaded={loadedDevedores}
          />
        </>
      )}
    </Content>
  )
}

const TabelaDevedoresJive = React.memo(({ devedores, loaded }) => {
  const { targetCase } = useSelector(selectMinData)
  const relateds = useSelector(selectRelateds)
  const name = 'operacionais.devedoresJive'

  if (!loaded) {
    return (
      <JiveWindow title='Devedores Jive' name={name} showNote>
        <Loading />
      </JiveWindow>
    )
  }

  const devedoresData = devedores || []

  if (devedoresData.length === 0) {
    return (
      <JiveWindow title='Devedores Jive' name={name} showNote>
        <div />
      </JiveWindow>
    )
  }

  return (
    <JiveWindow title='Devedores Jive' name={name} showNote>
      <ToolkitProvider
        keyField='id'
        search
        columns={[
          {
            dataField: 'documento',
            text: 'Documento',
            sortFunc: onSortDocument,
            sort: true,
            searchable: false
          },
          { dataField: 'rawDocumento', hidden: true },
          { dataField: 'uNomePessoaJive', text: 'Nome', sort: true },
          {
            dataField: 'grau',
            text: 'Grau',
            sort: true
          },
          {
            dataField: 'uDevedorPrincipal',
            text: 'É Devedor principal?',
            sort: true
          },
          {
            dataField: 'uDevedorDeRisco',
            text: 'É Devedor de Risco?',
            sort: true
          }
        ].map(item => ({ ...item, headerAlign: 'center', align: 'center' }))}
        data={mapWithRelationship({
          array: devedores || [],
          documentKey: 'cpfCnpjBusca',
          nameKey: 'uNomePessoaJive',
          relateds
        }).map(({ cpfCnpjBusca: doc, ...item }) => {
          const id = hashObject(item)

          return {
            id,
            rawDocumento: formatDocument(doc),
            grau: item.relationship,
            documento: doc ? (
              <a
                href={`?documento=${onlyNumbers(doc)}&casos=${targetCase.join(
                  ';'
                )}`}
                target='_blank'
                rel='noopener noreferrer'
              >
                {doc.length === 11 ? CPF_FORMATTER(doc) : CNPJ_FORMATTER(doc)}
              </a>
            ) : (
              '-'
            ),
            uNomePessoaJive: item.uNomePessoaJive,
            uDevedorPrincipal: item.uDevedorPrincipal ? 'Sim' : 'Não',
            uDevedorDeRisco: item.uDevedorDeRisco ? 'Sim' : 'Não'
          }
        })}
      >
        {props => (
          <>
            <div className='row'>
              <div className='col-12 text-right'>
                <SearchBar {...props.searchProps} placeholder='Pesquisar' />
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <Table enablePagination {...props.baseProps} />
              </div>
            </div>
          </>
        )}
      </ToolkitProvider>
    </JiveWindow>
  )
})

const TabelaMatchFuncionarios = React.memo(
  ({ funcionarios, exFuncionarios, match, loaded }) => {
    const notLoaded = !funcionarios.loaded || !exFuncionarios.loaded
    const error = funcionarios.error || exFuncionarios.error

    const { targetName, isCPF, targetDocument, targetCase } = useSelector(
      selectMinData
    )
    const [tipoMatch, setTipoMatch] = useState('only')
    const name = 'operacionais.matchFuncionarios'

    if (!loaded) {
      return (
        <JiveWindow
          title='Match Funcionários e Ex-funcionários'
          name={name}
          showNote
        >
          <Loading />
        </JiveWindow>
      )
    }

    if (notLoaded) {
      return (
        <JiveWindow title='Match Funcionários e Ex-funcionários' name={name}>
          <div />
        </JiveWindow>
      )
    }

    if (error) {
      return (
        <JiveWindow
          title={`Match Funcionários e Ex-funcionários ${
            targetName ? `- ${targetName}` : ''
          }`}
          subtitle={`${
            targetDocument ? (isCPF ? 'CPF:' : 'CNPJ:') : ''
          } ${formatDocument(targetDocument)}`}
          name={name}
          showNote
        >
          <div>
            Ops! Aconteceu um erro e por isso não foi possível carregar a tabela
            de matches dos funcionários e ex-funcionários jive.
          </div>
        </JiveWindow>
      )
    }

    const funcionariosData = funcionarios?.data || []
    const exFuncionariosData = exFuncionarios?.data || []
    const mathData = match?.data || []

    if (
      funcionariosData.length === 0 &&
      exFuncionariosData.length === 0 &&
      mathData.length === 0
    ) {
      return (
        <JiveWindow
          title={`Match Funcionários e Ex-funcionários ${
            targetName ? `- ${targetName}` : ''
          }`}
          subtitle={`${
            targetDocument ? (isCPF ? 'CPF:' : 'CNPJ:') : ''
          } ${formatDocument(targetDocument)}`}
          name={name}
          showNote
        >
          <div />
        </JiveWindow>
      )
    }

    // Computa os matches dos funcionários e ex-funcionários
    const matches = matchFuncionarios({
      funcionarios,
      exFuncionarios,
      targetDocument,
      match,
      tipoMatch
    })

    return (
      <JiveWindow
        title={`Match Funcionários e Ex-funcionários ${
          targetName ? `- ${targetName}` : ''
        }`}
        subtitle={`${
          targetDocument ? (isCPF ? 'CPF:' : 'CNPJ:') : ''
        } ${formatDocument(targetDocument)}`}
        name={name}
        showNote
      >
        <ToolkitProvider
          keyField='id'
          search
          columns={[
            { dataField: 'nome', text: 'Nome Funcionário', sort: true },
            {
              dataField: 'cpf',
              text: 'CPF',
              searchable: false,
              sort: true,
              sortFunc: onSortDocument
            },
            { dataField: 'funcionario', text: 'É funcionário?', sort: true },
            {
              dataField: 'exfuncionario',
              text: 'É ex-funcionário?',
              sort: true
            },
            { dataField: 'dataAdmissao', text: 'Data admissão', sort: true },
            {
              dataField: 'anoMesDesligamento',
              text: 'Data desligamento',
              sort: true
            },
            {
              dataField: 'documentoRazaoSocial',
              text: 'Razão Social Empresa',
              sort: true
            },
            {
              dataField: 'documentoEmpresa',
              text: 'Empresa (CNPJ)',
              searchable: false,
              sort: true,
              sortFunc: (...args) => {
                const order = args[2]
                const a = args[4]?.rawEmpresa || ''
                const b = args[5]?.rawEmpresa || ''

                const cleanedA = onlyNumbers(a)
                const cleanedB = onlyNumbers(b)

                if (order === 'asc') {
                  return cleanedA.localeCompare(cleanedB)
                } else {
                  return cleanedB.localeCompare(cleanedA)
                }
              }
            },
            { dataField: 'rawCpf', hidden: true },
            { dataField: 'rawEmpresa', hidden: true }
          ].map(item => ({ ...item, align: 'center', headerAlign: 'center' }))}
          data={matches.map(item => {
            const id = hashObject(item)
            return {
              id,
              nome: item.nome,
              rawDocumento: CPF_FORMATTER(item.cpf),
              rawEmpresa: CNPJ_FORMATTER(item.documentoEmpresa),
              cpf: item.cpf ? (
                <a
                  href={`?documento=${onlyNumbers(
                    item.cpf
                  )}&casos=${targetCase.join(';')}`}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {CPF_FORMATTER(item.cpf)}
                </a>
              ) : (
                '-'
              ),
              funcionario: item.funcionario ? 'Sim' : 'Não',
              exfuncionario: item.exfuncionario ? 'Sim' : 'Não',
              dataAdmissao: item.dataAdmissao
                .split('T')[0]
                .split('-')
                .reverse()
                .join('/'),
              anoMesDesligamento: item.anoMesDesligamento
                ?.split('T')[0]
                .split('-')
                .join('/'),
              documentoRazaoSocial: item.documentoRazaoSocial,
              documentoEmpresa: item.documentoEmpresa ? (
                <a
                  href={`?documento=${onlyNumbers(
                    item.documentoEmpresa
                  )}&casos=${targetCase.join(';')}`}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {CNPJ_FORMATTER(item.documentoEmpresa)}
                </a>
              ) : (
                '-'
              )
            }
          })}
        >
          {props => (
            <>
              <div className='row'>
                <div className='col-6'>
                  <div className='btn-group mb-2' role='group'>
                    <button
                      type='button'
                      className={`btn ${
                        tipoMatch === 'only' ? 'btn-primary' : ''
                      }`}
                      onClick={() => setTipoMatch('only')}
                    >
                      Match
                    </button>
                    <button
                      type='button'
                      className={`btn ${
                        tipoMatch === 'all' ? 'btn-primary' : ''
                      }`}
                      onClick={() => setTipoMatch('all')}
                    >
                      Todos
                    </button>
                  </div>
                </div>
                <div className='col-6 text-right'>
                  <SearchBar {...props.searchProps} placeholder='Pesquisar' />
                </div>
              </div>
              <Table enablePagination keyField='id' {...props.baseProps} />
            </>
          )}
        </ToolkitProvider>
      </JiveWindow>
    )
  }
)

import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { selectMinData } from '../../../redux/selectors/dadosBasicos'

import { fetchEndividamentoAnalyticsFromS3 } from '../../../lib/s3Theme'

import { formatCurrency, formatDecimal, isPending } from '../../../utils'

import { JiveWindow, Loading } from '../../../components'
import { Box, ListBox, PercentageListBox, TotalBox } from './Boxes'

const INITIAL_DATA_STATE = {
  cartorios: {
    total: 0,
    states: []
  },
  dadosAbertosFgts: {
    amountOfAjuizado: 0,
    amountOfNotAjuizado: 0,
    totalNotAjuizadoValue: 0,
    totalValue: 0
  },
  dadosAbertosNaoPrevidenciario: {
    amountOfAjuizado: 0,
    amountOfNotAjuizado: 0,
    totalNotAjuizadoValue: 0,
    totalValue: 0
  },
  dadosAbertosPrevidenciario: {
    amountOfAjuizado: 0,
    amountOfNotAjuizado: 0,
    totalNotAjuizadoValue: 0,
    totalValue: 0
  },
  status: {
    cartorios: 'PENDING',
    dadosAbertosFgts: 'PENDING',
    dadosAbertosNaoPrevidenciario: 'PENDING',
    dadosAbertosPrevidenciario: 'PENDING'
  }
}

export default function EndividamentoQuantitativos () {
  const { targetDocument } = useSelector(selectMinData)
  const endividamentoTheme = useSelector(
    state => state.analyticsTheme.endividamento
  )
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({
    data: INITIAL_DATA_STATE,
    loaded: false,
    error: false
  })

  useEffect(() => {
    const pending = isPending(endividamentoTheme)
    if (pending) {
      return
    }

    setLoading(true)

    fetchEndividamentoAnalyticsFromS3({
      payload: endividamentoTheme
    })
      .then(endividamento => {
        setData(endividamento)
      })
      .catch(error => {
        toast.error(error.message)
        console.error(error)
        setData({
          data: {
            ...INITIAL_DATA_STATE,
            status: {
              cartorios: 'FAILED',
              dadosAbertosFgts: 'FAILED',
              dadosAbertosNaoPrevidenciario: 'FAILED',
              dadosAbertosPrevidenciario: 'FAILED'
            }
          },
          loaded: false,
          error: true
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }, [endividamentoTheme])

  const isStepPending = targetDocument && isPending(endividamentoTheme)
  const title = 'Endividamento'
  const name = 'quantitativos.endividamento'

  if (loading || isStepPending) {
    return <Loading />
  }

  return (
    <JiveWindow title={title} name={name} showNote defaultOpen>
      <div className='container-fluid gap-2'>
        <div className='row'>
          <div className='col-6'>
            <div className='row'>
              <DadosAbertosFgtsBox
                error={data.error}
                data={data.data.dadosAbertosFgts}
                status={data.data.status.dadosAbertosFgts}
              />
              <DadosAbertosPrevidenciarioBox
                error={data.error}
                data={data.data.dadosAbertosPrevidenciario}
                status={data.data.status.dadosAbertosPrevidenciario}
              />
              <DadosAbertosNaoPrevidenciarioBox
                error={data.error}
                data={data.data.dadosAbertosNaoPrevidenciario}
                status={data.data.status.dadosAbertosNaoPrevidenciario}
              />
            </div>
          </div>
          <div className='col-6'>
            <div className='row'>
              <CartoriosBox
                error={data.error}
                data={data.data.cartorios}
                status={data.data.status.cartorios}
              />
            </div>
          </div>
        </div>
      </div>
    </JiveWindow>
  )
}

const CartoriosBox = ({ error, data, status }) => {
  const title = 'Cartórios'

  if (error || status === 'FAILED') {
    return (
      <Box title={title}>
        <div className='row'>
          <div>Erro no carregamento dos dados</div>
        </div>
      </Box>
    )
  }

  if (status === 'SKIPPED') {
    return (
      <Box title={title}>
        <div className='row'>
          <div>Dados não carregados para este tema</div>
        </div>
      </Box>
    )
  }

  const { total, states } = data

  const mappedStates = states
    .sort((a, b) => b.percentage - a.percentage)
    .map(item => ({
      name: item.state,
      total: item.total,
      percentage: item.percentage
    }))

  const formattedTotal = formatDecimal(total, null, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  })

  return (
    <Box title={title}>
      <div className='row'>
        <TotalBox value={formattedTotal} label='Total de imóveis' />
      </div>
      <div className='row'>
        <PercentageListBox showTotal items={mappedStates} />
      </div>
    </Box>
  )
}

const DadosAbertosFgtsBox = ({ error, data, status }) => {
  const title = 'Dados abertos FGTS'

  if (error || status === 'FAILED') {
    return (
      <Box title={title}>
        <div className='row'>
          <div>Erro no carregamento dos dados</div>
        </div>
      </Box>
    )
  }

  if (status === 'SKIPPED') {
    return (
      <Box title={title}>
        <div className='row'>
          <div>Dados não carregados para este tema</div>
        </div>
      </Box>
    )
  }

  const { totalValue, amountOfAjuizado } = data

  const formattedTotal = formatDecimal(totalValue, null, {
    fractionDigits: 0
  })
  const formattedAmountOfAjuizados = formatCurrency(
    amountOfAjuizado,
    null,
    null,
    {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2
    }
  )

  const mappedItems = [
    {
      name: 'Valor Total',
      value: formattedTotal
    },
    {
      name: 'Quantidade de ajuizados',
      value: formattedAmountOfAjuizados
    }
  ]

  return (
    <Box title={title}>
      <div className='row'>
        <ListBox items={mappedItems} />
      </div>
    </Box>
  )
}

const DadosAbertosPrevidenciarioBox = ({ error, data, status }) => {
  const title = 'Dados abertos Previdenciário'

  if (error || status === 'FAILED') {
    return (
      <Box title={title}>
        <div className='row'>
          <div>Erro no carregamento dos dados</div>
        </div>
      </Box>
    )
  }

  if (status === 'SKIPPED') {
    return (
      <Box title={title}>
        <div className='row'>
          <div>Dados não carregados para este tema</div>
        </div>
      </Box>
    )
  }

  const { totalValue, amountOfAjuizado } = data

  const formattedTotal = formatDecimal(totalValue, null, {
    fractionDigits: 0
  })
  const formattedAmountOfAjuizados = formatCurrency(
    amountOfAjuizado,
    null,
    null,
    {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2
    }
  )

  const mappedItems = [
    {
      name: 'Valor Total',
      value: formattedTotal
    },
    {
      name: 'Quantidade de ajuizados',
      value: formattedAmountOfAjuizados
    }
  ]

  return (
    <Box title={title}>
      <div className='row'>
        <ListBox items={mappedItems} />
      </div>
    </Box>
  )
}

const DadosAbertosNaoPrevidenciarioBox = ({ error, data, status }) => {
  const title = 'Dados abertos não Previdenciário'

  if (error || status === 'FAILED') {
    return (
      <Box title={title}>
        <div className='row'>
          <div>Erro no carregamento dos dados</div>
        </div>
      </Box>
    )
  }

  if (status === 'SKIPPED') {
    return (
      <Box title={title}>
        <div className='row'>
          <div>Dados não carregados para este tema</div>
        </div>
      </Box>
    )
  }

  const { totalValue, amountOfAjuizado } = data

  const formattedTotal = formatDecimal(totalValue, null, {
    fractionDigits: 0
  })
  const formattedAmountOfAjuizados = formatCurrency(
    amountOfAjuizado,
    null,
    null,
    {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2
    }
  )

  const mappedItems = [
    {
      name: 'Valor Total',
      value: formattedTotal
    },
    {
      name: 'Quantidade de ajuizados',
      value: formattedAmountOfAjuizados
    }
  ]

  return (
    <Box title='Dados abertos não Previdenciário'>
      <div className='row'>
        <ListBox items={mappedItems} />
      </div>
    </Box>
  )
}

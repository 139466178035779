import { useSelector } from 'react-redux'

import { factoryDate, formatDate } from '../../../utils'
import Field from '../../../components/Field'

export default function DadosBasicosCPF ({ dadosCadastrais }) {
  const address = useSelector(state => state.googleMaps)
  return (
    <>
      <div className='row'>
        <Field
          className='col-3 mb-3'
          label='Nome'
          value={dadosCadastrais.data?.dadosBasicos?.nome}
        />
        <Field
          className='col-3 mb-3'
          label='Idade'
          value={dadosCadastrais.data?.dadosBasicos?.idade}
        />
        <Field
          className='col-3 mb-3'
          label='Indício Falecimento'
          value={
            dadosCadastrais.data?.dadosBasicos?.falecimento !== undefined &&
            dadosCadastrais.data?.dadosBasicos?.falecimento !== null
              ? dadosCadastrais.data?.dadosBasicos?.falecimento
                ? 'SIM'
                : 'NÃO'
              : undefined
          }
        />
        <Field
          className='col-3 mb-3'
          label='Gênero'
          value={dadosCadastrais.data?.dadosBasicos?.genero}
        />
        <Field
          className='col-3 mb-3'
          label='Nacionalidade'
          value={dadosCadastrais.data?.dadosBasicos?.nacionalidade}
        />

        <Field
          className='col-3 mb-3'
          label='PIS'
          value={dadosCadastrais.data?.dadosBasicos?.pis}
        />
        <Field
          className='col-3 mb-3'
          label='Quantidade de empresas'
          value={dadosCadastrais.data?.quantidadeEmpresas}
        />

        <Field
          className='col-3 mb-3'
          label='Data de nascimento'
          value={
            dadosCadastrais.data?.dadosBasicos?.dataNascimento
              ? formatDate(
                  factoryDate(
                    dadosCadastrais.data.dadosBasicos.dataNascimento.split(
                      'T'
                    )[0]
                  )
                )
              : ''
          }
        />

        <Field
          className='col-3 mb-3'
          label='Local de votação'
          value={dadosCadastrais.data.dadosBasicos?.localVotacao}
        />
        <Field
          className='col-3 mb-3'
          label='Zona'
          value={dadosCadastrais.data.dadosBasicos?.zona}
        />
        <Field
          className='col-3 mb-3'
          label='Título'
          value={dadosCadastrais.data.dadosBasicos?.titulo}
        />
        <Field
          className='col-3 mb-3'
          label='Seção'
          value={dadosCadastrais.data.dadosBasicos?.secao}
        />

        <Field
          className='col-3 mb-3'
          label='Endereço'
          value={address.address}
        />
        <Field
          className='col-3 mb-3'
          label='Complemento'
          value={address.rawAddress?.complemento}
        />
      </div>
      <hr />
      <div className='row'>
        <Field
          className='col-3'
          label='Nome da mãe'
          value={dadosCadastrais.data.dadosBasicos?.nomeMae}
        />
        <Field
          className='col-3'
          label='Nome do pai'
          value={dadosCadastrais.data.dadosBasicos?.nomePai}
        />
      </div>
    </>
  )
}

import React from 'react'

import * as styles from './style.module.scss'

const Item = ({ children, onClick, style, className }) => {
  const child = React.Children.toArray(children)

  return (
    <div
      className={`${styles.item} ${className || ''}`}
      onClick={onClick}
      style={style || {}}
    >
      {child}
    </div>
  )
}

export default Item

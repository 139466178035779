import { put, select } from 'redux-saga/effects'
import { toast } from 'react-toastify'

import { Types as JiveThemeTypes } from '../store/jiveTheme'
import { Types as PatrimonioThemeTypes } from '../store/patrimonioTheme'
import { Types as DadosBasicosThemeTypes } from '../store/dadosBasicosTheme'
import { Types as OperacionaisThemeTypes } from '../store/operacionaisTheme'
import { Types as OffshoreThemeTypes } from '../store/offshoreTheme'
import { Types as EndividamentoThemeTypes } from '../store/endividamentoTheme'
import { Types as JuridicoThemeTypes } from '../store/juridicoTheme'
import { Types as MatchGrupoSocietarioThemeTypes } from '../store/matchGrupoSocietarioTheme'
import { Types as DiscreditingMediaThemeTypes } from '../store/discreditingMediaTheme'
import { Types as AnalyticsThemeTypes } from '../store/analyticsTheme'
import { Types as BandeiraAmarelaThemeTypes } from '../store/bandeiraAmarelaTheme'
import { Types as GlobalStepTypes } from '../store/globalStep'

import { Types as GrafoTypes } from '../reducers/grafo'
import { Types as GoogleMapsTypes } from '../reducers/googleMaps'

import { setStatusProgressStatus } from '../store/progressStatus'

import { PROGRESS_TYPES } from '../../lib/progressStatus'

const resolveProgressStatus = (s3Path, name) => {
  if (!s3Path) {
    return PROGRESS_TYPES.UNLOADED
  }

  const { succeeded, total } = s3Path
  if (typeof succeeded !== 'number' || typeof total !== 'number') {
    throw new Error(`${name}:Tipo inválido`)
  }

  if (succeeded === total) {
    return PROGRESS_TYPES.LOADED
  }

  if (succeeded === 0) {
    return PROGRESS_TYPES.ERROR
  }

  return PROGRESS_TYPES.PARTIAL_ERROR
}

const resolveAnalyticsStatus = (state, selectedThemes) => {
  const themes = Object.keys(state)
  const total = selectedThemes.length
  const status = themes.reduce(
    (obj, themeKey) => {
      const theme = state[themeKey]
      if (theme.loaded) {
        obj.succeeded += 1
      } else if (theme.error) {
        obj.error += 1
      }

      return obj
    },
    {
      total,
      succeeded: 0,
      error: 0
    }
  )

  return status
}

const resolveProgressStatusAnalytics = (s3Path, name) => {
  if (!s3Path) {
    return PROGRESS_TYPES.UNLOADED
  }

  const { succeeded, total } = s3Path
  if (typeof succeeded !== 'number' || typeof total !== 'number') {
    throw new Error(`${name}:Tipo inválido`)
  }

  if (succeeded >= total) {
    return PROGRESS_TYPES.LOADED
  }

  if (succeeded === 0) {
    return PROGRESS_TYPES.ERROR
  }

  return PROGRESS_TYPES.PARTIAL_ERROR
}

export function * listenerProgressStatus ({ type, payload }) {
  try {
    const allAnalyticsTypes = Object.values(AnalyticsThemeTypes).filter(
      event => event !== AnalyticsThemeTypes.RESET
    )
    if (allAnalyticsTypes.includes(type)) {
      const state = yield select(state => state.analyticsTheme)
      const themes = yield select(state => state.globalStep.themes)
      const status = resolveAnalyticsStatus(state, themes)

      if (status.succeeded + status.error < status.total) {
        return
      }
      const analyticsTheme = resolveProgressStatusAnalytics(status)
      yield put(setStatusProgressStatus({ analyticsTheme }))

      return
    }

    switch (type) {
      case GlobalStepTypes.SET_ERROR:
      case GlobalStepTypes.FINISHED: {
        const dataStatus = yield select(
          state => state.progressStatus.dataStatus
        )
        const array = Object.entries(dataStatus)
          .filter(
            ([key, value]) =>
              [PROGRESS_TYPES.PENDING, null].includes(value) &&
              key !== 'globalStep'
          )
          .map(([key]) => [key, PROGRESS_TYPES.ERROR])

        const newDataStatus = {
          ...Object.fromEntries(array),
          globalStep:
            type === GlobalStepTypes.FINISHED
              ? PROGRESS_TYPES.LOADED
              : PROGRESS_TYPES.ERROR
        }
        yield put(setStatusProgressStatus(newDataStatus))
        break
      }
      case DiscreditingMediaThemeTypes.SET_S3_PATH: {
        const discreditingNewsTheme = resolveProgressStatus(
          payload.discreditingNews
        )
        yield put(
          setStatusProgressStatus({
            discreditingNewsTheme
          })
        )
        break
      }
      case DiscreditingMediaThemeTypes.SET_ERROR: {
        yield put(
          setStatusProgressStatus({
            discreditingNews: PROGRESS_TYPES.ERROR
          })
        )
        break
      }
      case JiveThemeTypes.SET_S3_PATH: {
        const certidoesJiveTheme = resolveProgressStatus(payload.certidoes)
        const devedoresJiveTheme = resolveProgressStatus(payload.devedores)
        const jiveApiJiveTheme = resolveProgressStatus(payload.jiveApi)

        yield put(
          setStatusProgressStatus({
            certidoesJiveTheme,
            devedoresJiveTheme,
            jiveApiJiveTheme
          })
        )
        break
      }
      case JiveThemeTypes.SET_S3_PATH_BOTS: {
        const jiveBotsJiveTheme = resolveProgressStatus(payload.jivebots)

        yield put(
          setStatusProgressStatus({
            jiveBotsJiveTheme
          })
        )
        break
      }
      case JiveThemeTypes.SET_ERROR:
        yield put(
          setStatusProgressStatus({
            certidoesJiveTheme: PROGRESS_TYPES.ERROR,
            devedoresJiveTheme: PROGRESS_TYPES.ERROR,
            jiveApiJiveTheme: PROGRESS_TYPES.ERROR
          })
        )
        break
      case JiveThemeTypes.SET_ERROR_BOTS:
        yield put(
          setStatusProgressStatus({
            jiveBotsJiveTheme: PROGRESS_TYPES.ERROR
          })
        )
        break
      case PatrimonioThemeTypes.SET_S3_PATH: {
        const aeronavesPatrimonioTheme = resolveProgressStatus(
          payload.aeronaves
        )
        const sncrPatrimonioTheme = resolveProgressStatus(payload.sncr)
        const inpiPatrimonioTheme = resolveProgressStatus(payload.inpi)

        yield put(
          setStatusProgressStatus({
            aeronavesPatrimonioTheme,
            sncrPatrimonioTheme,
            inpiPatrimonioTheme
          })
        )
        break
      }
      case PatrimonioThemeTypes.SET_ERROR:
        yield put(
          setStatusProgressStatus({
            aeronavesPatrimonioTheme: PROGRESS_TYPES.ERROR,
            sncrPatrimonioTheme: PROGRESS_TYPES.ERROR,
            inpiPatrimonioTheme: PROGRESS_TYPES.ERROR
          })
        )
        break
      case BandeiraAmarelaThemeTypes.SET_S3_PATH: {
        const pepBandeiraAmarela = resolveProgressStatus(payload.pep)
        const acordosLenienciaBandeiraAmarela = resolveProgressStatus(
          payload.acordosLeniencia
        )
        const autoInfracaoBandeiraAmarela = resolveProgressStatus(
          payload.autoInfracao
        )
        const cepimBandeiraAmarela = resolveProgressStatus(payload.cepim)
        const ofacBandeiraAmarela = resolveProgressStatus(payload.ofac)
        const termoApreensaoBandeiraAmarela = resolveProgressStatus(
          payload.termoApreensao
        )
        const termoEmbargoBandeiraAmarela = resolveProgressStatus(
          payload.termoEmbargo
        )
        const termoSuspensaoBandeiraAmarela = resolveProgressStatus(
          payload.termoSuspensao
        )
        const cadastroExpulsoesBandeiraAmarela = resolveProgressStatus(
          payload.cadastroExpulsoes
        )
        const cnepBandeiraAmarela = resolveProgressStatus(payload.cnep)
        const ceisBandeiraAmarela = resolveProgressStatus(payload.ceis)
        const ceafBandeiraAmarela = resolveProgressStatus(payload.ceaf)

        yield put(
          setStatusProgressStatus({
            pepBandeiraAmarela,
            acordosLenienciaBandeiraAmarela,
            autoInfracaoBandeiraAmarela,
            cepimBandeiraAmarela,
            ofacBandeiraAmarela,
            termoApreensaoBandeiraAmarela,
            termoEmbargoBandeiraAmarela,
            termoSuspensaoBandeiraAmarela,
            cadastroExpulsoesBandeiraAmarela,
            cnepBandeiraAmarela,
            ceisBandeiraAmarela,
            ceafBandeiraAmarela
          })
        )
        break
      }
      case BandeiraAmarelaThemeTypes.SET_ERROR:
        yield put(
          setStatusProgressStatus({
            pepBandeiraAmarela: PROGRESS_TYPES.ERROR,
            acordosLenienciaBandeiraAmarela: PROGRESS_TYPES.ERROR,
            autoInfracaoBandeiraAmarela: PROGRESS_TYPES.ERROR,
            cepimBandeiraAmarela: PROGRESS_TYPES.ERROR,
            ofacBandeiraAmarela: PROGRESS_TYPES.ERROR,
            termoApreensaoBandeiraAmarela: PROGRESS_TYPES.ERROR,
            termoEmbargoBandeiraAmarela: PROGRESS_TYPES.ERROR,
            termoSuspensaoBandeiraAmarela: PROGRESS_TYPES.ERROR,
            cadastroExpulsoesBandeiraAmarela: PROGRESS_TYPES.ERROR,
            cnepBandeiraAmarela: PROGRESS_TYPES.ERROR,
            ceisBandeiraAmarela: PROGRESS_TYPES.ERROR,
            ceafBandeiraAmarela: PROGRESS_TYPES.ERROR
          })
        )
        break
      case MatchGrupoSocietarioThemeTypes.SET_S3_PATH: {
        const matchGrupoSocietarioTheme = resolveProgressStatus(payload)

        yield put(
          setStatusProgressStatus({
            matchGrupoSocietarioTheme
          })
        )
        break
      }
      case MatchGrupoSocietarioThemeTypes.SET_ERROR:
        yield put(
          setStatusProgressStatus({
            matchGrupoSocietarioTheme: PROGRESS_TYPES.ERROR
          })
        )
        break
      case DadosBasicosThemeTypes.SET_S3_PATH: {
        const dadosBasicosTheme = resolveProgressStatus(payload)
        yield put(
          setStatusProgressStatus({
            dadosBasicosTheme
          })
        )
        break
      }
      case DadosBasicosThemeTypes.SET_ERROR:
        yield put(
          setStatusProgressStatus({
            dadosBasicosTheme: PROGRESS_TYPES.ERROR
          })
        )
        break
      case OperacionaisThemeTypes.SET_S3_PATH: {
        const operacionaisTheme = resolveProgressStatus(payload)
        yield put(setStatusProgressStatus({ operacionaisTheme }))
        break
      }
      case OperacionaisThemeTypes.SET_ERROR:
        yield put(
          setStatusProgressStatus({ operacionaisTheme: PROGRESS_TYPES.ERROR })
        )
        break
      case OffshoreThemeTypes.SET_S3_PATH: {
        const dataLeaksOffshore = resolveProgressStatus(payload)
        yield put(setStatusProgressStatus({ dataLeaksOffshore }))
        break
      }
      case OffshoreThemeTypes.SET_ERROR:
        yield put(
          setStatusProgressStatus({ dataLeaksOffshore: PROGRESS_TYPES.ERROR })
        )
        break
      case EndividamentoThemeTypes.SET_S3_PATH: {
        const cndtEndividamentoTheme = resolveProgressStatus(payload.cndt)
        const pgfnEndividamentoTheme = resolveProgressStatus(payload.pgfn)
        const protestoEndividamentoTheme = resolveProgressStatus(
          payload.protesto
        )

        yield put(
          setStatusProgressStatus({
            cndtEndividamentoTheme,
            pgfnEndividamentoTheme,
            protestoEndividamentoTheme
          })
        )
        break
      }
      case EndividamentoThemeTypes.SET_ERROR:
        yield put(
          setStatusProgressStatus({
            cndtEndividamentoTheme: PROGRESS_TYPES.ERROR,
            pgfnEndividamentoTheme: PROGRESS_TYPES.ERROR,
            protestoEndividamentoTheme: PROGRESS_TYPES.ERROR
          })
        )
        break
      case JuridicoThemeTypes.FINISHED: {
        const relateds = yield select(state => state.juridicoTheme.relateds)
        const relatedsWithError = relateds.filter(
          related => related.status === PROGRESS_TYPES.ERROR
        )
        const juridicoTheme =
          relatedsWithError.length === 0
            ? PROGRESS_TYPES.LOADED
            : relateds.length === relatedsWithError
            ? PROGRESS_TYPES.ERROR
            : PROGRESS_TYPES.PARTIAL_ERROR

        yield put(
          setStatusProgressStatus({
            juridicoTheme
          })
        )
        break
      }

      case GrafoTypes.SET_LINK:
        yield put(setStatusProgressStatus({ linkGrafo: PROGRESS_TYPES.LOADED }))
        break
      case GrafoTypes.SET_ERROR_LINK:
        yield put(setStatusProgressStatus({ linkGrafo: PROGRESS_TYPES.ERROR }))
        break
      case GoogleMapsTypes.SET_IMAGES:
        yield put(
          setStatusProgressStatus({ googleMaps: PROGRESS_TYPES.LOADED })
        )
        break
      case GoogleMapsTypes.SET_ERROR_IMAGES:
        yield put(setStatusProgressStatus({ googleMaps: PROGRESS_TYPES.ERROR }))
        break
      default:
        console.warn(`listenerProgressStatus: Tipo não esperado ${type}`)
    }
  } catch (err) {
    console.error(`${type}:${err.message}`, err)
    toast.error(`${type}:${err.message}`)
  }
}

import React, { useState, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import { toast } from 'react-toastify'
import { Form, Modal } from 'react-bootstrap'

import { selectMinData } from '../redux/selectors/dadosBasicos'
import { selectRelateds } from '../redux/selectors/relateds'
import { selectSearchOptions } from '../redux/selectors/searchOptions'

import { Content, DynamicGoogleMaps, JiveWindow } from './../components'
import Table from './../components/Table'

import './../assets/sass/patrimonio.scss'
import {
  CEP_FORMATTER,
  factoryDate,
  formatCurrency,
  formatDate,
  formatDocument,
  hashObject,
  isPending,
  isValid,
  onlyNumbers,
  RESOLVE_NONE,
  useJuridicoNotification,
  useTokenValidation
} from '../utils'
import { fetchPatrimonioFromS3 } from '../lib/s3Theme'
import { mapWithRelationship } from '../lib/detectMatch'
import {
  JURIDICO_NOTIFICATION_INTERVAL,
  TOKEN_VALIDATION_INTERVAL
} from '../config/consts'
import { onSortDate, onSortDocument } from '../components/Table/sort'

const { SearchBar } = Search

export default function Patrimonio () {
  useTokenValidation(TOKEN_VALIDATION_INTERVAL)
  useJuridicoNotification(JURIDICO_NOTIFICATION_INTERVAL)

  const patrimonioTheme = useSelector(state => state.patrimonioTheme)
  const jiveTheme = useSelector(state => state.jiveTheme)
  const operacionaisTheme = useSelector(state => state.operacionaisTheme)
  const { targetName, targetDocument } = useSelector(selectMinData)
  const { pages: enabledPages } = useSelector(selectSearchOptions)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({
    ambiental: { data: [], loaded: false, error: false },
    imoveisRurais: { data: [], loaded: false, error: false },
    imoveis: { data: [], loaded: false, error: false },
    aeronaves: { data: [], loaded: false, error: false },
    sncr: { data: [], loaded: false, error: false },
    imoveisNeoway: { data: [], loaded: false, error: false },
    inpi: {
      data: {
        contratoTecnologia: [],
        desenhoIndustrial: [],
        patentes: [],
        programaComputador: [],
        registroMarcas: []
      },
      loaded: false,
      error: false
    },
    jivebots: {
      imoveisRurais: { data: [], loaded: false, error: false },
      imoveisUrbanos: { data: [], loaded: false, error: false }
    }
  })

  useEffect(() => {
    if (
      isPending(patrimonioTheme.sncr) &&
      isPending(patrimonioTheme.aeronaves) &&
      isPending(patrimonioTheme.inpi) &&
      isPending(jiveTheme.jiveApi) &&
      isPending(jiveTheme.jivebots) &&
      isPending(operacionaisTheme)
    ) {
      return
    }

    setLoading(true)

    fetchPatrimonioFromS3({
      aeronavesPayload: patrimonioTheme.aeronaves,
      sncrPayload: patrimonioTheme.sncr,
      inpiPayload: patrimonioTheme.inpi,
      jiveApiPayload: jiveTheme.jiveApi,
      jiveBotsPayload: jiveTheme.jivebots,
      operacionaisPayload: operacionaisTheme
    })
      .then(patrimonio => {
        setData(patrimonio)
      })
      .catch(error => {
        toast.error(error.message)
        console.error(error)
        setData({
          ambiental: { data: [], loaded: false, error: true },
          imoveis: { data: [], loaded: false, error: false },
          imoveisRurais: { data: [], loaded: false, error: true },
          aeronaves: { data: [], loaded: false, error: true },
          sncr: { data: [], loaded: false, error: true },
          imoveisNeoway: { data: [], loaded: false, error: true },
          inpi: {
            data: {
              contratoTecnologia: [],
              desenhoIndustrial: [],
              patentes: [],
              programaComputador: [],
              registroMarcas: []
            },
            loaded: false,
            error: true
          },
          jivebots: {
            imoveisRurais: { data: [], loaded: false, error: true },
            imoveisUrbanos: { data: [], loaded: false, error: true }
          }
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }, [patrimonioTheme, jiveTheme, operacionaisTheme])

  const isStepPending =
    targetDocument &&
    isPending(patrimonioTheme.aeronaves) &&
    isPending(patrimonioTheme.sncr) &&
    isPending(patrimonioTheme.inpi) &&
    isPending(jiveTheme.jiveApi) &&
    isPending(operacionaisTheme) &&
    isPending(jiveTheme.jivebots)

  return (
    <Content
      title={`Patrimônio ${targetName ? `- ${targetName}` : ''}`}
      subtitle='Busca por dados patrimonias na base de dados de Aeronaves, INPI, Imóveis Rurais, Jive Bots e Jive Api.'
    >
      {!enabledPages.patrimonio ? (
        <div className='d-flex w-100 h-100 flex-1 justify-content-center align-items-center'>
          <p className='h3'>Esta aba está desabilitada!</p>
        </div>
      ) : loading || isStepPending ? (
        <div className='d-flex w-100 h-100 flex-1 justify-content-center align-items-center'>
          <div
            className='spinner-border text-primary'
            style={{ width: '3rem', height: '3rem' }}
            role='status'
          >
            <span className='sr-only'>Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <Aeronaves aeronaves={data.aeronaves} />
          <ContratoTecnologia inpi={data.inpi} />
          <DesenhoIndustrial inpi={data.inpi} />
          <ProgramaComputador inpi={data.inpi} />
          <Patentes inpi={data.inpi} />
          <RegistroMarcas inpi={data.inpi} />
          <ImoveisRurais
            sncr={data.sncr}
            imoveisRuraisJive={data.imoveisRurais}
            jivebots={data.jivebots.imoveisRurais}
          />
          <ImoveisUrbanosJive
            jivebots={data.jivebots.imoveisUrbanos}
            jiveApi={data.imoveis}
          />
          <ImoveisAmbientais ambiental={data.ambiental} />
          <ImoveisNeoway imoveisNeoway={data.imoveisNeoway} />
        </>
      )}
    </Content>
  )
}

const Aeronaves = React.memo(({ aeronaves }) => {
  const [showFull, setShowFull] = useState(false)
  const { isCPF, targetDocument, targetName, targetCase } = useSelector(
    selectMinData
  )
  const relateds = useSelector(selectRelateds)

  const name = 'patrimonio.aeronaves'

  if (isPending(aeronaves)) {
    return <div />
  }

  if (aeronaves.error) {
    return (
      <div className='col-12 px-0 mx-0'>
        <JiveWindow
          title={`Aeronaves ${targetName ? `- ${targetName}` : ''}`}
          subtitle={`${
            targetDocument ? (isCPF ? 'CPF:' : 'CNPJ:') : ''
          } ${formatDocument(targetDocument)}`}
          name={name}
          showNote
        >
          <div>
            Ops! Aconteceu um erro e não foi possível carregar as aeronaves
          </div>
        </JiveWindow>
      </div>
    )
  }

  const tableData = aeronaves.data || []

  return (
    <ToolkitProvider
      keyField='id'
      data={mapWithRelationship({
        array: tableData || [],
        documentKey: 'cpfCnpj',
        nameKey: 'proprietario',
        relateds
      }).map((aeronave, key) => {
        const id = hashObject({ aeronave, key })
        return {
          ...aeronave,
          id,
          grau: aeronave.relationship,
          cpfCnpj: aeronave.cpfCnpj ? (
            <a
              href={`?documento=${onlyNumbers(
                aeronave.cpfCnpj
              )}&casos=${targetCase.join(';')}`}
              target='_blank'
              rel='noopener noreferrer'
            >
              {aeronave.cpfCnpj}
            </a>
          ) : (
            '-'
          )
        }
      })}
      columns={[
        { dataField: 'marca', text: 'Marca', sort: true },
        { dataField: 'nmFabricante', text: 'Fabricante', sort: true },
        { dataField: 'dsModelo', text: 'Modelo', sort: true },
        { dataField: 'nmOperador', text: 'Operador', sort: true },
        { dataField: 'nrAnoFabricacao', text: 'Ano', sort: true },
        { dataField: 'nrAssentos', text: 'Assentos', sort: true },
        { dataField: 'nrSerie', text: 'Serie' },
        { dataField: 'nrTripulacaoMin', text: 'Tripulação' },
        { dataField: 'proprietario', text: 'Proprietário' },
        {
          dataField: 'grau',
          text: 'Grau',
          sort: true
        },
        { dataField: 'sgUf', text: 'UF' },
        ...(showFull
          ? [
              { dataField: 'cdCategoria', text: 'cat' },
              { dataField: 'cdInterdicao', text: 'Interdição' },
              { dataField: 'cdMarcaEstrangeira', text: 'Estr.' },
              { dataField: 'cdMarcaNac1', text: 'Nac. 1' },
              { dataField: 'cdMarcaNac2', text: 'Nac. 2' },
              { dataField: 'cdMarcaNac3', text: 'Nac. 3' },
              { dataField: 'cdTipo', text: 'Tipo' },
              { dataField: 'cdTipoIcao', text: 'Tipo icao' },
              { dataField: 'cpfCgc', text: 'CPF/cgc' },
              { dataField: 'cpfCnpj', text: 'CPF/CNPJ' },
              { dataField: 'dataDados', text: 'Data' },
              { dataField: 'dsGravame', text: 'Gravame' },
              { dataField: 'dsMotivoCanc', text: 'Motiv. canc.' },
              { dataField: 'dtCanc', text: 'Canc' },
              { dataField: 'dtValidadeCa', text: 'Validade CA' },
              { dataField: 'dtValidadeIam', text: 'Validade IAM' },
              { dataField: 'nrCertMatricula', text: 'Cert. Matrícula' },
              { dataField: 'nrPassageirosMax', text: 'N. Pass.' },
              { dataField: 'nrPmd', text: 'PMD' },
              { dataField: 'outrosOperadores', text: 'Outros Operadores' },
              {
                dataField: 'outrosProprietarios',
                text: 'Outros Proprietários'
              },
              { dataField: 'ufOperador', text: 'UF Operador' }
            ]
          : [])
      ].map(item => ({ ...item, align: 'center', headerAlign: 'center' }))}
      search
    >
      {props => (
        <JiveWindow
          title={`Aeronaves ${targetName ? `- ${targetName}` : ''}`}
          subtitle={`${
            targetDocument ? (isCPF ? 'CPF:' : 'CNPJ:') : ''
          } ${formatDocument(targetDocument)}`}
          name={name}
          showNote
        >
          {tableData.length === 0 ? (
            <div />
          ) : (
            <>
              <div className='row'>
                <div className='col-12 col-lg-6'>
                  <button
                    className='btn btn-sm btn-outline-primary mb-3'
                    onClick={() => setShowFull(!showFull)}
                  >
                    {showFull ? 'Modo Compacto' : 'Modo Completo'}
                  </button>
                </div>
                <div className='col-12 col-lg-6 text-lg-right'>
                  <SearchBar {...props.searchProps} placeholder='Pesquisar' />
                </div>
              </div>
              <Table {...props.baseProps} enablePagination />
            </>
          )}
        </JiveWindow>
      )}
    </ToolkitProvider>
  )
})

const ContratoTecnologia = React.memo(({ inpi }) => {
  const relateds = useSelector(selectRelateds)

  const name = 'patrimonio.contratoTecnologia'
  const title = 'Contratos de tecnologia'
  const subtitle = 'Busca suscetível a homônimos. Dados retirados do INPI'

  if (isPending(inpi)) {
    return <div />
  }

  if (inpi.error) {
    return (
      <div className='col-12 px-0 mx-0'>
        <JiveWindow title={title} subtitle={subtitle} name={name} showNote>
          <div>
            Ops! Aconteceu um erro e não foi possível carregar os contratos de
            tecnologia.
          </div>
        </JiveWindow>
      </div>
    )
  }

  const tableData = inpi.data.contratoTecnologia || []

  return (
    <ToolkitProvider
      keyField='id'
      data={mapWithRelationship({
        array: tableData || [],
        nameKey: 'nomeBuscado',
        relateds
      }).map((item, key) => {
        const id = hashObject({ item, key })
        const dataPublicacaoRevista = formatDate(
          factoryDate(item.dataPublicacaoRevista)
        )
        return {
          ...item,
          id,
          grau: item.relationship,
          dataPublicacaoRevista
        }
      })}
      columns={[
        { dataField: 'cedente', text: 'Nome', sort: true },
        {
          dataField: 'grau',
          text: 'Grau',
          sort: true
        },
        { dataField: 'titulo', text: 'Título', sort: true },
        { dataField: 'numeroRevista', text: 'Nº revista', sort: true },
        {
          dataField: 'dataPublicacaoRevista',
          text: 'Data de publicação',
          sort: true,
          sortFunc: onSortDate
        },
        { dataField: 'codigo', text: 'Código', sort: true },
        { dataField: 'cessionaria', text: 'Cessionária', sort: true },
        { dataField: 'nmProcesso', text: 'Processo', sort: true }
      ].map(item => ({ ...item, align: 'center', headerAlign: 'center' }))}
      search
    >
      {props => (
        <JiveWindow title={title} subtitle={subtitle} name={name} showNote>
          {tableData.length === 0 ? (
            <div />
          ) : (
            <>
              <div className='row'>
                <div className='col-12 col-lg-12 text-lg-right'>
                  <SearchBar {...props.searchProps} placeholder='Pesquisar' />
                </div>
              </div>
              <Table {...props.baseProps} enablePagination />
            </>
          )}
        </JiveWindow>
      )}
    </ToolkitProvider>
  )
})

const DesenhoIndustrial = React.memo(({ inpi }) => {
  const relateds = useSelector(selectRelateds)

  const name = 'patrimonio.desenhoIndustrial'
  const title = 'Desenhos industriais'
  const subtitle = 'Busca suscetível a homônimos. Dados retirados do INPI'

  if (isPending(inpi)) {
    return <div />
  }

  if (inpi.error) {
    return (
      <div className='col-12 px-0 mx-0'>
        <JiveWindow title={title} subtitle={subtitle} name={name} showNote>
          <div>
            Ops! Aconteceu um erro e não foi possível carregar os desenhos
            industriais.
          </div>
        </JiveWindow>
      </div>
    )
  }

  const tableData = inpi.data.desenhoIndustrial || []

  return (
    <ToolkitProvider
      keyField='id'
      data={mapWithRelationship({
        array: tableData || [],
        nameKey: 'nomeBuscado',
        relateds
      }).map((item, key) => {
        const id = hashObject({ item, key })
        const dataPublicacaoRevista = formatDate(
          factoryDate(item.dataPublicacaoRevista)
        )
        const dataDeposito = item.dataDeposito
          ? formatDate(factoryDate(item.dataDeposito))
          : ''
        const dataConcessao = item.dataConcessao
          ? formatDate(factoryDate(item.dataConcessao))
          : ''
        return {
          ...item,
          id,
          grau: item.relationship,
          dataPublicacaoRevista,
          dataConcessao,
          dataDeposito
        }
      })}
      columns={[
        { dataField: 'titular', text: 'Nome', sort: true },
        {
          dataField: 'grau',
          text: 'Grau',
          sort: true
        },
        { dataField: 'titulo', text: 'Título', sort: true },
        { dataField: 'numeroRevista', text: 'Nº revista', sort: true },
        {
          dataField: 'dataPublicacaoRevista',
          text: 'Data de publicação',
          sort: true,
          sortFunc: onSortDate
        },
        { dataField: 'codigo', text: 'Código', sort: true },
        { dataField: 'nmProcesso', text: 'Processo', sort: true },
        {
          dataField: 'dataDeposito',
          text: 'Data de depósito',
          sort: true,
          sortFunc: onSortDate
        },
        {
          dataField: 'dataConcessao',
          text: 'Data de concessão',
          sort: true,
          sortFunc: onSortDate
        },
        {
          dataField: 'classificacaoNacional',
          text: 'Classificação',
          sort: true
        },
        { dataField: 'inventor', text: 'Inventor', sort: true },
        { dataField: 'procurador', text: 'Procurador', sort: true },
        { dataField: 'descricao', text: 'Descrição', sort: true },
        { dataField: 'comentario', text: 'Comentário', sort: true }
      ].map(item => ({ ...item, align: 'center', headerAlign: 'center' }))}
      search
    >
      {props => (
        <JiveWindow title={title} subtitle={subtitle} name={name} showNote>
          {tableData.length === 0 ? (
            <div />
          ) : (
            <>
              <div className='row'>
                <div className='col-12 col-lg-12 text-lg-right'>
                  <SearchBar {...props.searchProps} placeholder='Pesquisar' />
                </div>
              </div>
              <Table {...props.baseProps} enablePagination />
            </>
          )}
        </JiveWindow>
      )}
    </ToolkitProvider>
  )
})

const ProgramaComputador = React.memo(({ inpi }) => {
  const relateds = useSelector(selectRelateds)

  const name = 'patrimonio.programaComputador'
  const title = 'Programas de computador'
  const subtitle = 'Busca suscetível a homônimos. Dados retirados do INPI'

  if (isPending(inpi)) {
    return <div />
  }

  if (inpi.error) {
    return (
      <div className='col-12 px-0 mx-0'>
        <JiveWindow title={title} subtitle={subtitle} name={name} showNote>
          <div>
            Ops! Aconteceu um erro e não foi possível carregar os programas de
            computador.
          </div>
        </JiveWindow>
      </div>
    )
  }

  const tableData = inpi.data.programaComputador || []

  return (
    <ToolkitProvider
      keyField='id'
      data={mapWithRelationship({
        array: tableData || [],
        nameKey: 'nomeBuscado',
        relateds
      }).map((item, key) => {
        const id = hashObject({ item, key })
        const dataPublicacaoRevista = formatDate(
          factoryDate(item.dataPublicacaoRevista)
        )
        const dataCriacao = item.dataCriacao
          ? formatDate(factoryDate(item.dataCriacao))
          : ''
        return {
          ...item,
          id,
          grau: item.relationship,
          dataPublicacaoRevista,
          dataCriacao
        }
      })}
      columns={[
        { dataField: 'titular', text: 'Nome', sort: true },
        {
          dataField: 'grau',
          text: 'Grau',
          sort: true
        },
        { dataField: 'titulo', text: 'Título', sort: true },
        { dataField: 'tituloPrograma', text: 'Título do programa', sort: true },
        { dataField: 'criador', text: 'Criador', sort: true },
        { dataField: 'numeroRevista', text: 'Nº revista', sort: true },
        {
          dataField: 'dataPublicacaoRevista',
          text: 'Data de publicação',
          sort: true,
          sortFunc: onSortDate
        },
        { dataField: 'codigo', text: 'Código', sort: true },
        { dataField: 'nmProcesso', text: 'Processo', sort: true },
        {
          dataField: 'dataCriacao',
          text: 'Data de criação',
          sort: true,
          sortFunc: onSortDate
        },
        { dataField: 'campoAplicacao', text: 'Campo de aplicação', sort: true },
        { dataField: 'linguagem', text: 'Linguagem', sort: true },
        { dataField: 'tipoPrograma', text: 'Tipo de programa', sort: true }
      ].map(item => ({ ...item, align: 'center', headerAlign: 'center' }))}
      search
    >
      {props => (
        <JiveWindow title={title} subtitle={subtitle} name={name} showNote>
          {tableData.length === 0 ? (
            <div />
          ) : (
            <>
              <div className='row'>
                <div className='col-12 col-lg-12 text-lg-right'>
                  <SearchBar {...props.searchProps} placeholder='Pesquisar' />
                </div>
              </div>
              <Table {...props.baseProps} enablePagination />
            </>
          )}
        </JiveWindow>
      )}
    </ToolkitProvider>
  )
})

const Patentes = React.memo(({ inpi }) => {
  const relateds = useSelector(selectRelateds)

  const name = 'patrimonio.patentes'
  const title = 'Patentes'
  const subtitle = 'Busca suscetível a homônimos. Dados retirados do INPI'

  if (isPending(inpi)) {
    return <div />
  }

  if (inpi.error) {
    return (
      <div className='col-12 px-0 mx-0'>
        <JiveWindow title={title} subtitle={subtitle} name={name} showNote>
          <div>
            Ops! Aconteceu um erro e não foi possível carregar as patentes.
          </div>
        </JiveWindow>
      </div>
    )
  }

  const tableData = inpi.data.patentes || []

  return (
    <ToolkitProvider
      keyField='id'
      data={mapWithRelationship({
        array: tableData || [],
        nameKey: 'nomeBuscado',
        relateds
      }).map((item, key) => {
        const id = hashObject({ item, key })
        const dataPublicacaoRevista = formatDate(
          factoryDate(item.dataPublicacaoRevista)
        )
        const dataDeposito = item.dataDeposito
          ? formatDate(factoryDate(item.dataDeposito))
          : ''
        const dataRpi = item.dataRpi
          ? formatDate(factoryDate(item.dataRpi))
          : ''
        return {
          ...item,
          id,
          grau: item.relationship,
          dataPublicacaoRevista,
          dataRpi,
          dataDeposito
        }
      })}
      columns={[
        { dataField: 'titular', text: 'Nome', sort: true },
        {
          dataField: 'grau',
          text: 'Grau',
          sort: true
        },
        { dataField: 'titulo', text: 'Título', sort: true },
        { dataField: 'tituloPatente', text: 'Título patente', sort: true },
        { dataField: 'numeroRevista', text: 'Nº revista', sort: true },
        {
          dataField: 'dataPublicacaoRevista',
          text: 'Data de publicação',
          sort: true,
          sortFunc: onSortDate
        },
        { dataField: 'codigo', text: 'Código', sort: true },
        { dataField: 'nmProcesso', text: 'Processo', sort: true },
        {
          dataField: 'dataDeposito',
          text: 'Data de depósito',
          sort: true,
          sortFunc: onSortDate
        },
        {
          dataField: 'dataRpi',
          text: 'Data RPI',
          sort: true,
          sortFunc: onSortDate
        },
        {
          dataField: 'classificacaoInternacional',
          text: 'Classificação',
          sort: true
        },
        { dataField: 'inventor', text: 'Inventor', sort: true },
        { dataField: 'comentario', text: 'Comentário', sort: true }
      ].map(item => ({ ...item, align: 'center', headerAlign: 'center' }))}
      search
    >
      {props => (
        <JiveWindow title={title} subtitle={subtitle} name={name} showNote>
          {tableData.length === 0 ? (
            <div />
          ) : (
            <>
              <div className='row'>
                <div className='col-12 col-lg-12 text-lg-right'>
                  <SearchBar {...props.searchProps} placeholder='Pesquisar' />
                </div>
              </div>
              <Table {...props.baseProps} enablePagination />
            </>
          )}
        </JiveWindow>
      )}
    </ToolkitProvider>
  )
})

const RegistroMarcas = React.memo(({ inpi }) => {
  const relateds = useSelector(selectRelateds)

  const name = 'patrimonio.registroMarcas'
  const title = 'Registro de marcas'
  const subtitle = 'Busca suscetível a homônimos. Dados retirados do INPI'

  if (isPending(inpi)) {
    return <div />
  }

  if (inpi.error) {
    return (
      <div className='col-12 px-0 mx-0'>
        <JiveWindow title={title} subtitle={subtitle} name={name} showNote>
          <div>
            Ops! Aconteceu um erro e não foi possível carregar os registros de
            marcas.
          </div>
        </JiveWindow>
      </div>
    )
  }

  const tableData = inpi.data.registroMarcas || []

  return (
    <ToolkitProvider
      keyField='id'
      data={mapWithRelationship({
        array: tableData || [],
        nameKey: 'nomeBuscado',
        relateds
      }).map((item, key) => {
        const id = hashObject({ item, key })
        const dataPublicacaoRevista = formatDate(
          factoryDate(item.dataPublicacaoRevista)
        )
        const dataDeposito = item.dataDeposito
          ? formatDate(factoryDate(item.dataDeposito))
          : ''
        const dataConcessao = item.dataConcessao
          ? formatDate(factoryDate(item.dataConcessao))
          : ''
        const dataVigencia = item.dataVigencia
          ? formatDate(factoryDate(item.dataVigencia))
          : ''
        return {
          ...item,
          id,
          grau: item.relationship,
          dataPublicacaoRevista,
          dataConcessao,
          dataDeposito,
          dataVigencia
        }
      })}
      columns={[
        { dataField: 'nomeTitular', text: 'Nome', sort: true },
        {
          dataField: 'grau',
          text: 'Grau',
          sort: true
        },
        { dataField: 'numeroRevista', text: 'Nº revista', sort: true },
        {
          dataField: 'dataPublicacaoRevista',
          text: 'Data de publicação',
          sort: true,
          sortFunc: onSortDate
        },
        { dataField: 'codigoDespacho', text: 'Código despacho', sort: true },
        { dataField: 'nmProcesso', text: 'Processo', sort: true },
        {
          dataField: 'dataDeposito',
          text: 'Data de depósito',
          sort: true,
          sortFunc: onSortDate
        },
        {
          dataField: 'dataConcessao',
          text: 'Data de concessão',
          sort: true,
          sortFunc: onSortDate
        },
        {
          dataField: 'dataVigencia',
          text: 'Data de vigência',
          sort: true,
          sortFunc: onSortDate
        },
        { dataField: 'nomeDespacho', text: 'Nome despacho', sort: true },
        { dataField: 'paisTitular', text: 'País', sort: true },
        { dataField: 'ufTitular', text: 'UF', sort: true },
        { dataField: 'apresentacaoMarca', text: 'Apresentação', sort: true },
        { dataField: 'naturezamarca', text: 'Natureza', sort: true },
        { dataField: 'nomeMarca', text: 'Marca', sort: true },
        { dataField: 'codigoNice', text: 'Código Nice', sort: true },
        {
          dataField: 'especificacaoNice',
          text: 'Especificação Nice',
          sort: true
        },
        { dataField: 'statusNice', text: 'Status Nice', sort: true },
        { dataField: 'procurador', text: 'Procurador', sort: true }
      ].map(item => ({ ...item, align: 'center', headerAlign: 'center' }))}
      search
    >
      {props => (
        <JiveWindow title={title} subtitle={subtitle} name={name} showNote>
          {tableData.length === 0 ? (
            <div />
          ) : (
            <>
              <div className='row'>
                <div className='col-12 col-lg-12 text-lg-right'>
                  <SearchBar {...props.searchProps} placeholder='Pesquisar' />
                </div>
              </div>
              <Table {...props.baseProps} enablePagination />
            </>
          )}
        </JiveWindow>
      )}
    </ToolkitProvider>
  )
})

const ImoveisRurais = React.memo(({ sncr, imoveisRuraisJive, jivebots }) => {
  const relateds = useSelector(selectRelateds)
  const { targetCase } = useSelector(selectMinData)
  const [selectedItem, setSelectedItem] = useState({
    open: false,
    item: null
  })
  const name = 'patrimonio.imoveisRurais'

  const [selectedTable, setSelectedTable] = useState('jivebots')

  const relatedsDict = useMemo(() => {
    return relateds.reduce((acc, item) => {
      acc[item.document] = item
      return acc
    }, {})
  }, [relateds])

  const error = sncr.error && imoveisRuraisJive.error && jivebots.error
  if (isPending(sncr) && isPending(imoveisRuraisJive) && isPending(jivebots)) {
    return <div />
  }

  if (error) {
    return (
      <div className='col-12 px-0 mx-0'>
        <JiveWindow
          title='Imóveis Rurais'
          subtitle='Busca suscetível a homônimos. Dados retirados do SNCR e Jive'
          name={name}
          showNote
        >
          <div>
            Ops! Aconteceu um erro ao carregar as informações dos imóveis rurais
          </div>
        </JiveWindow>
      </div>
    )
  }

  const sncrData = sncr.data || []
  const jiveData = imoveisRuraisJive.data || []
  const jivebotsData = jivebots.data || []

  if (
    sncrData.length === 0 &&
    jiveData.length === 0 &&
    jivebotsData.length === 0
  ) {
    return (
      <div className='col-12 px-0 mx-0'>
        <JiveWindow
          title='Imóveis Rurais'
          subtitle='Busca suscetível a homônimos. Dados retirados do SNCR e Jive'
          name={name}
          showNote
        >
          <div />
        </JiveWindow>
      </div>
    )
  }

  const getTableData = () => {
    const mapSncr = sncrData => {
      return mapWithRelationship({
        array: sncrData || [],
        nameKey: 'titular',
        relateds
      }).map((imovel, key) => {
        const id = hashObject({ imovel, key })
        return {
          id,
          areatotal: imovel.areatotal,
          titular: imovel.titular,
          percentualdedetencao: imovel.percentualdedetencao,
          codigodoimovel: imovel.codigodoimovel,
          condicaodapessoa: imovel.condicaodapessoa,
          denominacaodoimovel: imovel.denominacaodoimovel,
          municipio: imovel.municipio,
          grau: imovel.relationship,
          uf: imovel.uf,
          matching: imovel.matching,
          action: imovel
        }
      })
    }

    const mapJiveApi = jiveData => {
      return mapWithRelationship({
        array: jiveData || [],
        documentKey: 'documentoPessoa',
        nameKey: 'nomePessoa',
        relateds
      }).map((imovel, key) => {
        const id = hashObject({ imovel, key })
        return {
          id,
          inscricaoImobiliaria: imovel.inscricaoImobiliaria,
          areaTotal: imovel.areaTerreno,
          grau: imovel.relationship,
          proprietario:
            imovel.nomePessoa && imovel.documentoPessoa
              ? `${imovel.nomePessoa} (${imovel.documentoPessoa})`
              : imovel.documentoPessoa
              ? imovel.documentoPessoa
              : imovel.nomePessoa
              ? imovel.nomePessoa
              : '-',
          nomePropriedade: imovel.nomePropriedade ?? '-',
          endereco: imovel.endereco ?? '-',
          municipio: imovel.municipio ?? '-',
          uf: imovel.uf ?? '-'
        }
      })
    }

    const mapJiveBots = jivebotsData => {
      return mapWithRelationship({
        array: jivebotsData || [],
        documentKey: 'documentoPessoa1',
        nameKey: 'nomePessoa1',
        relateds
      }).map((imovel, key) => {
        const id = hashObject({ imovel, key })
        const rawDocumento = imovel.documentoPessoa1?.replace(/\D/g, '')
        const nomeProprietario =
          imovel.nomePessoa1 ?? relatedsDict[rawDocumento]?.name

        return {
          id,
          proprietario: nomeProprietario,
          relationship: imovel.relationship,
          documento: rawDocumento ? (
            <a
              href={`?documento=${onlyNumbers(
                rawDocumento
              )}&casos=${targetCase.join(';')}`}
              target='_blank'
              rel='noopener noreferrer'
            >
              {formatDocument(rawDocumento)}
            </a>
          ) : (
            '-'
          ),
          rawDocumento: rawDocumento,
          tipoPessoa: rawDocumento.length === 11 ? 'Física' : 'Jurídico',
          endereco:
            imovel.endereco ??
            `${imovel.logradouro ?? ''}${
              imovel.numero ? `, ${imovel.numero}` : ''
            }${imovel.complemento ? ` - ${imovel.complemento}` : ''}${
              imovel.bairro ? ` - ${imovel.bairro}` : ''
            } ${imovel.cep ? ` ${imovel.cep}` : ''}`,
          municipioUf: `${imovel.municipio}${
            imovel.uf ? ` / ${imovel.uf}` : ''
          }`,
          inscricaoImobiliaria: imovel.inscricaoImobiliaria
        }
      })
    }

    if (selectedTable === 'sncr') {
      return mapSncr(sncrData)
    } else if (selectedTable === 'jive') {
      return mapJiveApi(jiveData)
    } else if (selectedTable === 'jivebots') {
      return mapJiveBots(jivebotsData)
    } else {
      return []
    }
  }

  const getColumns = () => {
    if (selectedTable === 'sncr') {
      return [
        { dataField: 'areatotal', text: 'Área Total (hectares)', sort: true },
        { dataField: 'titular', text: 'Títular', sort: true },
        {
          dataField: 'grau',
          text: 'Grau',
          sort: true
        },
        { dataField: 'percentualdedetencao', text: '%', sort: true },
        { dataField: 'codigodoimovel', text: 'Cód. do Imóvel', sort: true },
        { dataField: 'condicaodapessoa', text: 'Condição', sort: true },
        { dataField: 'denominacaodoimovel', text: 'Denominação', sort: true },
        { dataField: 'municipio', text: 'Município', sort: true },
        { dataField: 'uf', text: 'UF', sort: true },
        { dataField: 'matching', text: 'Matching', sort: true },
        {
          dataField: 'actions',
          text: '',
          sort: false,
          formatter: (_, row) => {
            const item = row.action

            if (!item.geom) {
              return <span />
            }

            return (
              <span>
                <button
                  className='btn btn-primary btn-sm'
                  onClick={() => setSelectedItem({ open: true, item })}
                >
                  geolocalização
                </button>
              </span>
            )
          }
        }
      ]
    } else if (selectedTable === 'jive') {
      return [
        { dataField: 'areaTotal', text: 'Área Total (hectares)', sort: true },
        { text: 'Proprietário', dataField: 'proprietario', sort: true },
        {
          dataField: 'grau',
          text: 'Grau',
          sort: true
        },
        { text: 'Propriedade', dataField: 'nomePropriedade', sort: true },
        { text: 'Endereço', dataField: 'endereco', sort: true },
        { text: 'Município', dataField: 'municipio', sort: true },
        { text: 'UF', dataField: 'uf', sort: true },
        {
          text: 'Inscrição Imobiliária',
          dataField: 'inscricaoImobiliaria',
          sort: true
        }
      ]
    } else if (selectedTable === 'jivebots') {
      return [
        { text: 'Proprietário', dataField: 'proprietario' },
        {
          dataField: 'documento',
          text: 'Documento',
          searchable: false,
          sort: true,
          sortFunc: onSortDocument
        },
        {
          dataField: 'relationship',
          text: 'Grau',
          sort: true
        },
        { dataField: 'rawDocumento', hidden: true },
        { text: 'Tipo pessoa', dataField: 'tipoPessoa', sort: true },
        { text: 'Endereço', dataField: 'endereco', sort: true },
        { text: 'Município / UF', dataField: 'municipioUf', sort: true },
        {
          text: 'Inscrição Imobiliária',
          dataField: 'inscricaoImobiliaria',
          sort: true
        }
      ]
    } else {
      return []
    }
  }

  const tableData = getTableData()

  const columns = getColumns()

  return (
    <>
      <Modal
        show={selectedItem.open}
        onHide={() => setSelectedItem({ open: false, item: null })}
        centered
        size='lg'
      >
        <Modal.Header closeButton>
          <Modal.Title>Geolocalização</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row'>
            <div className='col-12 text-center'>
              <DynamicGoogleMaps
                geoJson={selectedItem?.item?.geom}
                mapContainerStyle={{
                  width: '100%',
                  height: '400px'
                }}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ToolkitProvider
        keyField='id'
        columns={columns.map(item => ({
          ...item,
          align: 'center',
          headerAlign: 'center'
        }))}
        data={tableData || []}
        search
      >
        {props => (
          <JiveWindow
            title='Imóveis Rurais'
            subtitle='Busca suscetível a homônimos. Dados retirados do SNCR e Jive'
            name={name}
            showNote
          >
            <div className='row mb-2'>
              <div className='col-12 col-lg-6 d-flex flex-row gap-3 mb-3'>
                <Form.Check
                  type='radio'
                  label='Jive Bots'
                  onChange={() => setSelectedTable('jivebots')}
                  checked={selectedTable === 'jivebots'}
                  disabled={!jivebots.loaded || jivebots.error}
                />

                <Form.Check
                  type='radio'
                  label='SNCR'
                  onChange={() => setSelectedTable('sncr')}
                  checked={selectedTable === 'sncr'}
                  disabled={!sncr.loaded || sncr.error}
                />

                <Form.Check
                  type='radio'
                  label='Jive API'
                  onChange={() => setSelectedTable('jive')}
                  checked={selectedTable === 'jive'}
                  disabled={
                    !imoveisRuraisJive.loaded || imoveisRuraisJive.error
                  }
                />
              </div>
              <div className='col-12 col-lg-6 text-lg-right'>
                <SearchBar {...props.searchProps} placeholder='Pesquisar' />
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <Table enablePagination {...props.baseProps} />
              </div>
            </div>
          </JiveWindow>
        )}
      </ToolkitProvider>
    </>
  )
})

const ImoveisUrbanosJive = React.memo(({ jivebots, jiveApi }) => {
  const [selectedTable, setSelectedTable] = useState('jivebots')
  const relateds = useSelector(selectRelateds)
  const { targetCase } = useSelector(selectMinData)
  const name = 'patrimonio.imoveisJive'

  const relatedsDict = useMemo(() => {
    return relateds.reduce((acc, item) => {
      acc[item.document] = item
      return acc
    }, {})
  }, [relateds])

  if (isPending(jivebots) && isPending(jiveApi)) {
    return <div />
  }

  if (jivebots.error && jiveApi.error) {
    return (
      <div className='col-12 px-0 mx-0'>
        <JiveWindow
          title='Imóveis Urbanos Jive'
          subtitle='Busca suscetível a homônimos'
          name={name}
          showNote
        >
          <div>
            Ops! Aconteceu um erro e não foi possível consultar os imóveis
            urbanos jive
          </div>
        </JiveWindow>
      </div>
    )
  }

  const tableData =
    (selectedTable === 'jivebots' ? jivebots.data : jiveApi.data) || []

  if ((jivebots.data || []).length === 0 && (jiveApi.data || []).length === 0) {
    return (
      <div className='col-12 px-0 mx-0'>
        <JiveWindow
          title='Imóveis Urbanos Jive'
          subtitle='Busca suscetível a homônimos'
          name={name}
          showNote
        >
          <div />
        </JiveWindow>
      </div>
    )
  }

  const columns =
    selectedTable === 'jivebots'
      ? [
          { text: 'Proprietário', dataField: 'proprietario' },
          {
            dataField: 'documento',
            text: 'Documento',
            searchable: false,
            sort: true,
            sortFunc: onSortDocument
          },
          {
            dataField: 'relationship',
            text: 'Grau',
            sort: true
          },
          { text: 'Tipo pessoa', dataField: 'tipoPessoa', sort: true },
          { text: 'Endereço', dataField: 'endereco', sort: true },
          { text: 'Município / UF', dataField: 'municipioUf', sort: true },
          {
            text: 'Inscrição Imobiliária',
            dataField: 'inscricaoImobiliaria',
            sort: true
          },
          {
            dataField: 'rawDocumento',
            hidden: true
          }
        ]
      : [
          { text: 'Proprietário(s)', dataField: 'proprietario' },
          {
            text: 'Inscrição Imobiliária',
            dataField: 'inscricaoImobiliaria',
            sort: true
          },
          { text: 'UF', dataField: 'uf', sort: true },
          { text: 'Município', dataField: 'municipio', sort: true },
          { text: 'Logradouro', dataField: 'logradouro', sort: true },
          { text: 'Endereço', dataField: 'endereco', sort: true },
          { text: 'Bairro', dataField: 'bairro', sort: true },
          { text: 'Número', dataField: 'numero', sort: true },
          { text: 'Complemento', dataField: 'complemento', sort: true },
          { text: 'CEP', dataField: 'cep', sort: true },
          {
            text: 'Cód. Cartográfico',
            dataField: 'codigoCartografico',
            sort: true
          }
        ]

  const parseJiveBots = (imovel, key) => {
    const id = hashObject({ imovel, key })
    const rawDocumento = imovel.documentoPessoa1?.replace(/\D/g, '')

    const nomeProprietario =
      imovel.nomePessoa1 ?? relatedsDict[rawDocumento]?.name

    return {
      id,
      proprietario: nomeProprietario,
      relationship: imovel.relationship,
      documento: rawDocumento ? (
        <a
          href={`?documento=${onlyNumbers(
            rawDocumento
          )}&casos=${targetCase.join(';')}`}
          target='_blank'
          rel='noopener noreferrer'
        >
          {formatDocument(rawDocumento)}
        </a>
      ) : (
        '-'
      ),
      rawDocumento: rawDocumento.replace(/\D/g, ''),
      tipoPessoa: rawDocumento.length === 11 ? 'Física' : 'Jurídico',
      endereco:
        imovel.endereco ??
        `${imovel.logradouro ?? ''}${
          imovel.numero ? `, ${imovel.numero}` : ''
        }${imovel.complemento ? ` - ${imovel.complemento}` : ''}${
          imovel.bairro ? ` - ${imovel.bairro}` : ''
        } ${imovel.cep ? ` ${imovel.cep}` : ''}`,
      municipioUf: `${imovel.municipio}${imovel.uf ? ` / ${imovel.uf}` : ''}`,
      inscricaoImobiliaria: imovel.inscricaoImobiliaria
    }
  }

  const parseJiveApi = (imovel, key) => {
    const id = hashObject({ imovel, key })

    const proprietario =
      imovel.proprietarios && imovel.proprietarios.length > 0
        ? imovel.proprietarios.map(function (i) {
            const nome = i.nomePessoa ? i.nomePessoa : '-'
            const tipo = i.tipoPessoa
            const doc = i.documentoPessoa ? i.documentoPessoa : '-'
            return nome + (tipo ? ` (${tipo}) : ` : ' : ') + doc + '\t'
          })
        : '-'

    return {
      id,
      proprietario,
      cep: CEP_FORMATTER(imovel.cep),
      uf: imovel.uf,
      bairro: RESOLVE_NONE(imovel.bairro),
      codigoCartografico: RESOLVE_NONE(imovel.codigoCartografico),
      complemento: RESOLVE_NONE(imovel.complemento),
      endereco: RESOLVE_NONE(imovel.endereco),
      inscricaoImobiliaria: imovel.inscricaoImobiliaria,
      logradouro: RESOLVE_NONE(imovel.logradouro),
      municipio: imovel.municipio,
      numero: RESOLVE_NONE(imovel.numero)
    }
  }

  return (
    <ToolkitProvider
      keyField='id'
      columns={columns.map(item => ({
        ...item,
        align: 'center',
        headerAlign: 'center'
      }))}
      data={
        selectedTable === 'jivebots'
          ? mapWithRelationship({
              array: tableData || [],
              documentKey: 'documentoPessoa1',
              nameKey: 'nomePessoa1',
              relateds
            }).map((imovel, index) => parseJiveBots(imovel, index))
          : tableData.map((imovel, index) => parseJiveApi(imovel, index))
      }
      search
    >
      {props => (
        <JiveWindow
          title='Imóveis Urbanos Jive'
          subtitle='Busca suscetível a homônimos'
          name={name}
          showNote
        >
          <div className='row mb-2'>
            <div className='col-12 col-lg-6 d-flex flex-row gap-3 mb-3'>
              <Form.Check
                type='radio'
                label='Jive Bots'
                onChange={() => setSelectedTable('jivebots')}
                checked={selectedTable === 'jivebots'}
                disabled={
                  jivebots.error ||
                  (jivebots.loaded !== true && jivebots.error !== true)
                }
              />
              <Form.Check
                type='radio'
                label='Jive Api'
                onChange={() => setSelectedTable('jiveApi')}
                checked={selectedTable === 'jiveApi'}
                disabled={jiveApi.error}
              />
            </div>
            <div className='col-12 col-lg-6 text-lg-right'>
              <SearchBar {...props.searchProps} placeholder='Pesquisar' />
            </div>
          </div>
          <Table enablePagination {...props.baseProps} />
        </JiveWindow>
      )}
    </ToolkitProvider>
  )
})

const ImoveisAmbientais = React.memo(({ ambiental }) => {
  const relateds = useSelector(selectRelateds)
  const { targetCase } = useSelector(selectMinData)

  const name = 'patrimonio.imoveisJiveAmbiental'

  if (isPending(ambiental)) {
    return <div />
  }

  if (ambiental.error) {
    return (
      <div className='col-12 px-0 mx-0'>
        <JiveWindow title='Imóveis Jive Ambiental' name={name} showNote>
          <div>
            Ops! Aconteceu um erro e por isso não foi possível carregar os
            imóveis jive ambientais
          </div>
        </JiveWindow>
      </div>
    )
  }

  const tableData = ambiental.data || []

  if (tableData.length === 0) {
    return (
      <div className='col-12 px-0 mx-0'>
        <JiveWindow title='Imóveis Jive Ambiental' name={name} showNote>
          <div />
        </JiveWindow>
      </div>
    )
  }

  return (
    <ToolkitProvider
      keyField='id'
      columns={[
        { dataField: 'documento', text: 'CPF / CNPJ', sort: true },
        { dataField: 'nomePessoa', text: 'Nome', sort: true },
        {
          dataField: 'grau',
          text: 'Grau',
          sort: true
        },
        { dataField: 'municipio', text: 'Município', sort: true },
        { dataField: 'uf', text: 'UF' },
        { dataField: 'nomePropriedade', text: 'Propriedade', sort: true },
        { dataField: 'licencaUso', text: 'Licença de Uso', sort: true },
        { dataField: 'localizacao', text: 'Localização', sort: true },
        { dataField: 'tamanho', text: 'Tamanho', sort: true }
      ].map(item => ({ ...item, align: 'center', headerAlign: 'center' }))}
      data={mapWithRelationship({
        array: tableData || [],
        documentKey: 'documento',
        nameKey: 'nomePessoa',
        relateds
      }).map((imovel, key) => {
        const id = hashObject({ imovel, key })
        return {
          id,
          documento: imovel.documentoPessoa ? (
            <a
              href={`?documento=${onlyNumbers(
                imovel.documentoPessoa
              )}&casos=${targetCase.join(';')}`}
              target='_blank'
              rel='noopener noreferrer'
            >
              {imovel.documentoPessoa}
            </a>
          ) : (
            '-'
          ),
          grau: imovel.relationship,
          nomePessoa: imovel.nomePessoa,
          municipio: imovel.municipio,
          uf: imovel.uf,
          nomePropriedade: imovel.nomePropriedade,
          licencaUso: imovel.licencaUso,
          localizacao: imovel.localizacao,
          tamanho: imovel.tamanhoPropriedade
        }
      })}
      search
    >
      {props => (
        <JiveWindow title='Imóveis Jive Ambiental' name={name} showNote>
          <div className='col-12 text-lg-right'>
            <SearchBar {...props.searchProps} placeholder='Pesquisar' />
          </div>
          <Table enablePagination {...props.baseProps} />
        </JiveWindow>
      )}
    </ToolkitProvider>
  )
})

const ImoveisNeoway = React.memo(({ imoveisNeoway }) => {
  const relateds = useSelector(selectRelateds)
  const { targetCase } = useSelector(selectMinData)

  const title = 'Imóveis Neoway'
  const name = 'patrimonio.imoveisNeoway'

  if (isPending(imoveisNeoway)) {
    return <div />
  }

  if (imoveisNeoway.error) {
    return (
      <div className='col-12 px-0 mx-0'>
        <JiveWindow title={title} name={name} showNote>
          <div>
            Ops! Aconteceu um erro e por isso não foi possível carregar os
            imóveis neoway
          </div>
        </JiveWindow>
      </div>
    )
  }

  const tableData = imoveisNeoway.data || []

  if (tableData.length === 0) {
    return (
      <div className='col-12 px-0 mx-0'>
        <JiveWindow title={title} name={name} showNote>
          <div />
        </JiveWindow>
      </div>
    )
  }

  return (
    <ToolkitProvider
      keyField='id'
      columns={[
        {
          dataField: 'documento',
          text: 'Documento',
          sort: true,
          sortFunc: onSortDocument
        },
        { dataField: 'nomePessoa', text: 'Nome', sort: true },
        {
          dataField: 'grau',
          text: 'Grau',
          sort: true
        },
        { dataField: 'endereco', text: 'Endereço', sort: true },
        { dataField: 'valorAvaliacao', text: 'Valor', sort: true },
        { dataField: 'areaConstruida', text: 'Área construída', sort: true },
        { dataField: 'areaTerreno', text: 'Área terreno', sort: true },
        { dataField: 'municipio', text: 'Município', sort: true },
        { dataField: 'uf', text: 'UF' },
        {
          dataField: 'rawDocumento',
          hidden: true
        }
      ].map(item => ({ ...item, align: 'center', headerAlign: 'center' }))}
      data={mapWithRelationship({
        array: tableData || [],
        documentKey: 'documentoPessoaLigacao',
        nameKey: 'nomePessoaLigacao',
        relateds
      }).map((item, key) => {
        const id = hashObject({ item, key })
        return {
          id,
          rawDocumento: formatDocument(item.documentoPessoaLigacao),
          documento: item.documentoPessoaLigacao ? (
            <a
              href={`?documento=${onlyNumbers(
                item.documentoPessoaLigacao
              )}&casos=${targetCase.join(';')}`}
              target='_blank'
              rel='noopener noreferrer'
            >
              {formatDocument(item.documentoPessoaLigacao)}
            </a>
          ) : (
            '-'
          ),
          grau: item.relationship,
          nomePessoa: item.nomePessoaLigacao,
          municipio: item.municipio,
          uf: item.uf,
          licencaUso: item.licencaUso,
          localizacao: item.localizacao,
          tamanho: item.tamanhoPropriedade,
          areaConstruida: item.areaConstruida,
          areaTerreno: item.areaTerreno,
          valorAvaliacao: isValid(item.valorAvaliacao)
            ? formatCurrency(item.valorAvaliacao)
            : '',
          endereco: isValid(item.logradouro)
            ? `${item.logradouro}${
                item.numeroLogradouro ? `, ${item.numeroLogradouro}` : ''
              }${item.bairro ? `, ${item.bairro}` : ''}${
                item.cep ? ` - ${item.cep}` : ''
              }`
            : ''
        }
      })}
      search
    >
      {props => (
        <JiveWindow title={title} name={name} showNote>
          <div className='col-12 text-lg-right'>
            <SearchBar {...props.searchProps} placeholder='Pesquisar' />
          </div>
          <Table enablePagination {...props.baseProps} />
        </JiveWindow>
      )}
    </ToolkitProvider>
  )
})

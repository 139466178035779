import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import Swal from 'sweetalert2'
import { ToastContainer, toast } from 'react-toastify'

import 'react-step-progress-bar/styles.css'
import 'react-toastify/dist/ReactToastify.css'

// Pages
import {
  Login,
  DadosBasicos,
  Grupo,
  Endividamento,
  Juridico,
  Offshore,
  Operacionais,
  Patrimonio,
  Registrais,
  RF,
  Home,
  Historico,
  Pesquisa,
  MidiaDesabonadora,
  BandeiraAmarela,
  DadosQuantitativos
} from './pages'

import {
  getParentHostName,
  getWhiteListOfDomains,
  removeAuthToken,
  removeUserId,
  getUserId,
  setAuthToken,
  getAuthToken,
  setScope,
  usePrevious,
  toCamelCase,
  setUserId
} from './utils'

import { setUser } from './redux/actions/autenticacao'

import PrivateRoute, {
  PrivateRouteWithSidebarAndSubHeader
} from './components/PrivateRoute'
import API from './services/api'

import { selectProgressStatus } from './redux/selectors/progressStatus'
import { selectMinData } from './redux/selectors/dadosBasicos'
import { saveDocx } from './redux/store/doc'

import searchDocument from './lib/searchDocument'
import { SQSConsumer } from './lib/sqs'
import { PROGRESS_TYPES } from './lib/progressStatus'

import {
  DISCOVERY_PROVIDER_NAME,
  DISCOVERY_TOAST_ID,
  JIVE_PROVIDER_NAME,
  JIVE_TOAST_ID,
  OPERACIONAIS_PROVIDER_NAME,
  OPERACIONAIS_TOAST_ID,
  BASIC_INFO_PROVIDER_NAME,
  BASIC_INFO_TOAST_ID,
  OFFSHORE_PROVIDER_NAME,
  OFFSHORE_TOAST_ID,
  PATRIMONIO_PROVIDER_NAME,
  PATRIMONIO_TOAST_ID,
  ENDIVIDAMENTO_PROVIDER_NAME,
  ENDIVIDAMENTO_TOAST_ID,
  JURIDICO_PROVIDER_NAME,
  MATCH_GRUPO_SOCIETARIO_TOAST_ID,
  MATCH_GRUPO_SOCIETARIO_PROVIDER_NAME,
  DOCX_PROVIDER_NAME,
  XLSX_PROVIDER_NAME,
  DISCREDITING_MEDIA_PROVIDER_NAME,
  DISCREDITING_MEDIA_TOAST_ID,
  XLSX_TOAST_ID,
  BANDEIRA_AMARELA_TOAST_ID,
  BANDEIRA_AMARELA_PROVIDER_NAME,
  JIVE_BOTS_PROVIDER_NAME,
  JIVE_BOTS__TOAST_ID
} from './config/stepFunction'
import {
  setS3PathOffshore,
  setErrorOffshore
} from './redux/store/offshoreTheme'
import {
  setErrorOperacionais,
  setS3PathOperacionais
} from './redux/store/operacionaisTheme'
import {
  setS3PathJive,
  setErrorJive,
  setBotsStatusJive,
  setS3PathBotsJive,
  setErrorBotsJive
} from './redux/store/jiveTheme'
import {
  setErrorEndividamento,
  setS3PathEndividamento
} from './redux/store/endividamentoTheme'
import {
  setErrorDadosBasicos,
  setS3PathDadosBasicos
} from './redux/store/dadosBasicosTheme'
import {
  setErrorPatrimonio,
  setS3PathPatrimonio
} from './redux/store/patrimonioTheme'
import {
  addRelatedStatusJuridico,
  finishJuridico,
  setRelatedsJuridico
} from './redux/store/juridicoTheme'
import {
  finishGlobalStep,
  setErrorGlobalStep,
  setMetadataGlobalStep
} from './redux/store/globalStep'
import {
  setErrorMatchGrupoSocietarioTheme,
  setS3PathMatchGrupoSocietarioTheme
} from './redux/store/matchGrupoSocietarioTheme'
import {
  setErrorDiscreditingMedia,
  setS3PathDiscreditingMedia
} from './redux/store/discreditingMediaTheme'
import {
  setErrorBandeiraAmarela,
  setS3PathBandeiraAmarela
} from './redux/store/bandeiraAmarelaTheme'
import { saveXlsx } from './redux/store/xlsx'

import { SEARCH_OPTIONS } from './config/consts'
import {
  setErrorDadosBasicosAnalytics,
  setErrorEndividamentoAnalytics,
  setErrorJiveAnalytics,
  setErrorJuridicoAnalytics,
  setErrorOffshoreAnalytics,
  setErrorOperacionaisAnalytics,
  setErrorPatrimonioAnalytics,
  setS3PathDadosBasicosAnalytics,
  setS3PathEndividamentoAnalytics,
  setS3PathJiveAnalytics,
  setS3PathJuridicoAnalytics,
  setS3PathOffshoreAnalytics,
  setS3PathOperacionaisAnalytics,
  setS3PathPatrimonioAnalytics
} from './redux/store/analyticsTheme'

const TOAST_ID_DOCX = 'gerando-dossie'

const resolveToastErrorMessage = (theme, { succeeded, total }) => {
  const failed = total - succeeded
  const errorMessage =
    failed === 1
      ? `${theme}: ${failed} de ${total} consulta falhou`
      : `${theme}: ${failed} de ${total} consultas falharam`

  return errorMessage
}

const Root = () => {
  const dispatch = useDispatch()

  const { targetDocument } = useSelector(selectMinData)

  const queueUrl = useSelector(state => state.globalStep.queueUrl)
  const globalStepError = useSelector(state => state.globalStep.error)
  const progressStatus = useSelector(selectProgressStatus)

  const currentUser = useSelector(state => state.autenticacao.user)

  const [loading, setLoading] = useState(
    window.self.location !== window.parent.location &&
      getWhiteListOfDomains().includes(getParentHostName())
  )
  const [dossierStatus, setDossierStatus] = useState('none')

  const previousQueueUrl = usePrevious(queueUrl)

  const _callback = useCallback(
    messages => {
      const handleDadosBasicos = message => {
        const contentData = toCamelCase(message.content.data)
        const s3Path = contentData

        if (message.content.action === 'error') {
          toast.error(`Dados básicos: Falhou`, {
            toastId: `${BASIC_INFO_TOAST_ID}-error-message`
          })

          dispatch(setErrorDadosBasicos(true))
          dispatch(setErrorDadosBasicosAnalytics(true))
          return
        }

        if (contentData?.total !== contentData?.succeeded) {
          const errorMessage = resolveToastErrorMessage(
            'Dados básicos',
            contentData
          )
          toast.error(errorMessage, {
            toastId: `${BASIC_INFO_TOAST_ID}-error-message`
          })
        }
        const { analytics } = contentData
        if (analytics) {
          dispatch(setS3PathDadosBasicosAnalytics(analytics))
        }
        dispatch(setS3PathDadosBasicos(s3Path))
      }

      const handleDiscreditingMedia = message => {
        const contentData = toCamelCase(message.content?.data || {})

        const { news: discreditingNews } = contentData.result

        if (message.content.action === 'error') {
          toast.error(`Midia Desabonadora: Todas as consultas falharam`, {
            toastId: `${DISCREDITING_MEDIA_TOAST_ID}-error-message`
          })
          dispatch(setErrorDiscreditingMedia(true))
          return
        }

        if (discreditingNews?.total !== discreditingNews?.succeeded) {
          const errorMessage = resolveToastErrorMessage(
            'Notícias Desabonadoras',
            discreditingNews
          )
          toast.error(errorMessage, {
            toastId: `${DISCREDITING_MEDIA_TOAST_ID}-discrediting-news-error-message`
          })
        }

        dispatch(setS3PathDiscreditingMedia({ discreditingNews }))
      }

      const handleMatchGrupoSocietario = message => {
        const contentData = toCamelCase(message.content.data)

        if (message.content.action === 'error') {
          toast.error(`Grupo societário: Falhou`, {
            toastId: `${MATCH_GRUPO_SOCIETARIO_TOAST_ID}-error-message`
          })

          dispatch(setErrorMatchGrupoSocietarioTheme(true))
          return
        }

        if (contentData?.total !== contentData?.succeeded) {
          const errorMessage = resolveToastErrorMessage(
            'Grupo societário',
            contentData
          )
          toast.error(errorMessage, {
            toastId: `${MATCH_GRUPO_SOCIETARIO_TOAST_ID}-error-message`
          })
        }

        dispatch(setS3PathMatchGrupoSocietarioTheme(contentData))
      }

      const handleOffshore = message => {
        const contentData = toCamelCase(message.content?.data || {})

        if (message.content.action === 'error') {
          toast.error(`Offshore: Todas as consultas falharam`, {
            toastId: `${OFFSHORE_TOAST_ID}-error-message`
          })
          dispatch(setErrorOffshore(true))
          dispatch(setErrorOffshoreAnalytics(true))
          return
        }

        const { analytics } = contentData

        if (contentData?.total !== contentData?.succeeded) {
          const errorMessage = resolveToastErrorMessage('Offshore', contentData)
          toast.error(errorMessage, {
            toastId: `${OFFSHORE_TOAST_ID}-error-message`
          })
        }

        if (analytics) {
          dispatch(setS3PathOffshoreAnalytics(analytics))
        }

        dispatch(setS3PathOffshore(contentData.result.offshoreLeaks))
      }

      const handleOperacionais = message => {
        const contentData = toCamelCase(message.content?.data || {})
        if (message.content.action === 'error') {
          toast.error(`Match funcionários: Todas as consultas falharam`, {
            toastId: `${OPERACIONAIS_TOAST_ID}-error-message`
          })
          dispatch(setErrorOperacionais(true))
          dispatch(setErrorOperacionaisAnalytics(true))
          return
        }

        const { analytics } = contentData
        if (contentData?.total !== contentData?.succeeded) {
          const errorMessage = resolveToastErrorMessage(
            'Match funcionários',
            contentData
          )
          toast.error(errorMessage, {
            toastId: `${OPERACIONAIS_TOAST_ID}-error-message`
          })
        }
        dispatch(setS3PathOperacionais(contentData))
        dispatch(setS3PathOperacionaisAnalytics(analytics))
      }

      const handlePatrimonio = message => {
        const contentData = toCamelCase(message.content?.data || {})

        if (message.content.action === 'error') {
          toast.error(`Patrimônio: Todas as consultas falharam`, {
            toastId: `${PATRIMONIO_TOAST_ID}-error-message`
          })
          dispatch(setErrorPatrimonio(true))
          dispatch(setErrorPatrimonioAnalytics(true))
          return
        }

        const { analytics } = contentData
        const { aeronaves, sncr, inpi } = contentData.result

        if (aeronaves?.total !== aeronaves?.succeeded) {
          const errorMessage = resolveToastErrorMessage('Aeronaves', aeronaves)
          toast.error(errorMessage, {
            toastId: `${PATRIMONIO_TOAST_ID}-aeronaves-error-message`
          })
        }

        if (sncr?.total !== sncr?.succeeded) {
          const errorMessage = resolveToastErrorMessage('Imóveis Rurais', sncr)
          toast.error(errorMessage, {
            toastId: `${PATRIMONIO_TOAST_ID}-sncr-error-message`
          })
        }

        if (inpi?.total !== inpi?.succeeded) {
          const errorMessage = resolveToastErrorMessage('INPI', inpi)
          toast.error(errorMessage, {
            toastId: `${PATRIMONIO_TOAST_ID}-inpi-error-message`
          })
        }

        if (analytics) {
          dispatch(setS3PathPatrimonioAnalytics(analytics))
        }

        dispatch(setS3PathPatrimonio({ aeronaves, sncr, inpi }))
      }

      const handleBandeiraAmarela = message => {
        const contentData = toCamelCase(message.content?.data || {})

        if (message.content.action === 'error') {
          toast.error(`Pontos de atenção: Todas as consultas falharam`, {
            toastId: `${BANDEIRA_AMARELA_TOAST_ID}-error-message`
          })
          dispatch(setErrorBandeiraAmarela(true))
          return
        }

        const {
          pep,
          acordosLeniencia,
          autoInfracao,
          cepim,
          ofac,
          termoApreensao,
          termoEmbargo,
          termoSuspensao,
          cadastroExpulsoes,
          ceaf,
          ceis,
          cnep
        } = contentData.result

        if (pep?.total !== pep?.succeeded) {
          const errorMessage = resolveToastErrorMessage('PEP', pep)
          toast.error(errorMessage, {
            toastId: `${BANDEIRA_AMARELA_TOAST_ID}-pep-error-message`
          })
        }

        if (acordosLeniencia?.total !== acordosLeniencia?.succeeded) {
          const errorMessage = resolveToastErrorMessage(
            'Acordos de leniência',
            acordosLeniencia
          )
          toast.error(errorMessage, {
            toastId: `${BANDEIRA_AMARELA_TOAST_ID}-acordosLeniencia-error-message`
          })
        }
        if (autoInfracao?.total !== autoInfracao?.succeeded) {
          const errorMessage = resolveToastErrorMessage(
            'Auto infração',
            autoInfracao
          )
          toast.error(errorMessage, {
            toastId: `${BANDEIRA_AMARELA_TOAST_ID}-autoInfracao-error-message`
          })
        }

        if (termoApreensao?.total !== termoApreensao?.succeeded) {
          const errorMessage = resolveToastErrorMessage(
            'Termo de apreensão',
            termoApreensao
          )
          toast.error(errorMessage, {
            toastId: `${BANDEIRA_AMARELA_TOAST_ID}-termoApreensao-error-message`
          })
        }

        if (cepim?.total !== cepim?.succeeded) {
          const errorMessage = resolveToastErrorMessage(
            'Entidades Privadas Sem Fins Lucrativos',
            cepim
          )
          toast.error(errorMessage, {
            toastId: `${BANDEIRA_AMARELA_TOAST_ID}-cepim-error-message`
          })
        }

        if (ofac?.total !== ofac?.succeeded) {
          const errorMessage = resolveToastErrorMessage(
            'Ativos Estrangeiros dos EUA',
            ofac
          )
          toast.error(errorMessage, {
            toastId: `${BANDEIRA_AMARELA_TOAST_ID}-ofac-error-message`
          })
        }

        if (termoEmbargo?.total !== termoEmbargo?.succeeded) {
          const errorMessage = resolveToastErrorMessage(
            'Termo de embargo',
            termoEmbargo
          )
          toast.error(errorMessage, {
            toastId: `${BANDEIRA_AMARELA_TOAST_ID}-termoEmbargo-error-message`
          })
        }

        if (termoSuspensao?.total !== termoSuspensao?.succeeded) {
          const errorMessage = resolveToastErrorMessage(
            'Termo de suspensão',
            termoSuspensao
          )
          toast.error(errorMessage, {
            toastId: `${BANDEIRA_AMARELA_TOAST_ID}-termoSuspensao-error-message`
          })
        }

        if (cadastroExpulsoes?.total !== cadastroExpulsoes?.succeeded) {
          const errorMessage = resolveToastErrorMessage(
            'Cadastro de expulsões',
            cadastroExpulsoes
          )
          toast.error(errorMessage, {
            toastId: `${BANDEIRA_AMARELA_TOAST_ID}-cadastroExpulsoes-error-message`
          })
        }

        if (cnep?.total !== cnep?.succeeded) {
          const errorMessage = resolveToastErrorMessage(
            'Empresas Punidas',
            cnep
          )
          toast.error(errorMessage, {
            toastId: `${BANDEIRA_AMARELA_TOAST_ID}-cnep-error-message`
          })
        }

        if (ceis?.total !== ceis?.succeeded) {
          const errorMessage = resolveToastErrorMessage(
            'Empresas Inidôneas e Suspensas',
            ceis
          )
          toast.error(errorMessage, {
            toastId: `${BANDEIRA_AMARELA_TOAST_ID}-ceis-error-message`
          })
        }

        if (ceaf?.total !== ceaf?.succeeded) {
          const errorMessage = resolveToastErrorMessage(
            'Expulsões da Administração Federal',
            ceaf
          )
          toast.error(errorMessage, {
            toastId: `${BANDEIRA_AMARELA_TOAST_ID}-ceaf-error-message`
          })
        }

        dispatch(
          setS3PathBandeiraAmarela({
            pep,
            acordosLeniencia,
            autoInfracao,
            cepim,
            ofac,
            termoApreensao,
            termoEmbargo,
            termoSuspensao,
            cadastroExpulsoes,
            cnep,
            ceis,
            ceaf
          })
        )
      }

      const handleJiveApi = message => {
        const contentData = toCamelCase(message.content?.data || {})

        if (message.content.action === 'error') {
          toast.error(`Jive API: Todas as consultas falharam`, {
            toastId: `${JIVE_TOAST_ID}-error-message`
          })
          dispatch(setErrorJive(true))
          dispatch(setErrorJiveAnalytics(true))
          return
        }

        const { certidoes, devedores, jiveApi, analytics } =
          contentData.result || {}

        if (certidoes && certidoes.total !== certidoes.succeeded) {
          const errorMessage = resolveToastErrorMessage('Certidões', certidoes)
          toast.error(errorMessage, {
            toastId: `${JIVE_TOAST_ID}-certidoes-error-message`
          })
        }

        if (devedores && devedores.total !== devedores.succeeded) {
          const errorMessage = resolveToastErrorMessage(
            'Devedores Jive',
            devedores
          )
          toast.error(errorMessage, {
            toastId: `${JIVE_TOAST_ID}-devedores-error-message`
          })
        }

        if (jiveApi && jiveApi.total !== jiveApi.succeeded) {
          const errorMessage = resolveToastErrorMessage('Jive Api', jiveApi)
          toast.error(errorMessage, {
            toastId: `${JIVE_TOAST_ID}-jiveApi-error-message`
          })
        }

        if (analytics) {
          dispatch(setS3PathJiveAnalytics(analytics))
        }

        dispatch(setS3PathJive({ certidoes, devedores, jiveApi }))
      }

      const handleJiveBots = message => {
        const contentData = toCamelCase(message.content?.data || {})

        if (message.content.action === 'error') {
          toast.error(`Jive Bots: Todas as consultas falharam`, {
            toastId: `${JIVE_BOTS__TOAST_ID}-error-message`
          })
          dispatch(setErrorBotsJive(true))
          return
        }

        const hasTaskState = !!contentData.taskState

        if (hasTaskState) {
          dispatch(setBotsStatusJive(contentData))
          return
        }

        const { jivebots } = contentData.result || {}

        if (jivebots && jivebots.total !== jivebots.succeeded) {
          const errorMessage = resolveToastErrorMessage('Jive Api', jivebots)
          toast.error(errorMessage, {
            toastId: `${JIVE_BOTS__TOAST_ID}-jivebots-error-message`
          })
        }

        dispatch(setS3PathBotsJive({ jivebots }))
      }

      const handleJuridico = message => {
        if (message.content.message === 'Success') {
          const analytics = toCamelCase(message.content?.data)

          if (!analytics) {
            dispatch(setErrorJuridicoAnalytics(true))
          } else {
            dispatch(setS3PathJuridicoAnalytics(analytics))
          }
          return
        }

        const contentData = toCamelCase(message.content?.data || {})

        const { name, document, personType, type } = contentData
        const { bucketName, key } = contentData.result || {}

        if (message.content.action === 'error') {
          dispatch(
            addRelatedStatusJuridico({
              parameters: { name, document, personType: personType || type },
              output: { bucketName, key },
              status: PROGRESS_TYPES.ERROR
            })
          )
          return
        }

        const { relateds } = contentData
        if (relateds) {
          dispatch(
            setRelatedsJuridico(
              relateds.map(({ result, personType, type, ...item }) => {
                const { bucketName, key } = result || {}
                return {
                  ...item,
                  type: personType || type,
                  bucketName,
                  key,
                  status: item.status || PROGRESS_TYPES.PENDING
                }
              })
            )
          )
          return
        }

        dispatch(
          addRelatedStatusJuridico({
            parameters: { name, document, personType },
            output: { bucketName, key },
            status: PROGRESS_TYPES.LOADED
          })
        )
      }

      const handleEndividamento = message => {
        const contentData = toCamelCase(message.content?.data || {})

        if (message.content.action === 'error') {
          toast.error(`Endividamento: Todas as consultas falharam`, {
            toastId: `${ENDIVIDAMENTO_TOAST_ID}-error-message`
          })
          dispatch(setErrorEndividamento(true))
          dispatch(setErrorEndividamentoAnalytics(true))
          return
        }

        const { analytics } = contentData
        const { cheque, cndt, pgfn, protesto } = contentData.result

        if (cheque && cheque.total !== cheque.succeeded) {
          const errorMessage = resolveToastErrorMessage('Cheques', cheque)
          toast.error(errorMessage, {
            toastId: `${ENDIVIDAMENTO_TOAST_ID}-cheques-error-message`
          })
        }

        if (cndt && cndt.total !== cndt.succeeded) {
          const errorMessage = resolveToastErrorMessage('CNDT', cndt)
          toast.error(errorMessage, {
            toastId: `${ENDIVIDAMENTO_TOAST_ID}-cndt-error-message`
          })
        }

        if (pgfn && pgfn.total !== pgfn.succeeded) {
          const errorMessage = resolveToastErrorMessage('PGFN', pgfn)
          toast.error(errorMessage, {
            toastId: `${ENDIVIDAMENTO_TOAST_ID}-pgfn-error-message`
          })
        }

        if (protesto && protesto.total !== protesto.succeeded) {
          const errorMessage = resolveToastErrorMessage('Protesto', protesto)
          toast.error(errorMessage, {
            toastId: `${ENDIVIDAMENTO_TOAST_ID}-protesto-error-message`
          })
        }

        if (analytics) {
          dispatch(setS3PathEndividamentoAnalytics(analytics))
        }

        dispatch(setS3PathEndividamento({ cheque, cndt, pgfn, protesto }))
      }

      const handleDiscovery = message => {
        if (message.content.action === 'end') {
          dispatch(finishGlobalStep())
          const sqsInstance = SQSConsumer.getInstance()

          if (
            sqsInstance.providers.includes(DOCX_PROVIDER_NAME) ||
            sqsInstance.providers.includes(XLSX_PROVIDER_NAME)
          ) {
            return
          }
          sqsInstance.stop()

          dispatch(setMetadataGlobalStep({ finishedAt: new Date() }))
          toast.success('Carregamento concluído com sucesso', {
            toastId: `${DISCOVERY_TOAST_ID}-success-message`,
            position: toast.POSITION.BOTTOM_RIGHT
          })
          return
        }

        if (message.content.action === 'error') {
          toast.error(`Discovery: Todas as consultas falharam`, {
            toastId: `${DISCOVERY_TOAST_ID}-error-message`,
            position: toast.POSITION.BOTTOM_RIGHT
          })
          dispatch(setErrorGlobalStep(true))
        }
      }

      const handleDocx = message => {
        const contentMetadata = toCamelCase(message.content?.metadata || {})

        if (message.content.action === 'error') {
          toast.error(`Erro ao gerar dossiê`, {
            toastId: `${TOAST_ID_DOCX}-error-message`
          })
          toast.dismiss(TOAST_ID_DOCX)
          setDossierStatus('error')
          return
        }
        const { bucket, key, link } = contentMetadata
        dispatch(saveDocx({ bucket, key, link }))
        setDossierStatus('loaded')
      }

      const handleXlsx = message => {
        const contentMetadata = toCamelCase(message.content?.metadata || {})

        if (message.content.action === 'error') {
          toast.error(`Erro ao gerar planilhas`, {
            toastId: `${XLSX_TOAST_ID}-error-message`
          })
          toast.dismiss(XLSX_TOAST_ID)
          return
        }
        const { bucket, key, link, type } = contentMetadata
        dispatch(saveXlsx({ bucket, key, link, type }))
      }

      while (messages.length > 0) {
        const message = messages.shift()
        console.debug('Message:', message)

        if (message.messageGroupId === DISCOVERY_PROVIDER_NAME) {
          handleDiscovery(message)
          continue
        }

        if (message.content.action === 'end') {
          if (message.messageGroupId === JURIDICO_PROVIDER_NAME) {
            dispatch(finishJuridico())
          }
          continue
        }

        if (message.messageGroupId === JURIDICO_PROVIDER_NAME) {
          handleJuridico(message)
        } else if (message.messageGroupId === DOCX_PROVIDER_NAME) {
          handleDocx(message)
        } else if (message.messageGroupId === XLSX_PROVIDER_NAME) {
          handleXlsx(message)
        } else if (message.messageGroupId === OPERACIONAIS_PROVIDER_NAME) {
          handleOperacionais(message)
        } else if (message.messageGroupId === JIVE_PROVIDER_NAME) {
          handleJiveApi(message)
        } else if (message.messageGroupId === JIVE_BOTS_PROVIDER_NAME) {
          handleJiveBots(message)
        } else if (
          message.messageGroupId === DISCREDITING_MEDIA_PROVIDER_NAME
        ) {
          handleDiscreditingMedia(message)
        } else if (message.messageGroupId === BANDEIRA_AMARELA_PROVIDER_NAME) {
          handleBandeiraAmarela(message)
        } else if (message.messageGroupId === PATRIMONIO_PROVIDER_NAME) {
          handlePatrimonio(message)
        } else if (message.messageGroupId === BASIC_INFO_PROVIDER_NAME) {
          handleDadosBasicos(message)
        } else if (message.messageGroupId === OFFSHORE_PROVIDER_NAME) {
          handleOffshore(message)
        } else if (message.messageGroupId === ENDIVIDAMENTO_PROVIDER_NAME) {
          handleEndividamento(message)
        } else if (
          message.messageGroupId === MATCH_GRUPO_SOCIETARIO_PROVIDER_NAME
        ) {
          handleMatchGrupoSocietario(message)
        }
      }
    },
    [dispatch]
  )

  useEffect(() => {
    setDossierStatus('none')
  }, [targetDocument])

  useEffect(() => {
    if (previousQueueUrl === queueUrl) {
      return
    }

    const sqsInstance = SQSConsumer.getInstance()

    if (previousQueueUrl && previousQueueUrl !== queueUrl) {
      sqsInstance.stop()
    }

    if (!queueUrl || globalStepError) {
      sqsInstance.queueUrl = undefined
      return
    }

    sqsInstance.callback = _callback
    sqsInstance.queueUrl = queueUrl
    sqsInstance.addProvider(DISCOVERY_PROVIDER_NAME)
    try {
      sqsInstance.consume()
    } catch (err) {
      console.error(err)
    }
  }, [dispatch, previousQueueUrl, queueUrl, globalStepError, _callback])

  useEffect(() => {
    let mounted = true

    const urlParams = new URLSearchParams(window.location.search)
    const documento = urlParams.get('documento')
    const casos = urlParams.get('casos')
    const filteredCasos = casos ? casos.split(';').filter(Boolean) : []

    if (
      window.self.location !== window.parent.location &&
      getWhiteListOfDomains().includes(getParentHostName()) &&
      !currentUser
    ) {
      const user = urlParams.get('uuid')
      setLoading(true)
      Swal.fire({
        title: 'Validando credenciais',
        text: 'Aguarde alguns instantes, estamos verificando seus dados',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        didOpen: () => {
          Swal.showLoading()
          API.autenticacao
            .consultarPermissaoJive({ uuid: user })
            .then(() => {
              if (mounted) {
                setUserId(user)
                setScope('jive')
                dispatch(setUser({ id: user, scope: 'jive' }))
                setAuthToken(user)
                Swal.close()
                if (documento) {
                  Swal.fire({
                    title: 'Pesquisando CNPJ/CPF',
                    imageUrl: '/img/logo-square.svg',
                    imageSize: '100x100',
                    imageWidth: 100,
                    imageHeight: 100
                  })
                  const result = searchDocument({
                    documento,
                    casos: filteredCasos,
                    user,
                    options: [SEARCH_OPTIONS[0]],
                    dispatch
                  })
                  if (result) {
                    Swal.close()
                  }
                }
              }
            })
            .catch(err => {
              console.error(err)
              dispatch(setUser(undefined))
              removeAuthToken()
              removeUserId()

              if (
                err?.response?.data?.name === 'AuthorizationError' &&
                err?.response?.data?.message ===
                  'Missing authorization. Contact the Jive support'
              ) {
                Swal.fire({
                  title: 'Falha na autenticação',
                  text:
                    'Entre em contato com a Jive para verificar as suas permissões',
                  icon: 'error'
                })
              } else {
                Swal.fire({
                  title: 'Falha na autenticação',
                  text:
                    'Não foi possível verificar a sua credencial. Se o erro persistir entre em contato com o suporte',
                  icon: 'error'
                })
              }
            })
            .finally(() => {
              setLoading(false)
            })
        }
      })
    } else if ((getUserId() || getAuthToken()) && !currentUser) {
      const user = getUserId()
        ? {
            id: getUserId(),
            scope: 'discovery'
          }
        : {
            id: getAuthToken(),
            scope: 'jive'
          }
      dispatch(setUser(user))
      if (documento) {
        Swal.fire({
          title: 'Pesquisando CNPJ/CPF',
          imageUrl: '/img/logo-square.svg',
          imageSize: '100x100',
          imageWidth: 100,
          imageHeight: 100
        })
        const result = searchDocument({
          documento,
          casos: filteredCasos,
          user,
          options: [SEARCH_OPTIONS[0]],
          dispatch
        })
        if (result) {
          Swal.close()
        }
      }
    }

    return () => {
      mounted = false
    }
  }, [dispatch, currentUser])

  if (loading) {
    return ''
  }

  return (
    <div
      className='position-relative h-100 d-flex flex-grow-1 flex-column'
      style={{ maxWidth: '100vw' }}
    >
      <BrowserRouter>
        <Switch>
          <Route path='/login' exact component={Login} />
          <PrivateRoute path='/' exact component={Home} />
          <PrivateRoute
            path='/historico'
            exact
            component={Historico}
            withSubHeader
            hideSearch
            hideTitle
          />
          <PrivateRouteWithSidebarAndSubHeader
            path='/pontos-atencao'
            component={BandeiraAmarela}
          />
          <PrivateRouteWithSidebarAndSubHeader
            path='/dados-quantitativos'
            component={DadosQuantitativos}
          />
          <PrivateRouteWithSidebarAndSubHeader
            path='/dados-basicos'
            component={DadosBasicos}
          />
          <PrivateRouteWithSidebarAndSubHeader
            path='/midia-desabonadora'
            component={MidiaDesabonadora}
          />
          <PrivateRouteWithSidebarAndSubHeader
            path='/pesquisa'
            component={Pesquisa}
          />
          <PrivateRouteWithSidebarAndSubHeader
            path='/grupo'
            component={Grupo}
          />
          <PrivateRouteWithSidebarAndSubHeader
            path='/patrimonio'
            component={Patrimonio}
          />
          <PrivateRouteWithSidebarAndSubHeader
            path='/registrais'
            component={Registrais}
          />
          <PrivateRouteWithSidebarAndSubHeader
            path='/offshore'
            component={Offshore}
          />
          <PrivateRouteWithSidebarAndSubHeader
            path='/endividamento'
            component={Endividamento}
          />
          <PrivateRouteWithSidebarAndSubHeader
            path='/juridico'
            component={Juridico}
          />
          <PrivateRouteWithSidebarAndSubHeader
            path='/operacionais'
            component={Operacionais}
          />
          <PrivateRouteWithSidebarAndSubHeader path='/rf' component={RF} />
        </Switch>
      </BrowserRouter>
      <ToastContainer
        position='bottom-right'
        autoClose={8000}
        hideProgressBar
        newestOnTop={false}
        draggable
        pauseOnVisibilityChange
        closeOnClick
        pauseOnHover
      />
      <div
        className='d-none'
        data-test={
          !targetDocument
            ? 'progress-none'
            : progressStatus.progressLoaded === 100
            ? 'progress-loaded'
            : 'progress-loading'
        }
      />
      <div className='d-none' data-test={`dossier-status-${dossierStatus}`} />
    </div>
  )
}

export default Root

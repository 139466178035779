import { takeLatest } from 'redux-saga/effects'

import { Types as GrafoTypes } from '../reducers/grafo'
import { Types as DocTypes } from '../store/doc'
import { Types as XlsxTypes } from '../store/xlsx'
import { Types as GoogleMapsTypes } from '../reducers/googleMaps'
import { Types as MatchesTypes } from '../reducers/matches'

import { Types as JiveThemeTypes } from '../store/jiveTheme'
import { Types as PatrimonioThemeTypes } from '../store/patrimonioTheme'
import { Types as DadosBasicosThemeTypes } from '../store/dadosBasicosTheme'
import { Types as OperacionaisThemeTypes } from '../store/operacionaisTheme'
import { Types as OffshoreThemeTypes } from '../store/offshoreTheme'
import { Types as EndividamentoThemeTypes } from '../store/endividamentoTheme'
import { Types as JuridicoThemeTypes } from '../store/juridicoTheme'
import { Types as MatchGrupoSocietarioThemeTypes } from '../store/matchGrupoSocietarioTheme'
import { Types as BandeiraAmarelaThemeTypes } from '../store/bandeiraAmarelaTheme'
import { Types as GlobalStepTypes } from '../store/globalStep'
import { Types as DiscreditingMediaThemeTypes } from '../store/discreditingMediaTheme'
import { Types as AnalyticsThemeTypes } from '../store/analyticsTheme'

import { putLocalHistory } from './history'
import { fetchAddress, fetchGoogleMaps } from './googleMaps'
import { fetchGrafoData } from './grafo'
import { listenerGerarDocumento, listenerSaveDocx } from './doc'
import { listenerGerarXlsx, listenerSaveXlsx } from './xlsx'
import { fetchGlobalStepName, startGlobalStep } from './globalStep'
import { listenerProgressStatus } from './progressStatus'
import { fetchRelateds } from './relateds'
import { handleErrorJuridico } from './juridico'

function * mySaga () {
  // EVENTOS CONSULTA DOCUMENTO
  yield takeLatest(GlobalStepTypes.TROCAR_DOCUMENTO, startGlobalStep)

  // LEITURA DO NOME DO TARGET
  yield takeLatest(DadosBasicosThemeTypes.SET_S3_PATH, fetchGlobalStepName)

  // LEITURA DO NOME DO TARGET PARA HISTORICO
  yield takeLatest(DadosBasicosThemeTypes.SET_S3_PATH, putLocalHistory)

  // RELATEDS
  yield takeLatest(DadosBasicosThemeTypes.SET_S3_PATH, fetchRelateds)

  // GOOGLE MAPS TARGET
  yield takeLatest(GoogleMapsTypes.SET_ADDRESS, fetchGoogleMaps)
  yield takeLatest(DadosBasicosThemeTypes.SET_ERROR, fetchAddress)
  yield takeLatest(DadosBasicosThemeTypes.SET_S3_PATH, fetchAddress)

  // EVENTOS DO GRAFO
  yield takeLatest(GrafoTypes.SET_SELECTED_NODES, fetchGrafoData)
  yield takeLatest(GrafoTypes.SET_SELECTED_NODES, fetchGrafoData)

  // EVENTO PROGRESS STATUS
  yield takeLatest(GlobalStepTypes.FINISHED, listenerProgressStatus)
  yield takeLatest(GlobalStepTypes.SET_ERROR, listenerProgressStatus)

  yield takeLatest(
    MatchGrupoSocietarioThemeTypes.SET_S3_PATH,
    listenerProgressStatus
  )
  yield takeLatest(
    MatchGrupoSocietarioThemeTypes.SET_ERROR,
    listenerProgressStatus
  )
  yield takeLatest(GrafoTypes.SET_LINK, listenerProgressStatus)
  yield takeLatest(GrafoTypes.SET_ERROR_LINK, listenerProgressStatus)

  yield takeLatest(GoogleMapsTypes.SET_IMAGES, listenerProgressStatus)
  yield takeLatest(GoogleMapsTypes.SET_ERROR_IMAGES, listenerProgressStatus)
  yield takeLatest(MatchesTypes.SET_DATA, listenerProgressStatus)
  yield takeLatest(MatchesTypes.SET_ERROR, listenerProgressStatus)

  const allAnalyticsTypes = Object.values(AnalyticsThemeTypes).filter(
    event => event !== AnalyticsThemeTypes.RESET
  )
  for (let i = 0; i < allAnalyticsTypes.length; i++) {
    yield takeLatest(allAnalyticsTypes[i], listenerProgressStatus)
  }

  yield takeLatest(
    BandeiraAmarelaThemeTypes.SET_S3_PATH,
    listenerProgressStatus
  )
  yield takeLatest(BandeiraAmarelaThemeTypes.SET_ERROR, listenerProgressStatus)
  yield takeLatest(JiveThemeTypes.SET_S3_PATH, listenerProgressStatus)
  yield takeLatest(JiveThemeTypes.SET_ERROR, listenerProgressStatus)
  yield takeLatest(JiveThemeTypes.SET_S3_PATH_BOTS, listenerProgressStatus)
  yield takeLatest(JiveThemeTypes.SET_ERROR_BOTS, listenerProgressStatus)
  yield takeLatest(PatrimonioThemeTypes.SET_S3_PATH, listenerProgressStatus)
  yield takeLatest(PatrimonioThemeTypes.SET_ERROR, listenerProgressStatus)
  yield takeLatest(DadosBasicosThemeTypes.SET_S3_PATH, listenerProgressStatus)
  yield takeLatest(DadosBasicosThemeTypes.SET_ERROR, listenerProgressStatus)
  yield takeLatest(OperacionaisThemeTypes.SET_S3_PATH, listenerProgressStatus)
  yield takeLatest(OperacionaisThemeTypes.SET_ERROR, listenerProgressStatus)
  yield takeLatest(OffshoreThemeTypes.SET_S3_PATH, listenerProgressStatus)
  yield takeLatest(OffshoreThemeTypes.SET_ERROR, listenerProgressStatus)
  yield takeLatest(EndividamentoThemeTypes.SET_S3_PATH, listenerProgressStatus)
  yield takeLatest(EndividamentoThemeTypes.SET_ERROR, listenerProgressStatus)
  yield takeLatest(JuridicoThemeTypes.FINISHED, listenerProgressStatus)
  yield takeLatest(
    DiscreditingMediaThemeTypes.SET_ERROR,
    listenerProgressStatus
  )
  yield takeLatest(
    DiscreditingMediaThemeTypes.SET_S3_PATH,
    listenerProgressStatus
  )

  // HANDLE ERROR
  yield takeLatest(JuridicoThemeTypes.ADD_RELATED_STATUS, handleErrorJuridico)

  // GERAR DOCUMENTO DOCX
  yield takeLatest(DocTypes.GERAR_DOCUMENTO, listenerGerarDocumento)
  yield takeLatest(DocTypes.SAVE_DOCX, listenerSaveDocx)

  // GERAR DOCUMENTO XLSX
  yield takeLatest(XlsxTypes.GERAR_DOCUMENTO, listenerGerarXlsx)
  yield takeLatest(XlsxTypes.SAVE_XLSX, listenerSaveXlsx)
}

export default mySaga

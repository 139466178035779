import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'

import { selectMinData } from '../../../redux/selectors/dadosBasicos'

import { fetchPatrimonioAnalyticsFromS3 } from '../../../lib/s3Theme'

import { formatDecimal, isPending } from '../../../utils'

import { JiveWindow, Loading } from '../../../components'
import { Box, ListBox, PercentageListBox, TotalBox } from './Boxes'

const INITIAL_DATA_STATE = {
  patrimonio: {
    sncr: {
      states: [],
      total: 0,
      totalHectares: 0
    },
    aeronaves: {
      total: 0,
      categories: []
    },
    status: {
      ibama: 'SKIPPED',
      sigef: 'SKIPPED',
      sncr: 'PENDING',
      aeronaves: 'PENDING'
    }
  },
  jive: {
    imoveis: {
      total: 0,
      states: []
    },
    imoveisRurais: {
      total: 0,
      totalHectares: 0,
      states: []
    },
    ambiental: {
      total: 0,
      states: []
    },
    status: {
      imoveis: 'PENDING',
      imoveisRurais: 'PENDING',
      ambiental: 'PENDING'
    }
  }
}

export default function PatrimonioQuantitativos () {
  const { targetDocument } = useSelector(selectMinData)
  const [patrimonioTheme, jiveTheme] = useSelector(state => [
    state.analyticsTheme.patrimonio,
    state.analyticsTheme.jive
  ])
  const name = 'quantitativos.patrimonio'

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({
    data: INITIAL_DATA_STATE,
    loaded: false,
    error: false
  })

  useEffect(() => {
    const pending = isPending(patrimonioTheme) && isPending(jiveTheme)
    if (pending) {
      return
    }

    setLoading(true)

    fetchPatrimonioAnalyticsFromS3({
      payloadPatrimonio: patrimonioTheme,
      payloadJive: jiveTheme
    })
      .then(patrimonio => {
        setData(patrimonio)
      })
      .catch(error => {
        toast.error(error.message)
        console.error(error)
        setData({
          data: {
            ...INITIAL_DATA_STATE,
            patrimonio: {
              ...INITIAL_DATA_STATE.patrimonio,
              status: {
                ibama: 'SKIPPED',
                sigef: 'SKIPPED',
                sncr: 'FAILED',
                aeronaves: 'FAILED'
              }
            },
            jive: {
              ...INITIAL_DATA_STATE.jive,
              status: {
                imoveis: 'FAILED',
                imoveisRurais: 'FAILED',
                ambiental: 'FAILED'
              }
            }
          },
          loaded: false,
          error: true
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }, [patrimonioTheme, jiveTheme])

  const isStepPending =
    targetDocument && isPending(patrimonioTheme) && isPending(jiveTheme)

  if (loading || isStepPending) {
    return <Loading />
  }

  return (
    <JiveWindow title='Patrimônio' name={name} showNote defaultOpen>
      <div className='container-fluid gap-2'>
        <div className='row'>
          <div className='col-6'>
            <div className='row'>
              <ImoveisRuraisBox
                error={data.error}
                data={data.data.patrimonio.sncr}
                status={data.data.patrimonio.status?.sncr}
              />
              <AeronavesBox
                error={data.error}
                data={data.data.patrimonio.aeronaves}
                status={data.data.patrimonio.status?.aeronaves}
              />
            </div>
          </div>
          <div className='col-6'>
            <div className='row'>
              <ImoveisUrbanosJiveBox
                error={data.error}
                data={data.data.jive.imoveis}
                status={data.data.jive.status?.imoveis}
              />
              <ImoveisJiveAmbientalBox
                error={data.error}
                data={data.data.jive.ambiental}
                status={data.data.jive.status?.ambiental}
              />
            </div>
          </div>
        </div>
      </div>
    </JiveWindow>
  )
}

const ImoveisRuraisBox = ({ error, data, status }) => {
  const title = 'Imóveis Rurais'

  if (error || status === 'FAILED' || !status) {
    return (
      <Box title={title}>
        <div className='row'>
          <div>Erro no carregamento dos dados</div>
        </div>
      </Box>
    )
  }

  if (status === 'SKIPPED') {
    return (
      <Box title={title}>
        <div className='row'>
          <div>Dados não carregados para este tema</div>
        </div>
      </Box>
    )
  }

  const { total, totalHectares, states } = data

  const mappedStates = states
    .sort((a, b) => b.percentage - a.percentage)
    .map(item => ({
      name: item.state,
      total: item.total,
      value: `${formatDecimal(item.totalHectares, null, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
      })} ha`,
      percentage: item.percentage
    }))

  const formattedTotalHectares = formatDecimal(totalHectares, null, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  })
  const formattedTotal = formatDecimal(total, null, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  })

  return (
    <Box title={title}>
      <div className='row pl-3 d-flex flex-row gap-3'>
        <TotalBox value={formattedTotal} className='' />
        <TotalBox
          label='Total Hectares'
          value={formattedTotalHectares}
          className=''
        />
      </div>
      <div className='row'>
        <PercentageListBox showTotal items={mappedStates} />
      </div>
      <div className='row'>
        <ListBox items={mappedStates} />
      </div>
    </Box>
  )
}

const AeronavesBox = ({ error, data, status }) => {
  const title = 'Aeronaves'

  if (error || status === 'FAILED' || !status) {
    return (
      <Box title={title}>
        <div className='row'>
          <div>Erro no carregamento dos dados</div>
        </div>
      </Box>
    )
  }

  if (status === 'SKIPPED') {
    return (
      <Box title={title}>
        <div className='row'>
          <div>Dados não carregados para este tema</div>
        </div>
      </Box>
    )
  }

  const { total, categories } = data
  const mappedCategories = categories
    .sort((a, b) => b.percentage - a.percentage)
    .map(item => ({
      name: item.category,
      total: item.total,
      percentage: item.percentage
    }))

  const formattedTotal = formatDecimal(total, null, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  })

  return (
    <Box title={title}>
      <div className='row'>
        <TotalBox value={formattedTotal} />
      </div>
      <div className='row'>
        <PercentageListBox items={mappedCategories} />
      </div>
    </Box>
  )
}

const ImoveisUrbanosJiveBox = ({ error, data, status }) => {
  const title = 'Imóveis Urbanos Jive'

  if (error || status === 'FAILED' || !status) {
    return (
      <Box title={title}>
        <div className='row'>
          <div>Erro no carregamento dos dados</div>
        </div>
      </Box>
    )
  }

  if (status === 'SKIPPED') {
    return (
      <Box title={title}>
        <div className='row'>
          <div>Dados não carregados para este tema</div>
        </div>
      </Box>
    )
  }

  const { total, states } = data
  const mappedStates = states
    .sort((a, b) => b.percentage - a.percentage)
    .map(item => ({
      name: item.state,
      total: item.total,
      percentage: item.percentage
    }))

  const formattedTotal = formatDecimal(total, null, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  })

  return (
    <Box title={title}>
      <div className='row pl-3 d-flex flex-row gap-3'>
        <TotalBox value={formattedTotal} className='' />
      </div>
      <div className='row'>
        <PercentageListBox showTotal items={mappedStates} />
      </div>
    </Box>
  )
}

const ImoveisJiveAmbientalBox = ({ error, data, status }) => {
  const title = 'Imóveis Jive Ambiental'

  if (error || status === 'FAILED' || !status) {
    return (
      <Box title={title}>
        <div className='row'>
          <div>Erro no carregamento dos dados</div>
        </div>
      </Box>
    )
  }

  if (status === 'SKIPPED') {
    return (
      <Box title={title}>
        <div className='row'>
          <div>Dados não carregados para este tema</div>
        </div>
      </Box>
    )
  }

  const { total } = data

  const formattedTotal = formatDecimal(total, null, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  })

  const mappedItems = [
    {
      name: 'Valor Total',
      value: formattedTotal
    }
  ]

  return (
    <Box title={title}>
      <div className='row'>
        <ListBox items={mappedItems} />
      </div>
    </Box>
  )
}

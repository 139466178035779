import { hashObject } from '../utils'

const visNetworkOptions = {
  autoResize: true,
  height: '100%',
  width: '100%',
  layout: {
    improvedLayout: true,
    hierarchical: {
      enabled: true,
      levelSeparation: 300,
      nodeSpacing: 400,
      treeSpacing: 100,
      blockShifting: true,
      edgeMinimization: true,
      parentCentralization: true,
      direction: 'UD',
      sortMethod: 'hubsize'
    }
  },
  groups: {
    PESQUISADO: {
      shape: 'icon',
      icon: {
        face: "'Font Awesome 5 Free'",
        code: '\uf005',
        size: 120,
        color: 'purple'
      }
    },
    ESTRANGEIRO: {
      shape: 'icon',
      icon: {
        face: "'Font Awesome 5 Free'",
        code: '\uf2c1',
        size: 75,
        color: 'moccasin'
      }
    },
    'PESSOA FÍSICA': {
      shape: 'icon',
      icon: {
        face: "'Font Awesome 5 Free'",
        code: '\uf2c1',
        size: 75,
        color: 'yellow'
      }
    },
    'PESSOA FÍSICA RELACIONADA': {
      shape: 'icon',
      icon: {
        face: "'Font Awesome 5 Free'",
        code: '\uf2c1',
        size: 75,
        color: 'fuchsia'
      }
    },
    NULA: {
      shape: 'icon',
      icon: {
        face: "'Font Awesome 5 Free'",
        code: '\uf1ad',
        size: 75,
        color: 'black'
      }
    },
    ATIVA: {
      shape: 'icon',
      icon: {
        face: "'Font Awesome 5 Free'",
        code: '\uf1ad',
        size: 75,
        color: '#00b07d'
      }
    },
    SUSPENSA: {
      shape: 'icon',
      icon: {
        face: "'Font Awesome 5 Free'",
        code: '\uf1ad',
        size: 75,
        color: 'blue'
      }
    },
    INAPTA: {
      shape: 'icon',
      icon: {
        face: "'Font Awesome 5 Free'",
        code: '\uf1ad',
        size: 75,
        color: 'red'
      }
    },
    BAIXADA: {
      shape: 'icon',
      icon: {
        face: "'Font Awesome 5 Free'",
        code: '\uf1ad',
        size: 75,
        color: '#00000050'
      }
    },
    FIP: {
      shape: 'icon',
      icon: {
        face: "'Font Awesome 5 Free'",
        code: '\uf1ad',
        size: 75,
        color: 'brown'
      }
    },
    GESTOR: {
      shape: 'icon',
      icon: {
        face: "'Font Awesome 5 Free'",
        code: '\uf1ad',
        size: 75,
        color: 'orange'
      }
    }
  },
  physics: {
    enabled: false,
    maxVelocity: 0,
    minVelocity: 0,
    stabilization: {
      enabled: false,
      iterations: 1
    }
  },
  edges: {
    width: 5,
    selectionWidth: 1,
    color: {
      color: '#00000020',
      highlight: '#9aca3c'
    }
  },
  nodes: {
    font: {
      color: '#343434',
      size: 14,
      face: 'arial',
      background: 'none',
      strokeWidth: 0,
      strokeColor: '#ffffff',
      align: 'center'
    }
  }
}

export class GraphNetwork {
  constructor (attrs) {
    this.graph = {}
    this.graph = { ...this.graph, ...attrs }
    this.nodes = {}
    this.edges = {}
  }

  nextNodeId () {
    return Object.keys(this.nodes).length.toString()
  }

  nextEdgeId () {
    return Object.keys(this.edges).length.toString()
  }

  hashNode (node) {
    return hashObject(
      Array.isArray(node) ? JSON.stringify(node) : node
    ).toString()
  }

  addNode (node, attrs, skipRegisteredNodes = false) {
    const hash = this.hashNode(node)
    if (!Object.prototype.hasOwnProperty.call(this.nodes, hash)) {
      this.edges[hash] = {}
      this.nodes[hash] = {}
      this.nodes[hash].id = this.nextNodeId()
      this.nodes[hash].value = node
      this.nodes[hash].attributes = {}
      this.nodes[hash].attributes = { ...this.nodes[hash].attributes, ...attrs }
    } else {
      if (skipRegisteredNodes === false) {
        throw new Error('Node already registered in graph.')
      }
    }
  }

  addEdge (sourceNode, targetNode, attrs) {
    const hashSource = this.hashNode(sourceNode)
    const hashTarget = this.hashNode(targetNode)

    if (!Object.prototype.hasOwnProperty.call(this.nodes, hashSource)) {
      throw new Error('Source node not registered in graph.')
    }
    if (!Object.prototype.hasOwnProperty.call(this.nodes, hashTarget)) {
      throw new Error('Target node not registered in graph.')
    }

    let dataDict = this.edges[hashSource][hashTarget] || {}
    dataDict = { ...dataDict, ...attrs }
    this.edges[hashSource][hashTarget] = dataDict
  }

  toVisNetwork () {
    const nodesView = []
    for (const nodeAttrs of Object.values(this.nodes)) {
      nodesView.push({
        id: nodeAttrs.id,
        label: nodeAttrs.attributes.label,
        group: nodeAttrs.attributes.group,
        metadata: nodeAttrs.attributes.metadata
      })
    }

    const edgesView = []
    for (const sourceNodeHash of Object.keys(this.edges)) {
      const neighborhoodNodes = this.edges[sourceNodeHash]
      const _from = this.nodes[sourceNodeHash].id
      for (const targetNodeHash of Object.keys(neighborhoodNodes)) {
        const _id = (edgesView.length + 1).toString()
        const _to = this.nodes[targetNodeHash].id
        edgesView.push({
          id: _id,
          from: _from,
          to: _to,
          label: neighborhoodNodes[targetNodeHash].label,
          metadata: neighborhoodNodes[targetNodeHash].metadata
        })
      }
    }

    return {
      nodes: nodesView,
      edges: edgesView,
      options: visNetworkOptions
    }
  }

  generateVisGraph (graphMetadata, includeFips = false) {
    for (const item of graphMetadata) {
      if (item.isFip && includeFips === false) {
        continue
      }

      const missingDocument = item.document.length > 14
      this.addNode(
        [item.document, item.name],
        {
          label:
            item.document.length < 15
              ? `${item.name} (${item.document})`
              : `${item.name} (DOCUMENTO INDISPONÍVEL)`,
          group: item.group,
          level: item.level,
          metadata: {
            cpfCnpj: item.document,
            missingDocument: missingDocument
          }
        },
        true
      )

      if (item.documentConnectedPerson) {
        this.addEdge(
          [item.document, item.name],
          [item.documentConnectedPerson, item.nameConnectedPerson],
          { label: item.relationship, metadata: {} }
        )
      }
    }
    return this.toVisNetwork()
  }
}

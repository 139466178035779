import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { Content, JiveWindow, RegistraisCard } from './../components'

import { selectMinData } from '../redux/selectors/dadosBasicos'
import { selectSearchOptions } from '../redux/selectors/searchOptions'

import {
  formatDocument,
  hashObject,
  isPending,
  useJuridicoNotification,
  useTokenValidation
} from '../utils'
import { fetchRegistraisJiveFromS3 } from '../lib/s3Theme'
import {
  JURIDICO_NOTIFICATION_INTERVAL,
  TOKEN_VALIDATION_INTERVAL
} from '../config/consts'

const Registrais = () => {
  useTokenValidation(TOKEN_VALIDATION_INTERVAL)
  useJuridicoNotification(JURIDICO_NOTIFICATION_INTERVAL)

  const { targetName } = useSelector(selectMinData)
  const jiveTheme = useSelector(state => state.jiveTheme)

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({
    registrais: { data: [], loaded: false, error: false }
  })

  useEffect(() => {
    if (!jiveTheme.certidoes.s3Path) {
      return
    }

    setLoading(true)
    fetchRegistraisJiveFromS3({ payload: jiveTheme.certidoes })
      .then(registrais => {
        setData(registrais)
      })
      .catch(error => {
        toast.error(error.message)
        console.error(error)
        setData({
          registrais: { data: [], loaded: false, error: true }
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }, [jiveTheme])

  return (
    <Content
      title={`Registrais  ${targetName ? `- ${targetName}` : ''}`}
      subtitle='Busca por certidões no Service Now JIVE.'
    >
      <CardsRegistrais registrais={data.registrais} loading={loading} />
    </Content>
  )
}

const CardsRegistrais = ({ registrais, loading }) => {
  const { targetName, targetDocument, isCPF } = useSelector(selectMinData)
  const { pages: enabledPages } = useSelector(selectSearchOptions)

  if (!targetDocument) {
    return null
  }

  if (!enabledPages.registrais) {
    return (
      <div className='d-flex w-100 h-100 flex-1 justify-content-center align-items-center'>
        <p className='h3'>Esta aba está desabilitada!</p>
      </div>
    )
  }

  if (isPending(registrais) || loading) {
    return (
      <div className='d-flex w-100 h-100 flex-1 justify-content-center align-items-center'>
        <div
          className='spinner-border text-primary'
          style={{ width: '3rem', height: '3rem' }}
          role='status'
        >
          <span className='sr-only'>Loading...</span>
        </div>
      </div>
    )
  }

  if (registrais.error) {
    return (
      <JiveWindow
        title={`Registrais  ${targetName ? `- ${targetName}` : ''}`}
        subtitle={`${
          targetDocument ? (isCPF ? 'CPF:' : 'CNPJ:') : ''
        } ${formatDocument(targetDocument)}`}
        name='registrais'
        defaultOpen
      >
        <div>Ops! Aconteceu um erro ao carregar os dados dos registrais</div>
      </JiveWindow>
    )
  }

  if (registrais.loaded && (!registrais.data || registrais.data.length === 0)) {
    return (
      <JiveWindow
        name='registrais'
        title={`Registrais  ${targetName ? `- ${targetName}` : ''}`}
        subtitle={`${
          targetDocument ? (isCPF ? 'CPF:' : 'CNPJ:') : ''
        } ${formatDocument(targetDocument)}`}
        defaultOpen
      />
    )
  }

  return (
    <div className='row'>
      {(registrais?.data || []).map((certidao, index) => {
        const id = hashObject({ certidao, index })
        return (
          <div className='col-12 col-md-6 col-lg-4' key={id}>
            <RegistraisCard certidao={certidao} hash={id} />
          </div>
        )
      })}
    </div>
  )
}

export default Registrais

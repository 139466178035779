import axios from 'axios'
import shajs from 'sha.js'

import { SQS_BASE_URL } from '../../config/env'
import { getScope, getUserId, toCamelCase } from '../../utils'

export const createQueue = async ({ documento }) => {
  const userId = getUserId()
  const scope = getScope()

  const now = new Date().getTime()

  const queueNameRaw = `${documento}-${scope}-${userId}-${now}`
  const queueNameHash = shajs('sha256')
    .update(queueNameRaw)
    .digest('hex')

  const url = `${SQS_BASE_URL}/create_queue`

  const { data } = await axios.post(url, {
    queue_name: `discovery-${queueNameHash}.fifo`
  })

  const formattedData = toCamelCase(data)
  return formattedData
}

import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { CircularProgressbar } from 'react-circular-progressbar'
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from 'react-icons/ai'

import API from '../services/api'

import 'react-circular-progressbar/dist/styles.css'

const DBJus = props => {
  const {
    data,
    showPartes,
    showScores,
    onClick,
    processosVinculados,
    mode
  } = props

  const [open, setOpen] = useState(false)
  const [vinculados, setVinculados] = useState({})

  return (
    <div
      className={mode ? 'dbjus-card ' : 'dbjus-card btn-card-juridico'}
      style={
        mode
          ? { borderTop: 'none', marginTop: '0' }
          : { borderTop: '2px solid #00b07d' }
      }
    >
      {data && data.classes && (
        <div>
          <div className='dbjus-classes mr-2'>{data && data.nup}</div>
          <div className='dbjus-classes'>{data.classes}</div>
        </div>
      )}

      <div className='row justify-content-start no-gutters'>
        {showScores && (
          <div className='score'>
            <div className='label'>Score </div>
            <div className='value'>
              <CircularProgressbar
                maxValue={1}
                value={parseFloat(data?.score || '0').toFixed(3)}
                text={
                  data?.score !== null
                    ? `${parseFloat((data?.score || 0) * 100).toFixed(0)}%`
                    : '?'
                }
              />
            </div>
          </div>
        )}

        {data && data.classes && (
          <div className='col-auto dbjus-block'>
            <div className='label'>assuntos</div>
            <div className='value valueH1'>{data.assuntos}</div>
          </div>
        )}

        <div className='col-12' />

        <div className='col-auto dbjus-block'>
          <div className='label'>diário</div>
          <div className='value'>{data && data.diario}</div>
        </div>

        <div className='col-auto dbjus-block'>
          <div className='label'>estado</div>
          <div className='value'>{data && data.estado}</div>
        </div>

        <div className='col-auto dbjus-block'>
          <div className='label'>justiça</div>
          <div className='value'>{data && data.justica}</div>
        </div>

        <div className='col-auto dbjus-block'>
          <div className='label'>valor</div>
          <div className='value'>{(data && data.valor) || '-'}</div>
        </div>

        {data.nupsVinculados && (
          <div className='col-auto dbjus-block'>
            <div className='label'>processo(s) vinculado(s)</div>
            {data?.nupsVinculados.map((nup, index) => (
              <div className='value' key={`${nup}-${index}`}>
                {nup}
              </div>
            ))}
          </div>
        )}

        {showPartes &&
          Object.entries(
            (data.partes || []).reduce((obj, { tipo, nome }) => {
              tipo = tipo.replace(/_/g, ' ')
              if (obj[tipo]) {
                obj[tipo].push(nome)
              } else {
                obj[tipo] = [nome]
              }

              return obj
            }, {})
          ).map(([tipo, nomes]) => (
            <div className='col-auto dbjus-block' key={tipo}>
              <div className='label'>{tipo}</div>
              <div className='value valueH2' style={{ paddingTop: '5px' }}>
                {nomes.map((nome, index) => (
                  <span key={(nome + index).toString()} className='dbjus-parte'>
                    {nome}
                  </span>
                ))}
              </div>
            </div>
          ))}
        <div className='col-12'>
          <div className='mt-3'>
            <button
              type='button'
              className='btn btn-primary'
              onClick={mode ? null : () => onClick(data)}
            >
              Ver detalhes
            </button>
          </div>
        </div>
        {processosVinculados.length > 0 && (
          <>
            <div className='col-12'>
              <div
                className='mt-3'
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  const pendingProcessosVinculados = processosVinculados
                    .map(nup => nup.replace(/\D/g, ''))
                    .filter(nup => !Object.keys(vinculados).includes(nup))

                  if (!open && pendingProcessosVinculados.length > 0) {
                    toast('Carregando processos vinculados...', {
                      toastId: 'toast-vinculados'
                    })

                    API.juridico
                      .consultarVinculados({
                        nups: pendingProcessosVinculados.join(',')
                      })
                      .then(items => {
                        const grupedNups = pendingProcessosVinculados.reduce(
                          (obj, nup) => {
                            obj[nup] = items.find(
                              item => item.nup.replace(/\D/g, '') === nup
                            )
                            return obj
                          },
                          {}
                        )
                        setVinculados(prev => ({ ...prev, ...grupedNups }))
                        setOpen(prev => !prev)
                      })
                      .catch(err => console.error(err))
                      .finally(() => {
                        toast.dismiss('toast-vinculados')
                      })
                  } else {
                    setOpen(prev => !prev)
                  }
                }}
              >
                {open ? (
                  <>
                    <AiOutlineMinusCircle /> Ocultar processos vinculados
                  </>
                ) : (
                  <>
                    <AiOutlinePlusCircle /> Ver processos vinculados
                  </>
                )}
              </div>
            </div>
            {open && (
              <>
                <div className='col-1' />
                <div className='col-11'>
                  {processosVinculados.map((nup, key) => {
                    const cleanedNup = nup.replace(/\D/g, '')
                    const processo = vinculados[cleanedNup] || {}

                    return (
                      <DBJus
                        data={processo}
                        key={`sub-processo-${nup}-${key}`}
                        showPartes={showPartes}
                        showScores={showScores}
                        processosVinculados={[]}
                        onClick={onClick}
                      />
                    )
                  })}
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default DBJus

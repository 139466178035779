import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity'
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity'
import {
  S3Client as S3ClientAWS,
  GetObjectCommand,
  PutObjectCommand
} from '@aws-sdk/client-s3'

const S3Client = ({ region, identityPoolId, credentials = undefined }) => {
  const internalRegion = region
  const internalIdentityPoolId = identityPoolId

  const internalCredentials =
    credentials ||
    fromCognitoIdentityPool({
      client: new CognitoIdentityClient({ region: internalRegion }),
      identityPoolId: internalIdentityPoolId
    })

  const client = new S3ClientAWS({
    region: internalRegion,
    credentials: internalCredentials
  })

  const readFile = async ({ bucket, key, useStream }) => {
    const command = new GetObjectCommand({
      Bucket: bucket,
      Key: key
    })

    const data = await client.send(command)

    if (
      ['application/octet-stream', 'binary/octet-stream'].includes(
        data.ContentType
      )
    ) {
      if (useStream) {
        return data.Body
      }

      return streamToBuffer(data.Body)
    }

    const bodyContent = await streamToString(data.Body)

    try {
      if (data.ContentType === 'application/json') {
        return JSON.parse(bodyContent)
      }

      return bodyContent
    } catch (err) {
      return bodyContent
    }
  }

  const writeFile = async ({ bucket, key, data, contentType }) => {
    const body =
      contentType === 'application/json' ? JSON.stringify(data) : data

    const command = new PutObjectCommand({
      Bucket: bucket,
      Key: key,
      Body: body,
      ContentType: contentType
    })

    return await client.send(command)
  }

  return {
    _client: client,
    readFile,
    writeFile
  }
}

async function streamToBuffer (stream) {
  return new Promise((resolve, reject) => {
    if (stream instanceof ReadableStream === false) {
      reject(
        new Error(
          'Expected stream to be instance of ReadableStream, but got ' +
            typeof stream
        )
      )
    }
    const array = []

    const reader = stream.getReader()
    const processRead = ({ done, value }) => {
      if (done) {
        resolve(Buffer.from(array))
        return
      }

      value.forEach(v => array.push(v))

      // Not done, keep reading
      reader.read().then(processRead)
    }

    // start read
    reader.read().then(processRead)
  })
}

const streamToString = stream => {
  return new Promise((resolve, reject) => {
    if (stream instanceof ReadableStream === false) {
      reject(
        new Error(
          'Expected stream to be instance of ReadableStream, but got ' +
            typeof stream
        )
      )
    }
    let text = ''
    const decoder = new TextDecoder('utf-8')

    const reader = stream.getReader()
    const processRead = ({ done, value }) => {
      if (done) {
        resolve(text)
        return
      }

      text += decoder.decode(value)

      // Not done, keep reading
      reader.read().then(processRead)
    }

    // start read
    reader.read().then(processRead)
  })
}

export default S3Client

// Action Types
export const Types = {
  RESET: 'PROGRESS_STATUS/RESET',
  SET_STATUS: 'PROGRESS_STATUS/SET_STATUS',
  OPEN_POPUP: 'PROGRESS_STATUS/OPEN_POPUP',
  CLOSE_POPUP: 'PROGRESS_STATUS/CLOSE_POPUP'
}

// Reducer
export const initialState = {
  popupOpened: false,
  dataStatus: {
    globalStep: null,
    googleMaps: null,
    analyticsTheme: null,

    matchGrupoSocietarioTheme: null,
    certidoesJiveTheme: null,
    devedoresJiveTheme: null,
    jiveBotsJiveTheme: null,
    jiveApiJiveTheme: null,
    aeronavesPatrimonioTheme: null,
    sncrPatrimonioTheme: null,
    inpiPatrimonioTheme: null,
    dadosBasicosTheme: null,
    operacionaisTheme: null,
    dataLeaksOffshore: null,
    cndtEndividamentoTheme: null,
    pgfnEndividamentoTheme: null,
    protestoEndividamentoTheme: null,
    juridicoTheme: null,
    pepBandeiraAmarela: null,
    acordosLenienciaBandeiraAmarela: null,
    autoInfracaoBandeiraAmarela: null,
    cepimBandeiraAmarela: null,
    ofacBandeiraAmarela: null,
    termoApreensaoBandeiraAmarela: null,
    termoEmbargoBandeiraAmarela: null,
    termoSuspensaoBandeiraAmarela: null,
    cadastroExpulsoesBandeiraAmarela: null,
    cnepBandeiraAmarela: null,
    ceisBandeiraAmarela: null,
    ceafBandeiraAmarela: null
  }
}

export default function reducer (state = initialState, action) {
  switch (action.type) {
    case Types.SET_STATUS:
      return {
        ...state,
        dataStatus: {
          ...state.dataStatus,
          ...action.payload
        }
      }
    case Types.OPEN_POPUP:
      return {
        ...state,
        popupOpened: true
      }
    case Types.CLOSE_POPUP:
      return {
        ...state,
        popupOpened: false
      }
    case Types.RESET:
      return {
        ...initialState
      }
    default:
      return state
  }
}

// Action Creators
export function resetProgressStatus () {
  return {
    type: Types.RESET
  }
}

export function setStatusProgressStatus (data) {
  return {
    type: Types.SET_STATUS,
    payload: data
  }
}

export function openProgressStatus () {
  return {
    type: Types.OPEN_POPUP
  }
}

export function closeProgressStatus () {
  return {
    type: Types.CLOSE_POPUP
  }
}

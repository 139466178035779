/* eslint-disable */

const unidecode = require('unidecode')
const levenshtein = require('js-levenshtein')

// Produz um mapeamento da quantidade de vezes que um elemento aparece
// em array, por exemplo:
//
// var a = ["Pedro", "Pedro", "Pedro", "Triste"]
// var result = {"Pedro": 3, "Triste": 1}
//
// Autor: Stack Overflow 2020
function count_array_into_json (array) {
  var count = {}
  for (var i = 0; i < array.length; i++) {
    var num = array[i]
    count[num] = count[num] ? count[num] + 1 : 1
  }
  return count
}

// Soma os objetos de contagem sem substituir as keys. Exemplo:
//
// var count1 = {"Pedro": 3, "Triste": 1}
// var count2 = {"Pedro": 1, "Triste": 2}
// var result = {"Pedro": 4, "Triste": 3}
//
// Autor: Stack Overflow 2020
function combine_count_object (count1, count2) {
  var combined = [count1, count2].reduce((a, obj) => {
    Object.entries(obj).forEach(([key, val]) => {
      a[key] = (a[key] || 0) + val
    })
    return a
  })
  return combined
}

// Calcula as estatística do dataset jurídico
//
// Key: justiça
// var result = {
//  'JUSTIÇA DO TRABALHO': 275,
//  'JUSTIÇA ESTADUAL': 34,
//  'JUSTIÇA FEDERAL': 9
// }
//
// Autor: Gabriel, 2020
function estatisticas_de_filtro (found, not_found) {
  if (found) {
    var estado_found = found.map(function (i) {
      return i.estado
    })
    var justica_found = found.map(function (i) {
      return i.justica
    })
    var assuntos_found = found
      .map(function (i) {
        return i.assuntos.trim().split(';')
      })
      .flat()
    var partes_found = found
      .map(function (i) {
        return i.partes.map(item => item.nome)
      })
      .flat()
    assuntos_found = assuntos_found.map(function (i) {
      return i.trim()
    })
    partes_found = partes_found.map(function (i) {
      return i.trim()
    })

    var EstadoCountFound = count_array_into_json(estado_found)
    var JusticaCountFound = count_array_into_json(justica_found)
    var AssuntosCountFound = count_array_into_json(assuntos_found)
    var PartesCountFound = count_array_into_json(partes_found)
  } else {
    var EstadoCountFound = {}
    var JusticaCountFound = {}
    var AssuntosCountFound = {}
    var PartesCountFound = {}
  }

  if (not_found) {
    var estado_not_found = not_found.map(function (i) {
      return i.estado
    })
    var justica_not_found = not_found.map(function (i) {
      return i.justica
    })
    var assuntos_not_found = not_found
      .map(function (i) {
        return i.assuntos.trim().split(';')
      })
      .flat()
    var partes_not_found = not_found
      .map(function (i) {
        return i.partes.map(item => item.nome)
      })
      .flat()
    assuntos_not_found = assuntos_not_found.map(function (i) {
      return i.trim()
    })
    partes_not_found = partes_not_found.map(function (i) {
      return i.trim()
    })

    var EstadoCountNotFound = count_array_into_json(estado_not_found)
    var JusticaCountNotFound = count_array_into_json(justica_not_found)
    var AssuntosCountNotFound = count_array_into_json(assuntos_not_found)
    var PartesCountNotFound = count_array_into_json(partes_not_found)
  } else {
    var EstadoCountNotFound = {}
    var JusticaCountNotFound = {}
    var AssuntosCountNotFound = {}
    var PartesCountNotFound = {}
  }

  var EstadoCount = combine_count_object(EstadoCountFound, EstadoCountNotFound)
  var JusticaCount = combine_count_object(
    JusticaCountFound,
    JusticaCountNotFound
  )
  var AssuntosCount = combine_count_object(
    AssuntosCountFound,
    AssuntosCountNotFound
  )
  var PartesCount = combine_count_object(PartesCountFound, PartesCountNotFound)

  return {
    estado: EstadoCount,
    justica: JusticaCount,
    assuntos: AssuntosCount,
    partes: PartesCount
  }
}

// Calcula se pelo menos uma substring de um string é similiar a palavra de somparação
//
// var to_split_input = "     protesto; protisto; prsotp; pedro"
// var compare = "protesto"
// var result = true
//
// Autor: Gabriel, 2020
// Autor: Pedro, 2020
// Autor: Fernando, 2020
function ComputeSimilarityLevenshtein (
  to_split_input,
  compare_word,
  thr = 0.2
) {
  compare_word = unidecode(compare_word.toUpperCase())
  var array =
    typeof to_split_input === 'string'
      ? to_split_input
          .trim()
          .toUpperCase()
          .split(';')
          .map(function (i) {
            return unidecode(i.trim())
          })
      : to_split_input.map(item => item.nome.toUpperCase().trim())
  var scores = array.map(function (i) {
    return levenshtein(i.toUpperCase(), compare_word) / i.length
  })
  var result = scores.some(function (i) {
    return i < thr
  })

  return result
}

// Faz os filtros de AND LÓGICO para os filtros selecionados
// na página de jurídico
//
// Autor: Gabriel, 2020
// Autor: Pedro, 2020
function filterAnd (keys, compare_words, found, not_found, relateds, scores) {
  if (keys && keys.length > 0) {
    for (var i in keys) {
      let Filtered = ComputeFilterAndBind(
        found,
        not_found,
        relateds,
        scores,
        compare_words[i],
        keys[i]
      )
      found = Filtered.dbjus.found
      not_found = Filtered.dbjus.not_found.probable
      relateds = Filtered.relateds
      scores = Filtered.scores
    }
  }
  return [found, not_found, relateds, scores]
}

// Dada a lista de filtros selecionadas e um elemento alterado
// define se o elemento alterado foi removido ou adicionado
// e devolve o novo array correspondente ao que deverá ser enviado para
// memória
//
// Autor: Pedro, 2020
function changeFilterItems (filterSelect, changedElement) {
  changedElement = changedElement[changedElement.length - 1]

  if (
    filterSelect.filter.length > 0 &&
    filterSelect.option.length > 0 &&
    filterSelect.type.length > 0 &&
    filterSelect.filter.includes(changedElement.value) &&
    filterSelect.option.includes(changedElement.label) &&
    filterSelect.type.includes(changedElement.type)
  ) {
    let _filter = filterSelect.filter.filter(function (elem) {
      return elem != changedElement.value
    })
    let _option = filterSelect.option.filter(function (elem) {
      return elem != changedElement.label
    })
    let _type = filterSelect.type.filter(function (elem) {
      return elem != changedElement.type
    })
    return [_filter, _option, _type]
  } else {
    let _filter = [...filterSelect.filter, changedElement.value]
    let _option = [...filterSelect.option, changedElement.label]
    let _type = [...filterSelect.type, changedElement.type]
    return [_filter, _option, _type]
  }
}

// Faz os filtros de OR LÓGICO para os filtros selecionados
// na página de jurídico
//
// Autor: Gabriel, 2020
// Autor: Pedro, 2020
function filterOr (keys, compare_words, found, not_found, relateds, scores) {
  if (keys && keys.length > 0) {
    var dummy_found = []
    var dummy_not_found = []

    for (var i in keys) {
      let filter_temp = filterAnd(
        [keys[i]],
        [compare_words[i]],
        found,
        not_found,
        relateds,
        scores
      )
      let temp_found = filter_temp[0]
      let temp_not_found = filter_temp[1]

      if (temp_found && temp_found.length > 0) {
        dummy_found = [...dummy_found, ...temp_found]
      }

      if (temp_not_found && temp_not_found.length > 0) {
        dummy_not_found = [...dummy_not_found, ...temp_not_found]
      }
    }
    found = dummy_found
    not_found = dummy_not_found
  }
  return [found, not_found, relateds, scores]
}

// Filtragem do dataset jurídico em uma determinada chave utilizando ComputeSimilarityLevenshtein
//
// Autor: Gabriel, 2020
// Autor: Pedro, 2020
// Autor: Fernando, 2020
function FilterJuridicoDataset (dataset, compare_word, key) {
  if (key == 'justica' || key == 'estado') {
    var filteredDataset = dataset.filter(a => a[key] == compare_word)
  } else {
    var filteredDataset = dataset.filter(a =>
      ComputeSimilarityLevenshtein(a[key], compare_word)
    )
  }

  return filteredDataset
}

// Filtragem do dataset jurídico found e not_found em uma determinada chave
// e reconstrói o dataset juridico original.
//
// Autor: Gabriel, 2020
// Autor: Pedro, 2020
// Autor: Fernando, 2020
function ComputeFilterAndBind (
  found,
  not_found,
  relateds,
  scores,
  compare_word,
  key
) {
  var structure = {
    dbjus: {
      found: null,
      not_found: {
        probable: null,
        improbable: null
      }
    },
    relateds: relateds
  }

  if (found) {
    var FilteredFound = FilterJuridicoDataset(found, compare_word, key)

    structure.dbjus.found = FilteredFound
  }

  if (not_found) {
    var FilteredNotFound = FilterJuridicoDataset(not_found, compare_word, key)

    structure.dbjus.not_found.probable = FilteredNotFound
  }

  return structure
}

export {
  count_array_into_json,
  combine_count_object,
  estatisticas_de_filtro,
  ComputeSimilarityLevenshtein,
  FilterJuridicoDataset,
  ComputeFilterAndBind,
  filterAnd,
  filterOr,
  changeFilterItems
}

import React, { useState } from 'react'

const RegistraisCardPartes = props => {
  const { cert } = props

  const [partes, setPartes] = useState(false)

  return (
    <div className='partesRoot'>
      <button
        className='btn btn-primary btn-sm col-auto btnPartes'
        onClick={() => setPartes(!partes)}
      >
        {partes ? 'Ocultar Partes' : 'Mostrar Partes'}
      </button>
      {cert.partes &&
        cert.partes.length > 0 &&
        partes &&
        cert.partes.map((parte, key) => (
          <div className='regCardPartesRoot' key={key}>
            <div className='parteItem'>
              <label>Documento</label>
              <div className='parteValue'>{parte.cpf_cnpj}</div>
            </div>

            <div className='parteItem'>
              <label>Nome</label>
              <div className='parteValue'>{parte.nome}</div>
            </div>

            <div className='parteItem'>
              <label>Tipo</label>
              <div className='parteValue'>{parte.tipo}</div>
            </div>

            <div className='parteItem'>
              <label>Papel</label>
              <div className='parteValue'>{parte.papel}</div>
            </div>
          </div>
        ))}
    </div>
  )
}

export default RegistraisCardPartes

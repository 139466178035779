// Action Types
export const Types = {
  SET_QUEUE_URL: 'GLOBAL_STEP/SET_QUEUE_URL',
  SET_METADATA: 'GLOBAL_STEP/SET_METADATA',
  SET_ERROR: 'GLOBAL_STEP/SET_ERROR',
  SET_LOADED: 'GLOBAL_STEP/SET_LOADED',
  SET_TARGET_DOCUMENT: 'GLOBAL_STEP/SET_TARGET_DOCUMENT',
  SET_TARGET_NAME: 'GLOBAL_STEP/SET_TARGET_NAME',
  SET_TARGET_CASE: 'GLOBAL_STEP/SET_TARGET_CASE',
  SET_TARGET_THEMES: 'GLOBAL_STEP/SET_TARGET_THEMES',
  TROCAR_DOCUMENTO: 'GLOBAL_STEP/TROCAR_DOCUMENTO',
  SET_SEARCH_OPTIONS: 'GLOBAL_STEP/SET_SEARCH_OPTIONS',
  FINISHED: 'GLOBAL_STEP/FINISHED',
  RESET: 'GLOBAL_STEP/RESET'
}

// Reducer
export const initialState = {
  queueUrl: undefined,
  options: {},
  themes: [],
  metadata: {
    executionArn: undefined,
    startedAt: undefined,
    finishedAt: undefined
  },
  loaded: false,
  error: false,
  targetDocument: undefined,
  targetCase: [],
  targetName: undefined
}

export default function reducer (state = initialState, action) {
  switch (action.type) {
    case Types.SET_QUEUE_URL:
      return {
        ...state,
        queueUrl: action.payload
      }
    case Types.SET_METADATA:
      return {
        ...state,
        metadata: {
          ...state.metadata,
          ...action.payload
        }
      }
    case Types.SET_SEARCH_OPTIONS:
      return {
        ...state,
        options: {
          ...state.options,
          ...action.payload
        }
      }
    case Types.SET_ERROR:
      return {
        ...state,
        error: action.payload,
        loaded: true
      }

    case Types.SET_LOADED:
      return {
        ...state,
        loaded: action.payload
      }

    case Types.SET_TARGET_DOCUMENT:
      return {
        ...state,
        targetDocument: action.payload
      }

    case Types.SET_TARGET_CASE:
      return {
        ...state,
        targetCase: action.payload
      }

    case Types.SET_TARGET_THEMES:
      return {
        ...state,
        themes: action.payload
      }

    case Types.SET_TARGET_NAME:
      return {
        ...state,
        targetName: action.payload
      }

    case Types.RESET:
      return { ...initialState }
    default:
      return state
  }
}

// Action Creators
export function setLoadedGlobalStep (data) {
  return {
    type: Types.SET_LOADED,
    payload: data
  }
}
export function finishGlobalStep () {
  return {
    type: Types.FINISHED
  }
}

export function setErrorGlobalStep (data) {
  return {
    type: Types.SET_ERROR,
    payload: data
  }
}

export function setQueueUrlGlobalStep (data) {
  return {
    type: Types.SET_QUEUE_URL,
    payload: data
  }
}

export function setMetadataGlobalStep (data) {
  return {
    type: Types.SET_METADATA,
    payload: data
  }
}

export function trocarDocumento ({ documento, casos, options, themes }) {
  return {
    type: Types.TROCAR_DOCUMENTO,
    payload: { documento, casos, options, themes }
  }
}

export function setTargetDocumentGlobalStep (data) {
  return {
    type: Types.SET_TARGET_DOCUMENT,
    payload: data
  }
}

export function setTargetCaseGlobalStep (data) {
  return {
    type: Types.SET_TARGET_CASE,
    payload: data
  }
}
export function setTargetThemesGlobalStep (data) {
  return {
    type: Types.SET_TARGET_THEMES,
    payload: data
  }
}

export function setTargetNameGlobalStep (data) {
  return {
    type: Types.SET_TARGET_NAME,
    payload: data
  }
}

export function setSearchOptionsGlobalStep (data) {
  return {
    type: Types.SET_SEARCH_OPTIONS,
    payload: data
  }
}

export function resetGlobalStep () {
  return {
    type: Types.RESET
  }
}

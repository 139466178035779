import React from 'react'

import * as styles from './style.module.scss'

const Container = ({ children }) => {
  const childrens = React.Children.toArray(children)
  return (
    <div className={`pl-3 container-fluid w-100 ${styles.container}`}>
      {childrens}
    </div>
  )
}

export default Container

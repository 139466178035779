import React from 'react'
import { FaUserCircle } from 'react-icons/fa'
import { useSelector } from 'react-redux'

import { hashObject, RESUME_TYPE_SOCIO } from '../../utils'

export default function RenderPF ({ selectedNode, dictKey }) {
  const values = useSelector(state => state.grafo.values)
  const { label } = selectedNode.node
  const data = values[dictKey]

  return (
    <div className='grafoDetailsRoot'>
      <div className='grafoDetailsHeader'>
        <div className='grafoDetailsIcon'>
          <FaUserCircle size={40} style={{ color: '#00b07d' }} />
        </div>
        <div className='grafoDetailsHeaderContent'>
          <h2 style={{ color: '#00b07d' }}>{label}</h2>
        </div>
      </div>

      <div className='grafoDetailsPFQtd'>
        Participa em
        <span>{data.quantidadeEmpresas}</span>
        empresas
      </div>
      <div className='grafoDetailsPFEmpresas'>
        <div className='grafoDetailsPF-title'>Empresas</div>
        {data.empresas.map((empresa, key) => {
          const id = hashObject({ empresa, key })
          return (
            <div key={id} className='empresasPF-details'>
              <div className='row justify-content-start col-12'>
                <div className='col-3 empresatipo'>
                  {RESUME_TYPE_SOCIO(empresa.qualificacaoSocio)}
                </div>
                <div className='col-9 empresaName'>{empresa.razaoSocial}</div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

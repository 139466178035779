import React from 'react'

import { useTokenValidation } from '../../utils'

import { TOKEN_VALIDATION_INTERVAL } from '../../config/consts'

const Pesquisa = () => {
  useTokenValidation(TOKEN_VALIDATION_INTERVAL)

  return <div />
}

export default Pesquisa

// Action Types
export const Types = {
  SET_PAYLOAD_GRUPO: 'devedoresJive/SET_PAYLOAD_GRUPO',

  ADD_DATA: 'devedoresJive/ADD_DATA',
  FINISHED: 'devedoresJive/FINISHED',
  ERROR_DATA: 'devedoresJive/ERROR_DATA',

  RESET: 'devedoresJive/RESET'
}

// Reducer
const INITIAL_STATE = {}

export default function reducer (state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.ADD_DATA:
      return {
        ...state,
        [action.payload.key]: {
          ...state[action.payload.key],
          data: action.payload.data,
          loaded: true,
          error: false
        }
      }
    case Types.ERROR_DATA:
      return {
        ...state,
        [action.payload.key]: {
          ...state[action.payload.key],
          error: action.payload.data
        }
      }

    case Types.RESET:
      return INITIAL_STATE
    default:
      return state
  }
}

export const adicionarDadosDevedoresJive = ({ key, data }) => ({
  type: Types.ADD_DATA,
  payload: {
    key,
    data
  }
})

export const errorDadosDevedoresJive = ({ key, data }) => ({
  type: Types.ERROR_DATA,
  payload: {
    key,
    data
  }
})

export const setPayloadDevedoresJive = data => ({
  type: Types.SET_PAYLOAD_GRUPO,
  payload: data
})

export const finishedDevedoresJive = () => ({
  type: Types.FINISHED
})

export const resetDevedoresJive = () => ({
  type: Types.RESET
})

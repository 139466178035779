import Swal from 'sweetalert2'
import { toast } from 'react-toastify'

import { gerarXlsx } from '../lib/gerarDocumento'
import { SQSConsumer } from '../lib/sqs'
import { XLSX_PROVIDER_NAME, XLSX_TOAST_ID } from '../config/stepFunction'

const exportXlsx = async ({ state, queueUrl }) => {
  const { globalStep, ...internalState } = state
  const document = globalStep?.targetDocument
  const discoveryStartedAt = globalStep?.metadata?.startedAt.toISOString()

  try {
    const { dismiss, value: name } = await Swal.fire({
      title: 'Exportar Dados',
      text:
        'As informações deste CNPJ/CPF serão exportadas para uma arquivo do excel ou zip',
      showCancelButton: true,
      icon: 'info',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off',
        placeholder: 'nome do documento'
      }
    })

    if (dismiss) {
      return { name: undefined, executionArn: undefined }
    }

    toast('Gerando Planilhas', {
      toastId: `${XLSX_TOAST_ID}`,
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: false
    })

    const consumer = SQSConsumer.getInstance()
    consumer.addProvider(XLSX_PROVIDER_NAME)
    const executionArn = await gerarXlsx({
      state: internalState,
      queueUrl,
      document,
      discoveryStartedAt
    })

    if (!consumer.running) {
      consumer.consume()
    }

    return { name, executionArn }
  } catch (err) {
    console.error(err)
    toast.error('Erro ao gerar planilhas!', {
      position: toast.POSITION.BOTTOM_RIGHT
    })
    toast.dismiss(`${XLSX_TOAST_ID}`)
  }

  return { name: undefined, executionArn: undefined }
}

export default exportXlsx

import React from 'react'

const DetailsPartes = props => {
  const { processo } = props

  const partes = React.useMemo(() => {
    const ativaTemp = processo.filter(item => item.tipo === 'ATIVA')
    const passivoTemp = processo.filter(item => item.tipo === 'PASSIVA')

    const ativoAdv = processo.filter(
      item => item.tipo === 'ADVOGADO_PARTE_ATIVA'
    )
    const passivoAdv = processo.filter(
      item => item.tipo === 'ADVOGADO_PARTE_PASSIVA'
    )

    return {
      ativa: ativaTemp,
      passivo: passivoTemp,
      ativo_adv: ativoAdv,
      passivo_adv: passivoAdv
    }
  }, [processo])

  return (
    <div className='row'>
      <div className='col-md-6 col-12'>
        {processo && (
          <>
            <p className='text-primary mt-4 mb-0' style={{ fontSize: 24 }}>
              Parte Ativa
            </p>
            <div className='parte-title'>Parte Ativa</div>

            <ul className='parte-content'>
              {partes &&
                partes.ativa &&
                partes.ativa.length > 0 &&
                partes.ativa.map((parte, key) => {
                  return (
                    <li className='parte-card parte-card-right' key={key}>
                      {parte.nome}
                    </li>
                  )
                })}
            </ul>

            <div className='parte-divisor' />

            <div className='parte-title'>Advogado - Parte Ativa</div>

            <ul className='parte-content'>
              {partes &&
                partes.ativo_adv &&
                partes.ativo_adv.length > 0 &&
                partes.ativo_adv.map((parte, key) => {
                  return (
                    <li className='parte-card parte-card-right' key={key}>
                      {parte.nome}
                    </li>
                  )
                })}
            </ul>
          </>
        )}
      </div>

      <div className='col-md-6 col-12'>
        {processo && (
          <>
            <p className='text-primary mt-4 mb-0' style={{ fontSize: 24 }}>
              Parte Passiva
            </p>
            <div className='parte-title'>Parte Passiva</div>

            <ul className='parte-content'>
              {partes &&
                partes.passivo &&
                partes.passivo.length > 0 &&
                partes.passivo.map((parte, key) => {
                  return (
                    <li className='parte-card parte-card-left' key={key}>
                      {parte.nome}
                    </li>
                  )
                })}
            </ul>

            <div className='parte-divisor' />

            <div className='parte-title'>Advogado - Parte Passivo</div>

            <ul className='parte-content'>
              {partes &&
                partes.passivo_adv &&
                partes.passivo_adv.length > 0 &&
                partes.passivo_adv.map((parte, key) => {
                  return (
                    <li className='parte-card parte-card-left' key={key}>
                      {parte.nome}
                    </li>
                  )
                })}
            </ul>
          </>
        )}
      </div>
    </div>
  )
}

export default DetailsPartes

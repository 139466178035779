import React, { useState } from 'react'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import { useSelector } from 'react-redux'
import Popup from 'reactjs-popup'

import { mapWithRelationship } from '../../../lib/detectMatch'

import { selectMinData } from '../../../redux/selectors/dadosBasicos'
import { selectRelateds } from '../../../redux/selectors/relateds'

import {
  formatDocument,
  hashObject,
  onlyNumbers,
  isCypheredDocument,
  formatDate
} from '../../../utils'

import { Table } from '../../../components'
import { onSortDocument } from '../../../components/Table/sort'

const { SearchBar } = Search

export default function PessoasFisicasRelacionadas ({ data, error }) {
  const { targetCase } = useSelector(selectMinData)
  const relateds = useSelector(selectRelateds)
  const [empresasPertencentes, setEmpresasPertencentes] = useState({
    data: [],
    open: false
  })
  if (error) {
    return <div>Erro no carregamento dos dados</div>
  }

  const pessoasFisicasRelacionadas =
    data?.pessoasFisicasRelacionadas.relateds || []

  if (pessoasFisicasRelacionadas.length === 0) {
    return <div />
  }

  const tableData = pessoasFisicasRelacionadas || []

  const tableSchema = [
    {
      dataField: 'document',
      text: 'Doc.',
      sort: true,
      sortFunc: onSortDocument
    },
    {
      dataField: 'nome',
      text: 'Nome',
      sort: true
    },
    {
      dataField: 'relationship',
      text: 'Grau',
      sort: true
    },
    {
      dataField: 'empresasPertencentes',
      text: 'Empresa',
      sort: true
    },
    {
      dataField: 'rawDocumento',
      hidden: true
    }
  ]

  const columns = tableSchema.map(item => ({
    ...item,
    align: 'center',
    headerAlign: 'center'
  }))

  const mapOutrosSocios = array =>
    array.map(item => {
      const id = hashObject(item)

      const empresas = item.empresasPertencentes || []
      return {
        id,
        ...item,
        nome: item.name,
        document: isCypheredDocument(item.document) ? (
          item.document
        ) : item.document ? (
          <a
            href={`?documento=${onlyNumbers(
              item.document
            )}&casos=${targetCase.join(';')}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            {' '}
            {formatDocument(item.document)}
          </a>
        ) : (
          '-'
        ),
        empresasPertencentes:
          empresas.length === 0 ? null : (
            <button
              className='btn btn-primary'
              disabled={empresas.length === 0}
              onClick={() =>
                setEmpresasPertencentes({
                  data: empresas,
                  open: true
                })
              }
            >
              Ver {empresas.length}
            </button>
          ),
        tipoSocio: item.identificadorSocio,
        dataEntradaSociedade: formatDate(item.dataEntradaSociedade),
        qualificacaoSocio: item.qualificacaoSocio,
        rawDocumento: isCypheredDocument(item.document)
          ? item.document
          : formatDocument(item.document)
      }
    })

  const mappedData = mapWithRelationship({
    array: mapOutrosSocios(tableData),
    documentKey: 'rawDocumento',
    nameKey: 'nome',
    relateds
  })

  return (
    <>
      <PopupEmpresas
        empresas={empresasPertencentes.data}
        open={empresasPertencentes.open}
        onClose={() =>
          setEmpresasPertencentes({
            data: [],
            open: false
          })
        }
      />
      <ToolkitProvider keyField='id' data={mappedData} columns={columns} search>
        {props => (
          <>
            <div className='row mb-2'>
              <div className='col-12 text-lg-right'>
                <SearchBar {...props.searchProps} placeholder='Pesquisar' />
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <Table {...props.baseProps} enablePagination />
              </div>
            </div>
          </>
        )}
      </ToolkitProvider>
    </>
  )
}

const PopupEmpresas = ({ empresas, open, onClose }) => {
  return (
    <Popup
      closeOnDocumentClick
      modal
      nested
      contentStyle={{ maxHeight: '90vh', width: '95%', overflowY: 'scroll' }}
      open={open}
      onClose={onClose}
    >
      <div className='mx-2 my-2'>
        <div style={{ width: '100%', display: 'flex' }} className='mb-3'>
          <span style={{ flex: 1 }}>Empresas pertencentes</span>
          <button
            style={{
              cursor: 'pointer',
              padding: '2px 5px',
              lineHeight: '20px',
              right: '-10px',
              top: '-10px',
              fontSize: '24px',
              background: '#ffffff',
              borderRadius: '18px',
              border: '1px solid #cfcece'
            }}
            onClick={onClose}
          >
            X
          </button>
        </div>
        <table className='table table-sm table-striped col-12'>
          <thead>
            <tr>
              <th scope='col'>Nome</th>
              <th scope='col'>CNPJ</th>
              <th scope='col'>Qualificação</th>
              <th scope='col'>Data entrada</th>
            </tr>
          </thead>
          <tbody>
            {(empresas || []).map((item, key) => {
              const id = hashObject({ item, key })
              return (
                <tr key={id}>
                  <td>{item.razaoSocial}</td>
                  <td>{formatDocument(item.cnpj)}</td>
                  <td>{item.qualificacaoSocio}</td>
                  <td>{formatDate(item.dataEntradaSociedade)}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </Popup>
  )
}

import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { selectMinData } from '../../redux/selectors/dadosBasicos'
import { fetchBandeiraAmarelaFromS3 } from '../../lib/s3Theme'

import '../../assets/sass/cards.scss'

import { Content, Loading } from '../../components'
import {
  AcordosLeniencia,
  ListaProibicaoEua,
  AutoInfracao,
  CadastroExpulsoes,
  EntidadesPrivadasSemFinsLucrativos,
  Pep,
  TermoApreensao,
  TermoEmbargo,
  TermoSuspensao,
  ExpulsoesDaAdministracaoFederal,
  EmpresasInidoneasESuspensas,
  EmpresasPunidas
} from './components'

const BandeiraAmarela = () => {
  const { targetName, targetDocument } = useSelector(selectMinData)
  const bandeiraAmarelaTheme = useSelector(state => state.bandeiraAmarelaTheme)

  const isStepPending = targetDocument && !bandeiraAmarelaTheme.pep.loaded

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({
    pep: { data: [], loaded: false, error: false },
    acordosLeniencia: { data: [], loaded: false, error: false },
    autoInfracao: { data: [], loaded: false, error: false },
    cepim: { data: [], loaded: false, error: false },
    ofac: { data: [], loaded: false, error: false },
    termoApreensao: { data: [], loaded: false, error: false },
    termoEmbargo: { data: [], loaded: false, error: false },
    termoSuspensao: { data: [], loaded: false, error: false },
    cadastroExpulsoes: { data: [], loaded: false, error: false },
    cnep: { data: [], loaded: false, error: false },
    ceis: { data: [], loaded: false, error: false },
    ceaf: { data: [], loaded: false, error: false }
  })

  useEffect(() => {
    const isLoaded =
      bandeiraAmarelaTheme.pep.loaded &&
      bandeiraAmarelaTheme.termoApreensao.loaded &&
      bandeiraAmarelaTheme.acordosLeniencia.loaded &&
      bandeiraAmarelaTheme.autoInfracao.loaded &&
      bandeiraAmarelaTheme.cepim.loaded &&
      bandeiraAmarelaTheme.ofac.loaded &&
      bandeiraAmarelaTheme.termoApreensao.loaded &&
      bandeiraAmarelaTheme.termoEmbargo.loaded &&
      bandeiraAmarelaTheme.termoSuspensao.loaded &&
      bandeiraAmarelaTheme.cadastroExpulsoes.loaded &&
      bandeiraAmarelaTheme.cnep.loaded &&
      bandeiraAmarelaTheme.ceis.loaded &&
      bandeiraAmarelaTheme.ceaf.loaded

    if (!isLoaded) {
      return
    }

    setLoading(true)

    fetchBandeiraAmarelaFromS3({
      pepPayload: bandeiraAmarelaTheme.pep,
      acordosLenienciaPayload: bandeiraAmarelaTheme.acordosLeniencia,
      autoInfracaoPayload: bandeiraAmarelaTheme.autoInfracao,
      cepimPayload: bandeiraAmarelaTheme.cepim,
      ofacPayload: bandeiraAmarelaTheme.ofac,
      termoApreensaoPayload: bandeiraAmarelaTheme.termoApreensao,
      termoEmbargoPayload: bandeiraAmarelaTheme.termoEmbargo,
      termoSuspensaoPayload: bandeiraAmarelaTheme.termoSuspensao,
      cadastroExpulsoesPayload: bandeiraAmarelaTheme.cadastroExpulsoes,
      cnepPayload: bandeiraAmarelaTheme.cnep,
      ceisPayload: bandeiraAmarelaTheme.ceis,
      ceafPayload: bandeiraAmarelaTheme.ceaf
    })
      .then(bandeiraAmarela => {
        setData(bandeiraAmarela)
      })
      .catch(error => {
        toast.error(error.message)
        console.error(error)
        setData({
          pep: {
            data: [],
            loaded: false,
            error: true
          },
          acordosLeniencia: {
            data: [],
            loaded: false,
            error: true
          },
          autoInfracao: {
            data: [],
            loaded: false,
            error: true
          },
          cepim: {
            data: [],
            loaded: false,
            error: true
          },
          ofac: {
            data: [],
            loaded: false,
            error: true
          },
          termoApreensao: {
            data: [],
            loaded: false,
            error: true
          },
          termoEmbargo: {
            data: [],
            loaded: false,
            error: true
          },
          termoSuspensao: {
            data: [],
            loaded: false,
            error: true
          },
          cadastroExpulsoes: {
            data: [],
            loaded: false,
            error: true
          },
          cnep: {
            data: [],
            loaded: false,
            error: true
          },
          ceis: {
            data: [],
            loaded: false,
            error: true
          },
          ceaf: {
            data: [],
            loaded: false,
            error: true
          }
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }, [bandeiraAmarelaTheme])

  return (
    <Content
      title={`Pontos de atenção ${targetName ? `- ${targetName}` : ''}`}
      subtitle=''
    >
      {loading || isStepPending ? (
        <Loading />
      ) : (
        <>
          <Pep
            data={data.pep.data}
            loaded={data.pep.loaded}
            error={data.pep.error}
          />
          <ExpulsoesDaAdministracaoFederal
            data={data.ceaf.data}
            loaded={data.ceaf.loaded}
            error={data.ceaf.error}
          />
          <EmpresasInidoneasESuspensas
            data={data.ceis.data}
            loaded={data.ceis.loaded}
            error={data.ceis.error}
          />
          <EmpresasPunidas
            data={data.cnep.data}
            loaded={data.cnep.loaded}
            error={data.cnep.error}
          />
          <AcordosLeniencia
            data={data.acordosLeniencia.data}
            loaded={data.acordosLeniencia.loaded}
            error={data.acordosLeniencia.error}
          />
          <AutoInfracao
            data={data.autoInfracao.data}
            loaded={data.autoInfracao.loaded}
            error={data.autoInfracao.error}
          />
          <EntidadesPrivadasSemFinsLucrativos
            data={data.cepim.data}
            loaded={data.cepim.loaded}
            error={data.cepim.error}
          />
          <TermoApreensao
            data={data.termoApreensao.data}
            loaded={data.termoApreensao.loaded}
            error={data.termoApreensao.error}
          />
          <TermoEmbargo
            data={data.termoEmbargo.data}
            loaded={data.termoEmbargo.loaded}
            error={data.termoEmbargo.error}
          />
          <TermoSuspensao
            data={data.termoSuspensao.data}
            loaded={data.termoSuspensao.loaded}
            error={data.termoSuspensao.error}
          />
          <CadastroExpulsoes
            data={data.cadastroExpulsoes.data}
            loaded={data.cadastroExpulsoes.loaded}
            error={data.cadastroExpulsoes.error}
          />
          <ListaProibicaoEua
            data={data.ofac.data}
            loaded={data.ofac.loaded}
            error={data.ofac.error}
          />
        </>
      )}
    </Content>
  )
}

export default BandeiraAmarela

import { createSelector } from 'reselect'
import { toCamelCase } from '../../utils'

const invalidTokens = ['DA', 'DE', 'DI', 'DO', 'DU', 'E', 'I', 'O', 'U']

const getGrupoSocietario = state => state.grupoSocietario
const getDadosCadastrais = state => state.dadosCadastrais
const getGlobalStep = state => state.globalStep
const getPh3a = state => state.ph3a

export const selectEmpresasCorrelacionadas = createSelector(
  getGrupoSocietario,
  getPh3a,
  (grupoSocietario, ph3a) => {
    if (
      !ph3a?.data?.correlatedCompanies?.header ||
      !grupoSocietario?.data?.grupoSocietario?.pessoasJuridicas
    ) {
      return []
    }

    const correlatedCompanies = (
      ph3a.data.correlatedCompanies?.header || []
    ).map(item => {
      const key = toCamelCase(item.name.replace(/ /g, '_'))
      const data = ph3a.data.correlatedCompanies.data[key]
      return { ...item, data }
    })
    const corrComp = correlatedCompanies.map(item => {
      return { ...item, document: String(item.document).padStart(14, 0) }
    })
    const pessoasJuridicas =
      grupoSocietario.data.grupoSocietario.pessoasJuridicas
    const documentosPJ = pessoasJuridicas.map(item => item.documento)
    const corrComFiltered = corrComp
      .filter(item => !documentosPJ.includes(item.document))
      .map(({ data, ...item }) => {
        return {
          ...item,
          correlatedPerson: {
            name: data?.name,
            document: data?.documentFormatted?.replace(/\D/g, '')
          }
        }
      })

    return corrComFiltered
  }
)

export const selectParentes = createSelector(
  getDadosCadastrais,
  getPh3a,
  (dadosCadastrais, ph3a) => {
    const isCPF = dadosCadastrais?.documento?.length === 11

    if (!isCPF) {
      return ph3a.data.relateds
    }

    const pessoasFisicas = isCPF
      ? [
          {
            ...(dadosCadastrais?.data?.pessoaFisica || {}),
            documento: (dadosCadastrais.data.pessoaFisica?.documento || '')
              .replace(/\D/g, '')
              .replace(/\d{3}(\d{6})\d{2}/, '***$1**')
          }
        ]
      : (dadosCadastrais.data.socios || [])
          .filter(socio => socio.identificadorSocio === 'PESSOA FÍSICA')
          .map(item => ({
            ...item,
            nome: item.nomeRazaoSocial,
            documento: item.cnpjCpfSocio
          }))

    const sobrenomes = (isCPF
      ? [dadosCadastrais.data.pessoaFisica]
      : (dadosCadastrais.data.socios || [])
          .filter(socio => socio.identificadorSocio === 'PESSOA FÍSICA')
          .map(socio => ({ ...socio, nome: socio.nomeRazaoSocial }))
    )
      .filter(Boolean)
      .map(pessoa => {
        const allTokens = pessoa.nome
          .trim()
          .toUpperCase()
          .split(' ')
        const lastNames = allTokens
          .slice(1, allTokens.length)
          .filter(token => !invalidTokens.includes(token))

        return lastNames
      })
      .flat()

    const filteredParents = (ph3a?.data?.relateds || [])
      .filter(item => {
        const ph3aDocument = item.documentFormatted
          .replace(/\D/g, '')
          .replace(/\d{3}(\d{6})\d{2}/, '***$1**')

        const match = pessoasFisicas.find(
          p =>
            p.documento === ph3aDocument &&
            p.nome.trim().toUpperCase() === item.name.trim().toUpperCase()
        )
        return !match
      })
      .filter(item => {
        const allTokens = item.name
          .trim()
          .toUpperCase()
          .split(' ')
        const lastNames = allTokens
          .slice(1, allTokens.length)
          .filter(token => !invalidTokens.includes(token))
        const match = lastNames.find(sobrenome =>
          sobrenomes.includes(sobrenome)
        )

        return match
      })

    return filteredParents
  }
)

export const selectMinData = createSelector(getGlobalStep, globalStep => {
  const targetDocument = globalStep?.targetDocument
  const targetCase = globalStep?.targetCase
  const isCPF = targetDocument?.length === 11

  const targetName = globalStep.targetName
  return {
    targetName: targetName,
    targetDocument,
    targetCase,
    isCPF
  }
})

import { differenceInMinutes } from 'date-fns'
import { AWS_IDENTITY_POOL, AWS_REGION, BUCKET_NAME } from '../config/env'
import { getScope, getUserId, hashObject } from '../utils'
import S3Client from './s3'

const toDateISOString = date => {
  return date.toISOString().split('T')[0]
}

export const MIN_DELAY_TO_REQUEST_RECENT_SEARCHES_IN_MINUTES = 15
const RECENT_SEARCHES_KEY = 'recentSearches4'

export default class RecentSearches {
  static getMetadata () {
    const { userId, scope } = RecentSearches._getFromLocalStorage()
    return { userId, scope }
  }

  static getMyRecentSearches () {
    const events = RecentSearches.getRecentSearches()
    const user = getUserId()?.toString()

    return events.filter(item => item.user === user)
  }

  static getRecentSearches () {
    const dataFromLocalStorage = RecentSearches._getDataFromLocalStorage()

    return dataFromLocalStorage.sort((a, b) => b.timestamp - a.timestamp)
  }

  static async updateRecentSearches () {
    const now = new Date()

    const { isUpdated, data } = await RecentSearches._fetchRecentSearches()

    const scope = getScope()
    const user = getUserId()?.toString()

    if (isUpdated) {
      RecentSearches._storeRecentSearches({
        updatedAt: now,
        data,
        userId: user,
        scope: scope
      })
    }

    return data
  }

  static put ({ id, document, casos, name }) {
    const scope = getScope()
    const user = getUserId()?.toString()

    const now = new Date()
    const timestamp = now.toISOString()

    const { data } = RecentSearches._getFromLocalStorage()
    const updatedAt = RecentSearches.getUpdatedAtFromLocalStorage()
    const newData = [
      ...data,
      { id, timestamp, document, casos, user, origin: 'custom', name }
    ]

    localStorage.setItem(
      RECENT_SEARCHES_KEY,
      JSON.stringify({ updatedAt, data: newData, userId: user, scope })
    )

    return { id, timestamp, document, scope, userId: user, origin: 'custom' }
  }

  static async _fetchRecentSearches () {
    const now = new Date()

    const dataFromLocalStorage = RecentSearches._getDataFromLocalStorage()
    const updatedAt = RecentSearches.getUpdatedAtFromLocalStorage()

    const isUpdateRequired =
      !updatedAt ||
      differenceInMinutes(now, updatedAt) >
        MIN_DELAY_TO_REQUEST_RECENT_SEARCHES_IN_MINUTES

    let recentSearches = [...dataFromLocalStorage]
    if (isUpdateRequired) {
      const dataFromApi = await RecentSearches._getFromApi()
      const idsFromApi = dataFromApi.map(item => item.id)

      const customItems = dataFromLocalStorage.filter(
        item => item.origin === 'custom' && !idsFromApi.includes(item.id)
      )

      recentSearches = [...customItems, ...dataFromApi]
    }

    const documentsWithName = new Map(
      recentSearches
        .filter(item => item.name?.length > 0)
        .map(item => [item.document, item.name])
    )

    const mappedRecentSearches = recentSearches.map(item => {
      const name = documentsWithName.get(item.document)
      return { ...item, name }
    })

    return {
      isUpdated: isUpdateRequired,
      data: mappedRecentSearches
    }
  }

  static resetLocalStorage () {
    localStorage.removeItem(RECENT_SEARCHES_KEY)
  }

  static _getDataFromLocalStorage () {
    const { data } = RecentSearches._getFromLocalStorage()
    return data
  }

  static getUpdatedAtFromLocalStorage () {
    const { updatedAt } = RecentSearches._getFromLocalStorage()
    return typeof updatedAt === 'string' ? new Date(updatedAt) : updatedAt
  }

  static _storeRecentSearches (jsonData) {
    const rawData = JSON.stringify(jsonData)
    localStorage.setItem(RECENT_SEARCHES_KEY, rawData)
  }

  static _getFromLocalStorage () {
    const rawData =
      localStorage.getItem(RECENT_SEARCHES_KEY) ||
      '{"updatedAt": null, "data":[], "userId": null, "scope": null}'
    const jsonData = JSON.parse(rawData)
    const arrayData = jsonData.data.map(item => ({
      ...item,
      timestamp: new Date(item.timestamp)
    }))

    return { ...jsonData, data: arrayData }
  }

  static async _getFromApi () {
    const s3Client = S3Client({
      region: AWS_REGION,
      identityPoolId: AWS_IDENTITY_POOL
    })

    const dataFromApi = await s3Client.readFile({
      bucket: BUCKET_NAME,
      key: 'logs-discovery/discovery/rolling/json'
    })

    const scope = getScope()

    return dataFromApi
      .filter(item => item.scope === scope)
      .map(item => {
        const timestamp = new Date(item.timestamp)
        return {
          ...item,
          timestamp: timestamp,
          origin: 'api',
          id:
            item.id ??
            hashObject({
              user: item.user,
              document: item.document,
              date: toDateISOString(timestamp)
            })
        }
      })
  }
}

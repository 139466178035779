import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import { useSelector } from 'react-redux'

import { JiveWindow, Table } from '../../../components'
import { mapWithRelationship } from '../../../lib/detectMatch'
import { selectRelateds } from '../../../redux/selectors/relateds'
import {
  formatDocument,
  isCypheredDocument,
  isPending,
  onlyNumbers,
  hashObject,
  formatCurrency,
  factoryDate,
  formatDate
} from '../../../utils'

const { SearchBar } = Search

export default function TermoSuspensao ({ data, error, loaded }) {
  const relateds = useSelector(selectRelateds)

  const name = 'bandeiraAmarela.termoSuspensao'
  const title = 'Termo de Suspensão'

  if (isPending({ error, loaded })) {
    return (
      <JiveWindow title={title} name={name}>
        <div />
      </JiveWindow>
    )
  }

  if (error) {
    return (
      <JiveWindow title={title} name={name} defaultOpen>
        <div>Ops! Aconteceu um erro ao carregar os termos de suspensão</div>
      </JiveWindow>
    )
  }

  return (
    <JiveWindow title={title} name={name} showNote>
      {data.length > 0 && (
        <ToolkitProvider
          keyField='id'
          search
          columns={[
            { dataField: 'nomPessoaSuspensao', text: 'Nome' },
            {
              dataField: 'relationship',
              text: 'Grau'
            },
            { dataField: 'document', text: 'CPF / CNPJ', searchable: false },
            { dataField: 'rawDocument', text: '', hidden: true },
            { dataField: 'date', text: 'Data' },
            { dataField: 'statusFormulario', text: 'Situação' },
            { dataField: 'local', text: 'Local' },
            { dataField: 'desTad', text: 'Circunstâncias da suspensão' },
            { dataField: 'desJustificativa', text: 'Justificativa' }
          ].map(item => ({ ...item, align: 'center', headerAlign: 'center' }))}
          data={mapWithRelationship({
            array: data || [],
            documentKey: 'cpfCnpjPessoaSuspensao',
            nameKey: 'nomPessoaSuspensao',
            relateds
          }).map(item => {
            const id = hashObject(item)
            return {
              ...item,
              id,
              rawDocument: item.cpfCnpjPessoaSuspensao,
              document: isCypheredDocument(item.cpfCnpjPessoaSuspensao) ? (
                item.cpfCnpjPessoaSuspensao
              ) : (
                <a
                  href={`?documento=${onlyNumbers(
                    item.cpfCnpjPessoaSuspensao
                  )}`}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {formatDocument(item.cpfCnpjPessoaSuspensao)}
                </a>
              ),
              date: formatDate(factoryDate(item.datTad)),
              local: `${item.desLocalizacao} - ${item.nomMunicipio}/${item.sigUf}`,
              valor: formatCurrency(parseFloat(item.valorTad))
            }
          })}
        >
          {props => (
            <>
              <div className='row'>
                <div className='col-12 text-lg-right'>
                  <SearchBar {...props.searchProps} placeholder='Pesquisar' />
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <Table enablePagination {...props.baseProps} />
                </div>
              </div>
            </>
          )}
        </ToolkitProvider>
      )}
    </JiveWindow>
  )
}

import S3Client from './s3'
import { AWS_IDENTITY_POOL, AWS_REGION } from '../config/env'
import { toCamelCase } from '../utils'

const s3Client = S3Client({
  region: AWS_REGION,
  identityPoolId: AWS_IDENTITY_POOL
})

const TEMPLATE_PENDING_DATA = { data: [], loaded: false, error: false }
const TEMPLATE_ERROR_DATA = { data: [], loaded: false, error: true }

const clone = obj => Object.assign({}, obj)
const isPending = obj => obj.loaded === false && obj.error === false
const resolveFetch = async ({
  payload,
  selectedFields,
  templatePendingData,
  templateErrorData
}) => {
  const internalTemplatePendingData =
    templatePendingData || TEMPLATE_PENDING_DATA
  const internalTemplateErrorData = templateErrorData || TEMPLATE_ERROR_DATA

  const fields = selectedFields || payload.s3Path?.fields
  const mappedFields = fields?.map(field => toCamelCase(field))

  if (isPending(payload)) {
    if (!mappedFields) {
      return clone(internalTemplatePendingData)
    }

    return mappedFields.reduce((obj, field) => {
      obj[field] = clone(internalTemplatePendingData)
      return obj
    }, {})
  }

  if (payload.error) {
    if (!mappedFields) {
      return clone(internalTemplateErrorData)
    }
    return mappedFields.reduce((obj, field) => {
      obj[field] = clone(internalTemplateErrorData)
      return obj
    }, {})
  }

  return fetchThemeFromS3({ s3Path: payload.s3Path, selectedFields })
}

export const fetchThemeFromS3 = async ({ s3Path, selectedFields }) => {
  if (s3Path.key) {
    const key = s3Path.key
    const bucket = s3Path.bucketName
    const data = await s3Client.readFile({ bucket, key })

    return {
      data: toCamelCase(data),
      error: false,
      loaded: true
    }
  }

  const fields = selectedFields
    ? Object.keys(s3Path.fields).filter(field => selectedFields.includes(field))
    : Object.keys(s3Path.fields)

  return Promise.all(
    fields.map(async field => {
      const key = `${s3Path.prefix}${s3Path.fields[field]}/${s3Path.objectName}`
      const bucket = s3Path.bucketName
      const data = await s3Client.readFile({ bucket, key })

      return {
        [toCamelCase(field)]: {
          data: toCamelCase(data),
          loaded: true,
          error: false
        }
      }
    })
  ).then(responses => {
    return responses.reduce((obj, response) => {
      return {
        ...obj,
        ...response
      }
    }, {})
  })
}

export const fetchBandeiraAmarelaFromS3 = async ({
  pepPayload,
  acordosLenienciaPayload,
  autoInfracaoPayload,
  cepimPayload,
  ofacPayload,
  termoApreensaoPayload,
  termoEmbargoPayload,
  termoSuspensaoPayload,
  cadastroExpulsoesPayload,
  cnepPayload,
  ceisPayload,
  ceafPayload
}) => {
  const promises = []
  if (pepPayload) {
    promises.push(
      resolveFetch({ payload: pepPayload }).then(response => ({
        pep: response
      }))
    )
  }

  if (acordosLenienciaPayload) {
    promises.push(
      resolveFetch({ payload: acordosLenienciaPayload }).then(response => ({
        acordosLeniencia: response
      }))
    )
  }

  if (autoInfracaoPayload) {
    promises.push(
      resolveFetch({ payload: autoInfracaoPayload }).then(response => ({
        autoInfracao: response
      }))
    )
  }

  if (cepimPayload) {
    promises.push(
      resolveFetch({ payload: cepimPayload }).then(response => ({
        cepim: response
      }))
    )
  }

  if (ofacPayload) {
    promises.push(
      resolveFetch({ payload: ofacPayload }).then(response => ({
        ofac: response
      }))
    )
  }

  if (termoApreensaoPayload) {
    promises.push(
      resolveFetch({ payload: termoApreensaoPayload }).then(response => ({
        termoApreensao: response
      }))
    )
  }

  if (termoEmbargoPayload) {
    promises.push(
      resolveFetch({ payload: termoEmbargoPayload }).then(response => ({
        termoEmbargo: response
      }))
    )
  }

  if (termoSuspensaoPayload) {
    promises.push(
      resolveFetch({ payload: termoSuspensaoPayload }).then(response => ({
        termoSuspensao: response
      }))
    )
  }

  if (cadastroExpulsoesPayload) {
    promises.push(
      resolveFetch({ payload: cadastroExpulsoesPayload }).then(response => ({
        cadastroExpulsoes: response
      }))
    )
  }

  if (cnepPayload) {
    promises.push(
      resolveFetch({ payload: cnepPayload }).then(response => ({
        cnep: response
      }))
    )
  }

  if (ceisPayload) {
    promises.push(
      resolveFetch({ payload: ceisPayload }).then(response => ({
        ceis: response
      }))
    )
  }

  if (ceafPayload) {
    promises.push(
      resolveFetch({ payload: ceafPayload }).then(response => ({
        ceaf: response
      }))
    )
  }

  const result = await Promise.all(promises)

  return result.reduce((obj, item) => {
    return {
      ...obj,
      ...item
    }
  }, {})
}

export const fetchDadosBasicosAnalyticsFromS3 = async ({ payload }) => {
  const data = await resolveFetch({ payload })

  return data
}

export const fetchEndividamentoAnalyticsFromS3 = async ({ payload }) => {
  const endividamento = await resolveFetch({ payload })

  return endividamento
}

export const fetchOffshoreAnalyticsFromS3 = async ({ payload }) => {
  const offshore = await resolveFetch({ payload })

  return offshore
}

export const fetchPatrimonioAnalyticsFromS3 = async ({
  payloadPatrimonio,
  payloadJive
}) => {
  const [patrimonio, jive] = await Promise.all([
    resolveFetch({ payload: payloadPatrimonio }),
    resolveFetch({ payload: payloadJive })
  ])

  return {
    data: {
      patrimonio: {
        ...patrimonio.data
      },
      jive: {
        ...jive.data
      }
    },
    loaded: patrimonio.loaded && jive.loaded,
    error: patrimonio.error || jive.error
  }
}

export const fetchOperacionaisAnalyticsFromS3 = async ({
  payloadOperacionais,
  payloadJive
}) => {
  const [operacionais, jive] = await Promise.all([
    resolveFetch({ payload: payloadOperacionais }),
    resolveFetch({ payload: payloadJive })
  ])

  return {
    data: {
      operacionais: {
        ...operacionais.data
      },
      jive: {
        ...jive.data
      }
    },
    loaded: operacionais.loaded && jive.loaded,
    error: operacionais.error || jive.error
  }
}

export const fetchJuridicoAnalyticsFromS3 = async ({ payload }) => {
  const [juridico] = await Promise.all([resolveFetch({ payload })])

  return juridico
}

export const fetchOperacionaisFromS3 = async ({ payload }) => {
  const {
    funcionarios,
    exfuncionarios: exFuncionarios,
    matchFuncionarios
  } = await resolveFetch({
    payload,
    selectedFields: ['funcionarios', 'exfuncionarios', 'matchFuncionarios']
  })
  return { funcionarios, exFuncionarios, matchFuncionarios }
}

export const fetchDevedoresJiveFromS3 = async ({ payload }) => {
  const devedores = await resolveFetch({ payload })

  return { devedores }
}

export const fetchEndividamentoFromS3 = async ({
  chequePayload,
  cndtPayload,
  pgfnPayload,
  protestoPayload
}) => {
  const formatProtesto = protesto => {
    const { data, ...remain } = protesto

    const cartorios = (data?.cartorio || [])
      .map(item => {
        return item.dadosCartorio.map(i => {
          const totalProtestado = (i.titulos || [])
            .map(t => {
              const valor = (t.valorProtestado || '0')
                .replace(/\./g, '')
                .replace(/,/g, '.')
              return valor
            })
            .reduce((acc, val) => acc + parseFloat(val), 0)
          return { ...i, totalProtestado, uf: item.uf }
        })
      })
      .flat()

    const totalProtestado = cartorios
      .map(c => c.totalProtestado)
      .reduce((acc, val) => acc + val, 0)

    const formatted = {
      ...remain,
      ...data,
      totalProtestado,
      cartorio: cartorios
    }

    return formatted
  }

  const templatePendingData = {
    data: { target: [], group: [] },
    loaded: false,
    error: false
  }
  const templateErrorData = {
    data: { target: [], group: [] },
    loaded: false,
    error: true
  }

  const result = await Promise.all([
    resolveFetch({
      payload: chequePayload,
      templatePendingData,
      templateErrorData
    }),
    resolveFetch({
      payload: cndtPayload,
      templatePendingData,
      templateErrorData
    }),
    resolveFetch({
      payload: pgfnPayload,
      selectedFields: [
        'pgfnFgts',
        'pgfnPrevidenciario',
        'pgfnNaoPrevidenciario'
      ],
      templatePendingData,
      templateErrorData
    }),
    resolveFetch({
      payload: protestoPayload,
      templatePendingData,
      templateErrorData
    })
  ])

  const [cheque, cndt, pgfn, protesto] = result

  return {
    cheque,
    cndt,
    ...pgfn,
    protesto: {
      ...protesto,
      data: {
        group: (protesto.data?.group || []).map(item => formatProtesto(item)),
        target: (protesto.data?.target || []).map(item => formatProtesto(item))
      }
    }
  }
}

export const fetchRegistraisJiveFromS3 = async ({ payload }) => {
  const registrais = await resolveFetch({ payload })

  return { registrais }
}

export const fetchPatrimonioFromS3 = async ({
  aeronavesPayload,
  sncrPayload,
  inpiPayload,
  jiveApiPayload,
  jiveBotsPayload,
  operacionaisPayload
}) => {
  const promises = []

  promises.push(resolveFetch({ payload: aeronavesPayload }))
  promises.push(resolveFetch({ payload: sncrPayload }))
  promises.push(
    resolveFetch({
      payload: jiveApiPayload,
      selectedFields: ['ambiental', 'imoveisRurais', 'imoveis']
    })
  )
  promises.push(
    resolveFetch({
      payload: jiveBotsPayload,
      selectedFields: ['imoveisRurais', 'imoveisUrbanos']
    })
  )
  promises.push(
    resolveFetch({
      payload: inpiPayload,
      templatePendingData: { data: {}, loaded: false, error: false },
      templateErrorData: { data: {}, loaded: false, error: true }
    })
  )
  promises.push(
    resolveFetch({
      payload: operacionaisPayload,
      selectedFields: ['imoveis']
    })
  )
  const [
    aeronaves,
    sncr,
    jiveApi,
    jivebots,
    inpi,
    { imoveis: imoveisNeoway }
  ] = await Promise.all(promises)

  const mappedInpi = {
    ...inpi,
    data: Object.values(inpi.data).reduce(
      (
        obj,
        {
          inpiContratoTecnologia,
          inpiDesenhoIndustrial,
          inpiPatentes,
          inpiProgramaComputador,
          inpiRegistroMarcas,
          nome: nomeBuscado
        }
      ) => {
        obj.contratoTecnologia = obj.contratoTecnologia.concat(
          (inpiContratoTecnologia || []).map(item => ({ ...item, nomeBuscado }))
        )
        obj.desenhoIndustrial = obj.desenhoIndustrial.concat(
          (inpiDesenhoIndustrial || []).map(item => ({ ...item, nomeBuscado }))
        )
        obj.patentes = obj.patentes.concat(
          (inpiPatentes || []).map(item => ({ ...item, nomeBuscado }))
        )
        obj.programaComputador = obj.programaComputador.concat(
          (inpiProgramaComputador || []).map(item => ({ ...item, nomeBuscado }))
        )
        obj.registroMarcas = obj.registroMarcas.concat(
          (inpiRegistroMarcas || []).map(item => ({ ...item, nomeBuscado }))
        )

        return obj
      },
      {
        contratoTecnologia: [],
        desenhoIndustrial: [],
        patentes: [],
        programaComputador: [],
        registroMarcas: []
      }
    )
  }

  return {
    aeronaves,
    sncr,
    inpi: mappedInpi,
    ...jiveApi,
    jivebots,
    imoveisNeoway
  }
}

export const fetchDadosBasicosFromS3 = async ({ payload }) => {
  const { basicInfo } = await resolveFetch({
    payload,
    selectedFields: ['basicInfo']
  })

  return basicInfo
}

export const fetchGraphMetadataFromS3 = async ({ payload }) => {
  const s3Response = await fetchThemeFromS3({
    s3Path: payload.s3Path,
    selectedFields: ['graph']
  })

  return s3Response.graph.data
}

export const fetchOffshoreFromS3 = async ({
  offshoreLeaksPayload,
  jiveApiPayload
}) => {
  const offshoreLeaks = await resolveFetch({ payload: offshoreLeaksPayload })
  const jiveApi = await resolveFetch({
    payload: jiveApiPayload,
    selectedFields: [
      'capitalEstrangeiro',
      'empresasOffshore',
      'imoveisOffshore'
    ]
  })

  return { offshoreLeaks, ...jiveApi }
}

export const fetchDiscreditingMediaFromS3 = async ({ newsPayload }) => {
  const discreditingNews = await resolveFetch({ payload: newsPayload })

  return {
    discreditingNews
  }
}

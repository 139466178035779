import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { selectMinData } from '../../../redux/selectors/dadosBasicos'

import { fetchJuridicoAnalyticsFromS3 } from '../../../lib/s3Theme'

import {
  formatCurrency,
  formatDecimal,
  formatPercent,
  isPending
} from '../../../utils'

import { JiveWindow, Loading } from '../../../components'
import {
  Box,
  CategoriesPercentageListBox,
  ListBox,
  PercentageListBox,
  TotalBox
} from './Boxes'

const INITIAL_DATA_STATE = {
  quantidadePorClasseCnj: { total: 0, classes: [] },
  quantidadePorTipoAssunto: { total: 0, assuntos: [] },
  quantidadePorTipoJustica: { total: 0, justicas: [] },
  quantidadeTotal: {
    total: 0,
    parts: {
      active: {
        total: 0,
        percentage: 0,
        totalValue: 0
      },
      passive: {
        total: 0,
        percentage: 0,
        totalValue: 0
      },
      other: {
        total: 0,
        percentage: 0,
        totalValue: 0
      }
    }
  },
  status: {
    quantidadePorClasseCnj: 'PENDING',
    quantidadePorTipoAssunto: 'PENDING',
    quantidadePorTipoJustica: 'PENDING',
    quantidadeTotal: 'PENDING'
  }
}

export default function JuridicoQuantitativos () {
  const { targetDocument } = useSelector(selectMinData)
  const juridicoTheme = useSelector(state => state.analyticsTheme.juridico)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({
    data: INITIAL_DATA_STATE,
    loaded: false,
    error: false
  })

  useEffect(() => {
    const pending = isPending(juridicoTheme)
    if (pending) {
      return
    }

    setLoading(true)

    fetchJuridicoAnalyticsFromS3({
      payload: juridicoTheme
    })
      .then(juridico => {
        setData(juridico)
      })
      .catch(error => {
        toast.error(error.message)
        console.error(error)
        setData({
          data: {
            ...INITIAL_DATA_STATE,
            status: {
              quantidadePorClasseCnj: 'FAILED',
              quantidadePorTipoAssunto: 'FAILED',
              quantidadePorTipoJustica: 'FAILED',
              quantidadeTotal: 'FAILED'
            }
          },
          loaded: false,
          error: true
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }, [juridicoTheme])

  const isStepPending = targetDocument && isPending(juridicoTheme)

  if (loading || isStepPending) {
    return <Loading />
  }

  const name = 'quantitativos.juridico'

  return (
    <JiveWindow title='Jurídico' name={name} showNote defaultOpen>
      <div className='container-fluid gap-2'>
        <div className='row'>
          <div className='col-6'>
            <div className='row'>
              <QuantidadeTotalBox
                error={data.error}
                data={data.data.quantidadeTotal}
                status={data.data.status.quantidadeTotal}
              />
              <QuantidadePorClasseCnjBox
                error={data.error}
                data={data.data.quantidadePorClasseCnj}
                status={data.data.status.quantidadePorClasseCnj}
              />
            </div>
          </div>
          <div className='col-6'>
            <div className='row'>
              <QuantidadePorTipoJusticaBox
                error={data.error}
                data={data.data.quantidadePorTipoJustica}
                status={data.data.status.quantidadePorTipoJustica}
              />
              <QuantidadePorTipoAssuntoBox
                error={data.error}
                data={data.data.quantidadePorTipoAssunto}
                status={data.data.status.quantidadePorTipoAssunto}
              />
            </div>
          </div>
        </div>
      </div>
    </JiveWindow>
  )
}

const QuantidadeTotalBox = ({ error, data, status }) => {
  const title = 'Quantidade total que envolve a empresa'

  if (error || status === 'FAILED') {
    return (
      <Box title={title}>
        <div className='row'>
          <div>Erro no carregamento dos dados</div>
        </div>
      </Box>
    )
  }

  if (status === 'SKIPPED') {
    return (
      <Box title={title}>
        <div className='row'>
          <div>Dados não carregados para este tema</div>
        </div>
      </Box>
    )
  }

  const {
    total,
    parts: { active, passive, other }
  } = data

  const mappedParts = [
    {
      name: 'Total que a empresa é parte Ativa',
      total: formatCurrency(active.totalValue, null, null, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
      }),
      quantity: formatDecimal(active.total, null, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
      }),
      percentage: active.percentage,
      value: formatPercent(active.percentage, null, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
      })
    },
    {
      name: 'Total que a empresa é parte Passiva',
      total: formatCurrency(passive.totalValue, null, null, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
      }),
      quantity: formatDecimal(passive.total, null, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
      }),
      percentage: passive.percentage,
      value: formatPercent(passive.percentage, null, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
      })
    },
    {
      name: 'Total que a empresa é parte Outros',
      total: formatCurrency(other.totalValue, null, null, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
      }),
      quantity: formatDecimal(other.total, null, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
      }),
      percentage: other.percentage,
      value: formatPercent(other.percentage, null, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
      })
    }
  ]

  const formattedTotal = formatDecimal(total, null, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  })

  return (
    <Box title='Quantidade total que envolve a empresa'>
      <div className='row'>
        <TotalBox value={formattedTotal} />
      </div>
      <div className='row'>
        <PercentageListBox
          showTotal
          showPercentage={false}
          showQtd
          items={mappedParts}
        />
      </div>
      <div className='row'>
        <ListBox items={mappedParts} />
      </div>
    </Box>
  )
}

const QuantidadePorClasseCnjBox = ({ error, data, status }) => {
  const title = 'Quantidade por tipo de classe CNJ'

  if (error || status === 'FAILED') {
    return (
      <Box title={title}>
        <div className='row'>
          <div>Erro no carregamento dos dados</div>
        </div>
      </Box>
    )
  }

  if (status === 'SKIPPED') {
    return (
      <Box title={title}>
        <div className='row'>
          <div>Dados não carregados para este tema</div>
        </div>
      </Box>
    )
  }

  const { total, classes } = data

  const formattedTotal = formatDecimal(total, null, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  })

  const items = classes.map(classe => {
    const { active, passive } = classe.parts

    const mappedParts = [
      {
        name: 'Parte Ativa',
        total: formatCurrency(active.totalValue, null, null, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2
        }),
        quantity: formatDecimal(active.total, null, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2
        }),
        percentage: active.percentage,
        value: formatPercent(active.percentage, null, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2
        })
      },
      {
        name: 'Parte Passiva',
        total: formatCurrency(passive.totalValue, null, null, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2
        }),
        quantity: formatDecimal(passive.total, null, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2
        }),
        percentage: passive.percentage,
        value: formatPercent(passive.percentage, null, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2
        })
      }
    ]

    return {
      name: classe.name,
      subItems: mappedParts
    }
  })

  const mappedRegions = classes.map(region => {
    return {
      name: region.name,
      value: formatPercent(region.percentage, null, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
      })
    }
  })

  return (
    <Box title='Quantidade por tipo de classe CNJ'>
      <div className='row'>
        <TotalBox value={formattedTotal} />
      </div>
      <CategoriesPercentageListBox
        showTotal
        alternate
        showQtd
        showName={false}
        showPercentage={false}
        items={items}
        classNameItem='mt-1'
        classNameTitleRow=''
      />
      <div className='row'>
        <ListBox items={mappedRegions} />
      </div>
    </Box>
  )
}

const QuantidadePorTipoJusticaBox = ({ error, data, status }) => {
  const title = 'Quantidade total por tipo de justiça'

  if (error || status === 'FAILED') {
    return (
      <Box title={title}>
        <div className='row'>
          <div>Erro no carregamento dos dados</div>
        </div>
      </Box>
    )
  }

  if (status === 'SKIPPED') {
    return (
      <Box title={title}>
        <div className='row'>
          <div>Dados não carregados para este tema</div>
        </div>
      </Box>
    )
  }

  const { total, justicas } = data

  const formattedTotal = formatDecimal(total, null, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  })

  const items = justicas.map(region => {
    const { active, passive } = region.parts

    const mappedParts = [
      {
        name: 'Parte Ativa',
        total: formatCurrency(active.totalValue, null, null, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2
        }),
        quantity: formatDecimal(active.total, null, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2
        }),
        percentage: active.percentage,
        value: formatPercent(active.percentage, null, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2
        })
      },
      {
        name: 'Parte Passiva',
        total: formatCurrency(passive.totalValue, null, null, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2
        }),
        quantity: formatDecimal(passive.total, null, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2
        }),
        percentage: passive.percentage,
        value: formatPercent(passive.percentage, null, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2
        })
      }
    ]

    return {
      name: region.name,
      subItems: mappedParts
    }
  })

  const mappedRegions = justicas.map(region => {
    return {
      name: region.name,
      value: formatPercent(region.percentage, null, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
      })
    }
  })

  return (
    <Box title='Quantidade total por tipo de justiça'>
      <div className='row'>
        <TotalBox value={formattedTotal} />
      </div>
      <CategoriesPercentageListBox
        showTotal
        alternate
        showQtd
        showName={false}
        showPercentage={false}
        items={items}
        classNameItem='mt-1'
        classNameTitleRow=''
      />
      <div className='row'>
        <ListBox items={mappedRegions} />
      </div>
    </Box>
  )
}

const QuantidadePorTipoAssuntoBox = ({ error, data, status }) => {
  const title = 'Quantidade total por tipo de assunto'

  if (error || status === 'FAILED') {
    return (
      <Box title={title}>
        <div className='row'>
          <div>Erro no carregamento dos dados</div>
        </div>
      </Box>
    )
  }

  if (status === 'SKIPPED') {
    return (
      <Box title={title}>
        <div className='row'>
          <div>Dados não carregados para este tema</div>
        </div>
      </Box>
    )
  }

  const { total, assuntos } = data

  const formattedTotal = formatDecimal(total, null, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  })

  const items = assuntos.map(assunto => {
    const { active, passive } = assunto.parts

    const mappedParts = [
      {
        name: 'Parte Ativa',
        total: formatCurrency(active.totalValue, null, null, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2
        }),
        quantity: formatDecimal(active.total, null, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2
        }),
        percentage: active.percentage,
        value: formatPercent(active.percentage, null, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2
        })
      },
      {
        name: 'Parte Passiva',
        total: formatCurrency(passive.totalValue, null, null, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2
        }),
        quantity: formatDecimal(passive.total, null, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2
        }),
        percentage: passive.percentage,
        value: formatPercent(passive.percentage, null, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2
        })
      }
    ]

    return {
      name: assunto.name,
      subItems: mappedParts
    }
  })

  const mappedRegions = assuntos.map(region => {
    return {
      name: region.name,
      value: formatPercent(region.percentage, null, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
      })
    }
  })

  return (
    <Box title='Quantidade total por tipo de assunto'>
      <div className='row'>
        <TotalBox value={formattedTotal} />
      </div>
      <CategoriesPercentageListBox
        showTotal
        alternate
        showQtd
        showName={false}
        showPercentage={false}
        items={items}
        classNameItem='mt-1'
        classNameTitleRow=''
      />
      <div className='row'>
        <ListBox items={mappedRegions} />
      </div>
    </Box>
  )
}

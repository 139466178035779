import React, { useState } from 'react'
import Graph from 'react-graph-vis'
import { useResizeDetector } from 'react-resize-detector'
import { useDispatch } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import { FiZoomOut } from 'react-icons/fi'
import { AiFillStar } from 'react-icons/ai'

import * as GrafoActions from './../redux/actions/grafo'
import './../assets/sass/grafo.scss'

import optionsGrafoVis from './grafovisOptions'

const GrafoVis = ({ data, error, loaded }) => {
  const { options, ...graphData } = data || {}
  const [net, setNet] = useState({})
  const dispatch = useDispatch()
  const { height, ref } = useResizeDetector()

  const setNetworkTemp = net => {
    setNet({ network: net })
  }

  const rede = {
    nodes:
      graphData?.nodes?.map(item => ({
        ...item,
        group: item.group // toCamelCase(item.group)
      })) || [],
    edges: graphData?.edges || []
  }
  const graphGrafo = rede

  const events = {
    select: function ({ nodes }) {
      const PF_TYPES = ['estrangeiro', 'pessoaFísica']
      const TARGET_TYPE = 'pesquisado'

      const selectedNodes = nodes
        .map(node => rede.nodes[parseInt(node - 1)])
        .map(node => {
          if (node.group === TARGET_TYPE) {
            const nodeData = {
              document: node.metadata.cpfCnpj,
              name: node.label
            }
            return { node: node, data: nodeData, type: 'TARGET' }
          } else if (PF_TYPES.includes(node.group)) {
            const nodeData = {
              document: node.metadata.cpfCnpj,
              name: node.label
            }
            return { node: node, data: nodeData, type: 'PF' }
          } else {
            const nodeData = { document: node.metadata.cpfCnpj }
            return { node: node, data: nodeData, type: 'PJ' }
          }
        })

      if (
        !selectedNodes ||
        !Array.isArray(selectedNodes) ||
        selectedNodes.length === 0
      ) {
        return
      }

      dispatch(GrafoActions.setSelectedNodes({ nodes: selectedNodes }))
    }
  }

  if (!loaded && !error) {
    return <>Carregando</>
  }

  return error ? (
    <>
      <p>Falha ao carregar grafo</p>
    </>
  ) : (
    <div style={{ height: 500 }} ref={ref}>
      <ReactTooltip />
      <div className='btn-group' role='group' aria-label='Basic example'>
        <button
          type='button'
          className='btn btn-outline-primary btn-sm'
          data-tip='Nó Central'
          onClick={() => net.network.focus(1, { scale: 0.5 })}
        >
          <AiFillStar size={15} />
        </button>

        <button
          type='button'
          className='btn btn-outline-primary btn-sm'
          data-tip='Centralizar Rede'
          onClick={() => net.network.fit()}
        >
          <FiZoomOut size={15} />
        </button>
      </div>
      <Graph
        style={{ height: '100%' }}
        graph={graphGrafo}
        options={{
          ...options,
          height:
            height?.toString() ||
            options?.height?.toString() ||
            optionsGrafoVis?.height?.toString()
        }}
        events={events}
        getNetwork={setNetworkTemp}
      />
    </div>
  )
}

export default GrafoVis
